
<template>
  <!-- <div
    class="container circular"
    :style="{ backgroundImage: 'url(' + image + ')' }"
  > -->
  <div>
    <div :style="HeaderStyles">
      <header-login></header-login>
    </div>
    <!-- <div :style="HeaderStyles"><img :src="imageHeader" /></div> -->
    <!-- <h1>{{ windowHeight }}</h1> -->
    <div class="columns is-mobile">
      <div class="column">
        <login-form class="card-body"></login-form>
      </div>
    </div>

    <!-- <div class="columns is-desktop">
      <div class="column">1</div>
      <div class="column">2</div>
      <div class="column">3</div>
      <div class="column">4</div>
    </div> -->

    <div :style="HeaderStyles">
      <!-- <img :src="logoImageFooter" class="logo" /> -->
    </div>
  </div>
</template>
<script>
import LoginForm from "./LoginForm.vue";
import HeaderLogin from "./HeaderLogin.vue";

export default {
  components: {
    LoginForm,
    HeaderLogin,
  },
  data() {
    return {
      logoImageFooter: require("@/assets/images/logo-footer.png"),
    };
  },
  computed: {
    HeaderStyles() {
      return {
        "text-align": "center",
        // "background-image": 'url("' + this.imageHeader + '")',
        // height: "300px",
        // "vertical-align": "middle",
        "padding-top": "25px",
        "padding-bottom": "25px",
      };
    },
  },
};
</script>
<style scoped>
.circular {
  width: windowWidth;
  height: windowHeight;
  opacity: 0.7;
  background-size: cover;
  /* background-size: "100% auto"; */
  /* border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px; */
}
#app {
  height: 1000px;
}
.card-body {
  /* align-self: center; */
  width: 80%;
}

.logo {
  max-width: 300px;
  /* width: 150px; */
}

@media screen and (min-width: 600px) {
  .card-body {
    /* align-self: center; */
    width: 500px;
  }
}


@media screen and (min-width: 768px) {
  #id {
  /* background-image: url('/assets/images/background2.jpg') */
  }
}

@media screen and (min-width: 360px) {
  .card-body {
    /* align-self: center; */
    /* width: 80%; */
  }
}

</style>
<template>
  <!-- <div
    id="app"
    class="circular"
    :style="{ backgroundImage: 'url(' + image + ')' }"
  > -->

  <div class="is-marginless is-paddingless" id="app" :style="BGStyles">
    <!-- <p>Page width is {{ $store.getters.isLogin }} px</p> -->

    <!-- <div>
    <p>Page width is {{ $screen.width }} px</p>
    <p>Page height is {{ $screen.height }} px</p>
    <p>Current breakpoint is {{ $screen.breakpoint }} px</p>
  </div> -->

    <!-- <div class="container"> -->
    <!-- <img :src="image" > -->

    <!-- <div class="input-group">
        <div class="input-group-prepend"></div>
        <span class="input-group-text" v-text="max - message.length"> </span>
        <input
          type="text"
          class="form-control"
          v-model="message"
          :maxlength="max"
        />
      </div> -->

    <!-- <router-link to="/"></router-link>
      <router-link to="/RegisterPage"></router-link>
      <router-link to="/HomePage"></router-link> -->
    <router-view :style="templeteStyle"></router-view>
    <!-- </div> -->

    <!-- <login-page></login-page> -->
    <!-- <post-url></post-url> -->
    <!-- <PostURL /> -->
    <!-- <Register /> -->
    <!-- <Ex01Page /> -->
    <!-- <Ex02Page /> -->
    <!-- <Ex03Page /> -->

    <!-- วิธีเรียกใช้ข้อมูลจาก state -->
    <center>
      <!-- <h2>{{ $store.getters.token }}</h2> -->
    </center>
    <span v-html="HTMLcontent"></span>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
// import PostURL from "./components/PostURL.vue";
// import Register from "./components/Register.vue";
// import LoginPage from "./components/LoginPage.vue";
// import Ex01Page from "./components/ex01/Ex01Page.vue";
// import Ex02Page from "./components/ex02/Ex02Page.vue";
// import Ex03Page from "./components/ex03/Ex03Page.vue";

// import store ให้ทุกคนเรียกใช้ได้
import { store } from "./store/store";
// import router from './router'

// import FooterComponent from "./components/footer.vue";
// Vue.use(FooterComponent);

import VueCoreVideoPlayer from "vue-core-video-player";
Vue.use(VueCoreVideoPlayer);

// import { VueContext } from "vue-context";

import VueLuxon from "vue-luxon";
Vue.use(VueLuxon);

import VueSimpleContextMenu from "vue-simple-context-menu";
Vue.component("vue-simple-context-menu", VueSimpleContextMenu);

import CircleMenu from "vue-circle-menu";
Vue.component("CircleMenu", CircleMenu);

// import { VueContext } from 'vue-context';
// Vue.component('VueContext', VueContext)

export default Vue.extend({
  name: "App",
  store,
  components: {
    // VueContext,
    // PostURL,
    // Register,
    // LoginPage,
    // Ex01Page,
    // Ex02Page,
    // Ex03Page,
  },
  data() {
    return {
      message: "",
      max: 30,
      HTMLcontent: null,
      image: require("@/assets/images/background.jpg"),
      image_mobile: require("@/assets/images/background-mobile.jpg"),
      // windowHeight: window.innerHeight,
      windowHeight: "900",
      // windowHeight: document.body.scrollHeight,

      windowWidth: window.innerWidth,
    };
  },
  computed: {
    templeteStyle() {
      return {
        "--templete-color": this.$store.getters.templeteBgColor,
        "--templete-font-color": this.$store.getters.templeteFontColor,
      };
    },

    btnStyles() {
      return {
        // "background-color": this.bgColor,
        height: `${this.height}px`,
        // backgroundImage: URL(this.image),
        "background-image": this.image,
      };
    },
    BGStyles() {
      if (this.$store.getters.flag_CSS_BG_IMAGE) {
        if (window.innerWidth > 900) {
          return {
            // "background-color": this.$store.getters.templeteBgColor,
            height: `${this.windowHeight}px`,
            "background-image": 'url("' + this.image + '")',
            "background-size": "cover",
          };
        } else {
          return {
            // "background-color": this.$store.getters.templeteBgColor,
            height: `${this.windowHeight}px`,
            "background-image": 'url("' + this.image_mobile + '")',
            "background-size": "cover",
          };
        }
      } else {
        return {
          "background-color": this.$store.getters.templeteBgColor,
          height: `${this.windowHeight}px`,
          // "background-image": 'url("'+ this.image+'")',
          // "background-size": "cover",
          "background-size": "contain",
        };
      }
    },
  },
  created() {
    this.HTMLcontent = `
    <video-js id=vid1 width=600 height=300 class="vjs-default-skin" controls>
  <source
     src="https://example.com/index.m3u8"
     type="application/x-mpegURL">
</video-js>

	`;
  },
});
</script>
<style>
.circular {
  /* background-image: image ; */
  width: windowWidth;
  height: 100vh;
  /* height: 2000; */
  position: relative;
  /* width: 100%;
  height: 100%; */

  opacity: 1;
   /*background-size: contain;
  background-size: cover;
  /* border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px; */
}

#app {
  height: var(--windowHeight);
  /* height: 100vh;  */
  /* height: var(--windowHeight); */
}
</style>



// import QAService from "@/service/QAService";


export default {
    // namespaced : true,


    state: {
        channel: "",
        questionId: 0,
        steamAnswers: [],
        questionIdChat: 0,
        steamAnswersChat: [],

        counterViewStream: 0,


        counterQAMessagetotal: 0,
        counterChatMessagetotal: 0,

        showEmoji: false,
        questionIdSticker: 0,
        steamAnswersSticker:[],

    },
    // setter กำหนดค่า ข้อมูล ลง state(store)
    mutations: {

        // showEmoji bool
        SET_SHOW_EMOJI_STATUS(state, showEmoji) {
            state.showEmoji = showEmoji; // กำหนดค่า state 

        },

        // Q&A >> for speker
        SET_ANSWER(state, steamAnswers) {
            state.steamAnswers = steamAnswers; // กำหนดค่า state 
        },
        // chat room >> for admin 
        SET_ANSWER_CHAT(state, steamAnswersChat) {
            state.steamAnswersChat = steamAnswersChat; // กำหนดค่า state 
        },
        // sticker room >> for admin 
        SET_ANSWER_STICKER(state, steamAnswersSticker) {
            state.steamAnswersSticker = steamAnswersSticker; // กำหนดค่า state 
        },

        


        // for Q&A what's your question
        SET_QUESTION_ID_DEFAULT(state, questionId) {
            state.questionId = questionId; // กำหนดค่า state 
        },
        // for Chat 
        SET_QUESTION_ID_CHAT(state, questionIdChat) {
            state.questionIdChat = questionIdChat; // กำหนดค่า state 
        },

        // for Sticker 
        SET_QUESTION_ID_STICKER(state, questionIdSticker) {
            state.questionIdSticker = questionIdSticker; // กำหนดค่า state 
        },




        SET_CHANNEL(state, channel) {
            state.channel = channel; // กำหนดค่า state 
        },
        SET_COUUNTER_VIEW_STREAM(state, counterViewStream) {
            state.counterViewStream = counterViewStream; // กำหนดค่า state 
        },
        SET_COUNTER_QA_STREAM(state, counterQAMessagetotal) {
            state.counterQAMessagetotal = counterQAMessagetotal; // กำหนดค่า state 
        },
        SET_COUNTER_CHAT_STREAM(state, counterChatMessagetotal) {
            state.counterChatMessagetotal = counterChatMessagetotal; // กำหนดค่า state 
        },
        // SET_COUNTER_STICKER_STREAM(state, counterChatMessagetotal) {
        //     state.counterChatMessagetotal = counterChatMessagetotal; // กำหนดค่า state 
        // },




    },


    // actions: {
    //     async updateSteamAnswers(_, credentials) {
    //         console.log(credentials);

    //         // update question id 


    //         // update answer 
    //         QAService.getQuestionStream(this.state.channel, this.state.questionIdDefault, 1);




    //     }
    // },

    getters: {
        // ดึงข้อมูล user จาก store ใส่ state  
        // steamAnswers: state => state.steamAnswers,
        steamAnswers: state => state.steamAnswers.filter(
            (element) => element.enabled != false
        ),

        steamAnswersFull: state => state.steamAnswers,

        questionId: state => state.questionId,
        channel: state => state.channel,
        steamAnswersExculdeCancel: state => state.steamAnswers.filter(
            (element) => element.enabled != false
        ),
        questionIdChat: state => state.questionIdChat,

        questionIdSticker: state => state.questionIdSticker,


        // steamAnswersChat: state => state.steamAnswersChat,

        steamAnswersChat: state => state.steamAnswersChat.filter(
            (element) => element.enabled != false
        ),

        steamAnswersChatFull: state => state.steamAnswersChat,

        counterViewStream: state => state.counterViewStream,
        counterQAMessagetotal: state => state.counterQAMessagetotal,
        counterChatMessagetotal: state => state.counterChatMessagetotal,

        counterAllMessagetotal: state => state.counterChatMessagetotal + state.counterQAMessagetotal,

        showEmojiStatus: state => state.showEmoji,
        steamAnswersSticker: state => state.steamAnswersSticker,
    },




}
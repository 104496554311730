<template>
  <section>
    <b-sidebar
      type="is-light"
      :fullheight="fullheight"
      :fullwidth="fullwidth"
      :overlay="overlay"
      :right="right"
      v-model="open"
    >
      <div class="p-1">
        <b-menu>
          <b-menu-list label="Menu Poll">
       
           <!-- IsVoted :  {{isVoted}} -->

            <b-menu-item icon="information-outline" label="Info Poll question." @click="getQuestionPoll(channelCode)">
              <div v-if="!isVoted  && $store.getters.questionPoll.length > 0">
              <div v-for="item in questionPoll" :key="item.pollId">
                <div>
                  <div class="question fontPoll">Q.{{ item.question }}</div>
                  <div
                    v-for="item2 in choicePoll[item.pollId]"
                    :key="item2.choiceId"
                  >
                    <b-field class="fontPoll">
                      <b-radio
                        v-model="radioSelected[item.pollId]"
                        :native-value="item2.choiceId"
                        type="is-success"
                      >
                        <!-- {{ item2.description + " : " + item.pollId }} -->
                        {{ item2.description}}
                      </b-radio>
                    </b-field>
                  </div>
                </div>
              </div>

                            <p v-if="errors.length" class="is-danger">
                              <b>Please correct the following error(s):</b>
                              <ul>
                                <li v-for="error in errors" :key="error">{{ error }}</li>
                              </ul>
                          </p>

                      <b-button class="is-rounded" label="Send" @click="savePoll()"></b-button>
                      
              </div>
              
             <div v-if="isVoted"> Complete Poll </div>
                    </b-menu-item>
              
          </b-menu-list>

          <!-- <b-menu-list>
            <b-menu-item
              label="Expo"
              icon="link"
              tag="router-link"
              target="_blank"
              to="/expo"
            ></b-menu-item>
          </b-menu-list> -->

          <!-- <b-menu-list label="Actions">
            <b-menu-item label="Logout"></b-menu-item>
          </b-menu-list> -->
        </b-menu>
      </div>
    </b-sidebar>
    <!-- <div class="block">
      <b-field grouped group-multiline>
        <div class="control">
          <b-switch v-model="overlay">Overlay</b-switch>
        </div>
        <div class="control">
          <b-switch v-model="fullheight">Fullheight</b-switch>
        </div>
        <div class="control">
          <b-switch v-model="fullwidth">Fullwidth</b-switch>
        </div>
        <div class="control">
          <b-switch v-model="right">Right</b-switch>
        </div>
      </b-field>
    </div> -->
    <b-button class="bt" rounded @click="open = true">Poll</b-button>
    
    <!-- <footer-component></footer-component> -->

  </section>
  
</template>

<script >
import axios from "axios";
import { store } from "@/store/store";

export default {
  data() {
    return {
      open: false,
      overlay: false,
      fullheight: true,
      fullwidth: false,
      right: false,

      channelCode: "",
      questionPoll: [],
      choicePoll: [],
      radioSelected: [],
      errors: [],
      isVoted: false,

      timerUpdateData: "",
    };
  },

  async created() {
    console.log("created sidebar vote");

    this.channelCode = this.$route.params.id;
    this.questionPoll = await this.getQuestionPoll(this.channelCode);

    // this.questionPoll.then((data) => {
    //   // alert(data);
    //   // DebugService.printConsole("status login ", data);
    //   if (!data) {
    //     // set state unload
    //   } else {
    //     this.$router.push({ path: "/HomePage" });

    //   }
    // });

    // this.isVoted = await this.CheckVoted();

    this.timerUpdateData = setInterval(() => {
      this.getQuestionPoll(this.channelCode);
      // UserService.keepAlive(this.$store.getters.token);
      //  this.viewer_total = this.getViewerStrem(this.params);
    }, 10000);
  },
  beforeDestroy() {
    clearInterval(this.timerUpdateData);
  },

  computed: {},

  methods: {
    success(msg) {
      this.$buefy.notification.open({
        message: msg,
        type: "is-success",
        duration: 5000,
      });
    },
    printOject(object) {
      console.log(" printOject : " + JSON.stringify(object, null, 2));
      return JSON.stringify(object, null, 2);
    },

    async CheckVoted() {
      console.log("== CheckVoted ==");
      // questionPoll
      // choicePoll
      this.printOject(this.questionPoll);
      for (let i = 0; i < this.questionPoll.length; i++) {
        let questionId = this.questionPoll[i].pollId;
        this.printOject(this.choicePoll);

        let choices = this.choicePoll[questionId];
        this.printOject(choices);
      }

      // for(questionPoll);

      return false;
    },

    savePoll() {
      console.log("=== click savePoll ===  ");

      // console.clear();
      this.errors = [];

      // loop add error
      for (let i = 0; i < this.questionPoll.length; i++) {
        // this.printOject(this.questionPoll[i]);
        if (!this.radioSelected[this.questionPoll[i].pollId]) {
          this.errors.push("Poll required NO." + (i + 1));
        }
      }

      // loop add save data
      if (this.errors.length == 0) {
        console.log("===  save data EA  ===  ");

        // loop save QuestionAns
        for (let i = 0; i < this.questionPoll.length; i++) {
          // this.printOject(this.questionPoll[i]);
          let choiceId = this.radioSelected[this.questionPoll[i].pollId];
          // this.printOject(choiceId);

          this.saveChoiceVote(choiceId);

          //  if (!this.radioSelected[this.questionPoll[i].pollId]) {
          //     this.errors.push("Poll required NO." + (i + 1));
          //     //saveChoiceVote( choiceId)
          //   }
        }
      }
      this.isVoted = true;

      this.CheckVoted();
    },

    // admin get question poll get question vote & choice
    // API_PL_03 Get stream poll list (Admin)
    // https://service.promptevent.com/api/v1/virtual/stream-poll-list-admin/S1613754744

    // user get question poll get question vote & choice >> enable = false >> not show
    // API_PL_04 Get stream poll list
    // https://dev.catsmodish.com/api/v1/virtual/stream-poll-list/S1612877527

    async getQuestionPoll(channelCode) {
      const url =
        store.getters.url + "/api/v1/virtual/stream-poll-list/" + channelCode;
      const token = store.getters.token;

      let dataReturn = [];

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      // let bodyParameters = {
      //   // "username": user.username,
      //   // "password": user.password,
      // };

      // console.log("url : " + JSON.stringify(url, null, 2));
      // console.log(
      //   "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      // );
      // console.log("config : " + JSON.stringify(config, null, 2));

      await axios
        .get(url, config)
        .then((response) => {
          // your code

          if (response.data.status == "success") {
            console.log(
              "getQuestionPoll response.data : " +
                JSON.stringify(response.data, null, 2)
            );

            dataReturn = response.data.steamPolls;

            this.questionPoll = dataReturn;

            // const sum_totalVoted = dataReturn
            //   .map((x) => x.totalVoted) // Extract only the achat field
            //   .flat() // Flatten the array Eg: [[1], [2], [3]] --> [1, 2, 3]
            //   // .map((x) => +x.initial) // Extract the initial field ("+" is to convert string to integer)
            //   .reduce((e, f) => e + f);

            // console.log("dataReturn.length : " + dataReturn.length);

            // clear
            this.isVoted = false;

            for (let i = 0; i < dataReturn.length; i++) {
              // if (dataReturn[i].pollId !=null) {
              // this.printOject(dataReturn[i].pollId);
              let questionId = dataReturn[i].pollId;
              this.getChoicePoll(questionId);
              //this.choicePoll
              // }
            }

            // set to store
            // this.choicePoll
            // this.questionPoll
            // this.isVoted

            // store.commit("SET_IS_VOTE", this.isVoted);
            // store.commit("SET_CHOICE_POLL",  this.choicePoll);
            // store.commit("SET_QUESTION_POLL", this.questionPoll);

            // this.printOject(this.choicePoll);
            //this.choicePoll
          }
          // this.articleId = response.data.token;
        }, console.log)
        .catch(console.log);

      // return response.data.conferenceEvaluations;
      return dataReturn;
    },

    // get choice vote
    // API_PL_06 Get stream poll details
    // https://dev.catsmodish.com/api/v1/virtual/stream-poll-details/2

    async getChoicePoll(pollId) {
      const url =
        store.getters.url + "/api/v1/virtual/stream-poll-details/" + pollId;
      const token = store.getters.token;

      let dataReturn = [];

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      // let bodyParameters = {
      //   // "username": user.username,
      //   // "password": user.password,
      // };

      // console.log("url : " + JSON.stringify(url, null, 2));
      // console.log(
      //   "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      // );
      // console.log("config : " + JSON.stringify(config, null, 2));

      await axios
        .get(url, config)
        .then((response) => {
          // your code

          if (response.data.status == "success") {
            console.log(
              "getChoicePoll response.data : " +
                JSON.stringify(response.data, null, 2)
            );

            dataReturn = response.data.pollChoices;
            this.choicePoll[pollId] = dataReturn;

            for (let i = 0; i < dataReturn.length; i++) {
              let voted = dataReturn[i].voted;
              if (voted > 0) {
                this.isVoted = true;
              }
              // this.printOject(dataReturn[i]);
            }
          }
          // this.articleId = response.data.token;
        }, console.log)
        .catch(console.log);

      // return response.data.conferenceEvaluations;
      return dataReturn;
    },

    // save choice vote
    // API_PL_05 Vote stream poll
    // https://dev.catsmodish.com/api/v1/virtual/stream-poll-vote

    async saveChoiceVote(choiceId) {
      const url = store.getters.url + "/api/v1/virtual/stream-poll-vote";
      const token = store.getters.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      let bodyParameters = {
        choiceId: choiceId, // int
      };

      // console.log("url : " + JSON.stringify(url, null, 2));
      // console.log("bodyParameters : " + JSON.stringify(bodyParameters, null, 2));
      // console.log("config : " + JSON.stringify(config, null, 2));

      await axios
        .post(url, bodyParameters, config)
        .then((response) => {
          // your code
          // this.articleId = response.data.token;
          console.log(
            "saveChoiceVote : " + JSON.stringify(response.data, null, 2)
          );
          this.success("send poll complete.");
        }, console.log)
        .catch(console.log);
    },
  },
};
</script>

<style scoped>
.bt {
  /* background-color: rgb(20, 164, 141);
  color: white; */
  color: var(--templete-font-color);
  background-color: var(--templete-color);
  /* margin: 25px; */
}
.p-1 {
  padding: 1em;
  /* opacity: 0.4; */
  background: transparent;
  /* color: rgb(255, 255, 255);
  background-color: black; */
}
.sidebar-content {
  background: transparent;
  /* opacity: 0.4; */
}
section {
  background: transparent;
  /* opacity: 0.4; */
}
.question {
  padding-bottom: 5px;
}
.fontPoll {
  font-size: larger;
}

p {
  color: rgb(255, 0, 0);
}
</style>
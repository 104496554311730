
import { store } from "@/store/store";


export default {
    printConsole(text, jsonObject) {
        // alert(store.getters.isDebug);
        if (store.getters.isDebug) {

            console.log(text + " : " + JSON.stringify(jsonObject, null, 2));
        }
    }
}
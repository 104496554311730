<template>
  <div>

    <!-- {{this.$store.getters.steamAnswersSticker}} -->
   
    <!-- <main>
      <button @click="start">Start</button>
      <button @click="stop">Stop</button>
      <button @click="love">Show some love</button>
      
    </main> -->

    <div v-if="this.$store.getters.showEmojiStatus">
      <div
        v-for="item in this.$store.getters.steamAnswersSticker"
        v-bind:key="item.answerId"
      >
        <div class="live-emotes">
          <div class="live-emote-container live-emote-container-1">
            <div class="live-emote">
              <div class="live-emote-content">
                <!-- 1 -->

                {{ item.answer }}
                <!-- <img
                  src=
                  alt=""
                /> -->
              </div>
            </div>
          </div>
          <div class="live-emote-container live-emote-container-2">
            <div class="live-emote">
              <div class="live-emote-content">
                <!-- 2 -->
                <!-- <img
                  src="https://1.bp.blogspot.com/-nkaZ6CX3LWQ/UZRSllxb65I/AAAAAAAAABs/enHmnDExBdw/s200/851575_126362140881916_1086262136_n.png"
                  alt=""
                /> -->

                {{ item.answer }}
              </div>
            </div>
          </div>
          <div class="live-emote-container live-emote-container-3">
            <div class="live-emote">
              <div class="live-emote-content">
                <!-- 3
                <img
                  src="https://1.bp.blogspot.com/-nkaZ6CX3LWQ/UZRSllxb65I/AAAAAAAAABs/enHmnDExBdw/s200/851575_126362140881916_1086262136_n.png"
                  alt=""
                /> -->

                {{ item.answer }}
              </div>
            </div>
          </div>

          <div class="live-emote-container live-emote-container-4">
            <div class="live-emote">
              <div class="live-emote-content">
                <!-- 4
                <img
                  src="https://1.bp.blogspot.com/-nkaZ6CX3LWQ/UZRSllxb65I/AAAAAAAAABs/enHmnDExBdw/s200/851575_126362140881916_1086262136_n.png"
                  alt=""
                /> -->

                {{ item.answer }}
              </div>
            </div>
          </div>

          <div class="live-emote-container live-emote-container-5">
            <div class="live-emote">
              <div class="live-emote-content">
                <!-- 5
                <img
                  src="https://1.bp.blogspot.com/-nkaZ6CX3LWQ/UZRSllxb65I/AAAAAAAAABs/enHmnDExBdw/s200/851575_126362140881916_1086262136_n.png"
                  alt=""
                /> -->

                {{ item.answer }}
              </div>
            </div>
          </div>

          <div class="live-emote-container live-emote-container-6">
            <div class="live-emote">
              <div class="live-emote-content">
                <!-- 6
                <img
                  src="https://1.bp.blogspot.com/-nkaZ6CX3LWQ/UZRSllxb65I/AAAAAAAAABs/enHmnDExBdw/s200/851575_126362140881916_1086262136_n.png"
                  alt=""
                /> -->

                {{ item.answer }}
              </div>
            </div>
          </div>

          <div class="live-emote-container live-emote-container-7">
            <div class="live-emote">
              <div class="live-emote-content">
                <!-- 7
                <img
                  src="https://1.bp.blogspot.com/-nkaZ6CX3LWQ/UZRSllxb65I/AAAAAAAAABs/enHmnDExBdw/s200/851575_126362140881916_1086262136_n.png"
                  alt=""
                /> -->

                {{ item.answer }}
              </div>
            </div>
          </div>
          <div class="live-emote-container live-emote-container-8">
            <div class="live-emote">
              <div class="live-emote-content">
                <!-- 8
                <img
                  src="https://1.bp.blogspot.com/-nkaZ6CX3LWQ/UZRSllxb65I/AAAAAAAAABs/enHmnDExBdw/s200/851575_126362140881916_1086262136_n.png"
                  alt=""
                /> -->

                {{ item.answer }}
              </div>
            </div>
          </div>

          <div class="live-emote-container live-emote-container-9">
            <div class="live-emote">
              <div class="live-emote-content">
                <!-- 9
                <img
                  src="https://1.bp.blogspot.com/-nkaZ6CX3LWQ/UZRSllxb65I/AAAAAAAAABs/enHmnDExBdw/s200/851575_126362140881916_1086262136_n.png"
                  alt=""
                /> -->

                {{ item.answer }}
              </div>
            </div>
          </div>

          <div class="live-emote-container live-emote-container-10">
            <div class="live-emote">
              <div class="live-emote-content">
                <!-- 10
                <img
                  src="https://1.bp.blogspot.com/-nkaZ6CX3LWQ/UZRSllxb65I/AAAAAAAAABs/enHmnDExBdw/s200/851575_126362140881916_1086262136_n.png"
                  alt=""
                /> -->

                {{ item.answer }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end loop -->
    </div>
    <!-- end if -->
  </div>
</template>

<script>
import Vue from "vue";
import VueConfetti from "vue-confetti";
// import { store } from "@/store/store";


Vue.use(VueConfetti);

export default {
  components: {
  },

  created() {
    // alert("test");
    // this.show = true;

    setTimeout(() => {
      this.show = true;
    }, 2000);

    setTimeout(() => {
      this.show = false;
    }, 4000);

    // setTimeout(() => {
    //   this.show = false;
    // }, 6000);

    // this.start();
  },

  data() {
    return {
      show: false,
    };
  },

  methods: {
   
    start() {
      this.$confetti.start({
        particles: [
          {
            // type: "heart",
            type: "image",
            url: "http://placekitten.com/50/50",
          },
        ],
      });

      // setTimeout(() => {
      //   this.$confetti.stop();
      // }, 2000);
    },

    stop() {
      this.$confetti.stop();
    },

    love() {
      this.$confetti.update({
        particles: [
          {
            // type: "heart",
            type: "image",
            url: "http://placekitten.com/50/50",
          },
          {
            type: "circle",
          },
        ],
        defaultColors: ["red", "pink", "#ba0000"],
      });
    },
  },
};
</script>

<style scoped>
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
  /* animation: bouncein 0.9s cubic-bezier(0.47, 0, 0.745, 0.715) both; */
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;

  /* animation: rollout 10s cubic-bezier(0.55, 0.085, 0.68, 0.53) both; */
}
p {
  margin: 25px;
  float: left;
}
img {
  margin: 25px;
  float: left;
}

.bounce-enter-active {
  animation: bounce-in 2.5s;
}
.bounce-leave-active {
  animation: bounce-in 2.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(2.5);
  }
  100% {
    transform: scale(2);
  }
}
</style>

<style lang="scss">
$c_container: #2980b9;
$c_emotes_container: #3498db;
$size_container: 60px;
$wave_duration: 8s;

@keyframes waveY {
  from {
    transform: translate3D(0, 0, 0);
  }
  to {
    transform: translate3D(0, 100%, 0);
  }
}

@keyframes waveX {
  0% {
    transform: translate3D(0, 0, 0);
  }
  100% {
    transform: translate3D(calc(-100vw - #{$size_container}), 0, 0);
  }
}

@keyframes scaleOut {
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.6);
  }
}

#container {
  height: $size_container * 3;
  padding-top: $size_container;
  box-sizing: border-box;
  background: $c_container;
  overflow: hidden;
}

.live-emotes {
  position: relative;
  height: $size_container;
  margin: auto;

  background: $c_emotes_container;

  .live-emote-container {
    width: $size_container;
    height: $size_container;
    position: absolute;
    right: -$size_container;
    // right: -300px;
    top: -50%;
    // background: green;
    user-select: none;

    animation: waveY 3s infinite;
    animation-direction: alternate;

    .live-emote {
      width: 100%;
      height: 100%;
      animation: waveX $wave_duration infinite;
      animation-timing-function: ease-in;
      font-size: 30px; // size emoji

      &-content {
        width: 100%;
        height: 100%;
        background: transparent;
        animation: scaleOut $wave_duration infinite;

        img {
          width: 50%;
        }
      }
    }
  }

  .live-emote-container:nth-child(2) {
    animation-duration: 7s;
    animation-timing-function: cubic-bezier(0.17, 0.67, 1, 0.85);
    .live-emote {
      animation-duration: 11s;
      animation-timing-function: cubic-bezier(0.17, 0.67, 1, 0.85);

      &-content {
        animation-duration: 11s;
      }
    }
  }

  .live-emote-container:nth-child(3) {
    animation-duration: 2s;
    animation-timing-function: cubic-bezier(0.65, 0, 0.82, 1);
    .live-emote {
      animation-duration: 9s;
      animation-timing-function: cubic-bezier(0.65, 0, 0.82, 1);

      &-content {
        animation-duration: 9s;
      }
    }
  }

  .live-emote-container:nth-child(4) {
    animation-duration: 6s;
    animation-timing-function: cubic-bezier(0.72, 0.14, 0.58, 0.98);
    .live-emote {
      animation-duration: 8s;
      animation-timing-function: cubic-bezier(0.72, 0.14, 0.58, 0.98);

      &-content {
        animation-duration: 8s;
      }
    }
  }

  .live-emote-container:nth-child(5) {
    animation-duration: 3s;
    animation-timing-function: cubic-bezier(0.17, 0.31, 0.58, 0.98);
    .live-emote {
      animation-duration: 9s;
      animation-timing-function: cubic-bezier(0.17, 0.31, 0.58, 0.98);

      &-content {
        animation-duration: 9s;
      }
    }
  }

  .live-emote-container:nth-child(6) {
    animation-duration: 6s;
    animation-timing-function: cubic-bezier(0.23, 0.22, 1, 0.84);
    .live-emote {
      animation-duration: 11s;
      animation-timing-function: cubic-bezier(0.23, 0.22, 1, 0.84);

      &-content {
        animation-duration: 11s;
      }
    }
  }

  .live-emote-container:nth-child(7) {
    animation-duration: 5s;
    animation-timing-function: cubic-bezier(1, 0.11, 0.51, 0.78);
    .live-emote {
      animation-duration: 8s;
      animation-timing-function: cubic-bezier(1, 0.11, 0.51, 0.78);

      &-content {
        animation-duration: 8s;
      }
    }
  }

  .live-emote-container:nth-child(8) {
    animation-duration: 4s;
    animation-timing-function: cubic-bezier(0.7, 0.21, 0.74, 0.78);
    .live-emote {
      animation-duration: 9s;
      animation-timing-function: cubic-bezier(0.7, 0.21, 0.74, 0.78);

      &-content {
        animation-duration: 9s;
      }
    }
  }

  .live-emote-container:nth-child(9) {
    animation-duration: 6s;
    animation-timing-function: cubic-bezier(0.48, 0.15, 0.86, 0.75);
    .live-emote {
      animation-timing-function: cubic-bezier(0.48, 0.15, 0.86, 0.75);
    }
  }

  .live-emote-container:nth-child(10) {
    animation-duration: 3s;
    animation-timing-function: cubic-bezier(0.76, 0.2, 0.47, 0.85);
    .live-emote {
      animation-duration: 11s;
      animation-timing-function: cubic-bezier(0.76, 0.2, 0.47, 0.85);

      &-content {
        animation-duration: 11s;
      }
    }
  }

  // overlay

  .overlay {
    height: 0%;
    width: 100%;
    position: fixed;
    z-index: 2147483647;
    top: 0;
    left: 0;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.9);
    overflow-y: hidden;
    transition: 0.5s;
  }

  .overlay-content {
    position: relative;
    top: 25%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }

  .overlay a {
    padding: 8px;
    text-decoration: none;
    font-size: 36px;
    color: #818181;
    display: block;
    transition: 0.3s;
  }

  .overlay a:hover,
  .overlay a:focus {
    color: #f1f1f1;
  }

  .overlay .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
  }

  @media screen and (max-height: 450px) {
    .overlay {
      overflow-y: auto;
    }
    .overlay a {
      font-size: 20px;
    }
    .overlay .closebtn {
      font-size: 40px;
      top: 15px;
      right: 35px;
    }
  }

  // end overlay
}
</style>

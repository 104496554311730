<template>
  <section class="container has-text-left is-primary">
    <!-- <div>store : {{ this.$store.getters["userLogin/messageErrorLogin"] }}</div> -->

    <b-field>
      <b-input
        placeholder="อีเมล์"
        v-model="email"
        type="email"
        icon="email"
        required
        rounded
      >
      </b-input>
    </b-field>

    <b-field style="margin-bottom: 25px">
      <!-- <b-field style="visibility: hidden; display: none"> -->
      <b-input
        v-model="password"
        class="has-text-primary"
        type="password  is-dark"
        icon="lock"
        placeholder="Password"
        password-reveal
        required
        rounded
      >
      </b-input>
    </b-field>

    <!-- <b-field class="signup">
      <div class="tile is-ancestor">
        <div class="tile is-4">
          <b-field grouped position="is-left ">
            <label @click="ForgetPassword()">Forget password</label>
          </b-field>
        </div>
        <div class="tile is-6"></div>

        <div class="tile is-4">
          <b-field
            grouped
            position="is-right"
            class="fontStyle"
            :style="{ visibility: flag_fn_register ? 'visible' : 'hidden' }"
          >
            <label :style="{ opacity: '0', color: templeteFontColor }">
              ลงทะเบียนใหม่</label
            >
            <label @click="goRegisterPage()" style="cursor: pointer">
              ลงทะเบียนใหม่</label
            >
          </b-field>
        </div>
      </div>
    </b-field> -->

    <div
      style="margin-top: 25px"
      v-if="this.$store.getters.flag_CSS_BG_IMAGE"
      class="buttons"
    >
      <b-button
        @click="onLogin2"
        type="botton"
        :loading="btLoading"
        :disabled="btLoading"
        expanded
        rounded
      >
        {{bt_login_title}}</b-button
      >
    </div>

    <div v-else class="buttons">
      <b-button
        @click="onLogin2"
        type="is-dark"
        :loading="btLoading"
        :disabled="btLoading"
        expanded
        rounded
      >
      {{bt_login_title}}</b-button
      >
    </div>
    <!-- 
    <div>
      <b-button
        label="Launch card modal (Component)"
        type="is-primary"
        size="is-medium"
        @click="cardModal"
      />
    </div> -->

    <!-- type="is-info" -->

    <div class="buttons" v-if="this.$store.getters.flag_fn_info">
      <b-button
        expanded
        rounded
        label="กำหนดการ"
        type="button"
        @click="isCardModalActive = true"
      />
    </div>

    <b-modal v-model="isCardModalActive">
      <div class="card">
        <div class="card-image">
          <figure class="image">
            <img src="@/assets/images/info.jpg" alt="Image" />
          </figure>
        </div>
      </div>
    </b-modal>

    <b-field>
      <!-- <b-button
        @click="goRegisterPage()"
        type="is-dark"
        :loading="btLoading"
        :disabled="btLoading"
        expanded
        rounded
      >
        ลงทะเบียนใหม่</b-button
      > -->

      <b-button  v-if="this.$store.getters.flag_fn_register"
        style="background-color: #2c296b;"
        @click="goRegisterPage()"
        class="button is-info"
        :loading="btLoading"
        :disabled="btLoading"
        expanded
        rounded
        >{{bt_register_title}}</b-button
      >
    </b-field>

    <!-- <span class="has-text-danger is-italic"> {{ errorMsg }}</span> -->
    <span class="has-text-danger is-italic">
      {{ this.$store.getters["userLogin/messageErrorLogin"] }}</span
    >
  </section>
</template>

<script>
import axios from "axios";
import DebugService from "@/service/DebugService";
// import userLogin from '../store/modules/userLogin';
import UserService from "@/service/UserService";
// import ErrorMessageService from "@/service/ErrorMessageService";
// import { Settings } from "../variables.js";
// import FooterComponent from "./footer.vue";

// From inside Vue instance
// this.$buefy.modal.open(props)

// From outside Vue instance
// import { ModalProgrammatic as Modal } from 'buefy'
// Modal.open()

export default {
  components: {},
  name: "RegisterForm",
  props: {},
  data() {
    return {
      isCardModalActive: false,
      
      // bt_login_title : "ล็อคอินเข้างาน",
      bt_login_title : "Log in with password",
      // bt_register_title : "ลงทะเบียน",
      bt_register_title : "Register for new attendee",

      // email: "boe.admin01@catsmodish.com",
      // password: "boe2022",

      // email: "ssy.admin01@catsmodish.com",
      // password: "SSY2012",

      // email: "ssy.admin01@catsmodish.com",
      // password: "SSY2012",

      // email: "xen.admin01@catsmodish.com",
      // password: "XEN2412",

      // email: "cs.admin01@catsmodish.com",
      // password: "admin12345",

      // email: "tt.admin03@catsmodish.com",
      // password: "admin12345",

      // email: "chanon@gmail.com",
      // email: "tester@catsmodish.com",
      // password: "admin12345",

      // email: "admin01@promptevent.co.th",
      // password: "AMI2411",

      // email: "http://localhost:8080/,
      // password: "admin12345",

      // email: "singha@hotmail.com",
      // password: "admin12345",

      // email: "atb.admin01@catsmodish.com",
      // password: "atb290522",

      
      // email: "botox.admin01@catsmodish.com", // TOXIN1807 
      // password: "TOXIN1508",       

      // email: "toxin.admin01@catsmodish.com", // TOXIN1212 
      // password: "TOXIN1212",       
      
      // email: "ami.admin05@catsmodish.com",
      // password: "AMI2002", // new 

      
      // conferenceCode: "m125yFYOgQ3lS3eXgY2CjF1ZW65tPOQR",
      // email: "ami.admin01@catsmodish.com",
      // password: "AMI2002", // QR_CODE 

      
      // conferenceCode: "vWplpt5UCCdhU15jftwwBu1HqlecWOOz",
      //  email: "sic.admin02@catsmodish.com",
      //  password: "SIC1201", // QR_CODE 20240112
      
      
      // conferenceCode: "rqkO9ZjUkUgOZjXq56ZkR2boLrCfvPEc",
      //  email: "atb.admin02@catsmodish.com", // uat QR_CODE
      //  password: "admin12345",      
            
      // email: "admin5@catsmodish.com", // uat         
      // password: "admin12345",      

      email: "",
      password: "",

      token: "",
      errorMsg: this.$store.getters["userLogin/messageErrorLogin"],
      btLoading: false,

      flag_fn_register: this.$store.getters.flag_fn_register,
      templeteColor: this.$store.getters.templeteBgColor,
      templeteFontColor: this.$store.getters.templeteFontColor,
    };
  },
  mounted() {
    // this.$refs.myInput.$el.querySelector("input").style.color = "red";
  },

  computed: {
    // errorMsg: this.$store.getters["userLogin/messageErrorLogin"],
    // errorMsg: this.$store.getters["userLogin/messageErrorLogin"],
  },

  // "conferenceId": 2,
  // https://www.promptevent.com // UAT
  // conferenceCode: "rqkO9ZjUkUgOZjXq56ZkR2boLrCfvPEc",  // uat
  // templeteBgColor: " #1d917e", // Blue Boehringer Ingelheim morning
  // 

  //  "conferenceId": 10,
  // https://www.promptevent.com/BoehringerIngelheimMoring
  // conferenceCode: "XlkBvpMVduJeIXIucvxJHu7ONhNWGmDs",
  // templeteBgColor: " #06214d", // Blue Boehringer Ingelheim morning

  //  "conferenceId": 9,
  // https://www.promptevent.com/BoehringerIngelheimNoon
  // conferenceCode: "Gm8jEQ3Ef7fRHBuusEJ2HO3DPkZ5krW8",
  // templeteBgColor: " #daeaf9", // Blue Boehringer Ingelheim noon

  //  "conferenceId": 11,
  // https://www.promptevent.com/ATB
  // conferenceCode: "HfMXOhOg6rui2uRIJQ5vEBsvimCbm8xe",
  // templeteBgColor: " #fbe5e7", // ATB
  
  // "conferenceId": 4,
  // https://ami.promptevent.com
  // conferenceCode: "OHzTydNMRsKQpCgFV4ax5LFHnZezGoiO",
  // templeteBgColor: "#f2dad0", // pink AMI 

  
  // "conferenceId": 15,
  // "website": "https://www.promptevent.com/CGM",
  // conferenceCode: "WvBx51SvRrKEgYlbFXQJWq7TvT4AaLor",
  // templeteBgColor: "#f2dad0", // pink AMI 

  
  // "conferenceId": 12,
  // https://www.promptevent.com/TOXIN 
  // conferenceCode: "SOe6K5jGdvid8kXvdCG3polZjgRHZmu5",
  // templeteBgColor: "#320f51", // TOXIN EP 1 - 2
  // templeteBgColor: "#c3d3ed", // TOXIN EP 3 TOXIN 20220815 EP 3
  // templeteBgColor: "#402a6a", // TOXIN EP 4 TOXIN 20221212 EP 4
  
  // "conferenceId": 12,
  // https://www.promptevent.com/TOXIN 
  // conferenceCode: "SOe6K5jGdvid8kXvdCG3polZjgRHZmu5",
  // templeteBgColor: "#320f51", // TOXIN EP 1 - 2

  // "conferenceId": 17,
  //  "code": "m125yFYOgQ3lS3eXgY2CjF1ZW65tPOQR",
  //  "name": "Scan QR CODE",
  //  "website": "https://promptevent.com",


  // templeteFontColor : "#000000", // black mode     AMI
  // templeteFontColor: "#ffffff",// white mode


  
  created() {
    window.localStorage.clear();
    // console.clear();
    console.log("created Login");
    // setConferenceCode
    this.$store.commit("setConferenceCode", "vWplpt5UCCdhU15jftwwBu1HqlecWOOz");
    // rqkO9ZjUkUgOZjXq56ZkR2boLrCfvPEc // UAT
    

    this.$store.commit("setFlag_Supper_Admin", false);
    this.$store.commit("setFlag_CSS_BG_IMAGE", true);
    this.$store.commit("setFlag_fn_register", true);
    this.$store.commit("setFlag_fn_EA", true);
    this.$store.commit("setFlag_fn_sticker", true);
    this.$store.commit("setFlag_fn_info", false);
    this.$store.commit("setFlag_fn_Poll", false);    
    this.$store.commit("setFlag_fn_QA", true);
    this.$store.commit("setFlag_fix_password", false);
    this.$store.commit("setFix_password", "TOXIN1212");

    this.$store.commit("setFlag_QRCODE", false);
    


    this.$store.commit("setTempleteBgColor", "#1d917e");
    this.$store.commit("setTempleteFontColor", "#ffffff");

    console.log(this.$store.getters.conferenceCode);
  },

  methods: {
    danger(msg) {
      this.$buefy.notification.open({
        duration: 5000,
        message: msg,
        position: "is-bottom-right",
        type: "is-danger",
        hasIcon: true,
      });
    },

    clearIconClick() {
      this.email = "";
      alert("Email cleared!");
    },
    goRegisterPage() {
      // alert("goRegisterPage");
      this.$router.push({ path: "/registerPage" });
    },
    ForgetPassword() {
      // alert("goRegisterPage");
      // this.$router.push({ path: "/registerPage" });
    },

    onLogin() {
      // set load state
      this.btLoading = true;

      // alert("OnLogin");
      // const url = "https://service.promptevent.com/api/v1/virtual/login";
      const url = this.$store.getters.url + "/api/v1/virtual/login";
      // const token = "";

      const config = {
        headers: {
          // Authorization: `Bearer ${token}`,
          // "x-conference-code": "rqkO9ZjUkUgOZjXq56ZkR2boLrCfvPEc",
          "x-conference-code": this.$store.getters.conferenceCode,
          "Content-Type": "application/json",
        },
      };

      const bodyParameters = {
        // username: "admin5@catsmodish.com",
        // password: "admin12345",
        username: this.email,
        password: this.password,
      };

      // console.log("url : " + url);

      // console.log("url : " + JSON.stringify(url, null, 2));
      // console.log(
      //   "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      // );
      // console.log("config : " + JSON.stringify(config, null, 2));

      axios
        .post(url, bodyParameters, config)
        // .then(console.log)
        .then(
          (response) => {
            // alert("response");
            // console.log(
            //   "response.data : " + JSON.stringify(response.data, null, 2)
            // );

            DebugService.printConsole("onLoign", response.data);
            // console.log(response.data);

            if (response.data.status == "failure") {
              this.errorMsg = "Error : " + response.data.statusDesc;

              this.danger(this.errorMsg);
              DebugService.printConsole("data.status failure", response.data);

              // set state unload
              this.btLoading = false;
            } else if (response.data.status == "success") {
              this.errorMsg = response.data.status;
              this.danger("");
              // console.log("data : " + response.data);
              DebugService.printConsole("data success", response.data);
              // alert( JSON.stringify(response.data, null, 2));
              // setConferenceCode
              this.$store.commit("setUser", response.data.firstName);
              this.$store.commit("setToken", response.data.token);
              this.$store.commit("setRoles", response.data.roles);
              this.$store.commit("setIsLogin", true);
              this.$store.commit("SET_LOGIN_KEY", response.data.key);

              // loop
              // setInterval(() => {
              //   UserService.keepAlive(this.$store.getters.token);
              // }, 5000);

              this.$router.push({ path: "/HomePage" });
            } else {
              // error request
              // console.log("data : " + response.data);
              DebugService.printConsole("error request", response.data);

              this.$router.push({ path: "/" });
            }

            // save token

            // router to home

            // console.log;
            // alert(this.errorMsg);
            // this.$router.push({ name: "home" });
          }

          // console.log
        )
        .catch(function (error) {
          // handle error
          // alert("ERROR");
          console.log(error);
        })
        .then(function () {
          // always executed
          // alert("always executed");
        });

      // alert("ERROR");
    },

    async onLogin2() {
      // set load state
      this.btLoading = true;

      let statusLogin = UserService.onLogin(this.email, this.password);
      // alert(statusLogin); // return promise
      // console.log(statusLogin); // return promise
      // statusLogin.then(result => console.log(result));
      statusLogin.then((status) => {
        // alert(status);
        DebugService.printConsole("status login ", status);
        // let statusLogin = false;
        if (!status) {
          // set state unload
          this.danger(this.$store.getters["userLogin/messageErrorLogin"]);
          this.btLoading = false;
          // this.$router.push({ path: "/" });
        } else {
          this.$router.push({ path: "/HomePage" });
        }
      });
    },
  }, // end methods

  // created() {
  //   window.localStorage.clear();

  //   // this.$store.commit("userLogin/SET_MESSAGE_ERROR", errorMsg);
  //   // this.$store.commit(
  //   //   "userLogin/SET_MESSAGE_ERROR",
  //   //   "test store error message"
  //   // );
  //   // alert("creates");
  // },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.has-text-primary {
  color: #000000 !important;
  /* color: #ffffff !important; */
}

.signup {
  margin-top: 25px;
  margin-bottom: 25px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

@media screen and (min-width: 600px) {
  .card-body {
    /* align-self: center; */
    width: 80%;
  }
}

#mdi-eye {
  color: red;
}

.fontStyle {
  color: var(--templete-font-color);
}

/* .botton{
  cursor: pointer;
} */
</style>

// import { Settings } from '@/variables.js'
import axios from "axios";
import { store } from "@/store/store";

export default {



    // 	send ans Evaluation
    // API_EA_05 Answer conference evaluation
    // https://service.promptevent.com/api/v1/virtual/conference-evaluation-answer


    saveEvaluationQuestion(evaluationId, answer) {
        const url = store.getters.url + "/api/v1/virtual/conference-evaluation-answer";
        const token = store.getters.token;

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                "x-conference-code": store.getters.conferenceCode,
                "Content-Type": store.getters.ContentType,
            },
        };

        let bodyParameters = {
            "evaluationId": evaluationId, // int 
            "answer": answer // String
        };


        // console.log("url : " + JSON.stringify(url, null, 2));
        // console.log("bodyParameters : " + JSON.stringify(bodyParameters, null, 2));
        // console.log("config : " + JSON.stringify(config, null, 2));


        axios
            .post(url, bodyParameters, config)
            .then(
                // (response) => {
                //     // your code
                //     this.articleId = response.data.token;
                // },
                console.log)
            .catch(console.log);
    },

    // 	send vote rating Evaluation
    // API_EA_12 Vote conference poll
    // https://service.promptevent.com/api/v1/virtual/conference-poll-vote


    saveEvaluationQuestionRating(choiceId) {
        const url = store.getters.url + "/api/v1/virtual/conference-poll-vote";
        const token = store.getters.token;

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                "x-conference-code": store.getters.conferenceCode,
                "Content-Type": store.getters.ContentType,
            },
        };

        let bodyParameters = {
            "choiceId": choiceId // int
        };


        // console.log("url : " + JSON.stringify(url, null, 2));
        // console.log("bodyParameters : " + JSON.stringify(bodyParameters, null, 2));
        // console.log("config : " + JSON.stringify(config, null, 2));


        axios
            .post(url, bodyParameters, config)
            .then(
                // (response) => {
                //     // your code
                //     this.articleId = response.data.token;
                // },
                console.log)
            .catch(console.log);
    },


    register(user) {
        // https://service.promptevent.com/api/v1/virtual/user-register
        // const url = Settings.url + "/api/v1/virtual/user-register";
        const url = store.getters.url + "/api/v1/virtual/user-register";

        const config = {
            headers: {
                // Authorization: `Bearer ${token}`,
                // Authorization: `Bearer sss`,
                "x-conference-code": store.getters.conferenceCode,
                "Content-Type": store.getters.ContentType,
            },
        };

        let bodyParameters = {
            "username": user.username,
            "password": user.password,
            "firstName": user.firstName,
            "middleName": "middleName",
            "lastName": user.lastName,
            "careerPosition": "position",
            "workplace": user.workplace,
            "telephone": user.telephone
        };


        console.log("url : " + JSON.stringify(url, null, 2));
        console.log("bodyParameters : " + JSON.stringify(bodyParameters, null, 2));
        console.log("config : " + JSON.stringify(config, null, 2));


        axios
            .post(url, bodyParameters, config)
            .then(
                // (response) => {
                //     // your code
                //     this.articleId = response.data.token;
                // },
                console.log)
            .catch(console.log);
    },
}
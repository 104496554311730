// import { Settings } from '@/variables.js'
import axios from "axios";
import { store } from "@/store/store";
// import HttpService from "@/service/HttpService";

export default {
  // components: {
  //     HttpService
  // },



  // // 2.get ans form channel
  // // API_QA_06 Get stream question answer {streamName/questionId/pageNo}
  // // https://service.promptevent.com/api/v1/virtual/stream-question-answer/S1613754744/2/1
  // getQuestionStream(channel, questionIdDefault, page) {
  //   const url =
  //     store.getters.url +
  //     "/api/v1/virtual/stream-question-answer/" +
  //     channel +
  //     "/" +
  //     questionIdDefault +
  //     "/" +
  //     page;
  //   const token = store.getters.token;

  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       "x-conference-code": store.getters.conferenceCode,
  //       "Content-Type": store.getters.ContentType,
  //     },
  //   };

  //   let bodyParameters = {
  //     // "username": user.username,
  //     // "password": user.password,
  //   };

  //   console.log("url : " + JSON.stringify(url, null, 2));
  //   console.log(
  //     "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
  //   );
  //   console.log("config : " + JSON.stringify(config, null, 2));

  //   axios
  //     .get(url, config)
  //     .then((response) => {
  //       // your code
  //       if (response.data.status == "success") {
  //         console.log(
  //           "response.data : " + JSON.stringify(response.data, null, 2)
  //         );

  //         // this.steamAnswers = response.data.steamQuestion.steamAnswers;


  //         store.commit("SET_ANSWER", response.data.steamQuestion.steamAnswers);

  //         this.steamAnswers = response.data.steamQuestion.steamAnswers.filter(
  //           (element) => element.answer != "cancel"
  //         );

  //         this.totalPage = response.data.steamQuestion.totalPage;

  //         console.log(
  //           "response.data.steamAnswers : " +
  //           JSON.stringify(this.steamAnswers, null, 2)
  //         );
  //       }

  //       // this.articleId = response.data.token;
  //     }, console.log)
  //     .catch(console.log);

  //   // return {test : "test" };
  // },



  // send question
  // API_QA_05 Answer stream question
  // https://dev.catsmodish.com/api/v1/virtual/stream-question-answer

  async saveEvaluationQuestion(questionIdDefault, answer, replyToId) {
    const url = store.getters.url + "/api/v1/virtual/stream-question-answer";
    const token = store.getters.token;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-conference-code": store.getters.conferenceCode,
        "Content-Type": store.getters.ContentType,
      },
    };

    let bodyParameters = {
      questionId: questionIdDefault, // int
      answer: answer, // String
      replyToId: replyToId,
    };

    console.log("url : " + JSON.stringify(url, null, 2));
    console.log(
      "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
    );
    // console.log("config : " + JSON.stringify(config, null, 2));

    await axios
      .post(url, bodyParameters, config)
      .then((response) => {
        // your code
        // this.articleId = response.data.token;
        console.log(
          "saveEvaluationQuestion : " + JSON.stringify(response.data, null, 2)
        );
        // this.success("send question complete.");

        // update data answer in store 
        this.getQuestionStream(store.getters.channel, store.getters.questionId, 1);

      }, console.log)
      .catch(console.log);
  },




  // save sticker
  // API_QA_05 Answer stream question
  // https://dev.catsmodish.com/api/v1/virtual/stream-question-answer

  async insertSticker(channelCode,questionId, answer) {

    // alert("insertSticker : "+channelCode);
    const url = store.getters.url + "/api/v1/virtual/stream-question-answer";
    const token = store.getters.token;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-conference-code": store.getters.conferenceCode,
        "Content-Type": store.getters.ContentType,
      },
    };

    let bodyParameters = {
      questionId: questionId, // int
      answer: answer, // String
      replyToId: 0,
    };

    console.log("url : " + JSON.stringify(url, null, 2));
    console.log(
      "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
    );
    // console.log("config : " + JSON.stringify(config, null, 2));

    await axios
      .post(url, bodyParameters, config)
      .then((response) => {
        // your code
        // this.articleId = response.data.token;
        console.log(
          "insertSticker : " + JSON.stringify(response.data, null, 2)
        );

        if (response.data.status == "success") {
          console.log(
            "success : " + JSON.stringify(response.data, null, 2)
          );

          // update a
          this.updateStickerNotShow(channelCode, questionId, 1) 
        }



        // this.success("send insertSticker complete.");
      }, console.log)
      .catch(console.log);


  },



  // เพื่อไม่ให้แสดงย้อนหลัง 
  // Inital sticker 
  // 06 get history send question
  // API_QA_06 Get stream question answer
  // https://service.promptevent.com/api/v1/virtual/stream-question-answer/S1613754744/1/1
  //   "/api/v1/virtual/stream-question-answer/$channelCode/$questionId/$pageNo";

  async getHistorySendStickerInitial(channelCode, questionId, pageNo) {
    // console.log("getChoiceRating voteId : " + voteId);
    const url =
      store.getters.url +
      "/api/v1/virtual/stream-question-answer/" +
      channelCode +
      "/" +
      questionId +
      "/" +
      pageNo +
      "?sort=desc";
    const token = store.getters.token;

    let dataReturn = [];

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-conference-code": store.getters.conferenceCode,
        "Content-Type": store.getters.ContentType,
      },
    };

    // let bodyParameters = {
    //   // "username": user.username,
    //   // "password": user.password,
    // };

    console.log("url : " + JSON.stringify(url, null, 2));
    // console.log(
    //   "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
    // );
    // console.log("config : " + JSON.stringify(config, null, 2));

    await axios
      .get(url, config)
      .then((response) => {
        // your code

        if (response.data.status == "success") {
          console.log(
            "getHistorySendSticker response.data : " +
            JSON.stringify(response.data, null, 2)
          );

          dataReturn = response.data.steamQuestion.steamAnswers;

            // update ให้เป้น ปัจจุบัน ครั้งแรก ป้องกันแสดงย้อนหลัง
            // no data SET_ANSWER_STICKER
            // update chat
            store.commit(
              "SET_ANSWER_STICKER",
              response.data.steamQuestion.steamAnswers
            );
          
        }
      }, console.log)
      .catch(console.log);

    // return response.data.conferenceEvaluations;
    return dataReturn;
  },


  // refresh sticker 
  // 06 get history send question
  // API_QA_06 Get stream question answer
  // https://service.promptevent.com/api/v1/virtual/stream-question-answer/S1613754744/1/1
  //   "/api/v1/virtual/stream-question-answer/$channelCode/$questionId/$pageNo";

  async getHistorySendSticker(channelCode, questionId, pageNo) {
    // console.log("getChoiceRating voteId : " + voteId);
    const url =
      store.getters.url +
      "/api/v1/virtual/stream-question-answer/" +
      channelCode +
      "/" +
      questionId +
      "/" +
      pageNo +
      "?sort=desc";
    const token = store.getters.token;

    let dataReturn = [];

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-conference-code": store.getters.conferenceCode,
        "Content-Type": store.getters.ContentType,
      },
    };

    // let bodyParameters = {
    //   // "username": user.username,
    //   // "password": user.password,
    // };

    console.log("url : " + JSON.stringify(url, null, 2));
    // console.log(
    //   "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
    // );
    // console.log("config : " + JSON.stringify(config, null, 2));

    await axios
      .get(url, config)
      .then((response) => {
        // your code

        if (response.data.status == "success") {
          console.log(
            "getHistorySendSticker response.data : " +
            JSON.stringify(response.data, null, 2)
          );

          dataReturn = response.data.steamQuestion.steamAnswers;

          // this check nofity question
          // let counterNotify =
          //   response.data.steamQuestion.steamAnswers.length -
          //   store.getters.steamAnswersChatFull.length;

          // dataReturn.length > 0 // มีคำามส่งเข้า มา
          // store.getters.steamAnswersChatFull.length // ประวัติคำถามก็มากว่า 0
          if (
            dataReturn.length > 0 &&
            store.getters.steamAnswersSticker.length > 0
          ) {
            // new message
            // alert(
            //   response.data.steamQuestion.steamAnswers[0].lastModifiedDate +
            //     " - " +
            //     store.getters.steamAnswersChatFull[0].lastModifiedDate
            // )
            if (
              response.data.steamQuestion.steamAnswers[0].lastModifiedDate !=
              store.getters.steamAnswersSticker[0].lastModifiedDate
            ) {
              // alert("new sticker");
              // store.commit("SET_COUNTER_CHAT_STREAM", 1);
              console.log(
                "response.data : " +
                JSON.stringify(response.data.steamQuestion.steamAnswers[0].lastModifiedDate, null, 2)
              );

              console.log(
                "SET_ANSWER_STICKER : " +
                JSON.stringify(store.getters.steamAnswersSticker[0].lastModifiedDate, null, 2)
              );
              // insert new sticker only for display 
              let newSticker =
                response.data.steamQuestion.steamAnswers.filter(
                  (element) =>
                    element.answerId >
                    store.getters.steamAnswersSticker[0].answerId && element.firstName != store.getters.user
                );
              console.log(
                "SET_ANSWER_STICKER NEW response.data : " +
                JSON.stringify(newSticker, null, 2)
              );

              if (newSticker.length > 0) {
                store.commit("SET_ANSWER_STICKER", newSticker);

                store.commit("SET_SHOW_EMOJI_STATUS", true);

                setTimeout(() => {
                  store.commit("SET_SHOW_EMOJI_STATUS", false);
                }, 6000);
              }
            }
          } else {
            // no data SET_ANSWER_STICKER
            // update chat
            store.commit(
              "SET_ANSWER_STICKER",
              response.data.steamQuestion.steamAnswers
            );
          }
          // store.commit(
          //   "SET_COUNTER_CHAT_STREAM",
          //   store.getters.counterChatMessagetotal + counterNotify
          // );



          // if (dataReturn.length > 0) {
          //   this.hasHistorySendQuestion = true;
          // } else {
          //   this.hasHistorySendQuestion = false;
          // }

          // return this.questionIdDefault;
        }
        // this.articleId = response.data.token;
      }, console.log)
      .catch(console.log);

    // return response.data.conferenceEvaluations;
    return dataReturn;
  },



  // refresh sticker 
  // 06 get history send question
  // API_QA_06 Get stream question answer
  // https://service.promptevent.com/api/v1/virtual/stream-question-answer/S1613754744/1/1
  //   "/api/v1/virtual/stream-question-answer/$channelCode/$questionId/$pageNo";

  async updateStickerNotShow(channelCode, questionId, pageNo) {

    // alert("updateStickerNotShow");
    // console.log("getChoiceRating voteId : " + voteId);
    const url =
      store.getters.url +
      "/api/v1/virtual/stream-question-answer/" +
      channelCode +
      "/" +
      questionId +
      "/" +
      pageNo +
      "?sort=desc";
    const token = store.getters.token;

    let dataReturn = [];

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-conference-code": store.getters.conferenceCode,
        "Content-Type": store.getters.ContentType,
      },
    };

    // let bodyParameters = {
    //   // "username": user.username,
    //   // "password": user.password,
    // };

    console.log("url : " + JSON.stringify(url, null, 2));
    // console.log(
    //   "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
    // );
    // console.log("config : " + JSON.stringify(config, null, 2));

    await axios
      .get(url, config)
      .then((response) => {
        // your code

        if (response.data.status == "success") {
          console.log(
            "updateStickerNotShow response.data : " +
            JSON.stringify(response.data, null, 2)
          );

          dataReturn = response.data.steamQuestion.steamAnswers;

          let newSticker =
          response.data.steamQuestion.steamAnswers.filter(
            (element) =>
              element.answerId >
              store.getters.steamAnswersSticker[0].answerId 
          );
          // alert( JSON.stringify(newSticker, null, 2));
        console.log(
          "updateStickerNotShow NEW response.data : " +
          JSON.stringify(newSticker, null, 2)
        );

        if (newSticker.length > 0) {
          store.commit("SET_ANSWER_STICKER", newSticker);

          store.commit("SET_SHOW_EMOJI_STATUS", true);

          setTimeout(() => {
            store.commit("SET_SHOW_EMOJI_STATUS", false);
          }, 6000);
        }

          // store.commit(
          //   "SET_ANSWER_STICKER",
          //   newSticker
          // );

          // store.commit(
          //   "SET_COUNTER_CHAT_STREAM",
          //   store.getters.counterChatMessagetotal + counterNotify
          // );



          // if (dataReturn.length > 0) {
          //   this.hasHistorySendQuestion = true;
          // } else {
          //   this.hasHistorySendQuestion = false;
          // }

          // return this.questionIdDefault;
        }
        // this.articleId = response.data.token;
      }, console.log)
      .catch(console.log);

    // return response.data.conferenceEvaluations;
    return dataReturn;
  },


  // send sticker
  // API_ST_04 Send sticker
  // https://service.promptevent.com/api/v1/virtual/stream/send-sticker

  async sendSticker(channel, stickerNo) {
    const url = store.getters.url + "/api/v1/virtual/stream/send-sticker";
    const token = store.getters.token;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-conference-code": store.getters.conferenceCode,
        "Content-Type": store.getters.ContentType,
      },
    };

    let bodyParameters = {
      "streamName": channel,
      "stickerNo": stickerNo,
    };

    console.log("url : " + JSON.stringify(url, null, 2));
    console.log(
      "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
    );
    // console.log("config : " + JSON.stringify(config, null, 2));

    await axios
      .post(url, bodyParameters, config)
      .then((response) => {
        // your code
        // this.articleId = response.data.token;
        console.log(
          " send sticker : " + JSON.stringify(response.data, null, 2)
        );
        // this.success("send question complete.");

        // // update data answer in store 
        // this.getQuestionStream(store.getters.channel, store.getters.questionId, 1);

      }, console.log)
      .catch(console.log);
  },

}
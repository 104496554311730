
<template>
  <div class="card text-center">
    <header-component></header-component>


<div class="card-body has-text-centered">


          <b-image class="logoImage" :src="logoImage"></b-image>

</div>


    <footer-component></footer-component>
  </div>
</template>

<script>
import UserService from "@/service/UserService";
import ConferenceService from "@/service/ConferenceService";

import axios from "axios";
import { store } from "@/store/store";
import FooterComponent from "./footer.vue";
import HeaderComponent from "./header.vue";

import { useWindowSize } from "vue-window-size";

// import { myVar, Settings } from './variables.js'

export default {
  name: "post-request",
  components: {
    FooterComponent,
    HeaderComponent,
  },
  setup() {
    const { width, height } = useWindowSize();
    return {
      windowWidth: width,
      windowHeight: height,
    };
  },
  data() {
    return {
      
      logoImage: require("@/assets/images/info.jpg"),

      // Settings : Settings,
      // myVar : myVar,
      articleId: null,
      steams: [],
      image: require("@/assets/images/Intersection.png"),
      btLoading: false,
      // windowWidth: window.innerWidth,

      // fix data
      btnameWeb: ["Join Meeting", "Coming Soon"],
      btnameMobile: ["Join", "Coming.."],
      dateWeb: ["21", "27"],
      dateMobie: ["21 April 9.30 AM", "27 April 9.30 AM"],

      timer: "",
    };
  },
  async created() {
    // console.clear();
    console.log("created Home");

    // this.$store.commit("setRoles", response.data.roles);
    // this.$store.dispatch("setStatusLogin", false);
    // this.$store.commit("setIsLogin", false);
    // alert(this.$store.getters.conferenceCode);
    // alert(this.$myVar);

    // this.messageAnnounce("ยินดีตอนรับ ");

    // this.messageAnnounce(" ขออภัยในความไม่สะดวก เนื่องจากไฟดับ");

    this.checkSesseions();

    this.getlistStream();
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },

  // watch: {
  //   windowWidth: function() {
  //      console.log('The window width is '+this.windowWidth );
  //     if (this.windowWidth === 768) {
  //       console.log('The window width is 768px');
  //     }
  //   }
  // },

  computed: {
    // isAdmin() {
    //   let roles = this.$store.getters.roles;
    //   let adminFlag = false;
    //   for (let role in roles) {
    //     console.log("role : " + roles[role]);
    //     if (roles[role] == "ADMIN") {
    //       adminFlag = true;
    //     }
    //   }

    //   return adminFlag;
    // },

    getSteams() {
      return ConferenceService.getlistStream();
    },
    // windowWidth() {
    //   return this.$store.state.windowWidth;
    // },
    basedOnWindowWidth() {
      if (this.windowWidth > 768) {
        return true;
      }
      return false;
    },
    basedOnMobileWidth() {
      if (this.windowWidth <= 768) {
        return true;
      }
      return false;
    },
  },

  methods: {
    formatDateFormatMobile(dataDate) {
      // $luxon.fromSQL();
      return this.$luxon(dataDate, {
        input: { format: "yyyy-MM-dd HH:mm:ss", zone: "asia/bangkok" },
        output: { format: "dd MMMM t" },
      });
    },

    formatDateFormat(dataDate, formatOutput) {
      // $luxon.fromSQL();
      return this.$luxon(dataDate, {
        input: { format: "yyyy-MM-dd HH:mm:ss", zone: "asia/bangkok" },
        output: { format: formatOutput },
      });
    },

    changeToken() {
      // alert("changeToken");
      store.commit("setToken", "testestsetse");
    },

    messageAnnounce(msg) {
      this.$buefy.notification.open({
        message: msg,
        //type: "is-success",
        duration: 10000,
        position: "is-bottom-right",
      });
    },
    calDateFormatMobile(dateBegin) {
      alert(dateBegin);
      // alert(dateBegin.getDay());
      return "23 MAY 2021";
    },

    Onloading() {
      this.btLoading = true;
    },
    goLoginPage() {
      // alert("goRegisterPage");
      this.$router.push({ path: "/" });
    },

    goEaPage() {
      // alert("goRegisterPage");
      this.$router.push({ path: "/EvaluationPage" });
    },

    goStremPage(item) {
      // item.streamName

      // alert("item : " + JSON.stringify(item, null, 2));
      console.log("Conference code : " + item.streamName);
      // alert("goRegisterPage");

      if (item.m3u8 == null) {
        item["m3u8"] = item.streamUrl;
        // item["m3u8"] =
        //   "https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4";
        item["typeVideo"] = "video/mp4";
        // alert(item.streamUrl );
      } else {
        // item["m3u8"] =
        //   "https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8";
        item["typeVideo"] = "application/x-mpegURL";
      }
      // alert(item.m3u8);

      this.$store.commit("setSteams", item);

      this.$router.push({ path: "/StreamPage/" + item.streamName });
    },

    // https://dev.catsmodish.com/api/v1/virtual/conference-stream-list
    // API_CF_06 Get conference stream list
    getlistStream() {
      const url = store.getters.url + "/api/v1/virtual/conference-stream-list";
      const token = store.getters.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      let bodyParameters = {
        // "username": user.username,
        // "password": user.password,
      };

      console.log("url : " + JSON.stringify(url, null, 2));
      console.log(
        "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      );
      console.log("config : " + JSON.stringify(config, null, 2));

      axios
        .get(url, config)
        .then((response) => {
          // your code
          if (response.data.status == "success") {
            console.log(
              "response.data : " + JSON.stringify(response.data, null, 2)
            );
            store.commit("setSteams", response.data.steams);
            this.steams = response.data.steams;
            // "response.data.steams : " + JSON.stringify(this.steams, null, 2)
          }
          // else
          // this.articleId = response.data.token;
          // alert("unsuccess");
        }, console.log)
        // .catch(console.log);
        .catch((error) => {
          alert("unsuccess");
          // if (error.request._isRedirect) {
          //   return axios.request({
          //     ...myConfig,
          //     url: error.request._options.path,
          //   });
          // }
          // alert("unsuccess");
          if (error.request._isRedirect) {
            return this.$router.push({ path: "/" });
          }
        });

      // return {test : "test" };
    },

    checkSesseions() {
      /// check session ///

      UserService.keepAlive(this.$store.getters.token);

      let token = store.getters.token;

      if (!token) {
        this.$router.push({ path: "/" });
      }

      clearInterval(this.timer);
      this.timer = setInterval(() => {
        UserService.keepAlive(this.$store.getters.token);

        if (!this.$store.getters.isLogin) {
          // alert(" seesion expire please login again");
          clearInterval(this.timer);
          this.$router.push({ path: "/" });
        }
      }, 5000);

      /// check session ///
    },
  },
  mounted() {
    console.log("mounted");
  },
};
</script>
<style scoped>
.level-item .title,
.level-item .subtitle {
  margin-bottom: 30px;
}
.posterSize {
  width: 338px;
  height: 234px;
}
.tailHeader {
  width: 300px;
  text-align: left;
  padding-left: 25px;
}
.btJoin {
  margin-top: 100px;
}
.contentBox1 {
  /* margin-right: 15px; */
}
.tailCenter {
  color: crimson;
}
nav {
  width: 800px;
  margin-bottom: 25px;
}
.card-body {
  align-self: center;
}
.title {
  font-size: 1rem;
}
#app {
  background-color: white;
  /* background-image: url(""); */
}
.bt_mobile {
  width: 100px;
}

/* @media screen and (min-width: 600px) {
  .card-body {
    align-self: center;
    width: 80%;
  }
} */
.mainBox {
  padding-top: 25px;
}
.bottomBox {
  padding-top: 5px;
}

/* Pattern styles */
.left-half {
  /* background-color: #ff9e2c; */
  display: inline-block;
  /* float: left; */
  width: 70%;
  text-align: left;
  margin: auto;
}
.right-half {
  /* background-color: #b6701e; */

  display: inline-block;
  /* float: left; */
  width: 30%;
  margin: auto;
  text-align: right;
}
.img-round {
  border-radius: 15px;
}
</style>


<template>
  <div class="card text-center m-3">
    <header-component ></header-component>


    <!-- <form ref="form" class="formQA " @submit.prevent="onSubmit">
      <div v-for="item in eaQuestionAns" :key="item.evaluationId">

    <div class="card qa">
  <div class="card-content">
    <div class="content">
      <p class="qaQuestion">Q1.Lorem ipsum leo risus, porta ac consectetur ac, vestibulum at eros. Donec id </p>

        <p>
        <b-field label="ANS" :label-position="labelPosition">
          <b-input

            type="textarea"
          
          >
          </b-input>
        </b-field>
      </p>
    </div>
  </div>
</div>
</div>

    </form> -->


    <div class="card-body"><strong>แบบประเมินผลงาน (Evaluation)</strong></div>
    <h1>Topic: Webinar - Powered by Prompt Event & Organize.</h1>
    <h3>ความพึงพอใจต่องานสัมมนา และวิทยากร (5 = มากที่สุด ,  4 = มาก,  3 = ปานกลาง , 2 = พอใจ , 1 = ไม่พอใจ)</h3>
    
    <!-- <h1>steams : {{ steams }}</h1> -->
    <!-- <h1>eaQuestionRatingChoice.length : {{ eaQuestionRatingChoice.length }}</h1> -->
    <!-- <div class="card-body">Returned Id: {{ steams.name }}</div> -->
    <!-- <ul id="example-1"> -->
    <!-- <li v-for="item in steams" :key="item.name"> -->

    <!-- <div class="tile is-ancestor" v-for="item in steams" :key="item.streamName">
      <div class="tile is-4">{{ item.name }}</div>
      <div class="tile is-4">{{ item.streamName }}</div>
      <div class="tile is-4">
        <b-button
          @click="goStremPage(item.streamName)"
          class="button is-primary"
          expanded
          outlined
          rounded
          >JOIN</b-button
        >
      </div>
    </div> -->
    
  <div class="card-content" v-if="votedFlag">
    <div class="content">
          <div class="card qa" style="padding: 50px;" >
      <strong>You Evaluation Already .</strong>
      
      </div>
      </div>
  </div>

    <form v-if="!votedFlag" ref="form" class="formQA" @submit.prevent="onSubmit">

      
<div class="card qa">
  <div class="card-content">
    <div class="content">


      <table>

         <div
        v-for="item in sortFunc()"
        :key="item.pollId + item.question"
      >
<!-- eaQuestionRatingChoice -->
      <!-- sortFunc() -->
        <tr>
          <td class="t-left">
            <!-- {{ item.pollId  }}  -->
      <p class="qaQuestion">{{ item.question  }} </p></td>
      
      <td class="t-right">
        

         <b-rate
            custom-text="rating"
            v-model="dataRating[item.pollId]"
            @change="changeRating"
            icon-pack="fa"
            icon="star"
            required
          ></b-rate>
      </td>
        </tr>



      <!-- TEST NEW CHOICED -->
        <!-- <tr>
          <td class="t-left">
      <p class="qaQuestion">{{ item.question  }} </p></td>
       </tr> <tr>
      <td class="t-right">
        


 
                <div
                    v-for="item2 in item.pollChoices"
                    :key="item2.choiceId"  class="t-right"
                  >


                    <b-field class="fontPoll">

                      <b-radio
                        v-model="radioSelected[item.pollId]"
                        :native-value="item2.choiceId"
                        type="is-success"
                      >
                        {{ item2.description}}
                      </b-radio>

                    </b-field>
                  </div>
       
      </td>
        </tr> -->

        
      </div>



      </table>



    </div>
  </div>
</div>



      <div v-for="item in eaQuestionAns" :key="item.evaluationId">

          <div class="card qa">
  <div class="card-content">
    <div class="content">

     <p class="qaQuestion">Q.{{ item.question }}</p>
     
     
     <p>  

       <b-field label="ANS" :label-position="labelPosition">
          <b-input
            v-model="questionSelected[item.evaluationId]"
            maxlength="200"
            type="textarea"
            required
          >
          </b-input>
        </b-field>

        </p>
    </div>
  </div>
</div>


        <!-- <div>Q.{{ item.question }}</div>
        <b-field label="ANS" :label-position="labelPosition">
          <b-input
            v-model="questionSelected[item.evaluationId]"
            maxlength="200"
            type="textarea"
            required
          ></b-input>
        </b-field> -->


        <!-- <div>ANS : {{ questionSelected[item.evaluationId] }}</div> -->
      </div>

      <!-- <div>mouted : {{ eaQuestionRatingChoiceTest.pollChoices }}</div> -->

      <!-- <div v-for="item3 in eaQuestionRating" :key="item3.pollId">
      <div>R.{{ item3.question }}</div> -->
      <!-- <div>length.{{getChoiceRating(item.pollId).length}}</div> -->
      <!-- <div v-for="item in getChoiceRating(item.pollId)" :key="item.pollId">
        </div> -->
      <!-- </div> -->
      <!-- <div>R.{{ eaQuestionRatingChoice }}</div> -->



      <!-- <div
        v-for="item in eaQuestionRatingChoice"
        :key="item.pollId + item.question"
      >
        <div>R.{{ item.question + " : " + item.pollId }}</div>

        <section>
          <div
            v-for="item2 in item.pollChoices"
            :key="item2.pollId + item2.description"
          > -->
            <!-- <div>C.{{ item2.description }}</div> -->

            <!-- <div>C.{{ item2.choiceId }}</div> -->

            <!-- <b-field>
              <b-radio
                v-model="radioSelected[item.pollId]"
                :native-value="item2.choiceId"
                type="is-success"
              >
                {{ item2.description + " : " + item.pollId }}
              </b-radio>
            </b-field> -->

            <!-- <b-rate custom-text="With MDI"></b-rate> -->
          <!-- </div>
          <b-rate
            custom-text="rating"
            v-model="dataRating[item.pollId]"
            @change="changeRating"
            icon-pack="fa"
            icon="star"
            required
          ></b-rate> -->

          <!-- <div>ans .{{ radioSelected[item.pollId] }}</div>
          <div>dataRating ans .{{ dataRating[item.pollId] }}</div> -->
        <!-- </section> -->

        <!-- <div>length.{{getChoiceRating(item.pollId).length}}</div> -->
        <!-- <div v-for="item in getChoiceRating(item.pollId)" :key="item.pollId">
        </div> -->
      <!-- </div> -->

      <!-- </li> -->
      <!-- </ul> -->
      
  <div class="card-content">
    <div class="content">
        <p v-if="errors.length" class="is-danger error">
          <b>Please correct the following error(s):</b>
          <ul>
            <li v-for="error in errors" :key="error">{{ error }}</li>
          </ul>
      </p>


      <div>
        <b-button
          native-type="submit"
          @click="saveEvaluation"
          
            :loading="btLoading"
            :disabled="btLoading"

          class="button is-dark"
          expanded
          outlined
          rounded
          >Send Evaluation</b-button
        >
      </div>
    </div></div>
    </form>
    
    <footer-component></footer-component>
  </div>
</template>

<script>
// import UserService from "@/service/UserService";
import UserService from "@/service/UserService";
import ConferenceService from "@/service/ConferenceService";
import EvaluationService from "@/service/EvaluationService";

import axios from "axios";
import { store } from "@/store/store";
import FooterComponent from "./footer.vue";
import HeaderComponent from "./header.vue";

export default {
  name: "post-request",
  components: {
    FooterComponent,
    HeaderComponent,
  },

  data() {
    return {
      errors: [],
      dataRating: [],
      labelPosition: "on-border",
      radio: "default",
      radioSelected: [],
      questionSelected: [],
      articleId: null,
      steams: [],
      eaQuestionAns: [],
      eaQuestionRating: [],
      eaQuestionRatingChoice: [],
      eaQuestionRatingChoiceTest: [],
      votedFlag: false,
      btLoading: false,
    };
  },
  mounted() {
    // this.eaQuestionRatingChoiceTest = this.getChoiceRating(1);
  },

  computed: {
    getSteams() {
      return ConferenceService.getlistStream();
    },
    // getChoiceRatingCumputed(id) {
    //   return (this.eaQuestionRatingChoiceTest = this.getChoiceRating(id));
    // },

    // sortedArray: function () {
    //   function compare(a, b) {
    //     if (a.pollId < b.pollId) return -1;
    //     if (a.pollId > b.pollId) return 1;
    //     return 0;
    //   }

    //   return this.eaQuestionRatingChoice.conferencePolls.sort(compare);
    // },
  },

  methods: {

    
    checkSesseions() {
      /// check session ///

      UserService.keepAlive(this.$store.getters.token);

      let token = store.getters.token;

      if (!token) {
        this.$router.push({ path: "/" });
      }

      clearInterval(this.timer);
      this.timer = setInterval(() => {
        UserService.keepAlive(this.$store.getters.token);

        if (!this.$store.getters.isLogin) {
          // alert(" seesion expire please login again");
          clearInterval(this.timer);
          this.$router.push({ path: "/" });
        }
      }, 5000);

      /// check session ///
    },

    // sortFunc: function () {
    //   return this.eaQuestionRatingChoice.slice().sort(function (a, b) {
    //     return a.question > b.question ? 1 : -1;
    //   });
    // },

    sortFunc: function () {
      return this.eaQuestionRatingChoice.slice().sort(function (a, b) {
        return a.pollId > b.pollId ? 1 : -1;
      });
    },

    
    // sortFunction: function (Choice) {
    //   return Choice.slice().sort(function (a, b) {
    //     return a.question > b.question ? 1 : -1;
    //   });
    // },

    success(msg) {
      // const notif =
      this.$buefy.notification.open({
        message: msg,
        type: "is-success",
        duration: 5000,
      });
      // notif.$on("close", () => {
      // this.$buefy.notification.open("Custom notification closed!");
      // this.$router.push({ path: "/" });
      // });
    },
    printObject(obj) {
      console.log("printObject : " + JSON.stringify(obj, null, 2));
    },
    onSubmit() {
      this.$emit("submit", this.model);
    },
    doSomethings(val) {
      console.log(val);
      alert(val);
    },
    changeRating(value) {
      console.log("===  changeRating ===  " + value);
      // console.log("===  dataRating ===  " + dataRating[item.pollId]);

      // map

      // console.log("===  changeRating ===  " + this.dataRating[value]);

      // this.$buefy.toast.open({
      //   message: "Thanks for you Rate!",
      //   type: "is-success",
      // });
    },
    goLoginPage() {
      // alert("goRegisterPage");
      this.$router.push({ path: "/" });
    },
    goStremPage(code) {
      console.log("Conference code : " + code);
      // alert("goRegisterPage");
      this.$router.push({ path: "/StreamPage/" + code, code });
    },

    getTestFn() {
      console.log("===  getTestFn ===  ");
    },

    saveEvaluation() {
      this.btLoading = true;
      console.log("===  saveEvaluation ===  ");

      // console.clear();
      this.errors = [];

      // loop add error
      for (let i = 0; i < this.eaQuestionAns.length; i++) {
        console.log(
          " eaQuestionAns evaluationId : " + this.eaQuestionAns[i].evaluationId
        );
        if (!this.questionSelected[this.eaQuestionAns[i].evaluationId]) {
          this.errors.push("ANS required NO." + (i + 1));
        }
      }

      // loop add error
      for (let i = 0; i < this.eaQuestionRatingChoice.length; i++) {
        console.log(
          " eaQuestionRatingChoice pollId : " +
            this.eaQuestionRatingChoice[i].pollId
        );
        if (!this.dataRating[this.eaQuestionRatingChoice[i].pollId]) {
          this.errors.push("Rating required NO." + (i + 1));
        }
        let pollChoices = this.eaQuestionRatingChoice[i].pollChoices;
        let ansRating = this.dataRating[this.eaQuestionRatingChoice[i].pollId];

        // if rating has value
        if (ansRating) {
          this.printObject(ansRating);
          console.log("seleced star : " + ansRating);

          // map rating to eaQuestionRatingChoice
          let ansChoiceId = pollChoices[ansRating - 1].choiceId;

          console.log("choiceId rating  :" + ansChoiceId);
        }
        this.printObject(pollChoices[4].choiceId);

        // console.log(this.dataRating[this.eaQuestionRatingChoice[i].pollId]);
      }

      // eaQuestionAns" :key="item.evaluationId

      // loop add save data
      if (this.errors.length == 0) {
        console.log("===  save data EA  ===  ");

        // loop save QuestionAns
        for (let i = 0; i < this.eaQuestionAns.length; i++) {
          let idQuestion = this.eaQuestionAns[i].evaluationId;
          let ansQuestion =
            this.questionSelected[this.eaQuestionAns[i].evaluationId];

          console.log("id question : " + idQuestion + "save : " + ansQuestion);
          EvaluationService.saveEvaluationQuestion(idQuestion, ansQuestion);
        }

        // loop save Rating vote

        for (let i = 0; i < this.eaQuestionRatingChoice.length; i++) {
          let pollId = this.eaQuestionRatingChoice[i].pollId;
          let pollChoices = this.eaQuestionRatingChoice[i].pollChoices;
          let ansRating =
            this.dataRating[this.eaQuestionRatingChoice[i].pollId];

          // if rating has value
          if (ansRating) {
            // map rating to eaQuestionRatingChoice
            let ansChoiceId = pollChoices[ansRating - 1].choiceId;

            // console.log("save choiceId rating  :" + ansChoiceId);
            console.log(
              "id question : " +
                pollId +
                " : save choiceId rating  :" +
                ansChoiceId
            );
            EvaluationService.saveEvaluationQuestionRating(ansChoiceId);
          }
        }

        this.success("Complete Evaluation");
        var that = this;
        setTimeout(function () {
          that.$router.push({ path: "/HomePage" });
        }, 1500);
      } else {
        console.log("===  you have EA error  ===  ");
      }

      this.btLoading = false;
    },

    // https://dev.catsmodish.com/api/v1/virtual/conference-stream-list
    // API_CF_06 Get conference stream list
    getlistStream() {
      const url = store.getters.url + "/api/v1/virtual/conference-stream-list";
      const token = store.getters.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      // let bodyParameters = {
      //   // "username": user.username,
      //   // "password": user.password,
      // };

      // console.log("url : " + JSON.stringify(url, null, 2));
      // console.log(
      //   "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      // );
      // console.log("config : " + JSON.stringify(config, null, 2));

      axios
        .get(url, config)
        .then((response) => {
          // your code
          if (response.data.status == "success") {
            console.log(
              "response.data : " + JSON.stringify(response.data, null, 2)
            );
            store.commit("setSteams", response.data.steams);
            this.steams = response.data.steams;
            // "response.data.steams : " + JSON.stringify(this.steams, null, 2)
          }
          // this.articleId = response.data.token;
        }, console.log)
        .catch(console.log);

      // return {test : "test" };
    },

    //  list question Evaluation
    // API_EA_04 List conference evaluation (User)
    // https://service.promptevent.com/api/v1/virtual/conference-evaluation-list
    getListEaQuestion() {
      const url =
        store.getters.url + "/api/v1/virtual/conference-evaluation-list";
      const token = store.getters.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      // let bodyParameters = {
      //   // "username": user.username,
      //   // "password": user.password,
      // };

      // console.log("url : " + JSON.stringify(url, null, 2));
      // console.log(
      //   "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      // );
      // console.log("config : " + JSON.stringify(config, null, 2));

      axios
        .get(url, config)
        .then((response) => {
          // your code
          if (response.data.status == "success") {
            console.log(
              "eaQuestionAns.data : " + JSON.stringify(response.data, null, 2)
            );

            console.clear();
            this.eaQuestionAns = response.data.conferenceEvaluations;

            // store.commit("setSteams", response.data.steams);
            // this.steams = response.data.steams;
            // "response.data.steams : " + JSON.stringify(this.steams, null, 2)
          }
          // this.articleId = response.data.token;
        }, console.log)
        .catch(console.log);

      // return {test : "test" };
    },

    // list question rating VoteModel
    // API_EA_11 Get conference poll list
    // https://service.promptevent.com/api/v1/virtual/conference-poll-list
    async getListEaQuestionRating() {
      const url = store.getters.url + "/api/v1/virtual/conference-poll-list";
      const token = store.getters.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      let bodyParameters = {
        // "username": user.username,
        // "password": user.password,
      };

      console.log("url : " + JSON.stringify(url, null, 2));
      console.log(
        "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      );
      console.log("config : " + JSON.stringify(config, null, 2));

      await axios
        .get(url, config)
        .then((response) => {
          // your code
          if (response.data.status == "success") {
            console.log(
              "getListEaQuestionRating.data : " +
                JSON.stringify(response.data, null, 2)
            );

            this.eaQuestionRating = response.data.conferencePolls;

            // console.log(
            //   "eaQuestionRating.data : " +
            //     JSON.stringify(this.eaQuestionRating, null, 2)
            // );

            // let eaQuestionRatingChoice;

            // error for
            for (let i = 0; i < this.eaQuestionRating.length; i++) {
              // this.getTestFn();

              console.log(
                "this.getChoiceRating(item.pollId): " +
                  this.eaQuestionRating[i].pollId
              );

              console.log(
                "this.item(item.pollId): " +
                  JSON.stringify(
                    this.getChoiceRating(this.eaQuestionRating[i].pollId),
                    null,
                    2
                  )
              );

              //  this.eaQuestionRatingChoice[
              //   this.eaQuestionRating[i].pollId
              // ] =  this.getChoiceRating(this.eaQuestionRating[i].pollId);

              //  this.eaQuestionRatingChoice.push(
              //   this.eaQuestionRating[i].pollId,
              //   this.getChoiceRating(this.eaQuestionRating[i])
              // );
            }
            console.log(
              "eaQuestionRatingChoice : " +
                JSON.stringify(this.eaQuestionRatingChoice, null, 2)
            );
            // store.commit("setSteams", response.data.steams);
            // this.steams = response.data.steams;
            // "response.data.steams : " + JSON.stringify(this.steams, null, 2)
          }
          // this.articleId = response.data.token;
        }, console.log)
        .catch(console.log);

      // return {test : "test" };
    },

    /// get choice rating
    // API_EA_13 Get conference poll details {pollId}
    // https://service.promptevent.com/api/v1/virtual/conference-poll-details/1
    // Future getChoiceRating(int voteId) async {
    async getChoiceRating(voteId) {
      // console.log("getChoiceRating voteId : " + voteId);
      const url =
        store.getters.url + "/api/v1/virtual/conference-poll-details/" + voteId;
      const token = store.getters.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      // let bodyParameters = {
      //   // "username": user.username,
      //   // "password": user.password,
      // };

      // console.log("url : " + JSON.stringify(url, null, 2));
      // console.log(
      //   "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      // );
      // console.log("config : " + JSON.stringify(config, null, 2));

      await axios
        .get(url, config)
        .then((response) => {
          // your code
          if (response.data.status == "success") {
            console.log(
              "getChoiceRating.data : " + JSON.stringify(response.data, null, 2)
            );

            this.eaQuestionRatingChoice.push(response.data);

            // check already voted
            let objectVoted = response.data.pollChoices.filter(function (item) {
              // console.log("item.voted :"+item.voted );
              return item.voted > 0;
            });
            console.log(
              " already voted " +
                objectVoted.length +
                " : " +
                JSON.stringify(objectVoted, null, 2)
            );
            if (objectVoted.length > 0) {
              console.log("objectVoted : already voted");
              this.votedFlag = true;
            }
            // if()

            return response.data;
            // store.commit("setSteams", response.data.steams);
            // this.steams = response.data.steams;
            // "response.data.steams : " + JSON.stringify(this.steams, null, 2)
          }
          // this.articleId = response.data.token;
        }, console.log)
        .catch(console.log);

      // return response.data.conferenceEvaluations;
      return 100;
    },

    /// check Is already vote
    // API_EA_13 Get conference poll details {pollId}
    // https://service.promptevent.com/api/v1/virtual/conference-poll-details/1
    // isEvaluationAlready(int voteId)

    isEvaluationAlready(voteId) {
      // console.log("getChoiceRating voteId : " + voteId);
      const url =
        store.getters.url + "/api/v1/virtual/conference-poll-details/" + voteId;
      const token = store.getters.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      // let bodyParameters = {
      //   // "username": user.username,
      //   // "password": user.password,
      // };

      // console.log("url : " + JSON.stringify(url, null, 2));
      // console.log(
      //   "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      // );
      // console.log("config : " + JSON.stringify(config, null, 2));

      axios
        .get(url, config)
        .then((response) => {
          // your code
          if (response.data.status == "success") {
            console.log(
              "isEvaluationAlready.data : " +
                JSON.stringify(response.data, null, 2)
            );

            this.eaQuestionRatingChoice.push(response.data);
            return response.data;
          }
        }, console.log)
        .catch(console.log);

      return 100;
    },

    // 	send ans Evaluation
    // API_EA_05 Answer conference evaluation
    // https://service.promptevent.com/api/v1/virtual/conference-evaluation-answer

    // 	send vote rating Evaluation
    // API_EA_12 Vote conference poll
    // https://service.promptevent.com/api/v1/virtual/conference-poll-vote
  },

  async created() {
    console.log("created");
    
    this.checkSesseions();

    let token = store.getters.token;

    if (!token) {
      this.$router.push({ path: "/" });
    }

    // alert("sss");

    // loop
    // setInterval(() => {
    //   UserService.keepAlive(this.$store.getters.token);
    // }, 5000);

    // this.getlistStream();

    this.getListEaQuestion();
    this.getListEaQuestionRating();
    // let token = this.$store.getters.token;

    // let data =
    // this.steams = await ConferenceService.getlistStream();
    // this.steams = await rs.steams;
    // this.steams = data.steams;

    // console.log("rs = " + rs);
    // console.log("steams = " + this.$store.getters.steams);

    // const url = store.getters.url + "/api/v1/virtual/login";
    // // const token = "";

    // const config = {
    //   headers: {
    //     // Authorization: `Bearer ${token}`,
    //     "x-conference-code": store.getters.conferenceCode,
    //     "Content-Type": store.getters.ContentType,
    //   },
    // };

    // const bodyParameters = {
    //   // username: "admin5@catsmodish.com",
    //   // password: "admin12345",
    //   username: user.username,
    //   password: user.password,
    // };

    // axios
    //   .post(url, bodyParameters, config)
    //   .then((response) => {
    //     // your code
    //     this.articleId = response.data.token;

    //     // this.$router.push({ name: "home" });

    //   }, console.log)
    //   .catch(console.log);
    // // .then((response) => (this.articleId = response.data.id));
  },
};
</script>
<style scoped>
.error {
  color: rgb(255, 0, 0);
}
.qa {
  align-self: center;
  margin: 25px;
  /* width: 80%; */
}
.qaQuestion {
  text-align: left;
}
.formQA {
  align-self: center;
}
.t-right {
  width: 30%;
  /* min-width: 200px; */
}
.t-left {
  width: 100%;
}
</style>
// import { Settings } from '@/variables.js'
import axios from "axios";
import { store } from "@/store/store";
// import HttpService from "@/service/HttpService";

import DebugService from "@/service/DebugService";



export default {


    async onLogin(email, password) {
        // set load state
        // this.btLoading = true;
        let statusLogin;

        // alert("OnLogin");
        // const url = "https://service.promptevent.com/api/v1/virtual/login";
        const url = store.getters.url + "/api/v1/virtual/login";
        // const token = "";

        const config = {
            headers: {
                // Authorization: `Bearer ${token}`,
                // "x-conference-code": "rqkO9ZjUkUgOZjXq56ZkR2boLrCfvPEc",
                "x-conference-code": store.getters.conferenceCode,
                "Content-Type": "application/json",
            },
        };

        const bodyParameters = {
            // username: "admin5@catsmodish.com",
            // password: "admin12345",
            username: email,
            password: password,
        };

        // console.log("url : " + url);

        // console.log("url : " + JSON.stringify(url, null, 2));
        // console.log(
        //   "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
        // );
        // console.log("config : " + JSON.stringify(config, null, 2));

        await axios
            .post(url, bodyParameters, config)
            // .then(console.log)
            .then(
                (response) => {
                    // alert("response");
                    // console.log(
                    //   "response.data : " + JSON.stringify(response.data, null, 2)
                    // );

                    DebugService.printConsole("onLoign", response.data);
                    // console.log(response.data);

                    if (response.data.status == "failure") {
                        let errorMsg = "Error : " + response.data.statusDesc;

                        // store.userLogin.commit("SET_MESSAGE_ERROR",this.errorMsg);
                        store.commit("userLogin/SET_MESSAGE_ERROR", "login failure");
                        store.commit("userLogin/SET_MESSAGE_ERROR", errorMsg);
                        DebugService.printConsole("data.status failure", response.data);

                        // set state unload
                        // this.danger(errorMsg);
                        // this.btLoading = false;

                        statusLogin = false;
                        return false;


                    } else if (response.data.status == "success") {
                        this.errorMsg = response.data.status;
                        // console.log("data : " + response.data);
                        DebugService.printConsole("data success", response.data);
                        // store.commit("userLogin/SET_MESSAGE_ERROR", "login success");
                        // alert( JSON.stringify(response.data, null, 2));
                        store.commit("setUser", response.data.firstName);
                        store.commit("setToken", response.data.token);
                        store.commit("setRoles", response.data.roles);
                        store.commit("setIsLogin", true);
                        store.commit("SET_LOGIN_KEY", response.data.roles);
                        statusLogin = true;
                        return true;

                        // this.router.push({ path: "/HomePage" });

                    } else {
                        // error request
                        // console.log("data : " + response.data);
                        DebugService.printConsole("error request", response.data);
                        store.commit("userLogin/SET_MESSAGE_ERROR", "error request");
                        statusLogin = false;
                        return false;
                        // this.$router.push({ path: "/" });
                    }

                    // save token

                    // router to home

                    // console.log;
                    // alert(this.errorMsg);
                    // this.$router.push({ name: "home" });
                }

                // console.log
            )
            .catch(function (error) {
                // handle error
                // alert("ERROR");
                console.log(error);
            })
            .then(function () {
                // always executed
                // alert("always executed");
            });

        // alert("ERROR");

        return statusLogin;
    },


    
    
    // RT_008 Check in QR CODE Report
    // https://report.promptevent.com/rt/008?name=S1618896440&key=tDW9IkdpVT9cVhI35EhtgirigaO136b7HexlEnbIawgPHTXtEkMQ7lpYgVyajfXnZI8INeqFtfUQTkamnKHEE2k7Cclgyo2nspnKuCtrawmvgCBr5rfCkx1UdybAVYUMPQ6lUEMdMLyXgaI6OIDvZ1LhV2MHJT9tKdgZnKuUwUwAp9Mws8GQoQesZAP7LkLHLF79ztDStJi4HWh56TXso4XnxIqEiIS5mj7s2wEz5eVpCeNnpZCioyUuXhLXNNwCYgu9cqQgxvrkOzZxXHeJe4U5gZ6AY24cpsUb7pIFMiD3egvvLpff8PWHW22OVqjb9LxvEptIWHSGS5z3mPBnJuWOpZ9vDxpqZvribifLfU7sOJCrJGJFRK7x4Gep8x5xLoW34r2V3GUr271EQfHvuhprrJS44JzE8xT4oEhXMhDOefnpa2VZ1Q3wMIreFWMbF1Y5TxVEiZrk2ydmd2LEKHYgWlkTzzFtOHb2l1meqKH6TixlDWTJ9Qgx7ceXDX7y
    async reportCheckinQRCODE(channelCode) {


        // channelCode = "S1620809737";
        // https://report.promptevent.com/rt/003?name=S1618896440&key=$key
        // const url = store.getters.url + "/api/v1/virtual/keep-alive";
        // const token = store.getters.token;
        const loginKey = store.getters.loginKey;

        const url = "https://report.promptevent.com/rt/008?name=" + channelCode + "&key=" + loginKey;

        console.log("url : " + url);


        const label = "reportCheckinQRCODE_" + channelCode;

        let blob;

        await axios
            .get(url, { responseType: "blob" })
            .then((response) => {
                blob = new Blob([response.data], { type: "application/vnd.ms-excel" });
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = label;
                link.click();
                URL.revokeObjectURL(link.href);
            })
            .catch(console.error);


        // เพื่อให้ รอ ประมวลผลเสร็จเเ
        if (blob != null) {
            return false;
        }else{
            return false;
        }
    

    },



  // https://report.promptevent.com/rt/007?key=1XCmO3VKN3thP4Q5vXb6fP5TzTrL9v7O7SUQU1TvEITas7wmiZu1V99
  // RT_007 QRCode Print 1

  async DownloadAllQRCODE() {

        // channelCode = "S1620809737";
        // dateSelected = "20210513";

        // https://report.promptevent.com/rt/001?name=S1620809737&date=20210513&key=$key
        // const url = store.getters.url + "/api/v1/virtual/keep-alive";
        // const token = store.getters.token;
        const loginKey = store.getters.loginKey;

        const url = "https://report.promptevent.com/rt/007?key=" + loginKey ;

        console.log("url : " + url);


        const label = "reportAllQRCODE";

        let blob;

        await axios
            .get(url, { responseType: "blob" })
            .then((response) => {
                blob = new Blob([response.data], { type: "application/pdf" });
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = label;
                link.click();
                URL.revokeObjectURL(link.href);

                return false;
            }).catch((error) => {
                console.error(error);
                return true;
            });

        // เพื่อให้ รอ ประมวลผลเสร็จเเ
        if (blob != null) {
            return false;
        }else{
            return false;
        }


    },

    // RT_001 Live Streaming Viewer Report
    // https://report.promptevent.com/rt/001?name=S1620809737&date=20210513&key=Ujn9SBqmTqBHRCMjw8z4k8wL5Q2akSD3y32arJGmNA4968Nr4u2B5e4dZu2DgJeEprsoUs7b86o62j53p4vKLGYZiZiiI2HwyAx2Jsw3vK5VmBO1PyAbTMlVSq2Ck2p8bYhGQpSmxK8NOLoKENrjziDyWeIHXtf9Rur53fXEmxmGK1LjGkKPazOLHqxjkFkJz4Jt4wx9JNNTSadVhLgL1pRI6Ri67RK9BLeEILKQxpbCKxVDPmkXBiRRWrSHNClDiKekf14bYIB95KQis6x1NSYminvN7Ib2zo5ZzXunv8VkKwITvebvsYDVJ3ehF6NNgSJlGmq8fAH2HedlkE4vb3aLMzRtj4hhdsgWE5mhL4xJfcYmk2MB87IKtFv2vG91rgLS6YADww2EcpJN33jSrWnErXF5LdThiXxbGjTgvZZbZcvUN3chbRIOA47SxTczsBsJuG2BJVApA35YeEPPxPtAEGPS2mrKnxC8nnKbHsE47SkPKFgNSU8bwM6E7H5J
    async reportLiveStreamingViewer(channelCode, dateSelected) {

        // channelCode = "S1620809737";
        // dateSelected = "20210513";

        // https://report.promptevent.com/rt/001?name=S1620809737&date=20210513&key=$key
        // const url = store.getters.url + "/api/v1/virtual/keep-alive";
        // const token = store.getters.token;
        const loginKey = store.getters.loginKey;

        const url = "https://report.promptevent.com/rt/001?name=" + channelCode + "&date=" + dateSelected + "&key=" + loginKey;

        console.log("url : " + url);


        const label = "reportLiveStreamingViewer_" + channelCode + "_" + dateSelected;

        let blob;

        await axios
            .get(url, { responseType: "blob" })
            .then((response) => {
                blob = new Blob([response.data], { type: "application/vnd.ms-excel" });
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = label;
                link.click();
                URL.revokeObjectURL(link.href);

                return false;
            }).catch((error) => {
                console.error(error);
                return true;
            });

        // เพื่อให้ รอ ประมวลผลเสร็จเเ
        if (blob != null) {
            return false;
        }else{
            return false;
        }


    },

    // RT_002 Archived Streaming Viewer Report
    // https://report.promptevent.com/rt/002?name=S1623923766&date=20210704&key=A8DOaI8XhT7WgKrm9mRLNugokgPhbyC5h2kZRjZKlSa8VercgoJDo5a3ogOL6QMjH8hIivwkMfxZr94YZlfqTm7PBB4k6Wnn3ebnQYSMLeypwpySmdlA94y1dOD4M1hGyAij3dWjxDCPRztrGh93DOt4PMV1D5Fu3lucpg7ebuoVDji2jzWadJJSpFqETSBpM32sdZPnBfpJW4F4RAOl5MChRlOVhnxrWLKmdEokfzheaToJN11SKCWaJFZ8wgav3oJCsmEo6BdPnuvC5qSwygRzyRqX1nlnnt9aBjS2t9gI7Etx6myXHZVlLPm21sepgMzO7QB1VT7qR5S81Q85DrLPL3dMonDgJAtfYa33WcLJnGbf9Km2k2TzaJwvNRD2pQAMuGworirpgRC7eeN1XeVsqgJkCWS9tWjlPkxVhUKiHPx4XzLcYzdk6xeieYn8eAyPWn7cRWCpehBuz2cW9FOBrEgsHScjkiEPdAkFA1ZB2mZ1UWXEGBcbBQJuAIuS
    async reportPlaybackStreamingViewer(channelCode, dateSelected) {


        // channelCode = "S1620809737";
        // dateSelected = "20210513";

        // https://report.promptevent.com/rt/002?name=S1623923766&date=20210704&key=$key
        // const url = store.getters.url + "/api/v1/virtual/keep-alive";
        // const token = store.getters.token;
        const loginKey = store.getters.loginKey;

        const url = "https://report.promptevent.com/rt/002?name=" + channelCode + "&date=" + dateSelected + "&key=" + loginKey;

        console.log("url : " + url);


        const label = "reportPlaybackStreamingViewer_" + channelCode + "_" + dateSelected;

        let blob;

        await axios
            .get(url, { responseType: "blob" })
            .then((response) => {
                blob = new Blob([response.data], { type: "application/vnd.ms-excel" });
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = label;
                link.click();
                URL.revokeObjectURL(link.href);
            })
            .catch(console.error);


        // เพื่อให้ รอ ประมวลผลเสร็จเเ
        if (blob != null) {
            return false;
        }else{
            return false;
        }


    },


    // RT_003 Stream Question & Answer Report
    // https://report.promptevent.com/rt/003?name=S1618896440&key=tDW9IkdpVT9cVhI35EhtgirigaO136b7HexlEnbIawgPHTXtEkMQ7lpYgVyajfXnZI8INeqFtfUQTkamnKHEE2k7Cclgyo2nspnKuCtrawmvgCBr5rfCkx1UdybAVYUMPQ6lUEMdMLyXgaI6OIDvZ1LhV2MHJT9tKdgZnKuUwUwAp9Mws8GQoQesZAP7LkLHLF79ztDStJi4HWh56TXso4XnxIqEiIS5mj7s2wEz5eVpCeNnpZCioyUuXhLXNNwCYgu9cqQgxvrkOzZxXHeJe4U5gZ6AY24cpsUb7pIFMiD3egvvLpff8PWHW22OVqjb9LxvEptIWHSGS5z3mPBnJuWOpZ9vDxpqZvribifLfU7sOJCrJGJFRK7x4Gep8x5xLoW34r2V3GUr271EQfHvuhprrJS44JzE8xT4oEhXMhDOefnpa2VZ1Q3wMIreFWMbF1Y5TxVEiZrk2ydmd2LEKHYgWlkTzzFtOHb2l1meqKH6TixlDWTJ9Qgx7ceXDX7y
    async reportQAStreaming(channelCode) {


        // channelCode = "S1620809737";
        // https://report.promptevent.com/rt/003?name=S1618896440&key=$key
        // const url = store.getters.url + "/api/v1/virtual/keep-alive";
        // const token = store.getters.token;
        const loginKey = store.getters.loginKey;

        const url = "https://report.promptevent.com/rt/003?name=" + channelCode + "&key=" + loginKey;

        console.log("url : " + url);


        const label = "reportQAStreaming_" + channelCode;

        let blob;

        await axios
            .get(url, { responseType: "blob" })
            .then((response) => {
                blob = new Blob([response.data], { type: "application/vnd.ms-excel" });
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = label;
                link.click();
                URL.revokeObjectURL(link.href);
            })
            .catch(console.error);


        // เพื่อให้ รอ ประมวลผลเสร็จเเ
        if (blob != null) {
            return false;
        }else{
            return false;
        }
    

    },

    // RT_004 Stream Poll Report
    // https://report.promptevent.com/rt/004?name=S1613754744&key=7IrBChtDffj9wHv3HcwiNR4BtoZx5lcwTxmZBJvnZgI3mhqy5D2Hs436R4o8ps7nHYps3ZfO5wBoryuezpBcJrZTaTPX2Zi1cMnLQ5VP2Y5pWPFbkhjLqrYZ1KtG3kRC7eC7jpwBmrMM8Yked7dIyLmXhpVOc2ECMXdGoBvkAOuQIR4AuBpjaizxwR3X5iwKVZFzew7TjXdkTzjfda6hSakMigtWANTGSJ1NvMPwZgmzNe8gX5BPmKsuQLIHfCCheRF8z3gTL6pvr9G5uhWcTvdzQOpjJ1oThuoHHXaw7yao8HVv8fgrKdHXTSQfQhsvUdiQsNELCLMkCIGcya1ClKUr6k698uAQJDOJcJSSwNeJtoL8FixN1BQr8NbHzZv3KE3fGHxKMLw3fYRKncw64tAaiZmxVlAgxXJN6MR8vomSij93R7P1jx9kyAX4mxcCz9aK2bNN8t1XgnUlHh1pI2t4pfl28zorE4Gxfx9esWawIOUr7lYexmhbUvyJ7ee3
    async reportPollStreaming(channelCode) {

        // channelCode = "S1620809737";
        // https://report.promptevent.com/rt/004?name=S1613754744&key=$key
        // const url = store.getters.url + "/api/v1/virtual/keep-alive";
        // const token = store.getters.token;
        const loginKey = store.getters.loginKey;

        const url = "https://report.promptevent.com/rt/004?name=" + channelCode + "&key=" + loginKey;

        console.log("url : " + url);


        const label = "reportPollStreaming_" + channelCode;

       
        let blob;

        await axios
            .get(url, { responseType: "blob" })
            .then((response) => {
                blob = new Blob([response.data], { type: "application/vnd.ms-excel" });
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = label;
                link.click();
                URL.revokeObjectURL(link.href);
            })
            .catch(console.error);


        // เพื่อให้ รอ ประมวลผลเสร็จเเ
        if (blob != null) {
            return false;
        }else{
            return false;
        }

    },



    // RT_005 Conference Evaluation Question & Answer Report
    // https://report.promptevent.com/rt/005?key=A8DOaI8XhT7WgKrm9mRLNugokgPhbyC5h2kZRjZKlSa8VercgoJDo5a3ogOL6QMjH8hIivwkMfxZr94YZlfqTm7PBB4k6Wnn3ebnQYSMLeypwpySmdlA94y1dOD4M1hGyAij3dWjxDCPRztrGh93DOt4PMV1D5Fu3lucpg7ebuoVDji2jzWadJJSpFqETSBpM32sdZPnBfpJW4F4RAOl5MChRlOVhnxrWLKmdEokfzheaToJN11SKCWaJFZ8wgav3oJCsmEo6BdPnuvC5qSwygRzyRqX1nlnnt9aBjS2t9gI7Etx6myXHZVlLPm21sepgMzO7QB1VT7qR5S81Q85DrLPL3dMonDgJAtfYa33WcLJnGbf9Km2k2TzaJwvNRD2pQAMuGworirpgRC7eeN1XeVsqgJkCWS9tWjlPkxVhUKiHPx4XzLcYzdk6xeieYn8eAyPWn7cRWCpehBuz2cW9FOBrEgsHScjkiEPdAkFA1ZB2mZ1UWXEGBcbBQJuAIuS
    async reportEvaluationQAStreaming() {

        // https://report.promptevent.com/rt/005?key=$key
        // const url = store.getters.url + "/api/v1/virtual/keep-alive";
        // const token = store.getters.token;
        const loginKey = store.getters.loginKey;

        const url = "https://report.promptevent.com/rt/005?key=" + loginKey;

        console.log("url : " + url);


        const label = "reportEvaluationQAStreaming";

        
        let blob;

        await axios
            .get(url, { responseType: "blob" })
            .then((response) => {
                blob = new Blob([response.data], { type: "application/vnd.ms-excel" });
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = label;
                link.click();
                URL.revokeObjectURL(link.href);
            })
            .catch(console.error);


        // เพื่อให้ รอ ประมวลผลเสร็จเเ
        if (blob != null) {
            return false;
        }else{
            return false;
        }
    },



    // RT_006 Conference Evaluation Poll Report
    // https://report.promptevent.com/rt/006?key=EeFqGomU97eI5Yi4UHzgr3TlcOMATB3yW1MJq7raiWPWl2au3Lp7RZkVVu5xmjU71Y4y8xSKwSEZQRH8YeBMa2XZuNg6z2W2Y8MlInSpINtDfua23z5iEGOZkY5oqstQOYeeuAWbLxPme5cSRoyg4DmMl13vFSOral5jlMpljhRoztszEKkz3wtdsePu9bS1EOY1MZn1YjYwQPiJkeImQ462rUDqV2KRndCtHN2T7YQCa1MupggrTPF6F9JJGkclaGPt7bpha4UTj4Je3W81iPmmKfXpWZURv3yAyWH8UuBa3dLNX2FLDSCqo4lSiv5f1AmbnXPVwq6QGav4Uh8r26hgdCz1pfcCuJRSF7cac6qNvE2flexrp1K4nLzzsnq8UaHMBIOwlRFOloi2ZDJXg7qUqp6mJs7UrtoL9T5GXub2nacUHBlkvJDGUxT1G7jtqiNxwO5QejezOKetyBZiTHGFXXkJgb5FpjUEVpG3OAawPnX1aHUhlyNG4f6dayi7
    async reportEvaluationPollStreaming() {

        
        // https://report.promptevent.com/rt/006?key=$key
        // const url = store.getters.url + "/api/v1/virtual/keep-alive";
        // const token = store.getters.token;
        const loginKey = store.getters.loginKey;

        const url = "https://report.promptevent.com/rt/006?key=" + loginKey;

        console.log("url : " + url);


        const label = "reportEvaluationPollStreaming";

        
        let blob;

        await axios
            .get(url, { responseType: "blob" })
            .then((response) => {
                blob = new Blob([response.data], { type: "application/vnd.ms-excel" });
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = label;
                link.click();
                URL.revokeObjectURL(link.href);
            })
            .catch(console.error);


        // เพื่อให้ รอ ประมวลผลเสร็จเเ
        if (blob != null) {
            return false;
        }else{
            return false;
        }

    },



    currentDateTime() {
        const current = new Date();
        const date = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
        const time = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
        const dateTime = date + ' ' + time;

        return dateTime;
    },

    keepAlive() {
        // https://service.promptevent.com/api/v1/virtual/keep-alive
        const url = store.getters.url + "/api/v1/virtual/keep-alive";
        const token = store.getters.token;

        const config = {
            headers: {
                Authorization: 'Bearer ' + token + "",
                "x-conference-code": store.getters.conferenceCode,
                "Content-Type": store.getters.ContentType,
            },
        };

        // let bodyParameters = {
        //     "username": user.username,
        //     "password": user.password,

        // };


        // console.log("url : " + JSON.stringify(url, null, 2));
        // console.log("bodyParameters : " + JSON.stringify(bodyParameters, null, 2));
        // console.log("config : " + JSON.stringify(config, null, 2));

        let error;


        try {

            // alert("user keepAlive");

            let t = this.currentDateTime();
            // let t = this.$luxon({

            //     output: { format: "dd/MM HH:mm" },
            // });

            console.log("**** process axios get keepAlive " + t + " : counterErrorToken = " + store.getters.counterErrorToken
            );

            // store.commit("SET_COUNTER_ERROR_TOKEN", (store.getters.counterErrorToken + 1));

            axios
                .get(url, config)
                .then(
                    (response) => {


                        // alert("then");
                        if (response.data.status == "failure") {
                            // alert("failure");
                            console.log("data : " + JSON.stringify(response.data, null, 2));
                            this.$router.push({ path: "/" });
                            // store.dispatch('setStatusLogin', false)
                        } else if (response.data.status == "success") {
                            // alert("success");
                            console.log("data : " + JSON.stringify(response.data, null, 2));
                            store.commit("setToken", response.data.token);
                            store.commit("SET_COUNTER_ERROR_TOKEN", 0);
                            store.commit("setIsLogin", true)
                            // store.dispatch('setStatusLogin', true)
                        } else {
                            // error request
                            // alert("expire seesion");
                            console.log("error request : " + JSON.stringify(response.data, null, 2));
                            // store.dispatch('setStatusLogin', false)

                            if (store.getters.counterErrorToken > 6) {

                                this.$router.push({ path: "/" });
                            } else {

                                store.commit("SET_COUNTER_ERROR_TOKEN", (store.getters.counterErrorToken + 1));
                            }
                        }
                    })
                .catch(err => {
                    console.log("****** catch : catch 401 " + err);
                    // alert(" seesion expire please login again");

                    store.commit("setIsLogin", false);


                    // this.$router.push({ path: "/" });
                    // if (err.response.status === 404) {
                    //     throw new Error(`${err.config.url} not found`);
                    // }
                    error = err;
                    throw err;
                });

            if (error instanceof Error) {
                this.$router.push({ path: "/" });

            }

            // alert("error");

        } catch (err) {
            console.log("****** catch : catch 401 " + err);
            alert("error keepAlive");
            // this.$router.push({ path: "/" });

        }



        // console.log("****** catch : catch 401 "), 
        // throw new Error('Error text.'),

        // this.$router.replace('/'),

        // console.log("****** catch : catch 401 "), 
        // throw new Error('Error text.'),
        // alert("catch 401"),    
        // store.commit("setIsLogin", false),
        // store.commit("setSteams", item);

        // store.dispatch('setStatusLogin', false)
        // this.$router.push({ path: "/" })


        // error request
        // console.log(" catch error request : " + response.data),
        //this.$router.push({ path: "/" }),


        // this.$router.push({ path: "/" }),

        // console.log("catch : ERROR  "),
        // this.$router.push({ path: "/" }),

        // console.log
        // alert()



        // );
    },



    async register(user) {
        // https://service.promptevent.com/api/v1/virtual/user-register
        // const url = Settings.url + "/api/v1/virtual/user-register";
        const url = store.getters.url + "/api/v1/virtual/user-register";
        let message = "";

        const config = {
            headers: {
                // Authorization: `Bearer ${token}`,
                // Authorization: `Bearer sss`,
                "x-conference-code": store.getters.conferenceCode,
                "Content-Type": store.getters.ContentType,
            },
        };

        let bodyParameters = {
            "username": user.username,
            "password": user.password,
            "firstName": user.firstName,
            "middleName": "middleName",
            "lastName": user.lastName,
            "careerPosition": "position",
            "workplace": user.workplace,
            "telephone": user.telephone
        };


        console.log("url : " + JSON.stringify(url, null, 2));
        console.log("bodyParameters : " + JSON.stringify(bodyParameters, null, 2));
        console.log("config : " + JSON.stringify(config, null, 2));


        await axios
            .post(url, bodyParameters, config)
            .then(
                (response) => {
                    // your code
                    message = response.data.statusDesc;
                },

                // this.$router.push({ path: "/" })
                console.log)
            .catch(
                console.log);
        return message;
    },
}
<template>
  <div id="videowrapper" class="player-container">
    <video
      id="videoplay"
      ref="videoPlayer"
      class="video-js vjs-default-skin vjs-16-9"
      data-setup='{"fluid": true}'
      width="640"
      height="264"
    >
      <div class="vjs-big-play-button"></div>
    </video>

    <!-- <div id="advertisement2" class="advertisement hide">
      {{ this.$store.getters.token }}
    </div> -->

    <div id="advertisement">
      <animation class="advertisement hide"></animation>
    </div>

    <div id="overlays-wrap" v-if="false">
      <div class="overlay-item" data-overlayid="59" data-time="41">
        <p class="vo-question">Welche Zahl ist die Summe bei 3 + 50 = 53?</p>

        <div class="vtask-choices-wrap">
          <div class="vtask-choice-item">
            <input
              class="vtask-choice"
              type="radio"
              name="quiz"
              id="59-a1"
              value="1"
              data-correct="0"
            />
            <label class="radio-tile" for="59-a1">
              <span class="radio-tile-label"> 3 </span>
            </label>
          </div>

          <div class="vtask-choice-item">
            <input
              class="vtask-choice"
              type="radio"
              name="quiz"
              id="59-a2"
              data-correct="0"
            />
            <label class="radio-tile" for="59-a2">
              <span class="radio-tile-label"> 50 </span>
            </label>
          </div>

          <div class="vtask-choice-item">
            <input
              class="vtask-choice"
              type="radio"
              name="quiz"
              id="59-a3"
              value="3"
              data-correct="1"
            />
            <label class="radio-tile" for="59-a3">
              <span class="radio-tile-label"> 53 </span>
            </label>
          </div>
        </div>
        <!-- vtask-choices-wrap -->

        <a @click="Continue" class="buttonb vtask-btn-continue">Continue</a>
      </div>
      <!-- overlay-item -->
    </div>

    <div id="overlays-wrap-ea"></div>

    <!-- end ea -->

    <div id="overlays-wrap-poll">
      <div class="overlay-item" data-overlayid="59" data-time="41">
        <p class="vo-question">POLL</p>

        <!-- {{ $store.getters.questionPoll.length }} -->

        <div
          class="vtask-choices-wrap"
          v-if="!isVoted && $store.getters.questionPoll.length > 0"
        >
          <div class="vtask-choice-item">
            <input
              class="vtask-choice"
              type="radio"
              name="quiz"
              id="59-a1"
              value="1"
              data-correct="0"
            />

            <!-- <div
              v-for="item in questionPoll"
              :key="item.pollId"
              style="text-align: left"
            > -->
            <div
              v-for="item in $store.getters.questionPoll"
              :key="item.pollId"
              style="text-align: left"
            >
              <!-- questionPoll {{questionPoll}} -->
              <!-- store {{$store.getters.questionPoll}} -->

              <div>
                <div class="question fontPoll">
                  Q.{{ item.question }}
                  <!-- {{ item.pollId }} -->
                  <!-- <div>choicePoll {{choicePoll}}</div> -->
                  <!-- store {{$store.getters.choicePoll}} -->
                </div>
                <div v-for="item2 in choicePoll[item.pollId]" :key="item2.choiceId">
                  <!-- <div
                  v-for="item2 in $store.getters.choicePoll[item.pollId]"
                  :key="item2.choiceId"
                > -->
                  <b-field class="fontPoll" style="margin: 5px">
                    <b-radio
                      v-model="radioSelected[item.pollId]"
                      :native-value="item2.choiceId"
                      type="is-success"
                    >
                      {{ item2.description }}
                    </b-radio>
                  </b-field>
                </div>
              </div>
            </div>
            <div style="text-align: end">
              <b-button
                class="is-rounded buttonb vtask-btn-continue"
                label="Send"
                @click="savePoll()"
              ></b-button>
            </div>
          </div>
        </div>
        <div v-if="isVoted">Complete Poll</div>
        <!-- vtask-choices-wrap -->

        <a @click="Close" class="buttonb vtask-btn-continue">Close</a>
      </div>
      <!-- overlay-item -->
    </div>

    <!-- end Poll -->

    <div id="overlays-wrap-Emoji">
      <div class="overlay-item" data-overlayid="59" data-time="41">
        <p class="vo-question">SEND EMOJI</p>

        <div class="vtask-choices-wrap">
          <div class="vtask-choice-item">
            <input
              class="vtask-choice"
              type="radio"
              name="quiz"
              id="59-a1"
              value="1"
              data-correct="0"
            />
            <span class="btn_icon" @click.prevent="sendEmoji('😍')">😍</span>
            <span class="btn_icon" @click.prevent="sendEmoji('🤩')">🤩</span>
            <span class="btn_icon" @click.prevent="sendEmoji('👏')">👏</span>
            <span class="btn_icon" @click.prevent="sendEmoji('❤️')">❤️</span>
            <span class="btn_icon" @click.prevent="sendEmoji('😀')">😀</span>
            <span class="btn_icon" @click.prevent="sendEmoji('😂')">😂</span>
            <span class="btn_icon" @click.prevent="sendEmoji('👍')">👍</span>
            <span class="btn_icon" @click.prevent="sendEmoji('👎')">👎</span>
          </div>
        </div>
        <!-- vtask-choices-wrap -->

        <a @click="Close" class="buttonb vtask-btn-continue">Close</a>
      </div>
      <!-- overlay-item -->
    </div>

    <!-- Q&A -->

    <div id="overlays-wrap-QA">
      <div class="overlay-item" data-overlayid="59" data-time="41">
        <p class="vo-question">What's your question?</p>

        <!-- <div class="vtask-choices-wrap" > -->
        <div class="vtask-choices-wrap" style="overflow-y: scroll; height: 100%">
          <div class="vtask-choice-item">
            <input
              class="vtask-choice"
              type="radio"
              name="quiz"
              id="59-a1"
              value="1"
              data-correct="0"
            />

            <!-- display   -->

            <div><b-input v-model="questionName"></b-input></div>
            <div style="text-align: right; margin: 8px">
              <b-button label="SEND" @click="saveQuestion" rounded></b-button>
            </div>
            <div><hr style="background-color: #ffffff" /></div>

            <div
              v-for="item in this.$store.getters.steamAnswersExculdeCancel"
              :key="item.answerId"
            >
              <div style="width: 100%">
                <div style="text-align: left">Q. {{ item.answer }}</div>
                <div style="text-align: right; margin-top: 15px">
                  <span
                    v-for="item in item.steamReplyAnswers"
                    :key="item.answerId"
                    class="tag mr-2 replyStyle"
                  >
                    <div style="">reply : {{ item.answer }}</div>
                    <br />
                  </span>
                </div>

                <div style="text-align: right; margin: 8px">
                  <b-button
                    label="CANCEL"
                    @click="cancelQuestion(item.answerId)"
                    rounded
                  ></b-button>
                </div>

                <div><hr style="background-color: #ffffff" /></div>
              </div>
            </div>

            <!-- end display -->
          </div>
        </div>
        <!-- vtask-choices-wrap -->

        <!-- <a @click="Close" class="buttonb vtask-btn-continue">Close</a> -->
      </div>
      <!-- overlay-item -->
    </div>

    <!-- end Q&A -->

    <!-- <animation id="advertisement"  class="advertisement hide" ></animation> -->

    <!-- <animation id="advertisement200"  class="advertisement hide" style=" bottom: 200px;right: 150px;"></animation>
    <animation id="advertisement190"  class="advertisement hide" style=" bottom: 150px;"></animation>
    <animation id="advertisement180"  class="advertisement hide" style=" bottom: 100px;right: 150px;"></animation>
    <animation id="advertisement170"  class="advertisement hide" style=" bottom: 50px;"></animation>

    
    <animation id="advertisement200r100"  class="advertisement hide" style=" bottom: 200px;right: 300px;"></animation>
    <animation id="advertisement190r100"  class="advertisement hide" style=" bottom: 150px;right: 150px;"></animation>
    <animation id="advertisement180r100"  class="advertisement hide" style=" bottom: 100px;right: 300px;"></animation>
    <animation id="advertisement170r100"  class="advertisement hide" style=" bottom: 50px;right: 150px;"></animation> -->

    <!-- <animation id="advertisement170"  class="advertisement hide" style=" bottom: 50px;"></animation> -->
    <!-- <animation id="advertisement"  class="advertisement hide" style=" bottom: 350px;" ></animation> -->

    <!-- {{ "token :  "+this.$store.getters.token }}  -->
  </div>
</template>

<script>
import "video.js/dist/video-js.css";
import videojs from "video.js";

import Animation from "./TestAnimation.vue";
import QAService from "@/service/QAService";
import PollService from "@/service/PollService";

import axios from "axios";
import { store } from "@/store/store";

export default {
  name: "VideoPlayer",
  components: {
    Animation,
  },

  props: {
    options: {
      // autoplay: "muted",
      // playbackRates: [0.5, 1, 1.5, 2],
      // plugins: {
      //   foo: { bar: true },
      //   boo: { baz: false },
      // },
      // children: ["bigPlayButton", "controlBar"],

      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      linkURLEA : "www.google.com",

      isActive: true,

      // poll
      questionPoll: [],
      choicePoll: [],
      radioSelected: [],
      isVoted: false,
      // end poll

      // Q&A
      questionName: "",
      // end Q&A
    };
  },
  mounted() {
    this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {
      console.log("onPlayerReady", this);

      var z = document.createElement("div"); // is a node
      z.innerHTML = "test satu dua tiga";

      var player = this;
      player.el().appendChild(document.getElementById("advertisement"));

      // player.el().appendChild(document.getElementById("overlays-wrap"));
      player.el().appendChild(document.getElementById("overlays-wrap-Emoji"));
      player.el().appendChild(document.getElementById("overlays-wrap-poll"));
      player.el().appendChild(document.getElementById("overlays-wrap-QA"));
      player.el().appendChild(document.getElementById("overlays-wrap-ea"));

      // document.getElementById("overlays-wrap").style.visibility = "hidden";
      document.getElementById("overlays-wrap-Emoji").style.visibility = "hidden";
      document.getElementById("overlays-wrap-poll").style.visibility = "hidden";
      document.getElementById("overlays-wrap-QA").style.visibility = "hidden";

      // document.getElementById("overlays-wrap").appendTo(document.getElementById("videoplay"));

      var videoid = document.getElementsByClassName("vjs-control vjs-button");
      for (let i = 0; i < videoid.length; i++) {
        if (videoid[i].innerHTML == "Emoji") {
          videoid[i].style.setProperty("display", "none", "important");
        }

        if (videoid[i].innerHTML == "Poll") {
          videoid[i].style.setProperty("display", "none", "important");
        }

        if (videoid[i].innerHTML == "Q&amp;A") {
          videoid[i].style.setProperty("display", "none", "important");
        }

        if (videoid[i].innerHTML == "EA") {
          videoid[i].style.setProperty("display", "none", "important");
        }
      }
    });

    // var myButton = this.player.controlBar.addChild("button");

    // // Getting html DOM
    // var myButtonDom = myButton.el();
    // myButtonDom.innerHTML = "<div class='tooltip-trigger'>Hello</div> ";
    // myButtonDom.title = "tooltip-trigger";

    // myButtonDom.onclick = function () {
    //   // alert("Redirecting");
    //   // window.location.href = "https://www.google.com";

    //   var status = document.getElementById("overlays-wrap").style.visibility;

    //   if (status != "visible") {
    //     document.getElementById("overlays-wrap").style.visibility = "visible";
    //     document.getElementById("overlays-wrap").style.height = "100%";
    //   } else {
    //     document.getElementById("overlays-wrap").style.visibility = "hidden";
    //     document.getElementById("overlays-wrap").style.height = "0%";
    //   }

    //   // this.isActive = !this.isActive;
    // };

    // this.player.el().addEventListener('touchend', () => {

    //   if (!this.player.hasStarted() && this.player.fullscreenOnStart()) {
    //     // this.player.requestFullscreen();
    //     alert("xxx");
    //   }
    // });
    // var documnet = this.player.el()

    // document.querySelector('.vjs-big-play-button').addEventListener('click', player.requestFullscreen)

    // this.player.el().querySelector('.vjs-fullscreen-control vjs-control vjs-button').addEventListener("click", function () {
    this.player.el().addEventListener("fullscreenchange", function () {
      // if (this.player.el().fullscreen) {
      var videoid = null;

      document.getElementById("overlays-wrap-Emoji").style.visibility = "hidden";
      document.getElementById("overlays-wrap-poll").style.visibility = "hidden";
      document.getElementById("overlays-wrap-QA").style.visibility = "hidden";

      if (document.fullscreenElement) {
        console.log("Entering Full Screen Toggle");

        videoid = document.getElementsByClassName("vjs-control vjs-button");
        for (let i = 0; i < videoid.length; i++) {
          if (videoid[i].innerHTML == "Emoji") {
            videoid[i].style.setProperty("display", "block", "important");
          }

          if (videoid[i].innerHTML == "Poll") {
            videoid[i].style.setProperty("display", "block", "important");
          }

          if (videoid[i].innerHTML == "Q&amp;A") {
            videoid[i].style.setProperty("display", "block", "important");
          }
          if (videoid[i].innerHTML == "EA") {
            videoid[i].style.setProperty("display", "block", "important");
          }
        }
        // alert(" Full Screen");
        // this.player.getChild("controlBar").addChild("myButton", {}).el()
      } else {
        console.log("Exiting Full Screen Toggle");

        videoid = document.getElementsByClassName("vjs-control vjs-button");
        for (let i = 0; i < videoid.length; i++) {
          if (videoid[i].innerHTML == "Emoji") {
            videoid[i].style.setProperty("display", "none", "important");
          }

          if (videoid[i].innerHTML == "Poll") {
            videoid[i].style.setProperty("display", "none", "important");
          }

          if (videoid[i].innerHTML == "Q&amp;A") {
            videoid[i].style.setProperty("display", "none", "important");
          }
          if (videoid[i].innerHTML == "EA") {
            videoid[i].style.setProperty("display", "none", "important");
          }
        }
      }
      // alert(videoid[10].innerHTML);
      // videoid[11].style.setProperty("display", "none", "important");
      // alert(videoid.length);
      // alert("exit");
    });

    /// extend Button ///

    // var ButtonQA = videojs.getComponent("Button");
    // var MyButtonQA = videojs.extend(ButtonQA, {
    //   constructor: function () {
    //     Button.apply(this, arguments);
    //     /* initialize your button */
    //   },
    //   handleClick: function () {
    //     /* do something on click */
    //     alert("xxx");
    //   },
    // });
    // videojs.registerComponent("myButton", MyButtonQA);
    // // var player = videojs('myvideo');
    // var test2 = this.player.getChild("controlBar").addChild("myButton", {});
    // test2.el().innerHTML = "Q&A";

    var Button = videojs.getComponent("Button");

    ///  Evaluation ///
    var MyButtonEA = videojs.extend(Button, {
      constructor: function () {
        Button.apply(this, arguments);
        /* initialize your button */
      },
      handleClick: function () {
        /* do something on click */
        // alert("xxx");
        // window.open("https://www.w3schools.com");
        // window.open("/TOXIN/#/RedirectEAPage");
        window.open("window.location/#/RedirectEAPage");
        
        // window.open(this.linkURLEA);

        document.getElementById("overlays-wrap-QA").style.visibility = "hidden";
        document.getElementById("overlays-wrap-Emoji").style.visibility = "hidden";
        document.getElementById("overlays-wrap-poll").style.visibility = "hidden";
      },
    });
    videojs.registerComponent("MyButton", MyButtonEA);
    // var player = videojs('myvideo');
    this.player.getChild("controlBar").addChild("myButton", {}).el().innerHTML = "EA";

    ///  Q&A ///
    var MyButtonQA = videojs.extend(Button, {
      constructor: function () {
        Button.apply(this, arguments);
        /* initialize your button */
      },
      handleClick: function () {
        /* do something on click */
        // alert("xxx");
        var status = document.getElementById("overlays-wrap-QA").style.visibility;

        document.getElementById("overlays-wrap-Emoji").style.visibility = "hidden";
        document.getElementById("overlays-wrap-poll").style.visibility = "hidden";

        if (status != "visible") {
          document.getElementById("overlays-wrap-QA").style.visibility = "visible";
        } else {
          document.getElementById("overlays-wrap-QA").style.visibility = "hidden";
        }
      },
    });
    videojs.registerComponent("MyButton", MyButtonQA);
    // var player = videojs('myvideo');
    this.player.getChild("controlBar").addChild("myButton", {}).el().innerHTML = "Q&A";

    ///  Poll ///

    var MyButtonPoll = videojs.extend(Button, {
      constructor: function () {
        Button.apply(this, arguments);
        /* initialize your button */
      },
      handleClick: function () {
        /* do something on click */
        // alert("xxx");

        var status = document.getElementById("overlays-wrap-poll").style.visibility;

        document.getElementById("overlays-wrap-QA").style.visibility = "hidden";
        document.getElementById("overlays-wrap-Emoji").style.visibility = "hidden";

        if (status != "visible") {
          document.getElementById("overlays-wrap-poll").style.visibility = "visible";
        } else {
          document.getElementById("overlays-wrap-poll").style.visibility = "hidden";
        }
      },
    });
    videojs.registerComponent("MyButton", MyButtonPoll);
    // var player = videojs('myvideo');
    this.player.getChild("controlBar").addChild("myButton", {}).el().innerHTML = "Poll";

    // Emoji

    var MyButton = videojs.extend(Button, {
      constructor: function () {
        Button.apply(this, arguments);
        /* initialize your button */
      },
      handleClick: function () {
        /* do something on click */
        // alert("xxx");
        var status = document.getElementById("overlays-wrap-Emoji").style.visibility;

        document.getElementById("overlays-wrap-QA").style.visibility = "hidden";
        document.getElementById("overlays-wrap-poll").style.visibility = "hidden";

        if (status != "visible") {
          document.getElementById("overlays-wrap-Emoji").style.visibility = "visible";
        } else {
          document.getElementById("overlays-wrap-Emoji").style.visibility = "hidden";
        }
      },
    });
    videojs.registerComponent("MyButton", MyButton);
    // var player = videojs('myvideo');
    this.player.getChild("controlBar").addChild("myButton", {}).el().innerHTML = "Emoji";

    // Q&A

    // var myButtonQA = this.player.controlBar.addChild("button");
    // // Getting html DOM
    // var myButtonQADom = myButtonQA.el();
    // myButtonQADom.innerHTML = "Q&A";

    // myButtonQADom.onclick = async function () {
    //   // alert("Redirecting");
    //   // window.location.href = "https://www.google.com";

    //   var status = document.getElementById("overlays-wrap-QA").style.visibility;

    //   document.getElementById("overlays-wrap-poll").style.visibility = "hidden";
    //   document.getElementById("overlays-wrap-Emoji").style.visibility =
    //     "hidden";

    //   if (status != "visible") {
    //     document.getElementById("overlays-wrap-QA").style.visibility =
    //       "visible";
    //   } else {
    //     document.getElementById("overlays-wrap-QA").style.visibility = "hidden";
    //   }
    // };

    // end Q&A

    // poll

    // var myButtonPoll = this.player.controlBar.addChild("button");
    // // Getting html DOM
    // var myButtonPollDom = myButtonPoll.el();
    // myButtonPollDom.innerHTML = "Poll";

    // myButtonPollDom.onclick = async function () {
    //   // alert("Redirecting");
    //   // window.location.href = "https://www.google.com";

    //   var status =
    //     document.getElementById("overlays-wrap-poll").style.visibility;

    //   document.getElementById("overlays-wrap-QA").style.visibility = "hidden";
    //   document.getElementById("overlays-wrap-Emoji").style.visibility =
    //     "hidden";

    //   if (status != "visible") {
    //     document.getElementById("overlays-wrap-poll").style.visibility =
    //       "visible";
    //   } else {
    //     document.getElementById("overlays-wrap-poll").style.visibility =
    //       "hidden";
    //   }
    // };

    // end poll

    // Emoji

    // var myButton2 = this.player.controlBar.addChild("button");

    // // Getting html DOM
    // var myButtonDom2 = myButton2.el();
    // myButtonDom2.innerHTML = "Emoji";

    // myButtonDom2.onclick = function () {
    //   // alert("Redirecting");
    //   // window.location.href = "https://www.google.com";

    //   var status = document.getElementById("overlays-wrap-Emoji").style
    //     .visibility;

    //   document.getElementById("overlays-wrap-QA").style.visibility = "hidden";
    //   document.getElementById("overlays-wrap-poll").style.visibility = "hidden";

    //   if (status != "visible") {
    //     document.getElementById("overlays-wrap-Emoji").style.visibility =
    //       "visible";
    //   } else {
    //     document.getElementById("overlays-wrap-Emoji").style.visibility =
    //       "hidden";
    //   }
    // };

    // end Emoji
  },

  async created() {
    console.log("created sidebar vote");
    // alert("create poll");
    this.channelCode = this.$route.params.id;
    this.questionPoll = this.getQuestionPoll(this.channelCode);
    PollService.getQuestionPoll(this.channelCode);
    // this.questionPoll = this.$store.getters.questionPoll;
    // this.choicePoll = this.$store.getters.questionPoll;
    this.timer = setInterval(() => {
      this.questionPoll = this.getQuestionPoll(this.channelCode);
      PollService.getQuestionPoll(this.channelCode);
    }, 10000);
    // document.getElementById("overlays-wrap-poll").style.visibility =
    //     "hidden";
  },

  methods: {
    Continue() {
      alert("xxx");
      document.getElementById("myNav").style.height = "0%";
      document.getElementById("overlays-wrap").style.visibility = "hidden";
      document.getElementById("overlays-wrap-Emoji").style.visibility = "hidden";
    },
    open() {
      alert("xxx");
      document.getElementById("myNav").style.height = "0%";
      document.getElementById("overlays-wrap").style.visibility = "visible";
    },

    Close() {
      // document.getElementById("overlays-wrap").style.visibility = "hidden";
      document.getElementById("overlays-wrap-Emoji").style.visibility = "hidden";
      document.getElementById("overlays-wrap-poll").style.visibility = "hidden";
      document.getElementById("overlays-wrap-QA").style.visibility = "hidden";
    },

    async saveQuestion() {
      // this.questionName =
      if (this.questionName.length != 0) {
        console.log("=== save question === ");
        await this.saveEvaluationQuestion(
          // this.questionIdDefault,
          this.$store.getters.questionId,
          this.questionName
        );

        // this.printOject(status);

        await this.getHistorySendQuestion(
          // this.channelCode,
          // this.questionIdDefault,
          this.$store.getters.channel,
          this.$store.getters.questionId,
          1
        );

        // clear box
        this.questionName = "";

        // this.printOject(this.historySendQuestion);
      }
    },

    async cancelQuestion(id) {
      console.log("=== cancelQuestion === " + id);
      let temp = await this.cancelQuestionService(this.channelCode, id);

      await this.getHistorySendQuestion(
        this.$store.getters.channel,
        this.$store.getters.questionId,
        1
      );

      // await this.getHistorySendChat(
      //   this.$store.getters.channel,
      //   this.$store.getters.questionIdChat,
      //   1
      // );

      this.printOject(temp);
    },

    // cancel question
    // API_QA_07 Update stream question answer
    // https://service.promptevent.com/api/v1/virtual/stream-question-answer-update

    async cancelQuestionService(channelCode, questionId) {
      const url = store.getters.url + "/api/v1/virtual/stream-question-answer-update";
      const token = store.getters.token;

      let dataReturn = [];

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      let bodyParameters = {
        streamName: channelCode, // string
        answerId: questionId, // int
        answer: "cancel",
        enabled: false, // bool
      };

      // console.log("url : " + JSON.stringify(url, null, 2));
      // console.log("bodyParameters : " + JSON.stringify(bodyParameters, null, 2));
      // console.log("config : " + JSON.stringify(config, null, 2));

      await axios
        .post(url, bodyParameters, config)
        .then(
          (response) => {
            // your code
            dataReturn = response.data;
            // this.printOject(dataReturn);

            this.success("cancel question complete.");
            //   this.articleId = response.data.token;
          }
          //   console.log
        )
        .catch(console.log);

      return dataReturn;
    },

    savePoll() {
      console.log("=== click savePoll ===  ");

      // console.clear();
      this.errors = [];

      // loop add error
      for (let i = 0; i < this.questionPoll.length; i++) {
        // this.printOject(this.questionPoll[i]);
        if (!this.radioSelected[this.questionPoll[i].pollId]) {
          this.errors.push("Poll required NO." + (i + 1));
        }
      }

      // loop add save data
      if (this.errors.length == 0) {
        console.log("===  save data EA  ===  ");

        // loop save QuestionAns
        for (let i = 0; i < this.questionPoll.length; i++) {
          // this.printOject(this.questionPoll[i]);
          let choiceId = this.radioSelected[this.questionPoll[i].pollId];
          // this.printOject(choiceId);

          this.saveChoiceVote(choiceId);

          //  if (!this.radioSelected[this.questionPoll[i].pollId]) {
          //     this.errors.push("Poll required NO." + (i + 1));
          //     //saveChoiceVote( choiceId)
          //   }
        }
      }
      this.isVoted = true;

      this.CheckVoted();
    },

    // send question
    // API_QA_05 Answer stream question
    // https://dev.catsmodish.com/api/v1/virtual/stream-question-answer

    async saveEvaluationQuestion(questionId, answer) {
      const url = store.getters.url + "/api/v1/virtual/stream-question-answer";
      const token = store.getters.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      let bodyParameters = {
        questionId: questionId, // int
        answer: answer, // String
        replyToId: 0,
      };

      console.log("url : " + JSON.stringify(url, null, 2));
      console.log("bodyParameters : " + JSON.stringify(bodyParameters, null, 2));
      // console.log("config : " + JSON.stringify(config, null, 2));

      await axios
        .post(url, bodyParameters, config)
        .then((response) => {
          // your code
          // this.articleId = response.data.token;
          console.log(
            "saveEvaluationQuestion : " + JSON.stringify(response.data, null, 2)
          );
          this.success("send question complete.");
        }, console.log)
        .catch(console.log);
    },

    // 06 get history send question
    // API_QA_06 Get stream question answer
    // https://service.promptevent.com/api/v1/virtual/stream-question-answer/S1613754744/1/1
    //   "/api/v1/virtual/stream-question-answer/$channelCode/$questionId/$pageNo";

    async getHistorySendQuestion(channelCode, questionId, pageNo) {
      // console.log("getChoiceRating voteId : " + voteId);
      const url =
        store.getters.url +
        "/api/v1/virtual/stream-question-answer/" +
        channelCode +
        "/" +
        questionId +
        "/" +
        pageNo +
        "?sort=desc";
      const token = store.getters.token;

      let dataReturn = [];

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      // let bodyParameters = {
      //   // "username": user.username,
      //   // "password": user.password,
      // };

      console.log("url : " + JSON.stringify(url, null, 2));
      // console.log(
      //   "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      // );
      // console.log("config : " + JSON.stringify(config, null, 2));

      await axios
        .get(url, config)
        .then((response) => {
          // your code

          if (response.data.status == "success") {
            console.log(
              "getHistorySendQuestion response.data : " +
                JSON.stringify(response.data, null, 2)
            );

            // 20 รวม cancel question
            // ไม่รวม cancel 20
            dataReturn = response.data.steamQuestion.steamAnswers;

            // // alert(dataReturn.length);
            // if (dataReturn.length > 0) {
            //   this.hasHistorySendQuestion = true;
            //   // alert(dataReturn.length);

            //   // this check nofity question
            //   let counterNotify =
            //     response.data.steamQuestion.steamAnswers.length -
            //     store.getters.steamAnswersFull.length;
            //   // alert(
            //   //   response.data.steamQuestion.steamAnswers[0].lastModifiedDate +
            //   //     " - " +
            //   //     store.getters.steamAnswersFull[0].lastModifiedDate
            //   // );
            //   store.commit(
            //     "SET_COUNTER_QA_STREAM",
            //     store.getters.counterQAMessagetotal + counterNotify
            //   );
            // } else {
            //   // alert("false");
            //   this.hasHistorySendQuestion = false;
            // }

            // check have data QA
            if (dataReturn.length > 0 && store.getters.steamAnswersFull.length > 0) {
              // new message
              // alert(
              //   response.data.steamQuestion.steamAnswers[0].lastModifiedDate +
              //     " - " +
              //     store.getters.steamAnswersFull[0].lastModifiedDate
              // )

              // check last answer time
              if (
                response.data.steamQuestion.steamAnswers[0].lastModifiedDate !=
                store.getters.steamAnswersFull[0].lastModifiedDate
              ) {
                // alert("new message");
                if (
                  response.data.steamQuestion.steamAnswers[0].steamReplyAnswers.length > 0
                ) {
                  //  check last reply steamReplyAnswers
                  if (
                    response.data.steamQuestion.steamAnswers[0].steamReplyAnswers
                      .length !=
                    store.getters.steamAnswersFull[0].steamReplyAnswers.length
                  ) {
                    // alert("new reply");
                    store.commit("SET_COUNTER_QA_STREAM", 1);
                  }
                } else {
                  // alert("new QA");
                  store.commit("SET_COUNTER_QA_STREAM", 1);
                }
              }

              // alert(
              //   response.data.steamQuestion.steamAnswers[0].steamReplyAnswers.length  +
              //     " - " +
              //     store.getters.steamAnswersFull[0].steamReplyAnswers.length
              // )
            }
            // else {
            //   if (store.getters.steamAnswersFull.length > 0) {
            //     store.commit(
            //       "SET_ANSWER",
            //       response.data.steamQuestion.steamAnswers
            //     );
            //   }
            // }

            store.commit("SET_ANSWER", response.data.steamQuestion.steamAnswers);

            // console.log("temp.data : " + JSON.stringify(dataReturn, null, 2));
            // let steamQuestions = response.data.steamQuestions;

            // this.filtered = this.steams.filter(
            //   (element) => element.streamName === this.$route.params.id
            // );
            // let filter = "What's you question?";
            // // let filter = "Test create stream question 1";

            // const steamQuestions = response.data.steamQuestions;

            // const temp = steamQuestions.filter((steamQuestion) =>
            //   steamQuestion.question.includes(filter)
            // );

            // this.questionIdDefault = temp[0].questionId;
            // dataReturn = temp[0].questionId;

            // console.log(
            //   "temp.data : " + JSON.stringify(this.questionIdDefault, null, 2)
            // );

            // return this.questionIdDefault;
          }
          // this.articleId = response.data.token;
        }, console.log)
        .catch(console.log);

      // return response.data.conferenceEvaluations;
      return dataReturn;
    },

    // save choice vote
    // API_PL_05 Vote stream poll
    // https://dev.catsmodish.com/api/v1/virtual/stream-poll-vote

    async saveChoiceVote(choiceId) {
      const url = store.getters.url + "/api/v1/virtual/stream-poll-vote";
      const token = store.getters.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      let bodyParameters = {
        choiceId: choiceId, // int
      };

      // console.log("url : " + JSON.stringify(url, null, 2));
      // console.log("bodyParameters : " + JSON.stringify(bodyParameters, null, 2));
      // console.log("config : " + JSON.stringify(config, null, 2));

      await axios
        .post(url, bodyParameters, config)
        .then((response) => {
          // your code
          // this.articleId = response.data.token;
          console.log("saveChoiceVote : " + JSON.stringify(response.data, null, 2));
          this.success("send poll complete.");
        }, console.log)
        .catch(console.log);
    },

    // get choice vote
    // API_PL_06 Get stream poll details
    // https://dev.catsmodish.com/api/v1/virtual/stream-poll-details/2

    async getChoicePoll(pollId) {
      const url = store.getters.url + "/api/v1/virtual/stream-poll-details/" + pollId;
      const token = store.getters.token;

      let dataReturn = [];

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      // let bodyParameters = {
      //   // "username": user.username,
      //   // "password": user.password,
      // };

      // console.log("url : " + JSON.stringify(url, null, 2));
      // console.log(
      //   "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      // );
      // console.log("config : " + JSON.stringify(config, null, 2));

      await axios
        .get(url, config)
        .then((response) => {
          // your code

          if (response.data.status == "success") {
            console.log(
              "getChoicePoll response.data : " + JSON.stringify(response.data, null, 2)
            );

            dataReturn = response.data.pollChoices;
            this.choicePoll[pollId] = dataReturn;
            // alert(dataReturn);

            for (let i = 0; i < dataReturn.length; i++) {
              let voted = dataReturn[i].voted;
              if (voted > 0) {
                this.isVoted = true;
              }
              // this.printOject(dataReturn[i]);
            }
          }
          // this.articleId = response.data.token;
        }, console.log)
        .catch(console.log);

      // return response.data.conferenceEvaluations;
      return dataReturn;
    },

    // admin get question poll get question vote & choice
    // API_PL_03 Get stream poll list (Admin)
    // https://service.promptevent.com/api/v1/virtual/stream-poll-list-admin/S1613754744

    // user get question poll get question vote & choice >> enable = false >> not show
    // API_PL_04 Get stream poll list
    // https://dev.catsmodish.com/api/v1/virtual/stream-poll-list/S1612877527

    async getQuestionPoll(channelCode) {
      const url = store.getters.url + "/api/v1/virtual/stream-poll-list/" + channelCode;
      const token = store.getters.token;

      let dataReturn = [];

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      // let bodyParameters = {
      //   // "username": user.username,
      //   // "password": user.password,
      // };

      // console.log("url : " + JSON.stringify(url, null, 2));
      // console.log(
      //   "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      // );
      // console.log("config : " + JSON.stringify(config, null, 2));

      await axios
        .get(url, config)
        .then((response) => {
          // your code

          if (response.data.status == "success") {
            console.log(
              "getQuestionPoll response.data : " + JSON.stringify(response.data, null, 2)
            );

            dataReturn = response.data.steamPolls;

            this.questionPoll = dataReturn;

            // clear
            this.isVoted = false;

            for (let i = 0; i < dataReturn.length; i++) {
              // if (dataReturn[i].pollId !=null) {
              // this.printOject(dataReturn[i].pollId);
              let questionId = dataReturn[i].pollId;
              this.getChoicePoll(questionId);
              //this.choicePoll
              // }
            }

            // this.printOject(this.choicePoll);
            //this.choicePoll
          }
          // this.articleId = response.data.token;
        }, console.log)
        .catch(console.log);

      // return response.data.conferenceEvaluations;
      return dataReturn;
    },

    async sendEmoji(iconName) {
      // alert("sendEmoji");

      // store.commit("SET_SHOW_EMOJI_STATUS", true);

      // setTimeout(() => {
      //   store.commit("SET_SHOW_EMOJI_STATUS", false);
      // }, 2000);

      // this.$store.getters.steams.streamName
      // alert("es : " + JSON.stringify(this.$store.getters.steams, null, 2));
      // this.$store.getters.steams.streamNam
      // filtered //
      QAService.sendSticker(this.$route.params.id, "00");
      await QAService.insertSticker(
        this.$route.params.id,
        this.$store.getters.questionIdSticker,
        iconName
      );
    },
  },

  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>

<style scoped>
.player-container {
  position: relative;
}

.advertisement {
  position: absolute;
  color: rgb(230, 200, 98);
  padding: 5px 10px;
  text-align: right;
  /* background: rgba(0, 0, 0, 0.4); */
  bottom: 50px;
  right: 100px;
  /* left: 0px; */
  font-size: 14px;
  font-weight: 700;
  z-index: 1 !important;
}

.emo {
  left: 0;
  position: absolute;
  color: rgb(230, 200, 98);
  padding: 5px 10px;
  text-align: right;
  background: rgba(0, 0, 0, 0.4);
  bottom: 300px;
  right: 50px;
  font-size: 14px;
  font-weight: 700;
  z-index: 1 !important;
}

#overlays-wrap,
#overlays-wrap-qa,
#overlays-wrap-Poll,
#overlays-wrap-emoji {
  position: absolute;
  width: 100%;
  height: 100%;
  max-height: 460px;
  left: 0;
  top: 0;
  z-index: 2147483647;
}

.overlay-item {
  position: absolute;
  top: 0;
  color: #fff;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.85);
  /* width: 100%; */
  width: 30%;
  height: 100%;
  padding: 0;
  z-index: 2147483647;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  backface-visibility: hidden;
}

.overlay-item .vo-question {
  margin: 10px 0 40px 0;
  width: 80%;
  text-align: center;
  line-height: 1.5;
}

.vtask-choices-wrap {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
  /* width: 100%; */
  width: 90%;
  max-width: 1000px;
  min-height: 10rem;
}

.vtask-choice-item .vtask-choice {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.radio-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 2px solid #079ad9;
  border-radius: 5px;
  padding: 1rem;
  transition: transform 300ms ease;
  background: rgba(0, 0, 0, 0.5);
  z-index: 500;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
}

.vtask-btn-continue {
  color: #ffffff !important;
  border-color: #2b78c5;
  border-bottom-color: #2a65a0;
  text-decoration: none;
  text-shadow: none;
  color: #fff;
  background: #39f;
  margin-top: 40px;
  padding: 10px 20px;
  font-family: Arial, sans-serif;
  font-size: 16px;
}

.btn_icon {
  cursor: pointer;
  margin: 5px;
  font-size: 25px;
  float: none;
}

@media only screen and (max-device-width: 480px) {
  .overlay-item {
    font-size: 12px;
    width: 50%;
  }
}
</style>


<template>
  <div :style="HeaderStyles">
    <!-- <div> <img :src="logoImagerRight" :style="LogoStyles"  /></div> -->
    <div>  <img :src="imageHeader" :style="LogoStyles"  /></div>
   
  

              <!-- <div class="row">
            <div style="float: left;width: 25%;">
              <b-image class="logo-header-left" style="float: left;" :src="logoImageleft"></b-image>
            </div>
            <div style="float: left;width: 50%;">
              <b-image class="logoImage" style="float: left;" :src="imageHeader"></b-image>
            </div>
            <div style="float: right;width: 25%;">
            <b-image class="logo-header-left" style="float: right;" :src="logoImagerRight"></b-image>
          </div>
          </div> -->

  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      imageHeader: require("@/assets/images/logo-header.png"),
      // imageHeader: require("@/assets/images/header-home.jpg"),
      // logoImageleft: require("@/assets/images/logo-header-left.png"),
      // logoImagerRight: require("@/assets/images/logo-header-right.png"),
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    HeaderStyles() {
      return {
        "text-align": "center",
        "padding-top": "25px",
        // "padding-top": "50px",
        "padding-bottom": "25px",
        // "height": "130px",
      };
    },

    LogoStyles() {
      // if (window.innerWidth > 800) {
      //   return {
      //     height: "130px",
      //   };
      // } else {
      //   return {
      //     height: "130px",
      //   };
      // }
      return {};
    },
  },
};
</script>
<style scoped>


</style>
<template>
  <div>
    <apex-charts
      ref="realtimeChart"
      width="500"
      type="bar"
      :options="chartOptions"
      :series="series"
    ></apex-charts>

    <section>
      <b-button @click="clickMe">Click Me</b-button>
    </section>
  </div>
</template>

<script>
import ApexCharts from "vue-apexcharts";
// Vue.component('apexchart', apexchart)

export default {
  mounted() {
    this.setDataLineChart();
  },

  components: {
    ApexCharts,
  },

  methods: {
    clickMe() {
      // this.$buefy.notification.open("Clicked!!");
      alert("sss");
 console.log("realtimeChart test :");
      // this.data = [30, 40, 35, 50, 49, 60, 70, 80];
      // this.data.push(this.getRandomArbitrary(0, 99));
      // this.$buefy.notification.open(JSON.stringify(this.data, null, 2));

      
 console.log("realtimeChart x :"+this.$refs.realtimeChart);
    },

    getRandomArbitrary() {
      return Math.floor(Math.random() * 99);
    },

    setDataLineChart() {
      setInterval(() => {


        console.log("setDataLineChart");
        this.series[0].data.splice(0, 1);
        this.series[0].data.push(this.getRandomArbitrary(0, 99));
        this.updateSeriesLine();
      }, 3000);
    },
    updateSeriesLine() {
      this.$refs.realtimeChart.updateSeries(
        [
          {
            data: this.series[0].data,
          },
        ],
        false,
        true
      );
    },
  },

  data: function () {
    return {
      chartOptions: {
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
        },
      },
      series: [
        {
          name: "series-1",
          data: [30, 40, 35, 50, 49, 60, 70, 91],
        },
      ],
    };
  },
};
</script>
<template>
  <div class="card text-center m-3">
    <header-component></header-component>

    <admin-nav-component></admin-nav-component>

    <admin-setting-nav-componentponent></admin-setting-nav-componentponent>

    <!-- <form ref="form" class="formQA " @submit.prevent="onSubmit">
      <div v-for="item in eaQuestionAns" :key="item.evaluationId">

    <div class="card qa">
  <div class="card-content">
    <div class="content">
      <p class="qaQuestion">Q1.Lorem ipsum leo risus, porta ac consectetur ac, vestibulum at eros. Donec id </p>

        <p>
        <b-field label="ANS" :label-position="labelPosition">
          <b-input

            type="textarea"
          
          >
          </b-input>
        </b-field>
      </p>
    </div>
  </div>
</div>
</div>

    </form> -->

    <div class="card-body"><strong> User Management </strong></div>

    <!-- v-on:change="changeEnableUser(props.row.username,props.row.enabled)"  -->
    <!-- @update="changeEnableUser(props.row.username,props.row.enabled)" -->

    <div class="card-body" has-text-centered>
      <b-field>
        <b-input
          placeholder="Reply..."
          v-model="text_search"
          type="search"
          icon="magnify"
          value=""
        >
        </b-input>
        <p class="control">
          <b-button type="is-primary" label="Search" @click="getUser(text_search, 1)" />
        </p>
      </b-field>
    </div>

    <b-table has-text-centered :data="userList">
      <b-table-column
        has-text-centered
        field="id"
        label="username"
        v-slot="props"
        centered
      >
        {{ props.row.username }}
      </b-table-column>

      <b-table-column field="enabled" label="enable user" v-slot="props" centered>
        <!-- {{ props.row.enabled }} -->
        <b-switch
          v-model="props.row.enabled"
          @input="changeEnableUser(props.row.username, props.row.enabled)"
        >
          enabled</b-switch
        >
      </b-table-column>

      <b-table-column field="enableda" label="GEN QRCODE" v-slot="props" centered>
        <b-button
          style="background-color: #2c296b"
          @click="onGenQRcodeByUser(props.row.username)"
          class="button is-info"
          expanded
          rounded
          >Gen qrcode</b-button
        >
      </b-table-column>

      <b-table-column field="enableda" label="QR CODE" v-slot="props" centered>
        <b-button
          style="background-color: #2c296b"
          @click="onGetUserQRcode(props.row.username)"
          class="button is-info"
          expanded
          rounded
          >Download QRcode</b-button
        >
      </b-table-column>
      

      <!-- <b-table-column field="certified" label="enable certified" v-slot="props" centered>
        {{ props.row.certified }}
        <div class="control">
          <b-switch
            v-model="props.row.certified"
            @input="
              changeEnableCertified(props.row.username, props.row.certified)
            "
            >certified</b-switch
          >
        </div>
      </b-table-column> -->
    </b-table>

    <!-- <b-table :data="userList" style="overflow-x: hidden; max-height: 300px">
      <template v-for="column in columns">
        <b-table-column :key="column.id" v-bind="column">
          <template
            v-if="column.searchable && !column.numeric"
            #searchable="props"
          >
            <b-input
              v-model="props.filters[props.column.field]"
              placeholder="Search..."
              icon="magnify"
              size="is-small"
            />
          </template>
          <template v-slot="props">
            {{ props.row[column.field] }}

            <b-switch
              v-model="props.row.enabled"
              @input="changeEnableUser(props.row.username, props.row.enabled)"
            >
              enabled</b-switch
            >
          </template>
        </b-table-column>
      </template>
    </b-table> -->

    <div class="overflow-auto">
      <!-- <div>
        <h6>Small Pills</h6>
        <b-pagination
          v-model="currentPage"
          pills
          :total-rows="rows"
          size="sm"
        ></b-pagination>
      </div> -->

      <!-- <div class="mt-3">
        <h6>Default Pills</h6>
        <b-pagination
          v-model="currentPage"
          pills
          :total-rows="rows"
        ></b-pagination>
      </div> -->

      <!-- <div class="mt-3">
        <h6>Large Pills</h6>
        <b-pagination
          v-model="currentPage"
          pills
          :total-rows="rows"
          size="lg"
        ></b-pagination>
      </div> -->

      <!-- <VueTailwindPagination :current="currentPage" :total="total" :per-page="perPage" @page-changed="current = $event"/> -->
    </div>

    <div class="card-body">
      <paginate
        v-model="page"
        :page-count="totalPage"
        :page-range="3"
        :margin-pages="2"
        :click-handler="clickCallback"
        :prev-text="'Prev'"
        :next-text="'Next'"
        :container-class="'pagination'"
        :page-class="'page-item'"
      >
      </paginate>
    </div>

    <!-- <div>
      <b-image
        class="logoImage img-round posterSize"
        :src="img"
        img-height="234px"
        img-width="338px"
      ></b-image>
    </div> -->

    <footer-component></footer-component>
  </div>
</template>

<script>
// import UserService from "@/service/UserService";
// import VueTailwindPagination from "@ocrv/vue-tailwind-pagination";

import axios from "axios";
import { store } from "@/store/store";
import FooterComponent from "./footer.vue";
import HeaderComponent from "./header.vue";
import Paginate from "vuejs-paginate";
import adminNavComponent from "./adminNav.vue";
import UserService from "@/service/UserService";
import QRCodeService from "@/service/QRCodeService";
import adminSettingNavComponentponent from "./adminSettingNav.vue";


export default {
  name: "post-request",
  components: {
    FooterComponent,
    HeaderComponent,
    Paginate,
    adminNavComponent,
    adminSettingNavComponentponent,
    // VueTailwindPagination,
  },

  async created() {
    // alert(this.$store.getters.token);

    console.log("created");

    this.checkSesseions();

    console.log("getUser");
    this.getUser("@", 1);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },

  data() {
    return {
      columns: [
        {
          field: "username",
          label: "username",
          searchable: true,
        },
        {
          field: "enabled",
          label: "enabled",
          searchable: true,
        },
      ],

      text_search: "",

      img: "",

      errors: [],

      userList: [],
      // currentPage: 1,
      btLoading: false,

      ///
      totalPage: 0,
      page: 1,

      timer: "",
    };
  },
  mounted() {},

  computed: {},

  watch: {},
  filters: {},

  methods: {
    checkSesseions() {
      /// check session ///

      UserService.keepAlive(this.$store.getters.token);

      let token = store.getters.token;

      if (!token) {
        this.$router.push({ path: "/" });
      }

      clearInterval(this.timer);
      this.timer = setInterval(() => {
        UserService.keepAlive(this.$store.getters.token);

        if (!this.$store.getters.isLogin) {
          // alert(" seesion expire please login again");
          clearInterval(this.timer);
          this.$router.push({ path: "/" });
        }
      }, 5000);

      /// check session ///
    },

    clickCallback: function (pageNum) {
      console.log("PAGE : " + pageNum);
      // alert(pageNum);
      // this.getUser("@", pageNum);
      this.getUser(this.text_search, pageNum);
    },

    success(msg) {
      this.$buefy.notification.open({
        message: msg,
        type: "is-success",
        duration: 5000,
      });
    },
    printObject(obj) {
      console.log("printObject : " + JSON.stringify(obj, null, 2));
    },

    changeEnableUser(user, status) {
      console.log("changeEnableUser : " + user + " to status : " + status);
      this.updateStatusUser(user, status);

      // update user status
    },

    
    async onGenQRcodeByUser(username) {
      // alert("onGetUserQRcode" + username);
      // let label = "QR_CODE_"+username+".png";

      
      await QRCodeService.CreateUserQRcode(username);
      await QRCodeService.EnableUserQRcode(username);
      
      // const blob = new Blob([data], { type: "image/png" });
      //   const link = document.createElement("a");
      //   link.href = URL.createObjectURL(blob);
      //   link.download = label;
      //   link.click();
      //   URL.revokeObjectURL(link.href);
    },


    async onGetUserQRcode(username) {
      // alert("onGetUserQRcode" + username);
      // let label = "QR_CODE_"+username+".png";
      let data;
      await QRCodeService.getQRcodeByUser(username);

      data = this.getQRcodeByUser(username);

      console.log(data);
      // const blob = new Blob([data], { type: "image/png" });
      //   const link = document.createElement("a");
      //   link.href = URL.createObjectURL(blob);
      //   link.download = label;
      //   link.click();
      //   URL.revokeObjectURL(link.href);
    },

    covertToPng(data, label) {
      // alert(data);
      const blob = new Blob([data], { type: "image/png" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = label;
      link.click();
      URL.revokeObjectURL(link.href);
    },


    // https://dev.catsmodish.com/api/v1/virtual/qrcode-image?username=account@promptevent.co.th
    // API_QR_04 Get QRCode image by username
    async getQRcodeByUser(username) {
      const url = store.getters.url + "/api/v1/virtual/qrcode-image?username=" + username;
      const token = store.getters.token;
      const fileName = "QR_CODE_"+username+".png";

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
        responseType: "blob",
      };

      let bodyParameters = {
        // "username": user.username,
        // "password": user.password,
      };

      console.log("url : " + JSON.stringify(url, null, 2));
      console.log("bodyParameters : " + JSON.stringify(bodyParameters, null, 2));
      console.log("config : " + JSON.stringify(config, null, 2));

      let img = null;

      await axios
        .get(url, config)
        .then((response) => {
          // your code

          // alert(response.data);
          console.log("response : " + JSON.stringify(response, null, 2));

          this.covertToPng(response.data,fileName);

          // this.img = URL.createObjectURL(
          //   new Blob([response.data], { type: "image/png" })
          // );
         
          // this.articleId = response.data.token;
        }, console.log)
        .catch(console.log);

      // return {test : "test" };

      // alert(img);
      return img;
    },

    downloadPng(url, label) {
      axios
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "image/png" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = label;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },

    changeEnableCertified(user, status) {
      console.log("changeEnableCertified : " + user + " to status : " + status);
      this.updateCertifiedUser(user, status);

      // update user status
    },
    pageChangeHandler(selectedPage) {
      this.currentPage = selectedPage;
    },

    onSubmit() {
      this.$emit("submit", this.model);
    },
    doSomethings(val) {
      console.clear();
      console.log(val);
      alert(val);
    },
    changeRating(value) {
      console.log("===  changeRating ===  " + value);
      // console.log("===  dataRating ===  " + dataRating[item.pollId]);

      // map

      // console.log("===  changeRating ===  " + this.dataRating[value]);

      // this.$buefy.toast.open({
      //   message: "Thanks for you Rate!",
      //   type: "is-success",
      // });
    },
    // goLoginPage() {
    //   // alert("goRegisterPage");
    //   this.$router.push({ path: "/" });
    // },

    goStremPage(code) {
      console.log("Conference code : " + code);
      // alert("goRegisterPage");
      this.$router.push({ path: "/StreamPage/" + code, code });
    },

    getTestFn() {
      console.log("===  getTestFn ===  ");
    },

    // get all user
    // API_USER04 Search user details (Admin)
    // https://service.promptevent.com/api/v1/virtual/user-search

    getUser(searchKey, pageNo) {
      const url = store.getters.url + "/api/v1/virtual/user-search";

      // console.log("url : " + JSON.stringify(url, null, 2));
      const token = store.getters.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      if (searchKey == "") {
        searchKey = "@";
      }

      let bodyParameters = {
        searchKey: searchKey,
        pageNo: pageNo,
      };

      console.log("url : " + JSON.stringify(url, null, 2));
      console.log("bodyParameters : " + JSON.stringify(bodyParameters, null, 2));
      console.log("config : " + JSON.stringify(config, null, 2));

      axios
        .post(url, bodyParameters, config)
        .then((response) => {
          // your code
          // this.articleId = response.data.token;
          console.log("userList : " + JSON.stringify(response.data.users, null, 2));
          this.userList = response.data.users;
          this.totalPage = response.data.totalPage;
          // this.currentPage = pageNo;
        }, console.log)
        .catch(console.log);
    },

    // update status enable user
    // API-USER05 Update user details (Admin)
    // https://service.promptevent.com/api/v1/virtual/user-update-admin

    updateStatusUser(username, status) {
      const url = store.getters.url + "/api/v1/virtual/user-update-admin";

      // console.log("url : " + JSON.stringify(url, null, 2));
      const token = store.getters.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      let bodyParameters = {
        username: username, // email
        enabled: status, // bool
      };

      console.log("url : " + JSON.stringify(url, null, 2));
      console.log("bodyParameters : " + JSON.stringify(bodyParameters, null, 2));
      console.log("config : " + JSON.stringify(config, null, 2));

      axios
        .post(url, bodyParameters, config)
        .then(
          //   (response) => {
          //   // your code
          //   // this.articleId = response.data.token;
          //   console.log(
          //     "userList : " + JSON.stringify(response.data.users, null, 2)
          //   );
          //   this.userList = response.data.users;
          //   this.totalPage = response.data.totalPage;
          // },
          console.log
        )
        .catch(console.log);
    },

    // update Certified enable user
    // API-USER05 Update user details (Admin)
    // https://service.promptevent.com/api/v1/virtual/user-update-admin

    updateCertifiedUser(username, status) {
      const url = store.getters.url + "/api/v1/virtual/user-update-admin";

      // console.log("url : " + JSON.stringify(url, null, 2));
      const token = store.getters.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      let bodyParameters = {
        username: username, // email
        certified: status, // bool
      };

      console.log("url : " + JSON.stringify(url, null, 2));
      console.log("bodyParameters : " + JSON.stringify(bodyParameters, null, 2));
      console.log("config : " + JSON.stringify(config, null, 2));

      axios
        .post(url, bodyParameters, config)
        .then(
          //   (response) => {
          //   // your code
          //   // this.articleId = response.data.token;
          //   console.log(
          //     "userList : " + JSON.stringify(response.data.users, null, 2)
          //   );
          //   this.userList = response.data.users;
          //   this.totalPage = response.data.totalPage;
          // },
          console.log
        )
        .catch(console.log);
    },

    // update Certified enable user
    // API-USER05 Update user details (Admin)
    // https://service.promptevent.com/api/v1/virtual/user-update-admin

    resetPasswordUser(username, newPassword) {
      newPassword = "Abcd12345";
      const url = store.getters.url + "/api/v1/virtual/user-update-admin";

      // console.log("url : " + JSON.stringify(url, null, 2));
      const token = store.getters.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      let bodyParameters = {
        username: username, // email
        newPassword: newPassword, // string
      };

      console.log("url : " + JSON.stringify(url, null, 2));
      console.log("bodyParameters : " + JSON.stringify(bodyParameters, null, 2));
      console.log("config : " + JSON.stringify(config, null, 2));

      axios
        .post(url, bodyParameters, config)
        .then(
          //   (response) => {
          //   // your code
          //   // this.articleId = response.data.token;
          //   console.log(
          //     "userList : " + JSON.stringify(response.data.users, null, 2)
          //   );
          //   this.userList = response.data.users;
          //   this.totalPage = response.data.totalPage;
          // },
          console.log
        )
        .catch(console.log);
    },
  },
};
</script>
<style scoped>
.error {
  color: rgb(255, 0, 0);
}

table.text-center * {
  text-align: center;
}

.pagination {
}
.page-item {
}
</style>

<template>
  <section>
    <b-sidebar
      type="is-light"
      :fullheight="fullheight"
      :fullwidth="fullwidth"
      :overlay="overlay"
      :right="right"
      v-model="open"
      :auto-close="['outside', 'escape', 'inside']"
    >
      <div class="p-1">
        <b-menu>
          <b-menu-list label="Menu Q&A">
            <!-- hasHistorySendQuestion : {{ hasHistorySendQuestion }} -->
            <!-- counterQAMessagetotal : {{ this.$store.getters.counterQAMessagetotal }} -->
            <!-- steamAnswers : {{ this.$store.getters.steamAnswers }} -->

            <b-tooltip
              :label="'new'"
              :active="newMessageQA"
              position="is-right"
              always
              type="is-danger"
            >
              <!-- <b-tooltip
              :label="this.$store.getters.counterQAMessagetotal + ''"
              :active="newMessageQA"
              position="is-right"
              always
              type="is-danger"
            > -->
              <b-menu-item
                @click="resetCouterQA"
                icon="information-outline"
                label="What's your question ? "
              >
                <b-menu-item icon="account" label=""></b-menu-item>
                <div><b-input v-model="questionName"></b-input></div>
                <div style="text-align: right; margin: 8px">
                  <b-button label="SEND" @click="saveQuestion" rounded></b-button>
                </div>

                <div
                  v-for="item in this.$store.getters.steamAnswersExculdeCancel"
                  :key="item.answerId"
                >
                  <div>
                    <div>Q. {{ item.answer }}</div>
                    <div>
                      <span
                        v-for="item in item.steamReplyAnswers"
                        :key="item.answerId"
                        class="tag mr-2 replyStyle"
                      >
                        <div>reply : {{ item.answer }}</div>
                      </span>
                    </div>

                    <div style="text-align: right; margin: 8px">
                      <b-button
                        label="CANCEL"
                        @click="cancelQuestion(item.answerId)"
                        rounded
                      ></b-button>
                    </div>

                    <div><hr style="background-color: #080808" /></div>
                  </div>
                </div>

                <!-- <b-menu-item ><b-button label="Go"></b-button></b-menu-item> -->
              </b-menu-item>
            </b-tooltip>

            <!-- <b-menu-item
              icon="information-outline"
              label="Info History question."
            >
              <div
                v-for="item in this.$store.getters.steamAnswersExculdeCancel"
                :key="item.answerId"
              >
                <div>
                  <div>Q. {{ item.answer }}</div>
                  <div>
                    <span
                      v-for="item in item.steamReplyAnswers"
                      :key="item.answerId"
                      class="tag mr-2"
                    >
                      <div>reply : {{ item.answer }}</div>
                    </span>
                  </div>

                  <div style="text-align: right; margin: 8px">
                    <b-button
                      label="CANCEL"
                      @click="cancelQuestion(item.answerId)"
                      rounded
                    ></b-button>
                  </div>

                  <div><hr style="background-color: #080808" /></div>
                </div>
              </div>

              <div v-if="!hasHistorySendQuestion">Empty question</div>
            </b-menu-item> -->

            <!--  END Q&A -->

            <!--  Chat -->
            <b-tooltip
              :label="'new'"
              :active="newMessageChat"
              position="is-right"
              always
              type="is-danger"
            >
              <!-- <b-tooltip
              :label="this.$store.getters.counterChatMessagetotal + ''"
              :active="newMessageChat"
              position="is-right"
              always
              type="is-danger"
            > -->

              <b-menu-item
                icon="information-outline"
                label="ติดต่อ admin."
                @click="resetCouterChat"
              >
                <!-- <div v-for="item in historySendQuestion" :key="item.answerId"> -->
                <div><b-input v-model="questionNameChat"></b-input></div>
                <div style="text-align: right; margin: 8px">
                  <b-button label="SEND" @click="saveQuestionChat" rounded></b-button>
                </div>

                <div
                  v-for="(item, i) in this.$store.getters.steamAnswersChat"
                  :key="item.answerId"
                >
                  <div>
                    <div>
                      <!-- chat left  -->

                      <!-- {{ i % 2 }} -->
                      <b-field
                        v-if="i % 2 == 0"
                        id="labelNameLeft"
                        :label="
                          checkAdmin(item.firstName) +
                          ' : ' +
                          formatDate(item.lastModifiedDate)
                        "
                      >
                        <!-- ืืเทด -->

                        <!-- <b-button
                        v-if="$store.getters.user == item.firstName || isAdmin"
                        rounded
                        @contextmenu.prevent.stop="handleClick1($event, item)"
                        ><div style="width: 40px">
                          {{ item.answer }}
                        </div></b-button
                      >

                      <b-button v-else rounded>{{ item.answer }}</b-button> -->

                        <div
                          style="width: 150px; display: inline-block; text-align: left"
                          class="box boxcontent"
                        >
                          {{ item.answer }}
                        </div>
                      </b-field>

                      <!-- chat right  -->
                      <b-field
                        v-if="i % 2 != 0"
                        id="labelNameRight"
                        :label="
                          checkAdmin(item.firstName) +
                          ' : ' +
                          formatDate(item.lastModifiedDate)
                        "
                      >
                        <!-- <b-button
                        v-if="$store.getters.user == item.firstName || isAdmin"
                        rounded
                        @contextmenu.prevent.stop="handleClick1($event, item)"
                        >{{ item.answer }}</b-button
                      >
                      <b-button v-else rounded>{{ item.answer }}</b-button> -->

                        <div
                          style="width: 150px; display: inline-block; text-align: right"
                          class="box boxcontent"
                        >
                          {{ item.answer }}
                        </div>
                      </b-field>
                    </div>
                    <div>
                      <span
                        v-for="item in item.steamReplyAnswers"
                        :key="item.answerId"
                        class="tag mr-2"
                      >
                        <div>reply : {{ item.answer }}</div>
                      </span>
                    </div>

                    <!-- <div style="text-align: right; margin: 8px">
                    <b-button
                      label="CANCEL"
                      @click="cancelQuestion(item.answerId)"
                    ></b-button>
                  </div> -->
                  </div>
                </div>

                <!-- This is a basic use case where you have an array of items that you want
to allow to be clicked. In this case, `items` is an array of objects.
Each item has a click event that ties to a function. See the demo for a full example (with multiple menus as well). -->

                <!-- <div class="list-group">
                <div
                  v-for="(item, index) in itemArray1"
                  :key="index"
                  @contextmenu.prevent.stop="handleClick1($event, item)"
                  class="list-group-item list-group-item-action"
                >
                  {{ item.name }}
                </div>
              </div> -->

                <!-- Make sure you add the `ref` attribute, as that is what gives you the ability
to open the menu. -->

                <!-- <vue-simple-context-menu
                  :elementId="'myFirstMenu'"
                  :options="optionsArray1"
                  :ref="'vueSimpleContextMenu1'"
                  @option-clicked="optionClicked1"
                >
                </vue-simple-context-menu> -->

                <!-- <circle-menu
                type="middle"
                :number="3"
                animate="animated jello"
                mask="white"
                circle
              >
                <button type="button" slot="item_btn"></button>
                <a slot="item_1" class="fa fa-twitter fa-lg"></a>
                <a slot="item_2" class="fa fa-weixin fa-lg"></a>
                <a slot="item_3" class="fa fa-weibo fa-lg"></a>
                <a slot="item_4" class="fa fa-github fa-lg"></a>
              </circle-menu> -->

                <div v-if="!hasHistorySendChat">Empty Chat</div>
              </b-menu-item>
            </b-tooltip>
            <!-- end chat  -->
          </b-menu-list>
        </b-menu>
      </div>
    </b-sidebar>

    <b-tooltip :label="'new'" :active="newMessagetotal" always type="is-danger">
      <!-- <b-tooltip
      :label="this.$store.getters.counterAllMessagetotal + ''"
      :active="newMessagetotal"
      always
      type="is-danger"
    > -->
      <b-button class="bt" rounded @click="open = true">Q&A</b-button>
    </b-tooltip>
  </section>
</template>

<script>
import axios from "axios";
import { store } from "@/store/store";
import QAService from "@/service/QAService";

export default {
  data() {
    return {
      itemArray1: [
        {
          name: "Jim",
          job: "Salesman",
        },
        {
          name: "Dwight",
          job: "Assistant to the Regional Manager",
        },
        {
          name: "Pam",
          job: "Receptionist",
        },
      ],
      optionsArray1: [
        {
          name: "Delete",
          slug: "Delete",
        },
        // {
        //   name: "Duplicate",
        //   slug: "duplicate",
        // },
        // {
        //   type: "divider",
        // },
        // {
        //   name: "Edit",
        //   slug: "edit",
        // },
        // {
        //   name: "<em>Delete</em>",
        //   slug: "delete",
        // },
      ],
      open: false,
      overlay: false,
      fullheight: true,
      fullwidth: false,
      right: false,
      questionName: "",
      questionIdDefault: 0,
      questionIdDefaultChat: 0,
      historySendQuestion: [],
      channelCode: "",
      hasHistorySendQuestion: false,
      questionNameChat: "",

      isActive: true,

      timerUpdateData: "",
      timerUpdateDataSticker: "",
    };
  },

  computed: {
    newMessagetotal() {
      //  let total = this.$store.getters.counterQAMessagetotal  + this.$store.getters.counterChatMessagetotal;
      if (this.$store.getters.counterAllMessagetotal == 0) {
        return false;
      } else {
        return true;
      }
    },
    // totalMessage() {
    //   return (
    //     this.$store.getters.counterQAMessagetotal +
    //     this.$store.getters.counterChatMessagetotal
    //   );
    // },

    newMessageQA() {
      if (this.$store.getters.counterQAMessagetotal == 0) {
        return false;
      } else {
        return true;
      }
    },
    newMessageChat() {
      if (this.$store.getters.counterChatMessagetotal == 0) {
        return false;
      } else {
        return true;
      }
    },

    isAdmin() {
      let roles = this.$store.getters.roles;
      let adminFlag = false;
      for (let role in roles) {
        console.log("role : " + roles[role]);
        if (roles[role] == "ADMIN") {
          adminFlag = true;
        }
      }

      return adminFlag;
    },
    hasHistorySendChat() {
      if (this.$store.getters.steamAnswersChat.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },

  async created() {
    console.log("created sidebar");
    console.log("created sidebar : " + this.$route.params.id);
    this.channelCode = this.$route.params.id;
    store.commit("SET_CHANNEL", this.channelCode);

    // check and insert admin only
    this.checkQuestionIdDefault(this.channelCode, "What's you question?", false);
    this.checkQuestionIdDefault(this.channelCode, "Chat?", true);
    this.checkQuestionIdDefault(this.channelCode, "StickerRoom?", true);

    await this.getquestionIdDefault(this.channelCode, "What's you question?");
    await this.getquestionIdDefaultChat(this.channelCode, "Chat?");
    await this.getquestionIdDefaultSticker(this.channelCode, "StickerRoom?");

    // store.commit("SET_QUESTION_ID_DEFAULT", questionIdDefault);
    // alert(this.questionIdDefault);
    // store.commit("SET_QUESTION_ID_CHAT", questionIdDefaultChat);
    // alert(this.$store.getters.questionId);

    // call action update answer
    // store.dispatch("updateSteamAnswers");

    await console.log("created questionIdDefault : " + this.questionIdDefault);

    await this.getHistorySendQuestion(
      this.$store.getters.channel,
      this.$store.getters.questionId,
      1
    );

    await this.getHistorySendChat(
      this.$store.getters.channel,
      this.$store.getters.questionIdChat,
      1
    );

    await QAService.getHistorySendStickerInitial(
      this.$store.getters.channel,
      this.$store.getters.questionIdSticker,
      1
    );

    // refresh chat and reply

    this.timerUpdateData = setInterval(() => {
      this.getHistorySendQuestion(
        this.$store.getters.channel,
        this.$store.getters.questionId,
        1
      );
    }, 50000);

    this.timerUpdateDataSticker = setInterval(() => {
      this.getHistorySendChat(
        this.$store.getters.channel,
        this.$store.getters.questionIdChat,
        1
      );

      QAService.getHistorySendSticker(
        this.$store.getters.channel,
        this.$store.getters.questionIdSticker,
        1
      );
    }, 10000);

    console.log(
      "created historySendQuestion : " + this.printOject(this.historySendQuestion)
    );
  },

  beforeDestroy() {
    clearInterval(this.timerUpdateData);
    clearInterval(this.timerUpdateDataSticker);
  },

  methods: {
    checkAdmin(firstName) {
      // admin
      // rachanee_j, Juezaa
      let arr_admin = ["rachanee_j", "Juezaa"];
      // for ( let item, key, index in arr_admin) {
        for (let i = 0; i < arr_admin.length; i++) {
        console.log(arr_admin[i]);
        if (firstName == arr_admin[i]) {
          return "admin";
        }
      }

      return firstName;
    },

    resetCouterQA() {
      // alert(`You clicked resetCouterQA`);
      // this.$store.getters.counterQAMessagetotal
      this.$store.commit("SET_COUNTER_QA_STREAM", 0);
    },
    resetCouterChat() {
      // alert(`You clicked resetCouterQA`);
      console.log("=== out ===");
      // this.$store.getters.counterQAMessagetotal
      this.$store.commit("SET_COUNTER_CHAT_STREAM", 0);
    },

    handleClick1(event, item) {
      this.$refs.vueSimpleContextMenu1.showMenu(event, item);
    },
    optionClicked1(event) {
      // window.alert(JSON.stringify(event));

      // alert(event.item.answerId);

      this.cancelQuestion(event.item.answerId);
    },

    formatDate(dataDate) {
      // $luxon.fromSQL();
      return this.$luxon(dataDate, {
        input: { format: "yyyy-MM-dd HH:mm:ss", zone: "asia/bangkok" },
        output: { format: "dd/MM HH:mm" },
      });
    },

    success(msg) {
      this.$buefy.notification.open({
        message: msg,
        type: "is-success",
        duration: 5000,
      });
    },
    printOject(object) {
      console.log(" printOject : " + JSON.stringify(object, null, 2));
      return JSON.stringify(object, null, 2);
    },
    async saveQuestion() {
      // this.questionName =
      if (this.questionName.length != 0) {
        console.log("=== save question === ");
        let status = await this.saveEvaluationQuestion(
          // this.questionIdDefault,
          this.$store.getters.questionId,
          this.questionName
        );

        this.printOject(status);

        await this.getHistorySendQuestion(
          // this.channelCode,
          // this.questionIdDefault,
          this.$store.getters.channel,
          this.$store.getters.questionId,
          1
        );

        // clear box
        this.questionName = "";

        this.printOject(this.historySendQuestion);
      }
    },

    async saveQuestionChat() {
      // alert("saveQuestionChat : " + this.questionNameChat);
      // this.questionName =
      if (this.questionNameChat.length != 0) {
        console.log("=== save chat === ");

        await this.saveEvaluationQuestion(
          this.$store.getters.questionIdChat,
          this.questionNameChat
        );

        // this.printOject(status);

        // update getHistorySendChat
        await this.getHistorySendChat(
          this.$store.getters.channel,
          this.$store.getters.questionIdChat,
          1
        );

        // clear box
        this.questionNameChat = "";

        this.printOject(this.historySendQuestion);
      }
    },

    async cancelQuestion(id) {
      console.log("=== cancelQuestion === " + id);
      let temp = await this.cancelQuestionService(this.channelCode, id);

      await this.getHistorySendQuestion(
        this.$store.getters.channel,
        this.$store.getters.questionId,
        1
      );

      await this.getHistorySendChat(
        this.$store.getters.channel,
        this.$store.getters.questionIdChat,
        1
      );

      this.printOject(temp);

      //   await this.printOject(this.historySendQuestion);

      // this.questionName =
      //   if (this.questionName.length != 0) {
      //     // this.saveEvaluationQuestion(this.questionIdDefault, this.questionName);
      //   }
    },

    // send question
    // API_QA_05 Answer stream question
    // https://dev.catsmodish.com/api/v1/virtual/stream-question-answer

    async saveEvaluationQuestion(questionId, answer) {
      const url = store.getters.url + "/api/v1/virtual/stream-question-answer";
      const token = store.getters.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      let bodyParameters = {
        questionId: questionId, // int
        answer: answer, // String
        replyToId: 0,
      };

      console.log("url : " + JSON.stringify(url, null, 2));
      console.log("bodyParameters : " + JSON.stringify(bodyParameters, null, 2));
      // console.log("config : " + JSON.stringify(config, null, 2));

      await axios
        .post(url, bodyParameters, config)
        .then((response) => {
          // your code
          // this.articleId = response.data.token;
          console.log(
            "saveEvaluationQuestion : " + JSON.stringify(response.data, null, 2)
          );
          this.success("send question complete.");
        }, console.log)
        .catch(console.log);
    },

    // get id  What's is you question?
    // API_QA_04 Get stream question list
    // https://dev.catsmodish.com/api/v1/virtual/stream-question-list/S1612877527

    async getquestionIdDefault(streamCode, questionName) {
      // console.log("getChoiceRating voteId : " + voteId);
      const url =
        store.getters.url + "/api/v1/virtual/stream-question-list/" + streamCode;
      const token = store.getters.token;

      let dataReturn = 0;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      // let bodyParameters = {
      //   // "username": user.username,
      //   // "password": user.password,
      // };

      // console.log("url : " + JSON.stringify(url, null, 2));
      // console.log(
      //   "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      // );
      // console.log("config : " + JSON.stringify(config, null, 2));

      await axios
        .get(url, config)
        .then((response) => {
          // your code

          if (response.data.status == "success") {
            console.log(
              "response.data.steamQuestions : " +
                JSON.stringify(response.data.steamQuestions, null, 2)
            );
            // let steamQuestions = response.data.steamQuestions;

            // this.filtered = this.steams.filter(
            //   (element) => element.streamName === this.$route.params.id
            // );

            // let filter = "What's you question?";
            let filter = questionName;

            // let filter = "Test create stream question 1";

            const steamQuestions = response.data.steamQuestions;

            const temp = steamQuestions.filter((steamQuestion) =>
              steamQuestion.question.includes(filter)
            );

            this.questionIdDefault = temp[0].questionId;

            store.commit("SET_QUESTION_ID_DEFAULT", temp[0].questionId);
            dataReturn = temp[0].questionId;

            // console.log(
            //   "temp.data : " + JSON.stringify(this.questionIdDefault, null, 2)
            // );

            // return this.questionIdDefault;
            return dataReturn;
          }
          // this.articleId = response.data.token;
        }, console.log)
        .catch(console.log);

      // return response.data.conferenceEvaluations;
      return dataReturn;
    },

    // get id  Chat?
    // API_QA_04 Get stream question list
    // https://dev.catsmodish.com/api/v1/virtual/stream-question-list/S1612877527

    async getquestionIdDefaultChat(streamCode, questionName) {
      // console.log("getChoiceRating voteId : " + voteId);
      const url =
        store.getters.url + "/api/v1/virtual/stream-question-list/" + streamCode;
      const token = store.getters.token;

      let dataReturn = 0;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      // let bodyParameters = {
      //   // "username": user.username,
      //   // "password": user.password,
      // };

      // console.log("url : " + JSON.stringify(url, null, 2));
      // console.log(
      //   "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      // );
      // console.log("config : " + JSON.stringify(config, null, 2));

      await axios
        .get(url, config)
        .then((response) => {
          // your code

          if (response.data.status == "success") {
            console.log(
              "response.data.steamQuestions : " +
                JSON.stringify(response.data.steamQuestions, null, 2)
            );
            // let steamQuestions = response.data.steamQuestions;

            // this.filtered = this.steams.filter(
            //   (element) => element.streamName === this.$route.params.id
            // );

            // let filter = "What's you question?";
            let filter = questionName;

            // let filter = "Test create stream question 1";

            const steamQuestions = response.data.steamQuestions;

            const temp = steamQuestions.filter((steamQuestion) =>
              steamQuestion.question.includes(filter)
            );

            // this.questionIdDefault = temp[0].questionId;

            store.commit("SET_QUESTION_ID_CHAT", temp[0].questionId);
            dataReturn = temp[0].questionId;

            // console.log(
            //   "temp.data : " + JSON.stringify(this.questionIdDefault, null, 2)
            // );

            // return this.questionIdDefault;
            return dataReturn;
          }
          // this.articleId = response.data.token;
        }, console.log)
        .catch(console.log);

      // return response.data.conferenceEvaluations;
      return dataReturn;
    },

    // get id  Chat?
    // API_QA_04 Get stream question list
    // https://dev.catsmodish.com/api/v1/virtual/stream-question-list/S1612877527

    async getquestionIdDefaultSticker(streamCode, questionName) {
      // console.log("getChoiceRating voteId : " + voteId);
      const url =
        store.getters.url + "/api/v1/virtual/stream-question-list/" + streamCode;
      const token = store.getters.token;

      let dataReturn = 0;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      // let bodyParameters = {
      //   // "username": user.username,
      //   // "password": user.password,
      // };

      // console.log("url : " + JSON.stringify(url, null, 2));
      // console.log(
      //   "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      // );
      // console.log("config : " + JSON.stringify(config, null, 2));

      await axios
        .get(url, config)
        .then((response) => {
          // your code

          if (response.data.status == "success") {
            console.log(
              "response.data.steamQuestions : " +
                JSON.stringify(response.data.steamQuestions, null, 2)
            );
            // let steamQuestions = response.data.steamQuestions;

            // this.filtered = this.steams.filter(
            //   (element) => element.streamName === this.$route.params.id
            // );

            // let filter = "What's you question?";
            let filter = questionName;

            // let filter = "Test create stream question 1";

            const steamQuestions = response.data.steamQuestions;

            const temp = steamQuestions.filter((steamQuestion) =>
              steamQuestion.question.includes(filter)
            );

            // this.questionIdDefault = temp[0].questionId;

            store.commit("SET_QUESTION_ID_STICKER", temp[0].questionId);
            dataReturn = temp[0].questionId;

            // console.log(
            //   "temp.data : " + JSON.stringify(this.questionIdDefault, null, 2)
            // );

            // return this.questionIdDefault;
            return dataReturn;
          }
          // this.articleId = response.data.token;
        }, console.log)
        .catch(console.log);

      // return response.data.conferenceEvaluations;
      return dataReturn;
    },

    // check id  What's is you question?
    // API_QA_04 Get stream question list
    // https://dev.catsmodish.com/api/v1/virtual/stream-question-list/S1612877527

    async checkQuestionIdDefault(streamCode, questionName, visibled) {
      // alert("checkQuestionIdDefault");
      // console.log("getChoiceRating voteId : " + voteId);
      const url =
        store.getters.url + "/api/v1/virtual/stream-question-list/" + streamCode;
      const token = store.getters.token;

      let dataReturn = 0;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      // let bodyParameters = {
      //   // "username": user.username,
      //   // "password": user.password,
      // };

      // console.log("url : " + JSON.stringify(url, null, 2));
      // console.log(
      //   "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      // );
      // console.log("config : " + JSON.stringify(config, null, 2));

      await axios
        .get(url, config)
        .then((response) => {
          // your code

          if (response.data.status == "success") {
            console.log(
              "response.data.steamQuestions : " +
                JSON.stringify(response.data.steamQuestions, null, 2)
            );
            // let steamQuestions = response.data.steamQuestions;

            // this.filtered = this.steams.filter(
            //   (element) => element.streamName === this.$route.params.id
            // );

            // let filter = "What's you question?";
            let filter = questionName;

            // let filter = "Test create stream question 1";

            const steamQuestions = response.data.steamQuestions;

            const temp = steamQuestions.filter((steamQuestion) =>
              steamQuestion.question.includes(filter)
            );

            // alert("temp : " + temp);
            if (temp.length == 0) {
              // insert id  What's is you question?

              this.insertQuestionIdDefault(streamCode, filter, visibled);

              // if(filter == "What's is you question?")
              //   this.insertQuestionIdDefault(streamCode, filter,visibled);
              // else if(filter == "What's is you question?")
              //   this.insertQuestionIdDefault(streamCode, filter,true);
            }
            // else {
            //   store.commit("SET_CHANNEL", streamCode);
            //   store.commit("SET_QUESTION_ID_DEFAULT", this.questionIdDefault);
            // }
            // this.questionIdDefault = temp[0].questionId;
            // dataReturn = temp[0].questionId;

            // console.log(
            //   "temp.data : " + JSON.stringify(this.questionIdDefault, null, 2)
            // );

            return this.questionIdDefault;
          }
          // this.articleId = response.data.token;
        }, console.log)
        .catch(console.log);

      // return response.data.conferenceEvaluations;
      return dataReturn;
    },

    // 06 get history send question
    // API_QA_06 Get stream question answer
    // https://service.promptevent.com/api/v1/virtual/stream-question-answer/S1613754744/1/1
    //   "/api/v1/virtual/stream-question-answer/$channelCode/$questionId/$pageNo";

    async getHistorySendQuestion(channelCode, questionId, pageNo) {
      // console.log("getChoiceRating voteId : " + voteId);
      const url =
        store.getters.url +
        "/api/v1/virtual/stream-question-answer/" +
        channelCode +
        "/" +
        questionId +
        "/" +
        pageNo +
        "?sort=desc";
      const token = store.getters.token;

      let dataReturn = [];

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      // let bodyParameters = {
      //   // "username": user.username,
      //   // "password": user.password,
      // };

      console.log("url : " + JSON.stringify(url, null, 2));
      // console.log(
      //   "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      // );
      // console.log("config : " + JSON.stringify(config, null, 2));

      await axios
        .get(url, config)
        .then((response) => {
          // your code

          if (response.data.status == "success") {
            console.log(
              "getHistorySendQuestion response.data : " +
                JSON.stringify(response.data, null, 2)
            );

            // 20 รวม cancel question
            // ไม่รวม cancel 20
            dataReturn = response.data.steamQuestion.steamAnswers;

            // // alert(dataReturn.length);
            // if (dataReturn.length > 0) {
            //   this.hasHistorySendQuestion = true;
            //   // alert(dataReturn.length);

            //   // this check nofity question
            //   let counterNotify =
            //     response.data.steamQuestion.steamAnswers.length -
            //     store.getters.steamAnswersFull.length;
            //   // alert(
            //   //   response.data.steamQuestion.steamAnswers[0].lastModifiedDate +
            //   //     " - " +
            //   //     store.getters.steamAnswersFull[0].lastModifiedDate
            //   // );
            //   store.commit(
            //     "SET_COUNTER_QA_STREAM",
            //     store.getters.counterQAMessagetotal + counterNotify
            //   );
            // } else {
            //   // alert("false");
            //   this.hasHistorySendQuestion = false;
            // }

            // check have data QA
            if (dataReturn.length > 0 && store.getters.steamAnswersFull.length > 0) {
              // new message
              // alert(
              //   response.data.steamQuestion.steamAnswers[0].lastModifiedDate +
              //     " - " +
              //     store.getters.steamAnswersFull[0].lastModifiedDate
              // )

              // check last answer time
              if (
                response.data.steamQuestion.steamAnswers[0].lastModifiedDate !=
                store.getters.steamAnswersFull[0].lastModifiedDate
              ) {
                // alert("new message");
                if (
                  response.data.steamQuestion.steamAnswers[0].steamReplyAnswers.length > 0
                ) {
                  //  check last reply steamReplyAnswers
                  if (
                    response.data.steamQuestion.steamAnswers[0].steamReplyAnswers
                      .length !=
                    store.getters.steamAnswersFull[0].steamReplyAnswers.length
                  ) {
                    // alert("new reply");
                    store.commit("SET_COUNTER_QA_STREAM", 1);
                  }
                } else {
                  // alert("new QA");
                  store.commit("SET_COUNTER_QA_STREAM", 1);
                }
              }

              // alert(
              //   response.data.steamQuestion.steamAnswers[0].steamReplyAnswers.length  +
              //     " - " +
              //     store.getters.steamAnswersFull[0].steamReplyAnswers.length
              // )
            }
            // else {
            //   if (store.getters.steamAnswersFull.length > 0) {
            //     store.commit(
            //       "SET_ANSWER",
            //       response.data.steamQuestion.steamAnswers
            //     );
            //   }
            // }

            store.commit("SET_ANSWER", response.data.steamQuestion.steamAnswers);

            // console.log("temp.data : " + JSON.stringify(dataReturn, null, 2));
            // let steamQuestions = response.data.steamQuestions;

            // this.filtered = this.steams.filter(
            //   (element) => element.streamName === this.$route.params.id
            // );
            // let filter = "What's you question?";
            // // let filter = "Test create stream question 1";

            // const steamQuestions = response.data.steamQuestions;

            // const temp = steamQuestions.filter((steamQuestion) =>
            //   steamQuestion.question.includes(filter)
            // );

            // this.questionIdDefault = temp[0].questionId;
            // dataReturn = temp[0].questionId;

            // console.log(
            //   "temp.data : " + JSON.stringify(this.questionIdDefault, null, 2)
            // );

            // return this.questionIdDefault;
          }
          // this.articleId = response.data.token;
        }, console.log)
        .catch(console.log);

      // return response.data.conferenceEvaluations;
      return dataReturn;
    },

    // 06 get history send question
    // API_QA_06 Get stream question answer
    // https://service.promptevent.com/api/v1/virtual/stream-question-answer/S1613754744/1/1
    //   "/api/v1/virtual/stream-question-answer/$channelCode/$questionId/$pageNo";

    async getHistorySendChat(channelCode, questionId, pageNo) {
      // console.log("getChoiceRating voteId : " + voteId);
      const url =
        store.getters.url +
        "/api/v1/virtual/stream-question-answer/" +
        channelCode +
        "/" +
        questionId +
        "/" +
        pageNo +
        "?sort=desc";
      const token = store.getters.token;

      let dataReturn = [];

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      // let bodyParameters = {
      //   // "username": user.username,
      //   // "password": user.password,
      // };

      console.log("url : " + JSON.stringify(url, null, 2));
      // console.log(
      //   "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      // );
      // console.log("config : " + JSON.stringify(config, null, 2));

      await axios
        .get(url, config)
        .then((response) => {
          // your code

          if (response.data.status == "success") {
            console.log(
              "getHistorySendQuestionChat response.data : " +
                JSON.stringify(response.data, null, 2)
            );

            dataReturn = response.data.steamQuestion.steamAnswers;

            // this check nofity question
            // let counterNotify =
            //   response.data.steamQuestion.steamAnswers.length -
            //   store.getters.steamAnswersChatFull.length;

            // dataReturn.length > 0 // มีคำามส่งเข้า มา
            // store.getters.steamAnswersChatFull.length // ประวัติคำถามก็มากว่า 0
            if (dataReturn.length > 0 && store.getters.steamAnswersChatFull.length > 0) {
              // new message
              // alert(
              //   response.data.steamQuestion.steamAnswers[0].lastModifiedDate +
              //     " - " +SET_ANSWER_CHAT
              //     store.getters.steamAnswersChatFull[0].lastModifiedDate
              // )
              if (
                response.data.steamQuestion.steamAnswers[0].lastModifiedDate !=
                store.getters.steamAnswersChatFull[0].lastModifiedDate
              ) {
                // alert("new message");
                store.commit("SET_COUNTER_CHAT_STREAM", 1);
              }
            }
            // store.commit(
            //   "SET_COUNTER_CHAT_STREAM",
            //   store.getters.counterChatMessagetotal + counterNotify
            // );

            // update chat
            store.commit("SET_ANSWER_CHAT", response.data.steamQuestion.steamAnswers);

            // if (dataReturn.length > 0) {
            //   this.hasHistorySendQuestion = true;
            // } else {
            //   this.hasHistorySendQuestion = false;
            // }

            // return this.questionIdDefault;
          }
          // this.articleId = response.data.token;
        }, console.log)
        .catch(console.log);

      // return response.data.conferenceEvaluations;
      return dataReturn;
    },

    // API_QA_04 Get stream question list
    // https://dev.catsmodish.com/api/v1/virtual/stream-question-list/S1612877527

    // send question
    // API_QA_05 Answer stream question
    // https://dev.catsmodish.com/api/v1/virtual/stream-question-answer

    // insert  question
    // API_QA_01 Create new stream question (Admin)
    // https://service.promptevent.com/api/v1/virtual/stream-question-create

    async insertQuestionIdDefault(channelCode, question, visibled) {
      alert("insertQuestionIdDefault : " + question);
      const url = store.getters.url + "/api/v1/virtual/stream-question-create";
      const token = store.getters.token;

      let dataReturn = [];

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      let bodyParameters = {
        streamName: channelCode,
        question: question,
        visible: visibled,
      };

      console.log("url : " + JSON.stringify(url, null, 2));
      console.log("bodyParameters : " + JSON.stringify(bodyParameters, null, 2));
      console.log("config : " + JSON.stringify(config, null, 2));

      await axios
        .post(url, bodyParameters, config)
        .then(
          // (response) => {
          //   // your code
          //   dataReturn = response.data;
          //   this.success("create question complete. :" + question);
          // }
          console.log
        )
        .catch(console.log);

      return dataReturn;
    },

    // cancel question
    // API_QA_07 Update stream question answer
    // https://service.promptevent.com/api/v1/virtual/stream-question-answer-update

    async cancelQuestionService(channelCode, questionId) {
      const url = store.getters.url + "/api/v1/virtual/stream-question-answer-update";
      const token = store.getters.token;

      let dataReturn = [];

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      let bodyParameters = {
        streamName: channelCode, // string
        answerId: questionId, // int
        answer: "cancel",
        enabled: false, // bool
      };

      // console.log("url : " + JSON.stringify(url, null, 2));
      // console.log("bodyParameters : " + JSON.stringify(bodyParameters, null, 2));
      // console.log("config : " + JSON.stringify(config, null, 2));

      await axios
        .post(url, bodyParameters, config)
        .then(
          (response) => {
            // your code
            dataReturn = response.data;
            // this.printOject(dataReturn);

            this.success("cancel question complete.");
            //   this.articleId = response.data.token;
          }
          //   console.log
        )
        .catch(console.log);

      return dataReturn;
    },
  },
};
</script>

<style scoped>
.bt {
  /* background-color: rgb(20, 164, 141);
  color: white; */
  color: var(--templete-font-color);
  background-color: var(--templete-color);
  /* margin: 25px; */
}

.p-1 {
  padding: 1em;
  /* opacity: 0.4; */
  background: transparent;
  /* color: rgb(255, 255, 255);
  background-color: black; */
}
.sidebar-content {
  background: transparent;
  /* opacity: 0.4; */
}

section {
  background: transparent;
  /* opacity: 0.4; */
}

.field >>> .label {
  font-size: 0.5rem;
}

#labelNameRight {
  text-align: right;
  margin-top: 10px;
}
#labelNameLeft {
  text-align: left;
  margin-top: 10px;
}
.replyStyle {
  font-size: medium;
}
</style>

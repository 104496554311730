import axios from "axios";
import { store } from "@/store/store";
import DebugService from "@/service/DebugService";

import dataFilterId from "../assets/data/validateJsonId.json";
import dataFilterRoom from "../assets/data/validateJsonRoom.json";

export default {


  // https://dev.catsmodish.com/api/v1/virtual/qrcode-check
  // API_QR_02 QRCodeCheckInt

  async CheckinUser(codeCheckIn) {
    // alert("CheckinUser");
    const url = store.getters.url + "/api/v1/virtual/qrcode-check";
    const token = store.getters.token;
    let statusLogin;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-conference-code": store.getters.conferenceCode,
        "Content-Type": store.getters.ContentType,
      },
    };

    let bodyParameters = {
      qrcodeKey: codeCheckIn,
      checkType: "1",
    };

    console.log("url : " + JSON.stringify(url, null, 2));
    console.log("bodyParameters : " + JSON.stringify(bodyParameters, null, 2));
    console.log("config : " + JSON.stringify(config, null, 2));

    await axios
      .post(url, bodyParameters, config)
      // .then(console.log)
      .then(
        (response) => {
          DebugService.printConsole("on check in by qr code : ", response.data);
          // console.log(response.data);

          if (response.data.status == "failure") {
            let errorMsg = "Error : " + response.data.statusDesc;

            
            store.commit("storeQRCODE/SET_MessageErrorScanQR", errorMsg);

            DebugService.printConsole("data.status failure", response.data);

            // set state unload
            // this.danger(errorMsg);
            // this.btLoading = false;

            statusLogin = false;
            return false;
          } else if (response.data.status == "success") {
            this.errorMsg = response.data.status;
            // console.log("data : " + response.data);
            DebugService.printConsole("data success", response.data);
            statusLogin = true;

            store.commit("storeQRCODE/SET_nameScanQR", response.data.firstName);
            store.commit("storeQRCODE/SET_MessageErrorScanQR", "");
            return true;

            // this.router.push({ path: "/HomePage" });
          } else {
            // error request
            console.log("data : " + response.data);
            DebugService.printConsole("error request", response.data);
            store.commit("storeQRCODE/SET_MessageErrorScanQR", "error request");
            statusLogin = false;
            return false;
          }
        }

        // console.log
      )
      .catch(function(error) {
        // handle error
        // alert("ERROR");
        console.log(error);
      })
      .then(function() {
        // always executed
        // alert("always executed");
      });

    // alert("ERROR");

    return statusLogin;
  },

  IsFilterRoom(channelCode){
    console.log(channelCode);
    var IsFilterRoom = false;
    var flagRoom = false ;

    dataFilterRoom.forEach(element => {
      // check key register 
      if(element.Room == channelCode){
        flagRoom = true;
        if(element.PermitAll == "N"){
          IsFilterRoom = true;
          console.log("ต้องตรวจสอบการลงทะเบียน");   
          return true;     
        }else {
          console.log("ไม่ต้องตรวจสอบการลงทะเบียน");        
        }
      }
      
    });
    // กรณี หาข้อมูลไม่เจอ เท่่ากับ check in ได้ทุกคน 
    if(flagRoom == false){
      console.log("หาข้อมูลไม่เจอ เท่่ากับ check in ได้ทุกคน"); 
      IsFilterRoom = false;   
      return false;          
    }

    return IsFilterRoom;

  },

  CheckinUserByStreamFilterById(codeCheckIn,channelCode){
      console.log(codeCheckIn);
      console.log(channelCode);

      var flagRegister = false ;
      console.log( "call IsFilterRoom : " + this.IsFilterRoom(channelCode));
      if(this.IsFilterRoom(channelCode)){
        // ต้องตรวจสอบการลงทะเบียน 
            console.log("ห้องนี้ต้องตรวจสอบการลงทะเบียน ");
        // var data = dataFilterId.room.includes(channelCode);
        var data = dataFilterId.filter(x => x.Room == channelCode);
        // DebugService.printConsole("on dataFilterId : ", data);
        data.forEach(element => {
          // check key register 
          if(element.key == codeCheckIn ){
            console.log("go to check in API ");
            flagRegister = true;
          }
            
        });
     }else {
      // ไม่ต้องตรวจสอบการลงทะเบียน 
        flagRegister = true ;
     }

      if(flagRegister ){
        console.log("แจ้งเตือน มีการลงทะเบียนไว้");
      }else{
        console.log("แจ้งเตือน ไม่ได้ลงทะเบียนไว้");
      }

      return flagRegister;

  } ,
  
  // https://dev.catsmodish.com/api/v1/virtual/qrcode-check
  // API_QR_02 QRCodeCheckInt

  async CheckinUserByStream(codeCheckIn,channelCode) {
    // alert("CheckinUser");
    const url = store.getters.url + "/api/v1/virtual/qrcode-check";
    const token = store.getters.token;
    let statusLogin;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-conference-code": store.getters.conferenceCode,
        "Content-Type": store.getters.ContentType,
      },
    };

    let bodyParameters = {
      qrcodeKey: codeCheckIn,
      checkType: "1",
      name : channelCode,
    };

    console.log("url : " + JSON.stringify(url, null, 2));
    console.log("bodyParameters : " + JSON.stringify(bodyParameters, null, 2));
    console.log("config : " + JSON.stringify(config, null, 2));

    await axios
      .post(url, bodyParameters, config)
      // .then(console.log)
      .then(
        (response) => {
          DebugService.printConsole("on check in by qr code : ", response.data);
          // console.log(response.data);

          if (response.data.status == "failure") {
            let errorMsg = "Error : " + response.data.statusDesc;

            
            store.commit("storeQRCODE/SET_MessageErrorScanQR", errorMsg);

            DebugService.printConsole("data.status failure", response.data);

            // set state unload
            // this.danger(errorMsg);
            // this.btLoading = false;

            statusLogin = false;
            return false;
          } else if (response.data.status == "success") {
            this.errorMsg = response.data.status;
            // console.log("data : " + response.data);
            DebugService.printConsole("data success", response.data);
            statusLogin = true;

            store.commit("storeQRCODE/SET_nameScanQR", response.data.firstName);
            store.commit("storeQRCODE/SET_MessageErrorScanQR", "");
            return true;

            // this.router.push({ path: "/HomePage" });
          } else {
            // error request
            console.log("data : " + response.data);
            DebugService.printConsole("error request", response.data);
            store.commit("storeQRCODE/SET_MessageErrorScanQR", "error request");
            statusLogin = false;
            return false;
          }
        }

        // console.log
      )
      .catch(function(error) {
        // handle error
        // alert("ERROR");
        console.log(error);
      })
      .then(function() {
        // always executed
        // alert("always executed");
      });

    // alert("ERROR");

    return statusLogin;
  },

  // https://dev.catsmodish.com/api/v1/virtual/qrcode-create
  // API_QR_01 Create QRCode

  async CreateAllQRcode() {
    const url = store.getters.url + "/api/v1/virtual/qrcode-create";
    const token = store.getters.token;
    let statusLogin;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-conference-code": store.getters.conferenceCode,
        "Content-Type": store.getters.ContentType,
      },
    };

    // CREATE ALL QR CODE
    let bodyParameters = {
      "mode": "A",
      "usernames": ["cgm.admin01@catsmodish.com"]
    };

    console.log("url : " + JSON.stringify(url, null, 2));
    console.log("bodyParameters : " + JSON.stringify(bodyParameters, null, 2));
    console.log("config : " + JSON.stringify(config, null, 2));

    await axios
      .post(url, bodyParameters, config)
      // .then(console.log)
      .then(
        (response) => {
          DebugService.printConsole("on check in by qr code : ", response.data);
          console.log(response.data);

          if (response.data.status == "failure") {
            let errorMsg = "Error : " + response.data.statusDesc;

            // store.userLogin.commit("SET_MESSAGE_ERROR",this.errorMsg);
            store.commit("userLogin/SET_MESSAGE_ERROR", "login failure");
            store.commit("userLogin/SET_MESSAGE_ERROR", errorMsg);
            DebugService.printConsole("data.status failure", response.data);

            // set state unload
            // this.danger(errorMsg);
            // this.btLoading = false;

            statusLogin = false;
            return false;
          } else if (response.data.status == "success") {
            this.errorMsg = response.data.status;
            // console.log("data : " + response.data);
            DebugService.printConsole("data success", response.data);
            // store.commit("userLogin/SET_MESSAGE_ERROR", "login success");
            // alert( JSON.stringify(response.data, null, 2));
            statusLogin = true;

            store.commit("userLogin/SET_MESSAGE_ERROR", "");
            return true;

            // this.router.push({ path: "/HomePage" });
          } else {
            // error request
            // console.log("data : " + response.data);
            DebugService.printConsole("error request", response.data);
            store.commit("userLogin/SET_MESSAGE_ERROR", "error request");
            statusLogin = false;
            return false;
            // this.$router.push({ path: "/" });
          }

          // save token

          // router to home

          // console.log;
          // alert(this.errorMsg);
          // this.$router.push({ name: "home" });
        }

        // console.log
      )
      .catch(function(error) {
        // handle error
        // alert("ERROR");
        console.log(error);
      })
      .then(function() {
        // always executed
        // alert("always executed");
      });

    // alert("ERROR");

    return statusLogin;
  },


  
  // https://dev.catsmodish.com/api/v1/virtual/qrcode-create
  // API_QR_01 Create QRCode

  async CreateQRcodeNewUserOnly() {
    const url = store.getters.url + "/api/v1/virtual/qrcode-create";
    const token = store.getters.token;
    let statusLogin;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-conference-code": store.getters.conferenceCode,
        "Content-Type": store.getters.ContentType,
      },
    };

    // CREATE ALL QR CODE
    let bodyParameters = {
      "mode": "C",
      // "usernames": ["cgm.admin01@catsmodish.com"]
    };

    console.log("url : " + JSON.stringify(url, null, 2));
    console.log("bodyParameters : " + JSON.stringify(bodyParameters, null, 2));
    console.log("config : " + JSON.stringify(config, null, 2));

    await axios
      .post(url, bodyParameters, config)
      // .then(console.log)
      .then(
        (response) => {
          DebugService.printConsole("on check in by qr code : ", response.data);
          console.log(response.data);

          if (response.data.status == "failure") {
            let errorMsg = "Error : " + response.data.statusDesc;

            // store.userLogin.commit("SET_MESSAGE_ERROR",this.errorMsg);
            store.commit("userLogin/SET_MESSAGE_ERROR", "login failure");
            store.commit("userLogin/SET_MESSAGE_ERROR", errorMsg);
            DebugService.printConsole("data.status failure", response.data);

            // set state unload
            // this.danger(errorMsg);
            // this.btLoading = false;

            statusLogin = false;
            return false;
          } else if (response.data.status == "success") {
            this.errorMsg = response.data.status;
            // console.log("data : " + response.data);
            DebugService.printConsole("data success", response.data);
            // store.commit("userLogin/SET_MESSAGE_ERROR", "login success");
            // alert( JSON.stringify(response.data, null, 2));
            statusLogin = true;

            store.commit("userLogin/SET_MESSAGE_ERROR", "");
            return true;

            // this.router.push({ path: "/HomePage" });
          } else {
            // error request
            // console.log("data : " + response.data);
            DebugService.printConsole("error request", response.data);
            store.commit("userLogin/SET_MESSAGE_ERROR", "error request");
            statusLogin = false;
            return false;
            // this.$router.push({ path: "/" });
          }

          // save token

          // router to home

          // console.log;
          // alert(this.errorMsg);
          // this.$router.push({ name: "home" });
        }

        // console.log
      )
      .catch(function(error) {
        // handle error
        // alert("ERROR");
        console.log(error);
      })
      .then(function() {
        // always executed
        // alert("always executed");
      });

    // alert("ERROR");

    return statusLogin;
  },

  

  
  // https://dev.catsmodish.com/api/v1/virtual/qrcode-create
  // API_QR_01 Create QRCode

  async CreateUserQRcode(email) {
    const url = store.getters.url + "/api/v1/virtual/qrcode-create";
    const token = store.getters.token;
    let statusLogin;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-conference-code": store.getters.conferenceCode,
        "Content-Type": store.getters.ContentType,
      },
    };
    let user = [];
    user.push(email);
    // CREATE ALL QR CODE
    let bodyParameters = {
      "mode": "B",      
      "usernames": user
      // "usernames": ["cgm.admin01@catsmodish.com"]
    };

    console.log("url : " + JSON.stringify(url, null, 2));
    console.log("bodyParameters : " + JSON.stringify(bodyParameters, null, 2));
    console.log("config : " + JSON.stringify(config, null, 2));

    await axios
      .post(url, bodyParameters, config)
      // .then(console.log)
      .then(
        (response) => {
          DebugService.printConsole("CreateUserQRcode : ", response.data);
          console.log(response.data);

          if (response.data.status == "failure") {
            let errorMsg = "Error : " + response.data.statusDesc;

            // store.userLogin.commit("SET_MESSAGE_ERROR",this.errorMsg);            
            store.commit("storeQRCODE/SET_MessageErrorScanQR", errorMsg);
            DebugService.printConsole("data.status failure", response.data);

            // set state unload
            // this.danger(errorMsg);
            // this.btLoading = false;

            statusLogin = false;
            return false;
          } else if (response.data.status == "success") {
            this.errorMsg = response.data.status;
            // console.log("data : " + response.data);
            DebugService.printConsole("data success", response.data);
            statusLogin = true;

            store.commit("storeQRCODE/SET_MessageErrorScanQR", "");
            return true;

            // this.router.push({ path: "/HomePage" });
          } else {
            // error request
            // console.log("data : " + response.data);
            DebugService.printConsole("error request", response.data);
            store.commit("storeQRCODE/SET_MessageErrorScanQR", "error request");
            statusLogin = false;
            return false;
            // this.$router.push({ path: "/" });
          }

          // save token

          // router to home

          // console.log;
          // alert(this.errorMsg);
          // this.$router.push({ name: "home" });
        }

        // console.log
      )
      .catch(function(error) {
        // handle error
        // alert("ERROR");
        console.log(error);
      })
      .then(function() {
        // always executed
        // alert("always executed");
      });

    // alert("ERROR");

    return statusLogin;
  },

  
  // https://dev.catsmodish.com/api/v1/virtual/qrcode-status
  // API_QR_03 QRCode Status
  // Enable user for use QRCODE

  async EnableUserQRcode(email) {
    const url = store.getters.url + "/api/v1/virtual/qrcode-status";
    const token = store.getters.token;
    let statusLogin;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-conference-code": store.getters.conferenceCode,
        "Content-Type": store.getters.ContentType,
      },
    };
    let user = [];
    user.push(email);
    // CREATE ALL QR CODE
    let bodyParameters = {
      "mode": "B",      
      "usernames": user,
      // "usernames": ["cgm.admin01@catsmodish.com"]
      "action": "enable",
    };

    console.log("url : " + JSON.stringify(url, null, 2));
    console.log("bodyParameters : " + JSON.stringify(bodyParameters, null, 2));
    console.log("config : " + JSON.stringify(config, null, 2));

    await axios
      .post(url, bodyParameters, config)
      // .then(console.log)
      .then(
        (response) => {
          DebugService.printConsole("Enable user for use QRCODE : ", response.data);
          console.log(response.data);

          if (response.data.status == "failure") {
            let errorMsg = "Error : " + response.data.statusDesc;

            // store.userLogin.commit("SET_MESSAGE_ERROR",this.errorMsg);            
            store.commit("storeQRCODE/SET_MessageErrorScanQR", errorMsg);
            DebugService.printConsole("data.status failure", response.data);

            // set state unload
            // this.danger(errorMsg);
            // this.btLoading = false;

            statusLogin = false;
            return false;
          } else if (response.data.status == "success") {
            this.errorMsg = response.data.status;
            // console.log("data : " + response.data);
            DebugService.printConsole("data success", response.data);
            statusLogin = true;

            store.commit("storeQRCODE/SET_MessageErrorScanQR", "");
            return true;

            // this.router.push({ path: "/HomePage" });
          } else {
            // error request
            // console.log("data : " + response.data);
            DebugService.printConsole("error request", response.data);
            store.commit("storeQRCODE/SET_MessageErrorScanQR", "error request");
            statusLogin = false;
            return false;
            // this.$router.push({ path: "/" });
          }

          // save token

          // router to home

          // console.log;
          // alert(this.errorMsg);
          // this.$router.push({ name: "home" });
        }

        // console.log
      )
      .catch(function(error) {
        // handle error
        // alert("ERROR");
        console.log(error);
      })
      .then(function() {
        // always executed
        // alert("always executed");
      });

    // alert("ERROR");

    return statusLogin;
  },

  
  // https://dev.catsmodish.com/api/v1/virtual/qrcode-image?username=account@promptevent.co.th
  // API_QR_04 Get QRCode image by username
  async getQRcodeByUser(username) {
    const url = store.getters.url + "/api/v1/virtual/qrcode-image?username="+username;
    const token = store.getters.token;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-conference-code": store.getters.conferenceCode,
        "Content-Type": store.getters.ContentType,
      },
    };

    let bodyParameters = {
      // "username": user.username,
      // "password": user.password,
    };

    console.log("url : " + JSON.stringify(url, null, 2));
    console.log("bodyParameters : " + JSON.stringify(bodyParameters, null, 2));
    console.log("config : " + JSON.stringify(config, null, 2));

    let img = null;

    axios
      .get(url, config)
      .then((response) => {
        
          // alert(response.data);
        // your code
        if (response.data.status == "success") {
          console.log(
            "response.data : " + JSON.stringify(response.data, null, 2)
          );

          // alert(response.data);

          img = response.data;
        }
        // this.articleId = response.data.token;
      }, console.log)
      .catch(console.log);

    // return {test : "test" };
    
    // alert(img);
    return img;
  },


};

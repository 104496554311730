<template>
  <div class="card text-center m-3">
    <!-- <div :style="HeaderStyles"> -->

    <div v-if="this.$store.getters.flag_CSS_BG_IMAGE">
      <!-- <header class="header" :style="{ backgroundImage: 'url(' + image + ')' }"> -->
      <header class="header" :style="HeightStyles">
        <!-- <div class="content has-text-centered" style=""> -->

        <!-- <b-image class="logoleft" :src="logoImagerRight"></b-image> -->
        <!-- </div> -->

        <div class="content has-text-centered" style="">
          <!-- <div class="row"> -->
          <!-- <div style="float: left;width: 25%;">
              <b-image class="logo-header-left" style="float: left;" :src="logoImageleft"></b-image>
            </div> -->
          <!-- <div style="float: left;width: 100%;"> -->
          <b-image class="logoImage" :src="logoImage"></b-image>
          <!-- </div> -->
          <!-- <div style="float: right;width: 25%;">
            <b-image class="logo-header-left" style="float: right;" :src="logoImagerRight"></b-image>
          </div> -->
          <!-- </div> -->
        </div>
      </header>
    </div>
    <div v-else>
      <header class="header" :style="{}">
        <div class="content has-text-centered">
          <b-image class="logoImage" :src="logoImage"></b-image>
        </div>
      </header>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
export default {
  name: "footer",
  components: {},
  data() {
    return {
      // background header home
      image: require("@/assets/images/background_header.jpg"),
      image_mobile: require("@/assets/images/background_header_mobile.jpg"),
      // image: require("@/assets/images/header-home.jpg"),
      logoImage: require("@/assets/images/logo-header.png"),
      // logoImageleft: require("@/assets/images/logo-header-left.png"),
      // logoImagerRight: require("@/assets/images/logo-header-right.png"),
      footer: "Powered by Prompt​ Event&Organize.",
      templeteColor: this.$store.getters.templeteBgColor,
    };
  },
  async created() {},

  computed: {
    HeightStyles() {
      // return {
      //   "background-image": 'url("' + this.image + '")',
      //   opacity: 0.7,
      //   "background-size": "cover",

      // };

      if (window.innerWidth > 800) {
        return {
          // css header desktop
          "background-image": 'url("' + this.image + '")',
        };
      } else {
        return {
          // css header mobile
          "background-image": 'url("' + this.image_mobile + '")',
        };
      }
    },
  },

  methods: {
    goHomePage() {
      // alert("goRegisterPage");
      this.$router.push({ path: "/HomePage" });
    },
    goLoginPage() {
      // alert("goRegisterPage");
      this.$router.push({ path: "/" });
    },
    goEaPage() {
      // alert("goRegisterPage");
      this.$router.push({ path: "/EvaluationPage" });
    },

    mounted() {
      console.log("mounted");
    },
  },
};
</script>
<style scoped>
.header {
  /* height: 625px;  */
  /* background-image: url("/img/background.7780a5cd.jpg"); */
  /* img/background.096b4834.jpg */
  /* background-image: require("@/assets/images/background.jpg"); */

  /* opacity: 0.8; */
  background-color: var(--templete-color);
  background-size: cover;
  padding: 25px;
  color: rgb(255, 255, 255);
  /* padding-top: 50px; */
  /* height:150px; */
}
.logoImage {
  /* height: 20%; */
  /* width: 600px; */
  /* width: 800px; */

  /* height: 130px;
  
  max-width: 350px; */
  max-width: 350px;
  margin: auto;
  /* opacity: 0; */
}
.logo-header-left {
  /* height: 90px;
  width: 150px; */
}

/* Create three unequal columns that floats next to each other */
.column {
  float: left;
  /* padding: 10px; */
  /* height: 300px; */
  /* Should be removed. Only for demonstration */
}

.left,
.right {
  width: 25%;
}

.middle {
  width: 50%;
}
/* Clear floats after the columns */
/* .row:after {
  content: "";
  display: table;
  clear: both;
} */

.logoleft {
  /* width: 100px; */
  position: absolute;
  top: 10px;
  /* left: 0px; */
  right: 10px;
}

@media only screen and (max-width: 600px) {
  .logo-header-left {
    /* height: 90px; */
    width: 50px;
  }
  .logoImage {
    /* width: 200px; */
    height: auto;
  }
  .content figure {
    margin-left: 0em;
    margin-right: 0em;
    /* text-align: center; */
  }
  .logoleft {
    width: 75px;
    position: absolute;
    top: 10px;
    /* left: 0px; */
    right: 10px;
  }
}
@media only screen and (max-width: 900px) and (min-width: 600px) {
  .logoleft {
    width: 150px;
    position: absolute;
    top: 10px;
    /* left: 0px; */
    right: 10px;
  }
}
</style>

// import { Settings } from '@/variables.js'
import axios from "axios";
import { store } from "@/store/store";
// import HttpService from "@/service/HttpService";

export default {

  // user get question poll get question vote & choice >> enable = false >> not show
  // API_PL_04 Get stream poll list
  // https://dev.catsmodish.com/api/v1/virtual/stream-poll-list/S1612877527

  async getQuestionPoll(channelCode) {
    const url =
      store.getters.url + "/api/v1/virtual/stream-poll-list/" + channelCode;
    const token = store.getters.token;

    let dataReturn = [];

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-conference-code": store.getters.conferenceCode,
        "Content-Type": store.getters.ContentType,
      },
    };

    // let bodyParameters = {
    //   // "username": user.username,
    //   // "password": user.password,
    // };

    // console.log("url : " + JSON.stringify(url, null, 2));
    // console.log(
    //   "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
    // );
    // console.log("config : " + JSON.stringify(config, null, 2));

    await axios
      .get(url, config)
      .then((response) => {
        // your code

        if (response.data.status == "success") {
          console.log(
            "getQuestionPoll response.data : " +
            JSON.stringify(response.data, null, 2)
          );

          dataReturn = response.data.steamPolls;

          // this.questionPoll = dataReturn;
          store.commit("SET_QUESTION_POLL", response.data.steamPolls);
          // clear data
          store.commit("SET_CHOICE_POLL", null);

          // const sum_totalVoted = dataReturn
          //   .map((x) => x.totalVoted) // Extract only the achat field
          //   .flat() // Flatten the array Eg: [[1], [2], [3]] --> [1, 2, 3]
          //   // .map((x) => +x.initial) // Extract the initial field ("+" is to convert string to integer)
          //   .reduce((e, f) => e + f);

          // console.log("dataReturn.length : " + dataReturn.length);

          // clear
          // this.isVoted = false;
          store.commit("SET_IS_VOTE",false);
          
          alert("sss");

          for (let i = 0; i < dataReturn.length; i++) {
            // if (dataReturn[i].pollId !=null) {
            // this.printOject(dataReturn[i].pollId);
            let questionId = dataReturn[i].pollId;
            // alert(questionId);
            this.getChoicePoll(questionId);
            //this.choicePoll
            // }
          }

          // this.printOject(this.choicePoll);
          //this.choicePoll
        }
        // this.articleId = response.data.token;
      }, console.log)
      .catch(console.log);

    // return response.data.conferenceEvaluations;
    return dataReturn;
  },

  // get choice vote
  // API_PL_06 Get stream poll details
  // https://dev.catsmodish.com/api/v1/virtual/stream-poll-details/2

  async getChoicePoll(pollId) {
    const url =
      store.getters.url + "/api/v1/virtual/stream-poll-details/" + pollId;
    const token = store.getters.token;

    let dataReturn = [];

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-conference-code": store.getters.conferenceCode,
        "Content-Type": store.getters.ContentType,
      },
    };

    // let bodyParameters = {
    //   // "username": user.username,
    //   // "password": user.password,
    // };

    // console.log("url : " + JSON.stringify(url, null, 2));
    // console.log(
    //   "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
    // );
    // console.log("config : " + JSON.stringify(config, null, 2));

    await axios
      .get(url, config)
      .then((response) => {
        // your code

        if (response.data.status == "success") {
          console.log(
            "getChoicePoll response.data : " +
            JSON.stringify(response.data, null, 2)
          );

          dataReturn = response.data.pollChoices;
          // this.choicePoll[pollId] = dataReturn;
          store.commit("SET_CHOICE_POLL", [pollId,dataReturn]);
          
          // alert(JSON.stringify(response.data, null, 2));
          // alert(JSON.stringify(store.getters.choicePoll, null, 2));
          // alert();
          // alert(store.getters.questionPoll);

          for (let i = 0; i < dataReturn.length; i++) {
            let voted = dataReturn[i].voted;
            if (voted > 0) {
              // this.isVoted = true;
              store.commit("SET_IS_VOTE", true);
            }
            // this.printOject(dataReturn[i]);
          }
        }
        // this.articleId = response.data.token;
      }, console.log)
      .catch(console.log);

    // return response.data.conferenceEvaluations;
    return dataReturn;
  },


  // save choice vote
  // API_PL_05 Vote stream poll
  // https://dev.catsmodish.com/api/v1/virtual/stream-poll-vote

  async saveChoiceVote(choiceId) {
    const url = store.getters.url + "/api/v1/virtual/stream-poll-vote";
    const token = store.getters.token;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "x-conference-code": store.getters.conferenceCode,
        "Content-Type": store.getters.ContentType,
      },
    };

    let bodyParameters = {
      choiceId: choiceId, // int
    };

    // console.log("url : " + JSON.stringify(url, null, 2));
    // console.log("bodyParameters : " + JSON.stringify(bodyParameters, null, 2));
    // console.log("config : " + JSON.stringify(config, null, 2));

    await axios
      .post(url, bodyParameters, config)
      .then((response) => {
        // your code
        // this.articleId = response.data.token;
        console.log(
          "saveChoiceVote : " + JSON.stringify(response.data, null, 2)
        );
        this.success("send poll complete.");
      }, console.log)
      .catch(console.log);
  },

}
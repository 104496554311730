import Vue from "vue";
import Vuex from "vuex";

// persistedstate

import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

// import router from '@/router'
import UserService from "@/service/UserService";

// import { people } from "./modules/people";
import storeQA from "./storeQA";
import storePoll from "./storePoll";
import userLogin from './modules/userLogin'
import storeQRCODE from "./storeQRCODE";


Vue.use(Vuex);

export const store = new Vuex.Store({

    plugins: [
        createPersistedState({
            storage: {
                getItem: key => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: key => ls.remove(key)
            }
        })
    ],

    state: {
        // ที่เก็บข้อมูล  
        // demo
        // url : "https://dev.virtual.promptevent.com", // test
        url: "https://service.promptevent.com",
        // l3w5UT1XFXmmIYlhPuJeoroZWlt9pCwg


        // conferenceCode 0 admin 
        // l3w5UT1XFXmmIYlhPuJeoroZWlt9pCwg
        // {
        //     "username": " admin@catsmodish.com",
        //     "password": "Ad12345MiN6789"
        // }
        // ขาด admin create strem

        // "conferenceId": 2,
        // https://www.promptevent.com // UAT
        // conferenceCode: "rqkO9ZjUkUgOZjXq56ZkR2boLrCfvPEc",  // uat

        // https://ami.promptevent.com
        // conferenceCode: "OHzTydNMRsKQpCgFV4ax5LFHnZezGoiO",

        // https://coolsculpting.promptevent.com
        // conferenceCode:  "CkzEJY2uY9maPlDxHbl5F1lzNBph88zO",


        // https://thtoxin.promptevent.com
        // conferenceCode: "vI168JSu6Yl97fO1MgEDHN5YMkOX9S4e",

        //  "conferenceId": 7,
        // https://www.promptevent.com/SSY7theseries 
        // conferenceCode: "XQd3L6h5v7Dxh6HEnXC4KnBkTcfnqvNC",

        //  "conferenceId": 8,
        // https://www.promptevent.com/XenExpertMeeting 
        // conferenceCode: "3sMgQOlsVrtThqQqrpbfafDSVPrgGsZv",

        //  "conferenceId": 9,
        // https://www.promptevent.com/BoehringerIngelheimNoon 
        conferenceCode: "Gm8jEQ3Ef7fRHBuusEJ2HO3DPkZ5krW8",

        //  "conferenceId": 10,
        // https://www.promptevent.com/BoehringerIngelheimMoring 
        // conferenceCode: "XlkBvpMVduJeIXIucvxJHu7ONhNWGmDs",




        // isDebug : true,
        isDebug: true,

        ContentType: "application/json",
        token: "test",
        roles: [],
        user: "",
        steams: null,
        isLogin: null,
        windowWidth: window.innerWidth,


        // open or close function
        flag_fn_register: true,
        flag_fn_Poll: false,
        flag_fn_QA: true,
        flag_fn_sticker: true,
        flag_fn_EA: true,
        flag_fn_info: true,
        flag_CSS_BG_IMAGE: true,
        flag_Supper_Admin: true,
        flag_fix_password: false,   
        flag_QRCODE: false,       
        // flag_Supper_Admin : true,

        fix_password: "",

        // templete css
        // templeteBgColor :"#057565", // DEMO

        // templeteBgColor: "#f2dad0", // pink AMI 

        // templeteBgColor: "#6bc2c1", // Green SSY7theseries 

        // templeteBgColor: " #223579", // Blue XenExpertMeeting

        templeteBgColor: " #06214d", // Blue Boehringer Ingelheim morning
        // templeteBgColor: " #daeaf9", // Blue Boehringer Ingelheim noon 



        // templeteBgColor : "#e2c2b6",
        // templeteBgColor : "#c8998d", // pink AMI #f2dad0 

        // templeteBgColor : "#002454",
        // templeteBgColor : "#335076", // blue 24/06/2021 Botox



        // templeteFontColor : "#747476", // gray mode        
        // templeteFontColor : "#4a4a4a", // gray mode     AMI
        // templeteFontColor : "#000000", // black mode     AMI

        templeteFontColor: "#ffffff",// white mode

        counterErrorToken: 0,

        loginKey: "",

        // people,
    },
    // setter กำหนดค่า ข้อมูล ลง state(store)
    mutations: {


        setTempleteFontColor(state, templeteFontColor) {
            state.templeteFontColor = templeteFontColor; // กำหนดค่า state 
        },


        setTempleteBgColor(state, templeteBgColor) {
            state.templeteBgColor = templeteBgColor; // กำหนดค่า state 
        },

        setConferenceCode(state, conferenceCode) {
            state.conferenceCode = conferenceCode; // กำหนดค่า state 
        },
        
        
        setFlag_fn_QA(state, flag_fn_QA) {
            state.flag_fn_QA = flag_fn_QA; // กำหนดค่า state 
        },
        
        setFlag_Supper_Admin(state, flag_Supper_Admin) {
            state.flag_Supper_Admin = flag_Supper_Admin; // กำหนดค่า state 
        },

        setFlag_fn_register(state, flag_fn_register) {
            state.flag_fn_register = flag_fn_register; // กำหนดค่า state 
        },

        setFlag_fn_EA(state, flag_fn_EA) {
            state.flag_fn_EA = flag_fn_EA; // กำหนดค่า state 
        },

        setFlag_CSS_BG_IMAGE(state, flag_CSS_BG_IMAGE) {
            state.flag_CSS_BG_IMAGE = flag_CSS_BG_IMAGE; // กำหนดค่า state 
        },        
        
        setFlag_fn_Poll(state, flag_fn_Poll) {
            state.flag_fn_Poll = flag_fn_Poll; // กำหนดค่า state 
        },

        setFlag_fn_info(state, flag_fn_info) {
            state.flag_fn_info = flag_fn_info; // กำหนดค่า state 
        },

        setFlag_fn_sticker(state, flag_fn_sticker) {
            state.flag_fn_sticker = flag_fn_sticker; // กำหนดค่า state 
        },

        
        setFlag_fix_password(state, flag_fix_password) {
            state.flag_fix_password = flag_fix_password; // กำหนดค่า state 
        },   

        setFix_password(state, fix_password) {
            state.fix_password = fix_password; // กำหนดค่า state 
        },       
        
        setFlag_QRCODE(state, flag_QRCODE) {
            state.flag_QRCODE = flag_QRCODE; // กำหนดค่า state 
        },   

        
        
        

        setUser(state, user) {
            state.user = user; // กำหนดค่า state 
        },
        setRoles(state, roles) {
            state.roles = roles; // กำหนดค่า state 
        },
        setToken(state, token) {
            state.token = token; // กำหนดค่า state 
        },
        setSteams(state, steams) {
            state.steams = steams; // กำหนดค่า state 
        },
        setError(state, payload) {
            state.error = payload
        },
        setLoading(state, payload) {
            state.loading = payload
        },
        setIsLogin(state, status) {
            state.isLogin = status
        },
        setWindowWidth(state) {
            state.windowWidth = window.innerWidth;
        },

        SET_COUNTER_ERROR_TOKEN(state, counterErrorToken) {
            state.counterErrorToken = counterErrorToken
        },

        SET_LOGIN_KEY(state, loginKey) {
            state.loginKey = loginKey; // กำหนดค่า state 
        },
    },


    actions: {
        addUser(context, user) {
            context.commit('setLoading', true)

            UserService.register(user);
            context.commit("setUser", user);
            context.commit('setLoading', false)
            // router.push('/');
            this.$router.push({ path: "/" });
        },
        keepAlive(context) {
            context.commit("setToken");
        },

        setStatusLogin(context, status) {
            alert("setStatusLogin : " + status);
            context.commit("setIsLogin", status);

            if (status == false) {
                alert("router to login page : " + status);
                this.$router.push({ path: "/" });
            }

        },
    },

    getters: {
        // ดึงข้อมูล user จาก store ใส่ state  
        user: state => state.user,
        url: state => state.url,
        conferenceCode: state => state.conferenceCode,
        ContentType: state => state.ContentType,
        token: state => state.token,
        steams: state => state.steams,
        roles: state => state.roles,

        isLogin: state => state.isLogin,
        isDebug: state => state.isDebug,


        loginKey: state => state.loginKey,

        templeteBgColor: state => state.templeteBgColor,
        templeteFontColor: state => state.templeteFontColor,

        flag_fn_register: state => state.flag_fn_register,
        flag_fn_Poll: state => state.flag_fn_Poll,
        flag_fn_QA: state => state.flag_fn_QA,
        flag_fn_EA: state => state.flag_fn_EA,
        flag_fn_info: state => state.flag_fn_info,
        flag_fn_sticker: state => state.flag_fn_sticker,
        flag_CSS_BG_IMAGE: state => state.flag_CSS_BG_IMAGE,
        flag_Supper_Admin: state => state.flag_Supper_Admin,
        flag_fix_password: state => state.flag_fix_password,
        fix_password: state => state.fix_password,
        flag_QRCODE : state => state.flag_QRCODE,
        
        counterErrorToken: state => state.counterErrorToken,

    },

    modules: {
        // people,
        storeQA,
        storePoll,
        userLogin,
        storeQRCODE,
    },

});
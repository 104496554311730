const state = {
    messageErrorLogin: "",
}

const getters = {
    messageErrorLogin: state => state.messageErrorLogin,

    // fullName: (state, getters, rootState) => {
    //     return state.firstName + ' ' + state.lastName
    // }


}

//   const actions = {
//     increaseCounter: ({ commit, state }, payload) => {
//       commit('INCREASE_CURRENT_COUNTER')
//     }
//   }

const mutations = {
     SET_MESSAGE_ERROR(state, payload) {
        state.messageErrorLogin = payload;
    }

    // INCREASE_CURRENT_COUNTER(state, payload) {
    //     state.currentCounter++
    // }
}

export default {
    namespaced: true,
    state,
    getters,
    // actions,
    mutations
}
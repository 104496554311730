
<template>
  <div class="card text-center m-3">
    <header-component></header-component>

    <admin-nav-component></admin-nav-component>

    <div class="card-body"><strong> Summary Evaluation </strong></div>

    <!-- Channel : {{ channel }} DDL : {{ steams }} -->
    <!-- eaQuestionRatingChoice : {{ eaQuestionRatingChoice }} -->

    <!-- eaQuestionAns : {{ eaQuestionAns }} -->
    <div class="card-body">
      <b-tabs>
        <b-tab-item label="Table">
          <b-table :data="eaQuestionAns" :selected.sync="selected" focusable>
            <b-table-column
              centered
              field="question"
              label="question"
              v-slot="props"
            >
              {{ props.row.question }}
            </b-table-column>

            <!-- <b-table-column
              field="enabled"
              label="enable"
              v-slot="props"
              centered
            >
              <b-switch
                v-model="props.row.enabled"
                @input="changeEnablePoll(props.row, props.row.enabled)"
              >
                {{ props.row.enabled }}</b-switch
              >
            </b-table-column>
            <b-table-column
              field="opened"
              label="opened"
              v-slot="props"
              centered
            >
              <div class="control">
                <b-switch
                  v-model="props.row.opened"
                  @input="changeEnableOpened(props.row, props.row.opened)"
                  >{{ props.row.opened }}</b-switch
                >
              </div>
            </b-table-column> -->
          </b-table>
        </b-tab-item>

        <b-tab-item label="Answers">
          <!-- <pre>{{ selected }}</pre>
        <p>{{ evaluationAnswers }}</p> -->
          <p>
            <strong>Question : {{ selected.question }} </strong>
          </p>

          <b-table :data="evaluationAnswers">
            <b-table-column
              centered
              field="answer"
              label="answer"
              v-slot="props"
            >
              {{ props.row.answer }}
            </b-table-column>

            <b-table-column
              field="firstName"
              label="firstName"
              v-slot="props"
              centered
            >
              {{ props.row.firstName }}
            </b-table-column>

            <b-table-column
              field="lastModifiedDate"
              label="DATE"
              v-slot="props"
              centered
            >
              {{ props.row.lastModifiedDate }}
            </b-table-column>
          </b-table>

          <div class="card-body">
            <paginate
              v-model="page"
              :page-count="totalPage"
              :page-range="3"
              :margin-pages="2"
              :click-handler="clickCallback"
              :prev-text="'Prev'"
              :next-text="'Next'"
              :container-class="'pagination'"
              :page-class="'page-item'"
            >
            </paginate>
          </div>
        </b-tab-item>
      </b-tabs>
    </div>
    <!-- <section>
      <b-field group-multiline grouped horizontal>
        <b-field label=" Please Channel : "> </b-field>
        <b-dropdown aria-role="list">
          <template #trigger="{ active }">
            <b-button
              :label="selected"
              type="is-primary"
              :icon-right="active ? 'menu-up' : 'menu-down'"
            />
          </template>
          <div v-for="item in steams" :key="item.streamName">
            <b-dropdown-item aria-role="listitem" @click="onSelected(item)">{{
              item.name
            }}</b-dropdown-item>
          </div>
        </b-dropdown>
      </b-field>
    </section> -->

    <!-- Channel : {{ channel }} -->

    <!-- data {{ series[0].data }} choice {{ chartOptions.xaxis.categories }}
    <div id="chart">
      <apex-charts
        type="bar"
        height="350"
        :options="chartOptions"
        :series="series"
      ></apex-charts>
    </div>

    sadasd choicePoll
    {{ choicePoll }}
    choicePoll.length = {{ choicePoll.length }} -->

    <div
      v-for="item in eaQuestionRatingChoice"
      :key="item.pollId"
      class="qaQuestion"
    >
      <tr>
        <td class="">
          <p class="qaQuestion">Question : {{ item.question }}</p>
        </td>
        <!-- <div v-for="item in item.pollChoices" :key="item.choiceId">
          <p class="qaQuestion">
            Poll choice : .{{ item.description + " :  totalVoted " + item.totalVoted }}
          </p>
        </div> -->
      </tr>
      <div id="chart">
        <apex-charts
          type="bar"
          height="350"
          :options="seriesArrayDesc[item.pollId]"
          :series="seriesArray[item.pollId]"
        ></apex-charts>
      </div>
    </div>

    <!-- v-on:change="changeEnableUser(props.row.username,props.row.enabled)"  -->
    <!-- @update="changeEnableUser(props.row.username,props.row.enabled)" -->

    <div class="overflow-auto">
      <!-- <div>
        <h6>Small Pills</h6>
        <b-pagination
          v-model="currentPage"
          pills
          :total-rows="rows"
          size="sm"
        ></b-pagination>
      </div> -->

      <!-- <div class="mt-3">
        <h6>Default Pills</h6>
        <b-pagination
          v-model="currentPage"
          pills
          :total-rows="rows"
        ></b-pagination>
      </div> -->

      <!-- <div class="mt-3">
        <h6>Large Pills</h6>
        <b-pagination
          v-model="currentPage"
          pills
          :total-rows="rows"
          size="lg"
        ></b-pagination>
      </div> -->

      <!-- <VueTailwindPagination :current="currentPage" :total="total" :per-page="perPage" @page-changed="current = $event"/> -->
    </div>

    <footer-component></footer-component>
  </div>
</template>

<script>
// import UserService from "@/service/UserService";
// import VueTailwindPagination from "@ocrv/vue-tailwind-pagination";

import axios from "axios";
import { store } from "@/store/store";
import FooterComponent from "./footer.vue";
import HeaderComponent from "./header.vue";
import Paginate from "vuejs-paginate";
import adminNavComponent from "./adminNav.vue";
// import ApexCharts from "apexcharts";
import ApexCharts from "vue-apexcharts";
import UserService from "@/service/UserService";

export default {
  name: "post-request",
  components: {
    FooterComponent,
    HeaderComponent,
    Paginate,
    adminNavComponent,
    ApexCharts,
    // VueTailwindPagination,
  },

  async created() {
    console.log.clear;
    console.log("created");
    this.getListEaQuestionRating();
    this.getListEaQuestion();

    this.checkSesseions();

    console.log(
      "choicePoll response.data : " + JSON.stringify(this.choicePoll, null, 2)
    );
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },

  data() {
    return {
      errors: [],

      userList: [],
      // currentPage: 1,
      btLoading: false,

      ///
      // evaluationAnswers : 0;
      totalPage: 0,
      page: 1,

      steams: [],
      selected: "",

      channel: "",
      choicePoll: [],
      seriesArray: [],
      seriesArrayDesc: [],

      eaQuestionRatingChoice: [],
      eaQuestionAns: [],
      evaluationAnswers: [],

      timer: "",
    };
  },
  mounted() {},

  computed: {},

  watch: {
    // whenever question changes, this function will run
    selected: function (newSelected) {
      // alert("  new >> :" + newSelected );
      // alert("  new >> :" + newSelected + "  old >> :" + oldSelected);
      this.onSelected(newSelected);
    },
  },
  filters: {},

  methods: {
    checkSesseions() {
      /// check session ///

      UserService.keepAlive(this.$store.getters.token);

      let token = store.getters.token;

      if (!token) {
        this.$router.push({ path: "/" });
      }

      clearInterval(this.timer);
      this.timer = setInterval(() => {
        UserService.keepAlive(this.$store.getters.token);

        if (!this.$store.getters.isLogin) {
          // alert(" seesion expire please login again");
          clearInterval(this.timer);
          this.$router.push({ path: "/" });
        }
      }, 5000);

      /// check session ///
    },

    onSelected(itemSelected) {
      // alert("itemSelected.evaluationId : "+itemSelected.evaluationId);
      // alert("this.selected.evaluationId : " +this.selected.evaluationId);

      this.getAnsQuestionEA(itemSelected.evaluationId, 1);

      // this.selected = streamObject.name;
      // this.channel = streamObject.streamName;

      // this.getQuestionPoll(this.channel);
    },
    clickCallback: function (pageNum) {
      console.log("PAGE : " + pageNum);
      // alert("PAGE : " + pageNum);
      // alert(pageNum);
      // this.getUser("@", pageNum);
      this.getAnsQuestionEA(this.selected.evaluationId, pageNum);
    },

    success(msg) {
      this.$buefy.notification.open({
        message: msg,
        type: "is-success",
        duration: 5000,
      });
    },
    printObject(obj) {
      console.log("printObject : " + JSON.stringify(obj, null, 2));
    },

    changeEnableUser(user, status) {
      console.log("changeEnableUser : " + user + " to status : " + status);
      this.updateStatusUser(user, status);

      // update user status
    },

    changeEnableCertified(user, status) {
      console.log("changeEnableCertified : " + user + " to status : " + status);
      this.updateCertifiedUser(user, status);

      // update user status
    },
    pageChangeHandler(selectedPage) {
      this.currentPage = selectedPage;
    },

    onSubmit() {
      this.$emit("submit", this.model);
    },
    doSomethings(val) {
      console.clear();
      console.log(val);
      alert(val);
    },
    changeRating(value) {
      console.log("===  changeRating ===  " + value);
      // console.log("===  dataRating ===  " + dataRating[item.pollId]);

      // map

      // console.log("===  changeRating ===  " + this.dataRating[value]);

      // this.$buefy.toast.open({
      //   message: "Thanks for you Rate!",
      //   type: "is-success",
      // });
    },
    goLoginPage() {
      // alert("goRegisterPage");
      this.$router.push({ path: "/" });
    },
    goStremPage(code) {
      console.log("Conference code : " + code);
      // alert("goRegisterPage");
      this.$router.push({ path: "/StreamPage/" + code, code });
    },

    getTestFn() {
      console.log("===  getTestFn ===  ");
    },

    // API_EA_03 List conference evaluation (Admin) Question & Ans
    // https://service.promptevent.com/api/v1/virtual/conference-evaluation-list-admin

    // get ANS EA
    // https://service.promptevent.com/api/v1/virtual/conference-evaluation-answer/1/1
    // API_EA_06 Get conference evaluation answer (evaluationId/pageNo)

    getAnsQuestionEA(evaluationId, pageNo) {
      const url =
        store.getters.url +
        "/api/v1/virtual/conference-evaluation-answer/" +
        evaluationId +
        "/" +
        pageNo;
      const token = store.getters.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      // let bodyParameters = {
      //   // "username": user.username,
      //   // "password": user.password,
      // };

      // console.log("url : " + JSON.stringify(url, null, 2));
      // console.log(
      //   "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      // );
      // console.log("config : " + JSON.stringify(config, null, 2));

      axios
        .get(url, config)
        .then((response) => {
          // your code
          if (response.data.status == "success") {
            console.log(
              "eaQuestionAns.data : " + JSON.stringify(response.data, null, 2)
            );

            console.clear();
            this.evaluationAnswers = response.data.evaluation.evaluationAnswers;
            // this.evaluationAnswers = response.data.evaluation;
            this.totalPage = response.data.evaluation.totalPage;

            // store.commit("setSteams", response.data.steams);
            // this.steams = response.data.steams;
            // "response.data.steams : " + JSON.stringify(this.steams, null, 2)
          }
          // this.articleId = response.data.token;
        }, console.log)
        .catch(console.log);

      // return {test : "test" };
      return evaluationId;
    },

    //  list question Evaluation
    // API_EA_04 List conference evaluation (User)
    // https://service.promptevent.com/api/v1/virtual/conference-evaluation-list
    getListEaQuestion() {
      const url =
        store.getters.url + "/api/v1/virtual/conference-evaluation-list-admin";
      const token = store.getters.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      // let bodyParameters = {
      //   // "username": user.username,
      //   // "password": user.password,
      // };

      // console.log("url : " + JSON.stringify(url, null, 2));
      // console.log(
      //   "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      // );
      // console.log("config : " + JSON.stringify(config, null, 2));

      axios
        .get(url, config)
        .then((response) => {
          // your code
          if (response.data.status == "success") {
            console.log(
              "eaQuestionAns.data : " + JSON.stringify(response.data, null, 2)
            );

            console.clear();
            this.eaQuestionAns = response.data.conferenceEvaluations;
            // alert("xccc");
            // alert( this.eaQuestionAns[0].question);
            this.selected = this.eaQuestionAns[0];

            // store.commit("setSteams", response.data.steams);
            // this.steams = response.data.steams;
            // "response.data.steams : " + JSON.stringify(this.steams, null, 2)
          }
          // this.articleId = response.data.token;
        }, console.log)
        .catch(console.log);

      // return {test : "test" };
    },

    // list question rating VoteModel
    // API_EA_11 Get conference poll list
    // https://service.promptevent.com/api/v1/virtual/conference-poll-list

    // API_EA_10 Get conference poll list (Admin)
    // https://service.promptevent.com/api/v1/virtual/conference-poll-list-admin

    async getListEaQuestionRating() {
      const url =
        store.getters.url + "/api/v1/virtual/conference-poll-list-admin";
      const token = store.getters.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      let bodyParameters = {
        // "username": user.username,
        // "password": user.password,
      };

      console.log("url : " + JSON.stringify(url, null, 2));
      console.log(
        "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      );
      console.log("config : " + JSON.stringify(config, null, 2));

      await axios
        .get(url, config)
        .then((response) => {
          // your code
          if (response.data.status == "success") {
            console.log(
              "getListEaQuestionRating.data : " +
                JSON.stringify(response.data, null, 2)
            );

            this.eaQuestionRating = response.data.conferencePolls;

            // console.log(
            //   "eaQuestionRating.data : " +
            //     JSON.stringify(this.eaQuestionRating, null, 2)
            // );

            // let eaQuestionRatingChoice;

            // error for
            for (let i = 0; i < this.eaQuestionRating.length; i++) {
              // this.getTestFn();

              console.log(
                "this.getChoiceRating(item.pollId): " +
                  this.eaQuestionRating[i].pollId
              );

              console.log(
                "this.item(item.pollId): " +
                  JSON.stringify(
                    this.getChoiceRating(this.eaQuestionRating[i].pollId),
                    null,
                    2
                  )
              );

              //  this.eaQuestionRatingChoice[
              //   this.eaQuestionRating[i].pollId
              // ] =  this.getChoiceRating(this.eaQuestionRating[i].pollId);

              //  this.eaQuestionRatingChoice.push(
              //   this.eaQuestionRating[i].pollId,
              //   this.getChoiceRating(this.eaQuestionRating[i])
              // );
            }
            console.log(
              "eaQuestionRatingChoice : " +
                JSON.stringify(this.eaQuestionRatingChoice, null, 2)
            );
            // store.commit("setSteams", response.data.steams);
            // this.steams = response.data.steams;
            // "response.data.steams : " + JSON.stringify(this.steams, null, 2)
          }
          // this.articleId = response.data.token;
        }, console.log)
        .catch(console.log);

      // return {test : "test" };
    },

    /// get choice rating
    // API_EA_13 Get conference poll details {pollId}
    // https://service.promptevent.com/api/v1/virtual/conference-poll-details/1
    // Future getChoiceRating(int voteId) async {
    async getChoiceRating(voteId) {
      // console.log("getChoiceRating voteId : " + voteId);
      const url =
        store.getters.url + "/api/v1/virtual/conference-poll-details/" + voteId;
      const token = store.getters.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      // let bodyParameters = {
      //   // "username": user.username,
      //   // "password": user.password,
      // };

      // console.log("url : " + JSON.stringify(url, null, 2));
      // console.log(
      //   "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      // );
      // console.log("config : " + JSON.stringify(config, null, 2));

      await axios
        .get(url, config)
        .then((response) => {
          // your code
          if (response.data.status == "success") {
            console.log(
              "getChoiceRating.data : " + JSON.stringify(response.data, null, 2)
            );

            this.eaQuestionRatingChoice.push(response.data);

            // check already voted
            let objectVoted = response.data.pollChoices.filter(function (item) {
              // console.log("item.voted :"+item.voted );
              return item.voted > 0;
            });
            console.log(
              " already voted " +
                objectVoted.length +
                " : " +
                JSON.stringify(objectVoted, null, 2)
            );
            if (objectVoted.length > 0) {
              console.log("objectVoted : already voted");
              this.votedFlag = true;
            }
            // if()
            let arraytotalValue = [];
            let arraytotalValueDesc = [];
            for (let i = 0; i < response.data.pollChoices.length; i++) {
              let voted = response.data.pollChoices[i].voted;
              if (voted > 0) {
                this.isVoted = true;
              }
              // getPollSeriesData
              // set data to chart
              // this.seriesArray.push(dataReturn[i].totalVoted);
              arraytotalValue.push(response.data.pollChoices[i].totalVoted);
              arraytotalValueDesc.push(
                response.data.pollChoices[i].description
              );
              // this.printOject(dataReturn[i]);
            }
            /// test set data
            let temp = [
              {
                name: "Voted",
                data: arraytotalValue,
              },
            ];
            this.seriesArray[voteId] = temp;

            let tempDesc = {
              chart: {
                height: 350,
                type: "bar",
                events: {
                  // click: function (chart, w, e) {
                  //   // console.log(chart, w, e)
                  // },
                },
              },
              // colors: colors,
              plotOptions: {
                bar: {
                  columnWidth: "45%",
                  distributed: true,
                },
              },
              dataLabels: {
                enabled: false,
              },
              legend: {
                show: false,
              },
              xaxis: {
                categories: arraytotalValueDesc,
                labels: {
                  style: {
                    // colors: colors,
                    fontSize: "12px",
                  },
                },
              },
            };
            // chartOptions.xaxis.categories

            this.seriesArrayDesc[voteId] = tempDesc;

            return response.data;
            // store.commit("setSteams", response.data.steams);
            // this.steams = response.data.steams;
            // "response.data.steams : " + JSON.stringify(this.steams, null, 2)
          }
          // this.articleId = response.data.token;
        }, console.log)
        .catch(console.log);

      // return response.data.conferenceEvaluations;
      return 100;
    },

    // get DDL

    // https://dev.catsmodish.com/api/v1/virtual/conference-stream-list
    // API_CF_06 Get conference stream list
    getlistStream() {
      const url = store.getters.url + "/api/v1/virtual/conference-stream-list";
      const token = store.getters.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      let bodyParameters = {
        // "username": user.username,
        // "password": user.password,
      };

      console.log("url : " + JSON.stringify(url, null, 2));
      console.log(
        "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      );
      console.log("config : " + JSON.stringify(config, null, 2));

      axios
        .get(url, config)
        .then((response) => {
          // your code
          if (response.data.status == "success") {
            console.log(
              " getlistStream response.data : " +
                JSON.stringify(response.data, null, 2)
            );
            store.commit("setSteams", response.data.steams);
            this.steams = response.data.steams;

            for (let i = 0; i < this.steams.length; i++) {
              console.log(
                " getlistStream loop : " +
                  JSON.stringify(this.steams[i], null, 2)
              );
              let streamObject = this.steams[i];

              if (this.selected == "") {
                this.selected = streamObject.name;
                this.channel = streamObject.streamName;
                this.getQuestionPoll(this.channel);
              }
            }

            // for (let streamObject in this.steams) {
            //   console.log(
            //     " getlistStream loop : " +
            //       JSON.stringify(streamObject[0], null, 2)
            //   );
            //   if (this.selected == "") {
            //     this.selected = streamObject.name;
            //     this.channel = streamObject.streamName;
            //   }
            // }
            // "response.data.steams : " + JSON.stringify(this.steams, null, 2)
          }
          // this.articleId = response.data.token;
        }, console.log)
        .catch(console.log);

      // return {test : "test" };
    },

    async getQuestionPoll(channelCode) {
      const url =
        store.getters.url + "/api/v1/virtual/stream-poll-list/" + channelCode;
      const token = store.getters.token;

      let dataReturn = [];

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      // let bodyParameters = {
      //   // "username": user.username,
      //   // "password": user.password,
      // };

      console.log("url : " + JSON.stringify(url, null, 2));
      // console.log(
      //   "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      // );
      // console.log("config : " + JSON.stringify(config, null, 2));

      await axios
        .get(url, config)
        .then((response) => {
          // your code

          if (response.data.status == "success") {
            console.log(
              "getQuestionPoll response.data : " +
                JSON.stringify(response.data, null, 2)
            );

            dataReturn = response.data.steamPolls;
            console.log("dataReturn.length : " + dataReturn.length);

            // clear
            this.isVoted = false;

            for (let i = 0; i < dataReturn.length; i++) {
              // if (dataReturn[i].pollId !=null) {
              // this.printOject(dataReturn[i].pollId);
              let questionId = dataReturn[i].pollId;
              this.getChoicePoll(questionId);
              //this.choicePoll
              // }
            }
            // set zero
            if (dataReturn.length == 0) {
              this.seriesArray = [];
              this.seriesArrayDesc = [];
              this.choicePoll = [];
            }
            // this.getPollSeriesData();

            // this.printOject(this.choicePoll);
            //this.choicePoll
          }
          // this.articleId = response.data.token;
        }, console.log)
        .catch(console.log);

      // return response.data.conferenceEvaluations;
      return dataReturn;
    },

    // get choice vote
    // API_PL_06 Get stream poll details
    // https://dev.catsmodish.com/api/v1/virtual/stream-poll-details/2

    async getChoicePoll(pollId) {
      const url =
        store.getters.url + "/api/v1/virtual/stream-poll-details/" + pollId;
      const token = store.getters.token;

      let dataReturn = [];

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      // let bodyParameters = {
      //   // "username": user.username,
      //   // "password": user.password,
      // };

      // console.log("url : " + JSON.stringify(url, null, 2));
      // console.log(
      //   "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      // );
      // console.log("config : " + JSON.stringify(config, null, 2));

      await axios
        .get(url, config)
        .then((response) => {
          // your code

          if (response.data.status == "success") {
            console.log(
              "=== getChoicePoll response.data : " +
                JSON.stringify(response.data.pollChoices, null, 2)
            );

            dataReturn = response.data.pollChoices;
            if (response.data != null) {
              // this.eaQuestionRatingChoice.push(response.data);
              this.choicePoll.push(response.data);
              // this.choicePoll[pollId] = response.data;

              // getPollSeriesData
              // set data to chart
              // for (let item in response.data.pollChoices) {
              //   console.log("=== item  === : " + JSON.stringify(item, null, 2));
              //   this.seriesArray.push(item.totalVoted);
              // }
              // console.log(
              //   "=== this.seriesArray === : " +
              //     JSON.stringify(this.seriesArray, null, 2)
              // );
            }
            // this.choicePoll = response.data.pollChoices;
            let arraytotalValue = [];
            let arraytotalValueDesc = [];
            for (let i = 0; i < dataReturn.length; i++) {
              let voted = dataReturn[i].voted;
              if (voted > 0) {
                this.isVoted = true;
              }
              // getPollSeriesData
              // set data to chart
              // this.seriesArray.push(dataReturn[i].totalVoted);
              arraytotalValue.push(dataReturn[i].totalVoted);
              arraytotalValueDesc.push(dataReturn[i].description);
              // this.printOject(dataReturn[i]);
            }

            console.log("=== arraytotalValue : " + arraytotalValue);
            // this.printOject(arraytotalValue);
            let temp = [
              {
                name: "Voted",
                data: arraytotalValue,
              },
            ];
            this.seriesArray[pollId] = temp;

            let tempDesc = {
              chart: {
                height: 350,
                type: "bar",
                events: {
                  // click: function (chart, w, e) {
                  //   // console.log(chart, w, e)
                  // },
                },
              },
              // colors: colors,
              plotOptions: {
                bar: {
                  columnWidth: "45%",
                  distributed: true,
                },
              },
              dataLabels: {
                enabled: false,
              },
              legend: {
                show: false,
              },
              xaxis: {
                categories: arraytotalValueDesc,
                labels: {
                  style: {
                    // colors: colors,
                    fontSize: "12px",
                  },
                },
              },
            };
            // chartOptions.xaxis.categories

            this.seriesArrayDesc[pollId] = tempDesc;

            console.log(
              "=== this.seriesArray === : " +
                JSON.stringify(this.seriesArray, null, 2)
            );
            console.log(
              "=== this.seriesArray data === : " +
                JSON.stringify(this.seriesArray.data, null, 2)
            );
            console.log("=== choicePoll : ");
            console.log(
              "=== choicePoll : " + JSON.stringify(this.choicePoll, null, 2)
            );
          }
          // this.articleId = response.data.token;
        }, console.log)
        .catch(console.log);

      // return response.data.conferenceEvaluations;
      return dataReturn;
    },
  },
};
</script>
<style scoped>
.error {
  color: rgb(255, 0, 0);
}

table.text-center * {
  text-align: center;
}

.qaQuestion {
  text-align: center;
  margin: 50px;
}

.pagination {
}
.page-item {
}
</style>
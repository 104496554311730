<template>
  <div class="text-center m-3" v-if="this.$store.getters.flag_Supper_Admin">
    <!-- <div class=" text-center m-3" v-if="false"> -->
    <!-- {{ isAdmin }} -->

    <b-navbar centered>
      <template #start centered>
        <b-navbar-item @click="goHomePage()"> Setting </b-navbar-item>
        <b-navbar-dropdown label="Channel Management">
          <b-navbar-item @click="goCreateChannel()"> Create Channel </b-navbar-item>
          <b-navbar-item @click="goUpdateConference()"> Update Channel </b-navbar-item>


        </b-navbar-dropdown>

        
        <b-navbar-dropdown label="QR CODE">
            
          <b-navbar-item @click.native="goCreateRCODEPage()">
            Create QR CODE
          </b-navbar-item>

            <b-navbar-item @click.native="goAdminUserQRCODEPage()">
              USER QR CODE 
            </b-navbar-item>
          </b-navbar-dropdown>

        <b-navbar-item @click="goUpdateConference()"> Update Conference </b-navbar-item>

        <b-navbar-item @click="goAdminManageQuestionPage()">
          Management question
        </b-navbar-item>

        <b-navbar-item @click="goSummayPoll()"> Service Management </b-navbar-item>

        <b-navbar-dropdown label="Info">
          <b-navbar-item href="#"> About </b-navbar-item>
          <b-navbar-item href="#"> Contact </b-navbar-item>
        </b-navbar-dropdown>

        <!-- <b-navbar-item href="#" @click="goSummayEA()">
          Summay Evaluation
        </b-navbar-item> -->
        <!-- <b-navbar-item href="#" @click="goSetting()">
          Setting
        </b-navbar-item> -->

        <!-- <b-navbar-item href="#"> Documentation </b-navbar-item>
        <b-navbar-dropdown label="Info">
          <b-navbar-item href="#"> About </b-navbar-item>
          <b-navbar-item loading href="#"> Contact </b-navbar-item>
        </b-navbar-dropdown> -->
      </template>

      <template #end> </template>
    </b-navbar>
  </div>
  <!-- </div> -->
</template>

<script>
// import { store } from "@/store/store";

export default {
  name: "adminNav",
  components: {},
  data() {
    return {};
  },
  async created() {},
  computed: {
    isAdmin() {
      let roles = this.$store.getters.roles;
      let adminFlag = false;
      for (let role in roles) {
        console.log("role : " + roles[role]);
        if (roles[role] == "ADMIN") {
          adminFlag = true;
        }
      }

      return adminFlag;
    },
  },

  methods: {
    goAdminManageQuestionPage() {
      this.$router.push({ path: "/AdminManageQuestionPage" });
    },

    goCreateChannel() {
      // alert("goRegisterPage");
      this.$router.push({ path: "/CreateChannelPage" });
    },

    goUpdateConference() {
      // alert("goRegisterPage");
      this.$router.push({ path: "/UpdateCFPage" });
    },

    goUpdateChannel() {
      // alert("goRegisterPage");
      this.$router.push({ path: "/UpdateChannelPage" });
    },

    goCreateRCODEPage() {
      this.$router.push({ path: "/CreateQRCODEPage" });
    },
    
    
    goAdminUserQRCODEPage() {
      this.$router.push({ path: "/AdminUserQRCODEPage" });
    },

    goHomePage() {
      // alert("goRegisterPage");
      this.$router.push({ path: "/HomePage" });
    },
    goLoginPage() {
      // alert("goRegisterPage");
      this.$router.push({ path: "/" });
    },

    goSummayQuestion() {
      this.$router.push({ path: "/SummaryQuestionPage" });
    },

    goSummayPoll() {
      this.$router.push({ path: "/SummaryPollPage" });
    },

    goSummayEA() {
      this.$router.push({ path: "/SummaryEvaluationPage" });
    },

    mounted() {
      console.log("mounted");
    },
  },
};
</script>
<style scoped></style>

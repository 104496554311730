
<template>
  <div class="card text-center m-3">
    <header-component></header-component>

    <admin-setting-nav-componentponent></admin-setting-nav-componentponent>

    <!-- {{ "questionId :  " + this.$store.getters.questionId }}
    {{ "channel :  " + this.$store.getters.channel }}
    {{ "steamAnswers :  " + this.$store.getters.steamAnswers }}
    {{
      "steamAnswersExculdeCancel :  " +
      this.$store.getters.steamAnswersExculdeCancel
    }} -->

    <div>streamInput.streamUrl : {{ streamInput }}</div>
    <div>streamInput.streamUrl : {{ streamInput.streamName }}</div>
    <!-- <div> streamInput.xx : {{ streamInput.xxx }} </div> -->

    <div>keyStream : {{ keyStream }}</div>
    <!-- <div>keyStream streamName : {{ keyStream.status }}</div> -->

    <!-- <div>
      {{ "a : " + a }}  {{ a }}
    </div> -->

    <!-- <div>
sASAasa
ะำะหะำหะ{{steamAnswers}}

</div> -->
    <!-- <form ref="form" class="formQA " @submit.prevent="onSubmit">
      <div v-for="item in eaQuestionAns" :key="item.evaluationId">

    <div class="card qa">
  <div class="card-content">
    <div class="content">
      <p class="qaQuestion">Q1.Lorem ipsum leo risus, porta ac consectetur ac, vestibulum at eros. Donec id </p>

        <p>
        <b-field label="ANS" :label-position="labelPosition">
          <b-input

            type="textarea"
          
          >
          </b-input>
        </b-field>
      </p>
    </div>
  </div>
</div>
</div>

    </form> -->

    <div class="card-body"><strong> Management Channel </strong></div>

    <!-- v-on:change="changeEnableUser(props.row.username,props.row.enabled)"  -->
    <!-- @update="changeEnableUser(props.row.username,props.row.enabled)" -->

    <section>
      <b-field group-multiline grouped horizontal>
        <b-field label=" Please Channel : "> </b-field>
        <b-dropdown aria-role="list">
          <template #trigger="{ active }">
            <b-button
              :label="selected"
              type="is-primary"
              :icon-right="active ? 'menu-up' : 'menu-down'"
            />
          </template>
          <div v-for="item in steams" :key="item.streamName">
            <b-dropdown-item aria-role="listitem" @click="onSelected(item)">{{
              item.name
            }}</b-dropdown-item>
          </div>
        </b-dropdown>
      </b-field>
    </section>

    <div>
      <!-- "name": "Test conference stream name",
      "description": "Test conference stream description",
      "beginDate": "2019-03-29 09:30:00",
      "endDate": "2021-01-09 12:30:00",
      "streamName": "S1613754744",
      "enabled": true,
      "m3u8"
      posterUrl -->

      <div class="container has-text-left is-primary" style="padding: 25px">
        <b-field>
          <b-input
            v-model="streamInput.name"
            placeholder="Name"
            type="text"
            icon="account"
            required
            rounded
          >
          </b-input>
        </b-field>

        <b-field>
          <b-input
            v-model="streamInput.description"
            placeholder="description"
            type="text"
            icon="account"
            required
            rounded
          >
          </b-input>
        </b-field>

        <b-field>
          <b-input
            v-model="streamInput.beginDate"
            placeholder="beginDate"
            type="text"
            icon="ballot"
            required
            rounded
          >
          </b-input>
        </b-field>

        <b-field>
          <b-input
            placeholder="endDate"
            v-model="streamInput.endDate"
            type="text"
            icon="email"
            required
            rounded
          >
          </b-input>
        </b-field>

        <b-field>
          <b-input
            v-model="streamInput.streamName"
            placeholder="streamName"
            type="text"
            icon="phone"
            required
            rounded
          >
          </b-input>
        </b-field>

        <b-field>
          status :
          <b-switch v-model="streamInput.enabled">
            {{ streamInput.enabled }}
          </b-switch>
        </b-field>

        <b-field>
          <b-input
            v-model="streamInput.posterUrl"
            placeholder="posterUrl"
            type="text"
            icon="phone"
            required
            rounded
          >
          </b-input>
        </b-field>

        <b-field>
          <img :src="streamInput.posterUrl" />
        </b-field>

        <b-field>
          <b-input
            v-model="streamInput.streamUrl"
            placeholder="streamUrl"
            type="text"
            icon="link"
            rounded
          >
          </b-input>
        </b-field>

        <!-- <b-field> imageSrc : {{ streamInput }} </b-field> -->

        <!-- <b-field> imageName : {{ imageName }} </b-field> -->
        <!-- <b-field>  imageBase64 : {{ imageBase64 }} </b-field> -->
        <!-- <b-field> imageName : {{ imageName }} </b-field> -->
        <b-field>
          <p>
            <input
              @change="uploadImage"
              type="file"
              name="photo"
              accept="image/*"
            />
          </p>
        </b-field>

        <b-field v-if="imageBase64 != ''">
          <img :src="imageBase64" class="image" />
        </b-field>

        <b-button
          @click="onSave"
          class="button is-primary"
          :loading="btLoading"
          :disabled="btLoading"
          expanded
          outlined
          rounded
          >Save</b-button
        >

        <span class="has-text-danger is-italic"> {{ errorMsg }}</span>
      </div>
      <b-switch @input="changeStatusStream(streamInput.streamName, 'start')">
        enabled : {{ statusStream }}
      </b-switch>
    </div>

    <footer-component></footer-component>
  </div>
</template>

<script>
// import UserService from "@/service/UserService";
// import VueTailwindPagination from "@ocrv/vue-tailwind-pagination";

import axios from "axios";
import { store } from "@/store/store";
import FooterComponent from "../footer.vue";
import HeaderComponent from "../header.vue";
import adminSettingNavComponentponent from "../adminSettingNav.vue";

import conferenceService from "@/service/ConferenceService";
// import UserService from "@/service/UserService";

// import QAService from "@/service/QAService";

export default {
  name: "post-request",
  components: {
    FooterComponent,
    HeaderComponent,
    adminSettingNavComponentponent,
    // VueTailwindPagination,
  },

  async created() {
    console.log("created");

    this.a = { a: "a", b: "b" };

    this.a.c = "c";

    // get listStream
    this.getlistStream();

    let token = store.getters.token;

    if (!token) {
      this.$router.push({ path: "/" });
    }

    // console.log("getUser");
    // this.getUser("@", 1);
  },

  data() {
    return {
      a: null,
      // start form
      streamInput: {
        name: "",
        description: "",
        beginDate: "",
        endDate: "",
        streamName: "",
        streamUrl: "",
        streamPosterFileName: "",
        streamPosterBase64: "",
        enabled: false,

        posterUrl: "",
      },

      name: "",
      description: "",
      beginDate: "",
      endDate: "",
      streamName: "",
      enabled: false,
      posterUrl: "",
      errorMsg: "",
      // end form

      imageSrc: "",
      imageBase64: "",
      // upload image

      errors: [],

      userList: [],
      // currentPage: 1,
      btLoading: false,

      ///
      totalPage: 0,
      page: 1,

      //
      steamAnswers: [],

      steams: [],
      selected: "",
      channel: "",
      questionIdDefault: 0,

      dataReply: [],

      checkedRows: [],

      // keyStream: [],
      keyStream: { streamName: "", streamPassword: "", status: "" },
      statusStream: "",
    };
  },
  mounted() {},

  computed: {},

  watch: {},
  filters: {},

  methods: {
    uploadImage: function () {
      // alert(document.querySelector("input[type=file]").files);
      console.log(document.querySelector("input[type=file]").files);
      console.log(document.querySelector("input[type=file]").files[0].name); // final name

      this.streamInput.streamPosterFileName =
        document.querySelector("input[type=file]").files[0].name;

      var file = document.querySelector("input[type=file]").files[0];
      var reader = new FileReader();
      // reader.onload = function (e) {
      reader.onload = (e) => {
        // this.onSave();
        // alert(this.$store.getters.user);
        this.streamInput.streamPosterBase64 = e.target.result;
        this.imageBase64 = e.target.result; // name file
        this.imageSrc = e.target.imageSrc;

        // this.imageName = "testdddd";

        // alert(JSON.stringify(e.target.result));
        // alert(JSON.stringify(e.target.imageSrc));
        // console.log((JSON.stringify(e.target)));
      };
      reader.onerror = function (error) {
        alert(error);
      };
      reader.readAsDataURL(file);

      // alert(JSON.stringify(file));

      // alert(this.getBase64(file));
      // this.imageName = this.getBase64(file);
    },
    async changeStatusStream(key, status) {
      // console.log("keyStream : " + keyStream + " to status : " + status);
      // var key = keyStream[0];
      console.log("changeStatusStream : " + key + " to status : " + status);
      let statusMessge = conferenceService.updateStatusChannel(key, status);

      statusMessge.then((status) => {
        // alert(status);
        // DebugService.printConsole("status login ", status);
        // let statusLogin = false;
        if (!status) {
          // set state unload

          // this.danger(this.$store.getters["userLogin/messageErrorLogin"]);
          // this.btLoading = false;
          // this.$router.push({ path: "/" });
          this.statusStream = "on process";
        } else {
          // this.statusStream = statusMessge;

          // this.statusStream = "on process";
          this.statusStream = statusMessge;
          // alert("statusMessge : "+ statusMessge);
          // this.$router.push({ path: "/HomePage" });
        }
      });

      // update user status
    },

    onSave() {
      alert("on save");
      let index = this.streamInput.streamPosterBase64.indexOf("base64,");

      // alert(this.streamInput.streamPosterBase64);
      // // alert(this.streamInput.streamPosterBase64.length);

      // alert(
      //   this.streamInput.streamPosterBase64.substr(
      //     index + 7,
      //     this.streamInput.streamPosterBase64.length
      //   )
      // );

      this.streamInput.streamPosterBase64 =
        this.streamInput.streamPosterBase64.substr(
          index + 7,
          this.streamInput.streamPosterBase64.length
        );

      this.streamInput.beginDate = this.formatDateInsert(
        this.streamInput.beginDate
      );
      this.streamInput.endDate = this.formatDateInsert(
        this.streamInput.endDate
      );

      // 2019-03-2909:3009:30:00:2019-03-29 09:30:00
      // alert(this.formatDateInsert(this.streamInput.beginDate) + " ====  " +this.streamInput.beginDate);

      // alert(this.streamInput.streamPosterBase64.indexOf("base64,"));

      let debug = true;
      if (debug) {
        conferenceService.updateChannel(this.streamInput);
      }
    },

    formatDateInsert(dataDate) {
      // $luxon.fromSQL();
      return this.$luxon(dataDate, {
        input: { format: "yyyy-MM-dd HH:mm:ss", zone: "asia/bangkok" },
        output: { format: "yyyy-MM-dd'T'HH:mm:ss" },
      });
    },

    onSelected(streamObject) {
      // alert(JSON.stringify(streamObject, null, 2));
      this.selected = streamObject.name;
      this.channel = streamObject.streamName;

      store.commit("SET_CHANNEL", this.channel);

      if (streamObject.enabled) {
        this.getKeyStream(this.channel);
      } else {
        this.keyStream = "";
      }
      // streamObject.name;

      this.streamInput.name = streamObject.name;
      this.streamInput.description = streamObject.description;
      this.streamInput.beginDate = streamObject.beginDate;
      this.streamInput.endDate = streamObject.endDate;
      this.streamInput.streamName = streamObject.streamName;
      this.streamInput.enabled = streamObject.enabled;
      this.streamInput.posterUrl = streamObject.posterUrl;

      var streamUrl_list = streamObject.streamUrl.split("?");
      this.streamInput.streamUrl = streamUrl_list[0];

      // this.streamInput.streamUrl = streamObject.streamUrl;

      //   this.name = streamObject.name;
      // this.description = streamObject.description;
      // this.beginDate = streamObject.beginDate;
      // this.endDate = streamObject.endDate;
      // this.streamName = streamObject.streamName;
      // this.enabled = streamObject.enabled;
      // this.posterUrl = streamObject.posterUrl;

      // this.getQuestionPoll(this.channel);
      // this.getquestionIdDefault(this.channel, 1);

      // clearInterval(interval);
      // let interval = setInterval(() => {
      //   this.getquestionIdDefault(this.channel, 1);

      //   if (!this.$store.getters.isLogin) {
      //     // alert(" seesion expire please login again");
      //     clearInterval(interval);
      //     this.$router.push({ path: "/" });
      //   }
      // }, 5000);
    },

    clickCallback: function (pageNum) {
      console.log("PAGE : " + pageNum);
      // alert(pageNum);

      // this.getUser("@", pageNum);
      // this.getUser("@", pageNum);

      // this.getQuestionStream(pageNum);
      // this.page = pageNum;
      this.getquestionIdDefault(this.channel, pageNum);
    },

    success(msg) {
      this.$buefy.notification.open({
        message: msg,
        type: "is-success",
        duration: 5000,
      });
    },
    printObject(obj) {
      console.log("printObject : " + JSON.stringify(obj, null, 2));
    },

    pageChangeHandler(selectedPage) {
      this.currentPage = selectedPage;
    },

    onSubmit() {
      this.$emit("submit", this.model);
    },
    doSomethings(val) {
      console.clear();
      console.log(val);
      alert(val);
    },

    goLoginPage() {
      // alert("goRegisterPage");
      this.$router.push({ path: "/" });
    },

    // 1.get strem channel
    // get DDL

    // get key stream
    // API_CF_11 Setup live streaming (Admin)
    // https://service.promptevent.com/api/v1/virtual/conference-stream-setup

    getKeyStream(channel) {
      const url = store.getters.url + "/api/v1/virtual/conference-stream-setup";
      const token = store.getters.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      let bodyParameters = {
        streamName: channel,
        // "password": user.password,
      };

      console.log("url : " + JSON.stringify(url, null, 2));
      console.log(
        "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      );
      console.log("config : " + JSON.stringify(config, null, 2));

      axios
        .post(url, bodyParameters, config)
        .then((response) => {
          // your code
          console.log(
            " response getKeyStream : " + JSON.stringify(response, null, 2)
          );
          this.keyStream = JSON.stringify(response.data, null, 2);
        }, console.log)
        .catch(console.log);

      // return {test : "test" };
    },

    // API_CF_10 Get conference stream list (Admin)
    // https://service.promptevent.com/api/v1/virtual/conference-stream-list-admin

    getlistStream() {
      const url =
        store.getters.url + "/api/v1/virtual/conference-stream-list-admin";
      const token = store.getters.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      let bodyParameters = {
        // "username": user.username,
        // "password": user.password,
      };

      console.log("url : " + JSON.stringify(url, null, 2));
      console.log(
        "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      );
      console.log("config : " + JSON.stringify(config, null, 2));

      axios
        .get(url, config)
        .then((response) => {
          // your code
          if (response.data.status == "success") {
            console.log(
              " getlistStream response.data : " +
                JSON.stringify(response.data, null, 2)
            );
            // store.commit("setSteams", response.data.steams);
            this.steams = response.data.steams;

            for (let i = 0; i < this.steams.length; i++) {
              console.log(
                " getlistStream loop : " +
                  JSON.stringify(this.steams[i], null, 2)
              );
              let streamObject = this.steams[i];

              if (this.selected == "") {
                // intital

                this.selected = streamObject.name;
                this.channel = streamObject.streamName;
                // this.getquestionIdDefault(this.channel, this.page);
                // this.getQuestionPoll(this.channel);
              }
            }

            // for (let streamObject in this.steams) {
            //   console.log(
            //     " getlistStream loop : " +
            //       JSON.stringify(streamObject[0], null, 2)
            //   );
            //   if (this.selected == "") {
            //     this.selected = streamObject.name;
            //     this.channel = streamObject.streamName;
            //   }
            // }
            // "response.data.steams : " + JSON.stringify(this.steams, null, 2)
          }
          // this.articleId = response.data.token;
        }, console.log)
        .catch(console.log);

      // return {test : "test" };
    },

    // update status enable user
    // API-USER05 Update user details (Admin)
    // https://service.promptevent.com/api/v1/virtual/user-update-admin

    updateStatusUser(username, status) {
      const url = store.getters.url + "/api/v1/virtual/user-update-admin";

      // console.log("url : " + JSON.stringify(url, null, 2));
      const token = store.getters.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      let bodyParameters = {
        username: username, // email
        enabled: status, // bool
      };

      console.log("url : " + JSON.stringify(url, null, 2));
      console.log(
        "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      );
      console.log("config : " + JSON.stringify(config, null, 2));

      axios
        .post(url, bodyParameters, config)
        .then(
          //   (response) => {
          //   // your code
          //   // this.articleId = response.data.token;
          //   console.log(
          //     "userList : " + JSON.stringify(response.data.users, null, 2)
          //   );
          //   this.userList = response.data.users;
          //   this.totalPage = response.data.totalPage;
          // },
          console.log
        )
        .catch(console.log);
    },

    // update Certified enable user
    // API-USER05 Update user details (Admin)
    // https://service.promptevent.com/api/v1/virtual/user-update-admin

    updateCertifiedUser(username, status) {
      const url = store.getters.url + "/api/v1/virtual/user-update-admin";

      // console.log("url : " + JSON.stringify(url, null, 2));
      const token = store.getters.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      let bodyParameters = {
        username: username, // email
        certified: status, // bool
      };

      console.log("url : " + JSON.stringify(url, null, 2));
      console.log(
        "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      );
      console.log("config : " + JSON.stringify(config, null, 2));

      axios
        .post(url, bodyParameters, config)
        .then(
          //   (response) => {
          //   // your code
          //   // this.articleId = response.data.token;
          //   console.log(
          //     "userList : " + JSON.stringify(response.data.users, null, 2)
          //   );
          //   this.userList = response.data.users;
          //   this.totalPage = response.data.totalPage;
          // },
          console.log
        )
        .catch(console.log);
    },
  },
};
</script>
<style scoped>
.error {
  color: rgb(255, 0, 0);
}

table.text-center * {
  text-align: center;
}

.pagination {
}
.page-item {
}
</style>
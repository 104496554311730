
<template>
  <div class="card text-center m-3">
    <header-component></header-component>

    <admin-nav-component></admin-nav-component>

    <div class="card-body"><strong> Summary Poll</strong></div>

    <!-- Channel : {{ channel }} DDL : {{ steams }} -->

    <section>
      <b-field group-multiline grouped horizontal>
        <b-field label=" Please Channel : "> </b-field>
        <b-dropdown aria-role="list">
          <template #trigger="{ active }">
            <b-button
              :label="selected"
              type="is-primary"
              :icon-right="active ? 'menu-up' : 'menu-down'"
            />
          </template>
          <div v-for="item in steams" :key="item.streamName">
            <b-dropdown-item aria-role="listitem" @click="onSelected(item)">{{
              item.name
            }}</b-dropdown-item>
          </div>
        </b-dropdown>
      </b-field>
    </section>

    <b-table has-text-centered :data="arrayPollStatus">
      <b-table-column
        has-text-centered
        field="question"
        label="question"
        v-slot="props"
        centered
      >
        {{ props.row.question }}
      </b-table-column>

      <b-table-column field="enabled" label="enable" v-slot="props" centered>
        <!-- {{ props.row.enabled }} -->
        <b-switch
          v-model="props.row.enabled"
          @input="changeEnablePoll(props.row, props.row.enabled)"
        >
          {{ props.row.enabled }}</b-switch
        >
      </b-table-column>
      <b-table-column field="opened" label="opened" v-slot="props" centered>
        <!-- {{ props.row.certified }} -->
        <div class="control">
          <b-switch
            v-model="props.row.opened"
            @input="changeEnableOpened(props.row, props.row.opened)"
            >{{ props.row.opened }}</b-switch
          >
        </div>
      </b-table-column>
    </b-table>

    <!-- Channel : {{ channel }} -->

    <!-- data {{ series[0].data }} choice {{ chartOptions.xaxis.categories }}
    <div id="chart">
      <apex-charts
        type="bar"
        height="350"
        :options="chartOptions"
        :series="series"
      ></apex-charts>
    </div>

    sadasd choicePoll
    {{ choicePoll }}
    choicePoll.length = {{ choicePoll.length }} -->
    <!-- {{ sum_totalVoted }} -->

    <div v-for="item in choicePoll" :key="item.pollId" class="qaQuestion">
      <tr>
        <td class="">
          <p class="qaQuestion">Question : {{ item.question }}</p>
        </td>
        <!-- <div v-for="item in item.pollChoices" :key="item.choiceId">
          <p class="qaQuestion">
            Poll choice : .{{ item.description + " :  totalVoted " + item.totalVoted }}
          </p>
        </div> -->
      </tr>
      <div id="chart">
        <apex-charts
          ref="realtimeChart"
          type="bar"
          height="350"
          :options="seriesArrayDesc[item.pollId]"
          :series="seriesArray[item.pollId]"
        ></apex-charts>
      </div>
    </div>

    <!-- v-on:change="changeEnableUser(props.row.username,props.row.enabled)"  -->
    <!-- @update="changeEnableUser(props.row.username,props.row.enabled)" -->

    <div class="overflow-auto">
      <!-- <div>
        <h6>Small Pills</h6>
        <b-pagination
          v-model="currentPage"
          pills
          :total-rows="rows"
          size="sm"
        ></b-pagination>
      </div> -->

      <!-- <div class="mt-3">
        <h6>Default Pills</h6>
        <b-pagination
          v-model="currentPage"
          pills
          :total-rows="rows"
        ></b-pagination>
      </div> -->

      <!-- <div class="mt-3">
        <h6>Large Pills</h6>
        <b-pagination
          v-model="currentPage"
          pills
          :total-rows="rows"
          size="lg"
        ></b-pagination>
      </div> -->

      <!-- <VueTailwindPagination :current="currentPage" :total="total" :per-page="perPage" @page-changed="current = $event"/> -->
    </div>

    <div class="card-body">
      <paginate
        v-model="page"
        :page-count="totalPage"
        :page-range="3"
        :margin-pages="2"
        :click-handler="clickCallback"
        :prev-text="'Prev'"
        :next-text="'Next'"
        :container-class="'pagination'"
        :page-class="'page-item'"
      >
      </paginate>
    </div>

    <footer-component></footer-component>
  </div>
</template>

<script>
// import UserService from "@/service/UserService";
// import VueTailwindPagination from "@ocrv/vue-tailwind-pagination";

import axios from "axios";
import { store } from "@/store/store";
import FooterComponent from "./footer.vue";
import HeaderComponent from "./header.vue";
import Paginate from "vuejs-paginate";
import adminNavComponent from "./adminNav.vue";
// import ApexCharts from "apexcharts";
import ApexCharts from "vue-apexcharts";
import UserService from "@/service/UserService";

export default {
  name: "post-request",
  components: {
    FooterComponent,
    HeaderComponent,
    Paginate,
    adminNavComponent,
    ApexCharts,
    // VueTailwindPagination,
  },

  async created() {
    console.log.clear;
    console.log("created");
    this.getlistStream();
    // this.getQuestionPoll("S1613754744");

    this.checkSesseions();

    console.log(
      "choicePoll response.data : " + JSON.stringify(this.choicePoll, null, 2)
    );
    // console.log("getUser");
    // this.getUser("@", 1);

    this.timerUpdateChart = setInterval(() => {
      // this.getQuestionPoll(this.channel);

      console.log("realtimeChart x :" + this.$refs.realtimeChart[0]);

      // this.$refs.realtimeChart[0].updateSeries(
      //   [
      //     {
      //       // data: this.series[0].data,
      //       data: [30, 40, 35, 50],
      //     },
      //   ],
      //   false,
      //   true
      // );

      for (let i = 0; i < this.choicePoll.length; i++) {
        // alert(this.choicePoll[i].pollId);
        this.updateChoicePoll(
          this.choicePoll[i],
          this.choicePoll[i].pollId,
          this.$refs.realtimeChart[i]
        );
      }
    }, 10000);
  },

  beforeDestroy() {
    clearInterval(this.timer);
    clearInterval(this.timerUpdateChart);
  },

  data() {
    return {
      errors: [],

      userList: [],
      // currentPage: 1,
      btLoading: false,

      ///
      totalPage: 0,
      page: 1,

      steams: [],
      selected: "",
      arrayPollStatus: [],

      channel: "",
      choicePoll: [],
      seriesArray: [],
      seriesArrayDesc: [],

      interval: null,

      // loop update data
      timer: "",
      timerUpdateChart: "",
      // loop update data

      sum_totalVoted: [],

      // series: [
      //   {
      //     data: [21, 22, 10, 28, 16, 21, 13, 30],
      //   },
      // ],
      // chartOptions: {
      //   chart: {
      //     height: 350,
      //     type: "bar",
      //     events: {
      //       // click: function (chart, w, e) {
      //       //   // console.log(chart, w, e)
      //       // },
      //     },
      //   },
      //   // colors: colors,
      //   plotOptions: {
      //     bar: {
      //       columnWidth: "45%",
      //       distributed: true,
      //     },
      //   },
      //   dataLabels: {
      //     enabled: false,
      //   },
      //   legend: {
      //     show: false,
      //   },
      //   xaxis: {
      //     categories: [
      //       ["John", "Doe"],
      //       ["Joe", "Smith"],
      //       ["Jake", "Williams"],
      //       "Amber",
      //       ["Peter", "Brown"],
      //       ["Mary", "Evans"],
      //       ["David", "Wilson"],
      //       ["Lily", "Roberts"],
      //     ],
      //     labels: {
      //       style: {
      //         // colors: colors,
      //         fontSize: "12px",
      //       },
      //     },
      //   },
      // },
    };
  },
  mounted() {},

  computed: {},

  watch: {},
  filters: {},

  methods: {
    checkSesseions() {
      /// check session ///

      UserService.keepAlive(this.$store.getters.token);

      let token = store.getters.token;

      if (!token) {
        this.$router.push({ path: "/" });
      }

      clearInterval(this.timer);
      this.timer = setInterval(() => {
        UserService.keepAlive(this.$store.getters.token);

        if (!this.$store.getters.isLogin) {
          // alert(" seesion expire please login again");
          clearInterval(this.timer);
          this.$router.push({ path: "/" });
        }
      }, 5000);

      /// check session ///
    },
    onSelected(streamObject) {
      // alert(streamObject.streamName);
      this.selected = streamObject.name;
      this.channel = streamObject.streamName;

      this.getQuestionPoll(this.channel);

      // clearInterval(this.interval);

      //   this.interval = setInterval(() => {
      //   this.getQuestionPoll(this.channel);
      //   // if (!this.$store.getters.isLogin) {
      //   //   // alert(" seesion expire please login again");
      //   //   clearInterval(interval);
      //   //   this.$router.push({ path: "/" });
      //   // }
      // }, 50000);
    },
    clickCallback: function (pageNum) {
      console.log("PAGE : " + pageNum);
      // alert(pageNum);
      this.getUser("@", pageNum);
    },

    success(msg) {
      this.$buefy.notification.open({
        message: msg,
        type: "is-success",
        duration: 5000,
      });
    },
    printObject(obj) {
      console.log("printObject : " + JSON.stringify(obj, null, 2));
    },

    changeEnablePoll(pollOject, status) {
      // alert("changeEnablePoll : " + user + " to status : " + status);
      // console.log("changeEnablePoll : " + user + " to status : " + status);
      this.updateEnableStatusPoll(pollOject, status);

      // update user status
    },

    changeEnableOpened(pollOject, status) {
      // alert("changeEnableOpend : " + user + " to status : " + status);
      // console.log("changeEnableOpend : " + user + " to status : " + status);
      this.updateOpendStatus(pollOject, status);

      // update user status
    },
    pageChangeHandler(selectedPage) {
      this.currentPage = selectedPage;
    },

    onSubmit() {
      this.$emit("submit", this.model);
    },
    doSomethings(val) {
      console.clear();
      console.log(val);
      alert(val);
    },
    changeRating(value) {
      console.log("===  changeRating ===  " + value);
      // console.log("===  dataRating ===  " + dataRating[item.pollId]);

      // map

      // console.log("===  changeRating ===  " + this.dataRating[value]);

      // this.$buefy.toast.open({
      //   message: "Thanks for you Rate!",
      //   type: "is-success",
      // });
    },
    goLoginPage() {
      // alert("goRegisterPage");
      this.$router.push({ path: "/" });
    },
    goStremPage(code) {
      console.log("Conference code : " + code);
      // alert("goRegisterPage");
      this.$router.push({ path: "/StreamPage/" + code, code });
    },

    getTestFn() {
      console.log("===  getTestFn ===  ");
    },

    // update status poll
    // https://service.promptevent.com/api/v1/virtual/conference-poll-update
    // API_EA_09 Update conference poll
    // https://service.promptevent.com/api/v1/virtual/stream-poll-update
    // API_PL_02 Update stream poll

    updateOpendStatus(pollOject, status) {
      const url = store.getters.url + "/api/v1/virtual/stream-poll-update";

      // console.log("url : " + JSON.stringify(url, null, 2));
      const token = store.getters.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      let bodyParameters = {
        streamName: this.channel,
        pollId: pollOject.pollId,
        question: pollOject.question,
        enabled: pollOject.enabled,
        opened: status,
        // enabled: true,
      };

      console.log("url : " + JSON.stringify(url, null, 2));
      console.log(
        "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      );
      console.log("config : " + JSON.stringify(config, null, 2));

      axios
        .post(url, bodyParameters, config)
        .then(console.log)
        .catch(console.log);
    },

    // https://service.promptevent.com/api/v1/virtual/conference-poll-update
    // API_EA_09 Update conference poll

    // update status poll
    // https://service.promptevent.com/api/v1/virtual/stream-poll-update
    // API_PL_02 Update stream poll
    updateEnableStatusPoll(pollOject, status) {
      // object Poll
      // "pollId": 1,
      // "question": "Test create stream poll 5",
      // "opened": false,
      // "enabled": tru

      const url = store.getters.url + "/api/v1/virtual/stream-poll-update";

      // console.log("url : " + JSON.stringify(url, null, 2));
      const token = store.getters.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      let bodyParameters = {
        streamName: this.channel,
        pollId: pollOject.pollId,
        question: pollOject.question,
        opened: pollOject.opened,
        enabled: status,
      };

      console.log("url : " + JSON.stringify(url, null, 2));
      console.log(
        "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      );
      console.log("config : " + JSON.stringify(config, null, 2));

      axios
        .post(url, bodyParameters, config)
        .then(console.log)
        .catch(console.log);
    },

    // get DDL

    // https://dev.catsmodish.com/api/v1/virtual/conference-stream-list
    // API_CF_06 Get conference stream list
    getlistStream() {
      const url = store.getters.url + "/api/v1/virtual/conference-stream-list";
      const token = store.getters.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      let bodyParameters = {
        // "username": user.username,
        // "password": user.password,
      };

      console.log("url : " + JSON.stringify(url, null, 2));
      console.log(
        "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      );
      console.log("config : " + JSON.stringify(config, null, 2));

      axios
        .get(url, config)
        .then((response) => {
          // your code
          if (response.data.status == "success") {
            console.log(
              " getlistStream response.data : " +
                JSON.stringify(response.data, null, 2)
            );
            // store.commit("setSteams", response.data.steams);
            this.steams = response.data.steams;

            for (let i = 0; i < this.steams.length; i++) {
              console.log(
                " getlistStream loop : " +
                  JSON.stringify(this.steams[i], null, 2)
              );
              let streamObject = this.steams[i];

              if (this.selected == "") {
                this.selected = streamObject.name;
                this.channel = streamObject.streamName;
                this.getQuestionPoll(this.channel);
              }
            }

            // for (let streamObject in this.steams) {
            //   console.log(
            //     " getlistStream loop : " +
            //       JSON.stringify(streamObject[0], null, 2)
            //   );
            //   if (this.selected == "") {
            //     this.selected = streamObject.name;
            //     this.channel = streamObject.streamName;
            //   }
            // }
            // "response.data.steams : " + JSON.stringify(this.steams, null, 2)
          }
          // this.articleId = response.data.token;
        }, console.log)
        .catch(console.log);

      // return {test : "test" };
    },

    // API_PL_03 Get stream poll list (Admin)
    // https://service.promptevent.com/api/v1/virtual/stream-poll-list-admin/S1613754744
    async getQuestionPoll(channelCode) {
      const url =
        store.getters.url +
        "/api/v1/virtual/stream-poll-list-admin/" +
        channelCode;
      const token = store.getters.token;

      let dataReturn = [];

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      // let bodyParameters = {
      //   // "username": user.username,
      //   // "password": user.password,
      // };

      console.log("url : " + JSON.stringify(url, null, 2));
      // console.log(
      //   "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      // );
      // console.log("config : " + JSON.stringify(config, null, 2));

      await axios
        .get(url, config)
        .then((response) => {
          // your code

          if (response.data.status == "success") {
            console.log(
              "getQuestionPoll response.data : " +
                JSON.stringify(response.data, null, 2)
            );

            dataReturn = response.data.steamPolls;
            this.arrayPollStatus = response.data.steamPolls;
            // "pollId": 1,
            // "question": "Test create stream poll 5",
            // "opened": true,
            // "enabled": true
            console.log("dataReturn.length : " + dataReturn.length);

            // clear
            this.isVoted = false;
            // clear data
            this.choicePoll = [];

            for (let i = 0; i < dataReturn.length; i++) {
              // if (dataReturn[i].pollId !=null) {
              // this.printOject(dataReturn[i].pollId);
              let questionId = dataReturn[i].pollId;
              this.getChoicePoll(questionId);
              //this.choicePoll
              // }
            }
            // set zero
            if (dataReturn.length == 0) {
              this.seriesArray = [];
              this.seriesArrayDesc = [];
              this.choicePoll = [];
            }
            // this.getPollSeriesData();

            // this.printOject(this.choicePoll);
            //this.choicePoll
          }
          // this.articleId = response.data.token;
        }, console.log)
        .catch(console.log);

      // return response.data.conferenceEvaluations;
      return dataReturn;
    },

    // update choice PollId

    async updateChoicePoll(choicePoll, pollId, obj) {
      const url =
        store.getters.url + "/api/v1/virtual/stream-poll-details/" + pollId;
      const token = store.getters.token;

      let dataReturn = [];

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      await axios
        .get(url, config)
        .then((response) => {
          // your code

          if (response.data.status == "success") {
            console.log(
              "=== update ChoicePoll response.data : " +
                JSON.stringify(response.data.pollChoices, null, 2)
            );

            dataReturn = response.data.pollChoices;

            // this.choicePoll = response.data.pollChoices;
            let arraytotalValue = [];
            let arraytotalValueDesc = [];

            const sum_totalVoted = dataReturn
              .map((x) => x.totalVoted) // Extract only the achat field
              .flat() // Flatten the array Eg: [[1], [2], [3]] --> [1, 2, 3]
              // .map((x) => +x.initial) // Extract the initial field ("+" is to convert string to integer)
              .reduce((e, f) => e + f);

            console.log("sum_totalVoted :" + sum_totalVoted);

            this.sum_totalVoted[pollId] = sum_totalVoted;
            choicePoll["sum_totalVoted"] = sum_totalVoted;

            for (let i = 0; i < dataReturn.length; i++) {
              let voted = dataReturn[i].voted;
              if (voted > 0) {
                this.isVoted = true;
              }

              // getPollSeriesData
              // set data to chart
              // this.seriesArray.push(dataReturn[i].totalVoted);

              // insert count vote
              // arraytotalValue.push(dataReturn[i].totalVoted);

              // insert percent count vote
              arraytotalValue.push(
                ((dataReturn[i].totalVoted / sum_totalVoted) * 100).toFixed(2)
              );

              arraytotalValueDesc.push(
                dataReturn[i].description +
                  " (" +
                  dataReturn[i].totalVoted +
                  ")"
              );
              // this.printOject(dataReturn[i]);
            }

            console.log("=== arraytotalValue : " + arraytotalValue);
            // this.printOject(arraytotalValue);
            let temp = [
              {
                name: "% Voted",
                data: arraytotalValue,
              },
            ];

            this.seriesArray[pollId] = temp;

            // this.$refs.realtimeChart.updateSeries(
            //   [
            //     {
            //       // data: this.series[0].data,
            //       data: temp.data,
            //     },
            //   ],
            //   false,
            //   true
            // );

            let tempDesc = {
              chart: {
                height: 350,
                type: "bar",
                events: {
                  // click: function (chart, w, e) {
                  //   // console.log(chart, w, e)
                  // },
                },
              },
              // colors: colors,
              plotOptions: {
                bar: {
                  columnWidth: "45%",
                  distributed: true,
                },
              },
              dataLabels: {
                position: "top",
                enabled: true,
                formatter: function (val) {
                  return val + "%";
                },
              },
              legend: {
                show: false,
              },
              xaxis: {
                categories: arraytotalValueDesc,
                labels: {
                  style: {
                    // colors: colors,
                    fontSize: "12px",
                  },
                },
              },
            };

            if (response.data != null) {
              // do somethink

              // obj.updateSeries(
              //   [
              //     {
              //       // data: this.series[0].data,
              //       // data: [30, 40, 35, 50],
              //       data: arraytotalValue,
              //     },
              //   ],
              //   false,
              //   true
              // );

              obj.updateOptions({
                series: [
                  {
                    data: arraytotalValue,
                  },
                ],
                xaxis: {
                  categories: arraytotalValueDesc,
                },
              });
            }

            // chartOptions.xaxis.categories

            this.seriesArrayDesc[pollId] = tempDesc;

            console.log(
              "=== this.seriesArray === : " +
                JSON.stringify(this.seriesArray, null, 2)
            );
            console.log(
              "=== this.seriesArray data === : " +
                JSON.stringify(this.seriesArray.data, null, 2)
            );
            console.log("=== choicePoll : ");
            console.log(
              "=== choicePoll : " + JSON.stringify(this.choicePoll, null, 2)
            );
          }
          // this.articleId = response.data.token;
        }, console.log)
        .catch(console.log);

      // return response.data.conferenceEvaluations;
      return dataReturn;
    },

    // get choice vote
    // API_PL_06 Get stream poll details
    // https://dev.catsmodish.com/api/v1/virtual/stream-poll-details/2

    async getChoicePoll(pollId) {
      const url =
        store.getters.url + "/api/v1/virtual/stream-poll-details/" + pollId;
      const token = store.getters.token;

      let dataReturn = [];

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      // let bodyParameters = {
      //   // "username": user.username,
      //   // "password": user.password,
      // };

      // console.log("url : " + JSON.stringify(url, null, 2));
      // console.log(
      //   "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      // );
      // console.log("config : " + JSON.stringify(config, null, 2));

      await axios
        .get(url, config)
        .then((response) => {
          // your code

          if (response.data.status == "success") {
            console.log(
              "=== getChoicePoll response.data : " +
                JSON.stringify(response.data.pollChoices, null, 2)
            );

            dataReturn = response.data.pollChoices;
            if (response.data != null) {
              // this.eaQuestionRatingChoice.push(response.data);

              this.choicePoll.push(response.data);

              // this.choicePoll[pollId] = response.data;

              // getPollSeriesData
              // set data to chart
              // for (let item in response.data.pollChoices) {
              //   console.log("=== item  === : " + JSON.stringify(item, null, 2));
              //   this.seriesArray.push(item.totalVoted);
              // }
              // console.log(
              //   "=== this.seriesArray === : " +
              //     JSON.stringify(this.seriesArray, null, 2)
              // );
            }
            // this.choicePoll = response.data.pollChoices;
            let arraytotalValue = [];
            let arraytotalValueDesc = [];

            const sum_totalVoted = dataReturn
              .map((x) => x.totalVoted) // Extract only the achat field
              .flat() // Flatten the array Eg: [[1], [2], [3]] --> [1, 2, 3]
              // .map((x) => +x.initial) // Extract the initial field ("+" is to convert string to integer)
              .reduce((e, f) => e + f);

            console.log("sum_totalVoted :" + sum_totalVoted);

            this.sum_totalVoted["pollId"] = sum_totalVoted;

            for (let i = 0; i < dataReturn.length; i++) {
              let voted = dataReturn[i].voted;
              if (voted > 0) {
                this.isVoted = true;
              }

              // getPollSeriesData
              // set data to chart
              // this.seriesArray.push(dataReturn[i].totalVoted);

              // insert count vote
              // arraytotalValue.push(dataReturn[i].totalVoted);

              // insert percent count vote
              arraytotalValue.push(
                ((dataReturn[i].totalVoted / sum_totalVoted) * 100).toFixed(2)
              );

              arraytotalValueDesc.push(
                dataReturn[i].description +
                  " (" +
                  dataReturn[i].totalVoted +
                  ")"
              );
              // this.printOject(dataReturn[i]);
            }

            console.log("=== arraytotalValue : " + arraytotalValue);
            // this.printOject(arraytotalValue);
            let temp = [
              {
                name: "% Voted",
                data: arraytotalValue,
              },
            ];
            this.seriesArray[pollId] = temp;

            // this.$refs.realtimeChart.updateSeries(
            //   [
            //     {
            //       // data: this.series[0].data,
            //       data: temp.data,
            //     },
            //   ],
            //   false,
            //   true
            // );

            let tempDesc = {
              chart: {
                height: 350,
                type: "bar",
                events: {
                  // click: function (chart, w, e) {
                  //   // console.log(chart, w, e)
                  // },
                },
              },
              // colors: colors,
              plotOptions: {
                bar: {
                  columnWidth: "45%",
                  distributed: true,
                },
              },
              dataLabels: {
                position: "top",
                enabled: true,
                formatter: function (val) {
                  return val + "%";
                },
              },
              legend: {
                show: false,
              },
              xaxis: {
                categories: arraytotalValueDesc,
                labels: {
                  style: {
                    // colors: colors,
                    fontSize: "12px",
                  },
                },
              },
            };
            // chartOptions.xaxis.categories

            this.seriesArrayDesc[pollId] = tempDesc;

            console.log(
              "=== this.seriesArray === : " +
                JSON.stringify(this.seriesArray, null, 2)
            );
            console.log(
              "=== this.seriesArray data === : " +
                JSON.stringify(this.seriesArray.data, null, 2)
            );
            console.log("=== choicePoll : ");
            console.log(
              "=== choicePoll : " + JSON.stringify(this.choicePoll, null, 2)
            );
          }
          // this.articleId = response.data.token;
        }, console.log)
        .catch(console.log);

      // return response.data.conferenceEvaluations;
      return dataReturn;
    },
  },
};
</script>
<style scoped>
.error {
  color: rgb(255, 0, 0);
}

table.text-center * {
  text-align: center;
}

.qaQuestion {
  text-align: center;
  margin: 50px;
}

.pagination {
}
.page-item {
}
</style>

<template>
  <div class="card text-center m-3">
    <header-component></header-component>

    <admin-nav-component></admin-nav-component>


    <div class="card-body"><strong>  Report Check in QR CODE </strong></div>


    <section class="container has-text-left is-primary">
      <b-field label=" Please Channel : "> </b-field>

      <b-field>
        <b-dropdown aria-role="list">
          <template #trigger="{ active }">
            <b-button
              :label="selected"
              type="is-primary"
              :icon-right="active ? 'menu-up' : 'menu-down'"
            />
          </template>
          <div v-for="item in steams" :key="item.streamName">
            <b-dropdown-item aria-role="listitem" @click="onSelected(item)">{{
              item.name
            }}</b-dropdown-item>
          </div>
        </b-dropdown>
      </b-field>


      <b-button
        @click="downloadItem"
        class="button bt"
        :loading="btLoading"
        :disabled="btLoading"
        expanded
        rounded
        >Download Report</b-button
      >

      <b-field> </b-field>
    </section>

    <footer-component></footer-component>
  </div>
</template>

<script>
// import UserService from "@/service/UserService";

import axios from "axios";
import { store } from "@/store/store";
import FooterComponent from "./footer.vue";
import HeaderComponent from "./header.vue";
import adminNavComponent from "./adminNav.vue";

// import QAService from "@/service/QAService";
import ReportService from "@/service/ReportService";
import UserService from "@/service/UserService";


export default {
  name: "post-request",
  components: {
    FooterComponent,
    HeaderComponent,

    adminNavComponent,
    // VueTailwindPagination,
  },

  async created() {
    console.log("created");

    // get listStream
    this.getlistStream();

    this.checkSesseions();
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },

  

  data() {
    return {
      btLoading: false,

      steams: [],
      selected: "",
      channel: "",
      
      timer: "",


    };
  },
  mounted() {},

  computed: {},

  watch: {},
  filters: {},

  methods: {
    checkSesseions() {
      /// check session ///

      UserService.keepAlive(this.$store.getters.token);

      let token = store.getters.token;

      if (!token) {
        this.$router.push({ path: "/" });
      }

      clearInterval(this.timer);
      this.timer = setInterval(() => {
        UserService.keepAlive(this.$store.getters.token);

        if (!this.$store.getters.isLogin) {
          // alert(" seesion expire please login again");
          clearInterval(this.timer);
          this.$router.push({ path: "/" });
        }
      }, 5000);

      /// check session ///
    },
    async downloadItem() {
      this.btLoading = true;

      let channelCode = "";

      channelCode = this.channel;

        // alert(channelCode);

        let res = await ReportService.reportCheckinQRCODE(channelCode);

        // let res = await ReportService.reportLiveStreamingViewer(
        //   channelCode,
        //   dateSelected
        // );

        // alert(res);
        if (res == false) {
          this.btLoading = false;
          // alert(" complete  ");
        }
      
    },

    onSelected(streamObject) {
      // alert(streamObject.streamName);
      this.selected = streamObject.name;
      this.channel = streamObject.streamName;

      store.commit("SET_CHANNEL", this.channel);
    },

    // 1.get strem channel
    // get DDL

    // https://dev.catsmodish.com/api/v1/virtual/conference-stream-list
    // API_CF_06 Get conference stream list
    getlistStream() {
      const url = store.getters.url + "/api/v1/virtual/conference-stream-list";
      const token = store.getters.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      let bodyParameters = {
        // "username": user.username,
        // "password": user.password,
      };

      console.log("url : " + JSON.stringify(url, null, 2));
      console.log(
        "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      );
      console.log("config : " + JSON.stringify(config, null, 2));

      axios
        .get(url, config)
        .then((response) => {
          // your code
          if (response.data.status == "success") {
            console.log(
              " getlistStream response.data : " +
                JSON.stringify(response.data, null, 2)
            );
            store.commit("setSteams", response.data.steams);
            this.steams = response.data.steams;

            for (let i = 0; i < this.steams.length; i++) {
              console.log(
                " getlistStream loop : " +
                  JSON.stringify(this.steams[i], null, 2)
              );
              let streamObject = this.steams[i];

              if (this.selected == "") {
                // intital

                this.selected = streamObject.name;
                this.channel = streamObject.streamName;
                // this.getQuestionPoll(this.channel);
              }
            }

            // for (let streamObject in this.steams) {
            //   console.log(
            //     " getlistStream loop : " +
            //       JSON.stringify(streamObject[0], null, 2)
            //   );
            //   if (this.selected == "") {
            //     this.selected = streamObject.name;
            //     this.channel = streamObject.streamName;
            //   }
            // }
            // "response.data.steams : " + JSON.stringify(this.steams, null, 2)
          }
          // this.articleId = response.data.token;
        }, console.log)
        .catch(console.log);

      // return {test : "test" };
    },
  },
};
</script>
<style scoped>
.error {
  color: rgb(255, 0, 0);
}

table.text-center * {
  text-align: center;
}

.bt {
  /* background-color: rgb(20, 164, 141);
  color: white; */
  color: var(--templete-font-color);
  background-color: var(--templete-color);
  margin-top: 25px;
  margin-bottom: 25px;
}
</style>
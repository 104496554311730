

// import QAService from "@/service/QAService";


export default {
    // namespaced : true,


    state: {
        questionPoll: [],
        choicePoll: [], // [idPoll][dataPoll]
        isVoted: false,

    },
    // setter กำหนดค่า ข้อมูล ลง state(store)
    mutations: {

        SET_QUESTION_POLL(state, questionPoll) {
            state.questionPoll = questionPoll; // กำหนดค่า state 

        },

        SET_CHOICE_POLL(state, [pollId, choicePoll]) {

            // var test = null ;
            // test[choicePoll.pollid] = choicePoll.pollchoices;
            // alert(
            //     "getChoicePoll response.data : " +
            //     JSON.stringify(choicePoll.pollChoices , null, 2)
            //   );
            // alert(pollId);
            if (pollId == null) {
                state.choicePoll = [];
            } else {
                state.choicePoll[pollId] = choicePoll; // กำหนดค่า state 


            }


            // state.choicePoll[choicePoll.pollid] = choicePoll.pollchoices; // กำหนดค่า state 
            // alert(choicePoll);

        },

        SET_IS_VOTE(state, isVoted) {
            state.isVoted = isVoted; // กำหนดค่า state 

        },
    },


    getters: {
        // ดึงข้อมูล user จาก store ใส่ state  
        // steamAnswers: state => state.steamAnswers,

        // steamAnswers: state => state.steamAnswers.filter(
        //     (element) => element.enabled != false
        // ),

        // counterAllMessagetotal: state => state.counterChatMessagetotal + state.counterQAMessagetotal,

        questionPoll: state => state.questionPoll,
        choicePoll: state => state.choicePoll,
        isVoted: state => state.isVoted,

    },




}
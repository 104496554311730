// import { Settings } from '@/variables.js'
import axios from "axios";
import { store } from "@/store/store";
// import HttpService from "@/service/HttpService";

import DebugService from "@/service/DebugService";



export default {


    async onLogin(email,password) {
        // set load state
        // this.btLoading = true;
        let statusLogin;

        // alert("OnLogin");
        // const url = "https://service.promptevent.com/api/v1/virtual/login";
        const url = store.getters.url + "/api/v1/virtual/login";
        // const token = "";

        const config = {
            headers: {
                // Authorization: `Bearer ${token}`,
                // "x-conference-code": "rqkO9ZjUkUgOZjXq56ZkR2boLrCfvPEc",
                "x-conference-code": store.getters.conferenceCode,
                "Content-Type": "application/json",
            },
        };

        const bodyParameters = {
            // username: "admin5@catsmodish.com",
            // password: "admin12345",
            username: email,
            password: password,
        };

        // console.log("url : " + url);

        // console.log("url : " + JSON.stringify(url, null, 2));
        // console.log(
        //   "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
        // );
        // console.log("config : " + JSON.stringify(config, null, 2));

        await axios
            .post(url, bodyParameters, config)
            // .then(console.log)
            .then(
                (response) => {
                    // alert("response");
                    // console.log(
                    //   "response.data : " + JSON.stringify(response.data, null, 2)
                    // );

                    DebugService.printConsole("onLoign", response.data);
                    // console.log(response.data);

                    if (response.data.status == "failure") {
                        let errorMsg = "Error : " + response.data.statusDesc;

                        // store.userLogin.commit("SET_MESSAGE_ERROR",this.errorMsg);
                        store.commit("userLogin/SET_MESSAGE_ERROR", "login failure");
                        store.commit("userLogin/SET_MESSAGE_ERROR", errorMsg);
                        DebugService.printConsole("data.status failure", response.data);

                        // set state unload
                        // this.danger(errorMsg);
                        // this.btLoading = false;

                        statusLogin = false;
                        return false;


                    } else if (response.data.status == "success") {
                        this.errorMsg = response.data.status;
                        // console.log("data : " + response.data);
                        DebugService.printConsole("data success", response.data);
                        // store.commit("userLogin/SET_MESSAGE_ERROR", "login success");
                        // alert( JSON.stringify(response.data, null, 2));
                        store.commit("setUser", response.data.firstName);
                        store.commit("setToken", response.data.token);
                        store.commit("setRoles", response.data.roles);
                        store.commit("setIsLogin", true);
                        store.commit("SET_LOGIN_KEY", response.data.key);
                        statusLogin = true;
                        
                        store.commit("userLogin/SET_MESSAGE_ERROR", "");
                        return true;

                        // this.router.push({ path: "/HomePage" });

                    } else {
                        // error request
                        // console.log("data : " + response.data);
                        DebugService.printConsole("error request", response.data);
                        store.commit("userLogin/SET_MESSAGE_ERROR", "error request");
                        statusLogin = false;
                        return false;
                        // this.$router.push({ path: "/" });
                    }

                    // save token

                    // router to home

                    // console.log;
                    // alert(this.errorMsg);
                    // this.$router.push({ name: "home" });
                }

                // console.log
            )
            .catch(function (error) {
                // handle error
                // alert("ERROR");
                console.log(error);
            })
            .then(function () {
                // always executed
                // alert("always executed");
            });

        // alert("ERROR");

        return statusLogin;
    },


    logout() {

    },


    currentDateTime() {
        const current = new Date();
        const date = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
        const time = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
        const dateTime = date + ' ' + time;

        return dateTime;
    },

    keepAlive() {
        // https://service.promptevent.com/api/v1/virtual/keep-alive
        const url = store.getters.url + "/api/v1/virtual/keep-alive";
        const token = store.getters.token;

        const config = {
            headers: {
                Authorization: 'Bearer ' + token + "",
                "x-conference-code": store.getters.conferenceCode,
                "Content-Type": store.getters.ContentType,
            },
        };

        // let bodyParameters = {
        //     "username": user.username,
        //     "password": user.password,

        // };


        // console.log("url : " + JSON.stringify(url, null, 2));
        // console.log("bodyParameters : " + JSON.stringify(bodyParameters, null, 2));
        // console.log("config : " + JSON.stringify(config, null, 2));

        let error;


        try {

            // alert("user keepAlive");

            let t = this.currentDateTime();
            // let t = this.$luxon({

            //     output: { format: "dd/MM HH:mm" },
            // });

            console.log("**** process axios get keepAlive " + t + " : counterErrorToken = " + store.getters.counterErrorToken
            );

            // store.commit("SET_COUNTER_ERROR_TOKEN", (store.getters.counterErrorToken + 1));

            axios
                .get(url, config)
                .then(
                    (response) => {


                        // alert("then");
                        if (response.data.status == "failure") {
                            // alert("failure");
                            console.log("data : " + JSON.stringify(response.data, null, 2));
                            this.$router.push({ path: "/" });
                            // store.dispatch('setStatusLogin', false)
                        } else if (response.data.status == "success") {
                            // alert("success");
                            console.log("data : " + JSON.stringify(response.data, null, 2));
                            store.commit("setToken", response.data.token);
                            store.commit("SET_COUNTER_ERROR_TOKEN", 0);
                            store.commit("setIsLogin", true)
                            // store.dispatch('setStatusLogin', true)
                        } else {
                            // error request
                            // alert("expire seesion");
                            console.log("error request : " + JSON.stringify(response.data, null, 2));
                            // store.dispatch('setStatusLogin', false)

                            if (store.getters.counterErrorToken > 6) {

                                this.$router.push({ path: "/" });
                            } else {

                                store.commit("SET_COUNTER_ERROR_TOKEN", (store.getters.counterErrorToken + 1));
                            }
                        }
                    })
                .catch(err => {
                    console.log("****** catch : catch 401 " + err);
                    // alert(" seesion expire please login again");

                    store.commit("setIsLogin", false);


                    // this.$router.push({ path: "/" });
                    // if (err.response.status === 404) {
                    //     throw new Error(`${err.config.url} not found`);
                    // }
                    error = err;
                    throw err;
                });

            if (error instanceof Error) {
                this.$router.push({ path: "/" });

            }

            // alert("error");

        } catch (err) {
            console.log("****** catch : catch 401 " + err);
            alert("error keepAlive");
            // this.$router.push({ path: "/" });

        }



        // console.log("****** catch : catch 401 "), 
        // throw new Error('Error text.'),

        // this.$router.replace('/'),

        // console.log("****** catch : catch 401 "), 
        // throw new Error('Error text.'),
        // alert("catch 401"),    
        // store.commit("setIsLogin", false),
        // store.commit("setSteams", item);

        // store.dispatch('setStatusLogin', false)
        // this.$router.push({ path: "/" })


        // error request
        // console.log(" catch error request : " + response.data),
        //this.$router.push({ path: "/" }),


        // this.$router.push({ path: "/" }),

        // console.log("catch : ERROR  "),
        // this.$router.push({ path: "/" }),

        // console.log
        // alert()



        // );
    },



    async register(user) {
        // https://service.promptevent.com/api/v1/virtual/user-register
        // const url = Settings.url + "/api/v1/virtual/user-register";
        const url = store.getters.url + "/api/v1/virtual/user-register";
        let message = "";

        const config = {
            headers: {
                // Authorization: `Bearer ${token}`,
                // Authorization: `Bearer sss`,
                "x-conference-code": store.getters.conferenceCode,
                "Content-Type": store.getters.ContentType,
            },
        };

        let bodyParameters = {
            "username": user.username,
            "password": user.password,
            "firstName": user.firstName,
            "middleName": "middleName",
            "lastName": user.lastName,
            "careerPosition": "position",
            "workplace": user.workplace,
            "telephone": user.telephone
        };


        console.log("url : " + JSON.stringify(url, null, 2));
        console.log("bodyParameters : " + JSON.stringify(bodyParameters, null, 2));
        console.log("config : " + JSON.stringify(config, null, 2));


        await axios
            .post(url, bodyParameters, config)
            .then(
                (response) => {
                    // your code
                    message = response.data.statusDesc;
                },

                // this.$router.push({ path: "/" })
                console.log)
            .catch(
                console.log);
        return message;
    },
}

<template>
  <div class="card text-center">
    <header-component></header-component>

    <!-- <TestSSEClient></TestSSEClient> -->

    <!-- <div>questionIdSticker : {{ this.$store.getters.questionIdSticker }}</div> -->
    <!-- <div>questionIdChat : {{ this.$store.getters.questionIdChat }}</div> -->
    <!-- <div>questionId : {{ this.$store.getters.questionId }}</div> -->

    <!-- <div>steams : {{ this.$store.getters.steams }}</div> -->

    <!-- <div>
      steamAnswersSticker : {{ this.$store.getters.steamAnswersSticker }}
    </div> -->
    <!-- <div> steamAnswers : {{ this.$store.getters.steamAnswers}}/div> -->

    <!-- <div>{{ "questionIdChat :  " + this.$store.getters.questionIdChat }}</div>
        
    <div>{{ "questionId :  " + this.$store.getters.questionId }}</div>
    <div>{{ "steamAnswers :  " + this.$store.getters.steamAnswers }}</div>
    <div>{{ "steamAnswersChat :  " + this.$store.getters.steamAnswersChat }}</div> -->
    <!-- <div>{{ "user :  " + this.$store.getters.user }}</div> -->
    <!-- <div>{{ "counterViewStream :  " + this.$store.getters.counterViewStream }}</div> -->

    <!-- <div>{{ "flagStream404 :  " + flagStream404 }}</div> -->

    <!-- {{ "steamAnswers :  " + this.$store.getters.steamAnswers }} -->
    <!-- "showEmojiStatus :  " + {{ this.$store.getters.showEmojiStatus }} -->
    <!-- "stockData : " + {{ stockData }} -->

    <!-- {{store.getters.steams}} -->
    <!-- {{videoOptions}} -->
    <!-- {{videoOptions.sources[0].src}} -->

    <!-- <div>filtered totlal : {{ filtered }}</div> -->

    <!-- <div v-if="item.m3u8.includes('m3u8')">true</div>
          <div v-else>false</div> -->

    <!-- <div v-for="item in filtered" :key="item.streamName"> -->
    <div class="card-body" v-for="item in filtered" :key="item.streamName">
      <!-- <div class="card-body" v-for="item in this.$store.getters.steams " :key="item.streamName"> -->

      <div class="box boxheader">
        <!-- <p>CONSULTATION SERIES FOR EFFECTIVE COMUICATION SKILL </p> -->
        <p>{{ item.name }}</p>
        <p>
          ภายหลังรับชม ขอความกรุณาตอบแบบประเมิน Evaluation ด้านล่าง ขอบคุณค่ะ
        </p>

        <!-- <p>EP.1 Deliver with Excellnce</p> -->
        <!-- <p>EP. {{item.description}}</p> -->
      </div>

      <div class="box boxcontent">
        <div>
          <!-- <div>{{ item.m3u8 }}</div> -->
          <!-- <div v-if="item.m3u8 == null">
            <div class="player-container">
              <vue-core-video-player
                :src="item.streamUrl"
                :type="application / x - mpegURL"
              ></vue-core-video-player>
            </div>
          </div>
          <div v-else>
            <div class="player-container">
              <vue-core-video-player
                :core="HLSCore"
                :src="item.m3u8"
              ></vue-core-video-player>
            </div>
          </div>
          <div>dassaddsadsads</div> -->

          <!-- this.$store.getters.counterViewStream -->

          <div
            v-if="viewer_total == 0 && item.streamUrl == null"
            class="player-container"
          >
            <!-- {{": posterUrl : "+ item.posterUrl }} -->
            <img :src="item.posterUrl" />
            <!-- <img src="@/assets/images/welcome_stream.jpg" /> -->
          </div>

          <div v-else class="player-container">
            <video-player :options="videoOptions">
              <!-- <div class="vjs-big-play-button"></div> -->
            </video-player>
            <!-- <div class="moveToVideoJs">
                <div class="advertisement hide">Advertisement</div>
              </div> -->

            <!-- <div class="advertisement hide">Advertisement</div> -->

            <!-- <video-player
              class="video-player-box"
              ref="videoPlayer"
              :options="videoOptions"
              :playsinline="true"
              customEventName="customstatechangedeventname"
              @play="onPlayerPlay($event)"
              @pause="onPlayerPause($event)"
              @ended="onPlayerEnded($event)"
              @waiting="onPlayerWaiting($event)"
              @playing="onPlayerPlaying($event)"
              @loadeddata="onPlayerLoadeddata($event)"
              @timeupdate="onPlayerTimeupdate($event)"
              @canplay="onPlayerCanplay($event)"
              @canplaythrough="onPlayerCanplaythrough($event)"
              @statechanged="playerStateChanged($event)"
              @ready="playerReadied"
            >
            </video-player> -->
          </div>

          <!-- <div class="player-container">
            <vue-core-video-player
              :core="HLSCore"
              :src="item.m3u8"
            ></vue-core-video-player>
          </div> -->

          <!-- <div v-if="!item.m3u8.includes('m3u8')" class="player-container">
            <vue-core-video-player
              :core="HLSCore"
              src="http://demo.unified-streaming.com/video/tears-of-steel/tears-of-steel.ism/.m3u8"
            ></vue-core-video-player>
          </div> -->
        </div>

        <!-- <div v-if="!item.m3u8.includes('m3u8')">
          <div>asdsadsad</div>
          <div class="player-container">
            <vue-core-video-player
              src="item.m3u8"
            ></vue-core-video-player>
          </div>
        </div> -->

        <div class="columns">
          <div class="left-half">
            <div style="float: left">
              <sidebar class="tbMargin" v-if="flag_fn_QA"></sidebar>
            </div>

            <div style="float: left">
              <sidebar-vote class="tbMargin" v-if="flag_fn_Poll"></sidebar-vote>
            </div>

            <div style="float: left" v-if="'S1639405475' == item.streamName">
              <a
                href="https://docs.google.com/forms/d/1QtU2Nc_CZ3sXXG2f6WXQazqZYFnF4KNk02FPRNOOKN8/viewform?edit_requested=true"
                class="button is-rounded bt tbMargin"
                target="_blank"
                >Evaluation</a
              >
            </div>
            <div style="float: left" v-if="'S1639405469' == item.streamName">
              <a
                href="https://docs.google.com/forms/d/1_F4x8X0sbcXVoHXV5I82B64IzeTWsABqcC_TpXHygiw/viewform?edit_requested=true"
                class="button is-rounded bt tbMargin"
                target="_blank"
                >Evaluation</a
              >
            </div>

            <div style="float: left" v-if="'S1639405459' == item.streamName">
              <a
                href="https://docs.google.com/forms/d/1UbkdB70C62roo-2jKRFWg5ryBqdy0h3zw9rbKfplEmE/viewform?edit_requested=true"
                class="button is-rounded bt tbMargin"
                target="_blank"
                >Evaluation</a
              >
            </div>
            <div style="float: left" v-if="'S1639405452' == item.streamName">
              <a
                href="https://docs.google.com/forms/d/1cZE8tqWWCm8hhzXlzbkf-E_gYlbzD_vIlC8ohnM53N4/viewform?edit_requested=true"
                class="button is-rounded bt tbMargin"
                target="_blank"
                >Evaluation</a
              >
            </div>
            <div style="float: left" v-if="'S1639405427' == item.streamName">
              <a
                href="https://docs.google.com/forms/d/1DTWQVjCn9ntT02a-GmRV_5qo7it08qhofoNVfGwZfQI/viewform?edit_requested=true"
                class="button is-rounded bt tbMargin"
                target="_blank"
                >Evaluation</a
              >
            </div>

            <div style="float: left">
              <p v-for="item in handOut" :key="item.handoutId">
                <!-- <b-button class="bt" rounded> HandOut</b-button> -->

                <!-- <div v-for="item in handOut" :key="item.handoutId"> -->
                <a
                  class="button is-rounded bt tbMargin"
                  :href="item.handoutFileUrl"
                  @click.prevent="
                    downloadItem(item.handoutFileUrl, item.handoutFileName)
                  "
                >
                  HandOut</a
                >

                <!-- </div> -->
              </p>
            </div>

            <div style="float: left" v-if="flag_fn_sticker">
              <b-tooltip
                type="is-light"
                :triggers="['click']"
                :auto-close="['outside', 'escape', 'inside']"
              >
                <template v-slot:content>
                  <span class="btn_icon" @click.prevent="sendEmoji('😍')"
                    >😍</span
                  >
                  <span class="btn_icon" @click.prevent="sendEmoji('🤩')"
                    >🤩</span
                  >
                  <span class="btn_icon" @click.prevent="sendEmoji('👏')"
                    >👏</span
                  >
                  <span class="btn_icon" @click.prevent="sendEmoji('❤️')"
                    >❤️</span
                  >
                  <span class="btn_icon" @click.prevent="sendEmoji('😀')"
                    >😀</span
                  >
                  <span class="btn_icon" @click.prevent="sendEmoji('😂')"
                    >😂</span
                  >
                  <span class="btn_icon" @click.prevent="sendEmoji('👍')"
                    >👍</span
                  >
                  <span class="btn_icon" @click.prevent="sendEmoji('👎')"
                    >👎</span
                  >
                </template>

                <a class="button is-rounded bt tbMargin"> Emoji</a>
              </b-tooltip>
            </div>
          </div>
          <div class="right-half">
            <div style="float: right">
              <div style="float: left" class="tbMargin">
                <b-icon icon="eye"></b-icon>
              </div>
              <div style="float: left" class="tbMargin">
                <p>{{ viewer_total }}</p>
                <!-- <p>{{ this.$store.getters.counterViewStream }}</p> -->
                <!-- <div>{{ "counterViewStream :  " + this.$store.getters.counterViewStream }}</div> -->
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="columns">
   กหฟกหฟ
        </div>

        <table class="tbMargin">
          <tr>
            <td>assad</td>
          </tr>
        </table> -->
      </div>
    </div>
    <!-- </div> -->

    <!-- 
    <div class="card-body">Returned Id: {{ articleId }}</div>
    <h1>TEST ETESTS SES</h1>
    <div>User {{ $route.params.id }}</div>

    <p>Computed reversed message: "{{ urlStream }}"</p>

    <h1>steams.length : {{ steams.length }}</h1> -->

    <!-- <p>Computed reversed message: "{{ filtered[0].streamName }}"</p> -->

    <!-- <div class="container">
      <div class="row">
        <div class="col">1 of 2</div>
        <div class="col">2 of 2</div>
      </div>
      <div class="row">
        <div class="col">1 of 3</div>
        <div class="col">2 of 3</div>
        <div class="col">3 of 3</div>
      </div>
    </div> -->

    <!-- <div class="player-container">
      <vue-core-video-player
        :core="HLSCore"
        src="filtered.m3u8"
      ></vue-core-video-player>
    </div> -->

    <!-- <div v-for="item in filtered" :key="item.streamName">
      <div class="row"> -->
    <!-- <div class="col-sm">item.streamName : "{{ item.streamName }}"</div>
        <div class="col-sm">item.m3u8 : "{{ item.m3u8 }}"</div>
        <div class="col-sm">
      
        </div> -->

    <!-- <div class="row">
          <div class="player-container">
            <vue-core-video-player
              :core="HLSCore"
              :src="item.m3u8"
            ></vue-core-video-player>
          </div>
        </div> -->
    <!-- 
        <div class="player-container">
          <vue-core-video-player
            :core="HLSCore"
            :src="urlStream"
          ></vue-core-video-player>
        </div> -->

    <!-- <div class="player-container">
          <vue-core-video-player
            :core="HLSCore"
            src="http://demo.unified-streaming.com/video/tears-of-steel/tears-of-steel.ism/.m3u8"
          ></vue-core-video-player>
        </div>
      </div>
    </div> -->

    <!-- src="https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8" -->
    <!-- src="http://demo.unified-streaming.com/video/tears-of-steel/tears-of-steel.ism/.m3u8" -->

    <!-- <vue-core-video-player @play="your_method" src="./videos/your_video.mp4"></vue-core-video-player> -->

    <!-- </li> -->
    <!-- </ul> -->

    <!-- <nav class="level">
      <div class="level-item has-text-centered">
        <div>
          <sidebar></sidebar>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <sidebar-vote></sidebar-vote>
        </div>
      </div>
      <div class="level-item has-text-centered">
        <div>
          <div v-for="item in handOut" :key="item.handoutId">
            <a
              class="button is-rounded bt"
              :href="item.handoutFileUrl"
              @click.prevent="
                downloadItem(item.handoutFileUrl, item.handoutFileName)
              "
              >HandOut</a
            >
          </div>
        </div>
      </div>
    </nav> -->

    <footer-component></footer-component>
  </div>
  <!-- </div> -->
</template>

<script>
import HLSCore from "@core-player/playcore-hls";
import UserService from "@/service/UserService";
import QAService from "@/service/QAService";
// import sidebar from "@/sidebar";
// import ConferenceService from "@/service/ConferenceService";
// import {VideoPlayer} from 'vue-videojs7'

import axios from "axios";
import { store } from "@/store/store";
import Sidebar from "./sidebar.vue"; // Q&A
import SidebarVote from "./sidebarVote.vue"; // Poll
import FooterComponent from "./footer.vue";
import HeaderComponent from "./header.vue";

import VideoPlayer from "@/components/VideoPlayer.vue";
// import VideoPlayer from './VideoPlayer.vue';

import "video.js/dist/video-js.css";

// import TestSSEClient from "./TestSSEClient.vue";
import { EventSourcePolyfill } from "event-source-polyfill";

// import videoPlayer from "vue-video-player";

// import VideoJs from "videojs-http-streaming.min.js";

export default {
  name: "post-request",
  components: {
    Sidebar,
    SidebarVote,
    FooterComponent,
    HeaderComponent,
    VideoPlayer,
    // TestSSEClient,
    // VideoJs,
  },
  data() {
    return {
      articleId: null,
      steams: [],
      params: "",
      filtered: [],
      HLSCore,
      urlStream:
        "https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8",
      handOut: [],
      viewer_total: 0,

      videoOptions: {
        // autoplay: "muted",
        
        // playbackRates: [0.5, 1, 1.5, 2],
        controlBar: {
          pictureInPictureToggle: false,
          // playbackRateMenuButton: true,
          // liveDisplay: true,
          // addChild: {
          //   button : {
          //      text: "Back",
          //      tabIndex: 3
          //   },
          // },

          // qualityMenuButton  :true,
          // plugins: {
          //   foo: { bar: true },
          //   boo: { baz: false },
          // },

          // children: [
          //   "button",
          //   {
          //     name: "button",
          //     someOtherOption: true,
          //   },
          // ],
          //     text: "Press Me",
          // buttonChildExample: {
          //   buttonChildOption: true,
          // },
        },

        // plugins: {
        //   foo: { bar: true },
        //   boo: { baz: false },
        // },
        // children: ["bigPlayButton", "controlBar"],
        // children: [
        //   "button",
        //   {
        //     name: "button",
        //     someOtherOption: true,
        //   },
        // ],
        // settingsMenuButton: {
        //   entries: [
        //     "subtitlesButton",
        //     "captionsButton",
        //     "playbackRateMenuButton",
        //     "qualityMenuButton",
        //   ],
        // },
        // captionsButton: true,
        autoplay: true,
        controls: true,
        sources: [
          {
            // src: "/path/to/video.mp4",

            // type: "video/mp4",
            // src: this.urlStream,

            // src: "https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8",

            src: this.$store.getters.steams.m3u8,
            type: this.$store.getters.steams.typeVideo,

            // type: "application/x-mpegURL",
          },
        ],

        poster: this.$store.getters.steams.posterUrl,
        // options: {
        //   // autoplay: true,
        //   // volume: 0.6,
        //   poster: this.$store.getters.steams.posterUrl,
        // },
      },

      posterUrl: "",

      flagStream404: false,

      stockData: null,

      timer: "",
      timerUpdateData: "",

      flag_fn_QA: this.$store.getters.flag_fn_QA,
      flag_fn_sticker: this.$store.getters.flag_fn_sticker,
      flag_fn_Poll: this.$store.getters.flag_fn_Poll,
    };
  },
  async created() {
    console.clear();
    console.log("created live ");

    // this.filtered = steams.filter(
    //   (element) => element.streamName === this.$route.params.id
    // );

    // alert("es : " + JSON.stringify(this.$store.getters.steams, null, 2));

    // this.setupStream(this.$store.getters.steams.streamName);

    this.checkStatusMedia();

    // if (this.$store.getters.steams.m3u8 == null) {
    //   this.urlStream = this.$store.getters.steams.streamUrl;
    // } else {
    //   this.urlStream = this.$store.getters.steams.m3u8;
    // }

    // alert(this.$store.getters.steams.m3u8)
    this.printObject(this.$store.getters.steams);

    this.checkSesseions();

    console.log("created : " + this.$route.params.id);
    this.params = this.$route.params.id;
    // alert(this.$store.getters.steams.m3u8);

    // UserService.keepAlive(this.$store.getters.token);

    this.viewer_total = await this.getViewerStrem(
      // this.$store.getters.steams.streamName
      this.$route.params.id
    );

    this.posterUrl = this.$store.getters.steams.posterUrl;

    //  this.getViewerStrem(this.params);

    this.timerUpdateData = setInterval(() => {
      UserService.keepAlive(this.$store.getters.token);
      // alert(this.params);
      this.getViewerStrem(this.$route.params.id);
      this.viewer_total = this.$store.getters.counterViewStream;
    }, 10000);

    // this.viewer_total = await this.getViewerStrem(this.params);
    this.getlistStream();
    // alert("es : " + JSON.stringify(this.$store.getters.steams, null, 2));

    this.handOut = await this.getHandOut(this.$route.params.id);

    console.log(" === test m3u8 ===" + this.isM3u8);
    this.printObject(this.filtered);
    // if(this.filtered.length>0){
    //   this.videoOptions.sources[0].src = this.filtered[0].m3u8;
    //   this.VideoPlayer.play();
    // }
    console.log(" === test m3u8 ===" + this.isM3u8);
  },

  beforeDestroy() {
    clearInterval(this.timer);
    clearInterval(this.timerUpdateData);
  },

  computed: {
    isAdmin() {
      let roles = this.$store.getters.roles;
      let adminFlag = false;
      for (let role in roles) {
        console.log("role : " + roles[role]);
        if (roles[role] == "ADMIN") {
          adminFlag = true;
        }
      }

      return adminFlag;
    },

    templeteStyle() {
      return {
        "--templete-color": this.$store.getters.templeteBgColor,
        "--templete-font-color": this.$store.getters.templeteFontColor,
      };
    },

    isM3u8() {
      // this.steams.m3u8;
      console.log("isM3u8 : " + this.printObject(this.filtered));
      return "isM3u8";
    },
    // player() {
    //   return this.$refs.videoPlayer.player;
    // },
    // filteredItems: function () {
    //   return this.steams.filter(function (steam) {
    //     // return steam.streamName === this.params;
    //     return steam;
    //   });
    // },
  },
  mounted() {
    // videojs(this.$refs.videojs);
    // this.$refs.videoPlayer.player.controlBar.progressControl.disable();
    // this.$refs.videoPlayer.player.controlBar.disable();
  },

  methods: {
    checkSesseions() {
      /// check session ///

      UserService.keepAlive(this.$store.getters.token);

      let token = store.getters.token;

      if (!token) {
        this.$router.push({ path: "/" });
      }

      clearInterval(this.timer);
      this.timer = setInterval(() => {
        UserService.keepAlive(this.$store.getters.token);

        if (!this.$store.getters.isLogin) {
          // alert(" seesion expire please login again");
          clearInterval(this.timer);
          this.$router.push({ path: "/" });
        }
      }, 5000);

      /// check session ///
    },

    setupStream(channel) {
      console.log(channel);
      // const url =
      //   store.getters.url + "/api/v1/virtual/stream/receive-sticker/" + channel;
      const url =
        store.getters.url +
        "/api/v1/virtual/stream/receive-sticker/S1615295212";
      const token = store.getters.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      // // Not a real URL, just using for demo purposes
      // let es = new EventSource(url, {});

      console.log("url : " + JSON.stringify(url, null, 2));
      // console.log(
      //   "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      // );
      console.log("config : " + JSON.stringify(config, null, 2));

      // EventSource

      const es = new EventSourcePolyfill(url, {
        // headers: {
        //   Authorization: "my jwt token",
        // }
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          // "Content-Type": "text/event-stream",
          // "Cache-Control": "no-cache",
          // Connection: "keep-alive",
          // "X-Accel-Buffering": " no",
          "Content-Type": store.getters.ContentType,
          // "Content-Type": "text/event-stream"
        },
        withCredentials: false,
        polyfill: true,
        heartbeatTimeout: 300000, //5 minutes else 45 seconds by default, it initiates another request
      });

      console.log("es : " + JSON.stringify(es, null, 2));

      es.addEventListener(
        "message",
        (event) => {
          console.log(" == test SSE ==" + event.data);
          let data = JSON.parse(event.data);
          console.log(" == test SSE ==" + data);
          this.stockData = data;
        },
        false
      );

      console.log("es : " + JSON.stringify(es, null, 2));

      es.addEventListener(
        "error",
        (event) => {
          if (event.readyState == EventSourcePolyfill.CLOSED) {
            console.log("Event was closed");
            console.log(EventSourcePolyfill);
          }
        },
        false
      );

      console.log("es : " + JSON.stringify(es, null, 2));
    },

    async sendEmoji(iconName) {
      // alert("sendEmoji");

      // store.commit("SET_SHOW_EMOJI_STATUS", true);

      // setTimeout(() => {
      //   store.commit("SET_SHOW_EMOJI_STATUS", false);
      // }, 2000);

      // this.$store.getters.steams.streamName
      // alert("es : " + JSON.stringify(this.$store.getters.steams, null, 2));
      // this.$store.getters.steams.streamNam
      // filtered //
      QAService.sendSticker(this.$route.params.id, "00");
      await QAService.insertSticker(
        this.$route.params.id,
        this.$store.getters.questionIdSticker,
        iconName
      );
    },

    printObject(obj) {
      console.log("printObject : " + JSON.stringify(obj, null, 2));
    },
    HeightStyles() {
      return {
        "background-image": 'url("' + this.image + '")',
        opacity: 0.7,
        "background-size": "cover",
      };
    },
    goHomePage() {
      // alert("goRegisterPage");
      this.$router.push({ path: "/HomePage" });
    },
    goLoginPage() {
      // alert("goRegisterPage");
      this.$router.push({ path: "/" });
    },
    goStremPage(code) {
      console.log("Conference code : " + code);
      // alert("goRegisterPage");
      this.$router.push({ path: "/StreamPage/" + code, code });
    },

    downloadItem(url, label) {
      axios
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = label;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },

    checkStatusMedia() {
      console.log("****** checkStatusMedia **** ");

      const url = this.$store.getters.steams.m3u8;
      // const url =
      //   "https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8";

      const token = store.getters.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      let bodyParameters = {
        // "username": user.username,
        // "password": user.password,
      };

      console.log("url : " + JSON.stringify(url, null, 2));
      console.log(
        "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      );
      console.log("config : " + JSON.stringify(config, null, 2));

      try {
        axios
          .get(url, config)
          .then((response) => {
            // alert("response");
            // your code

            console.log(
              "response  checkStatusMedia : " +
                JSON.stringify(response.data, null, 2)
            );

            // this.articleId = response.data.token;
          }, console.log)
          .catch((e) => {
            // alert("error");
            console.log(
              "error  checkStatusMedia : " + JSON.stringify(e, null, 2)
            );
            this.flagStream404 = true;
          });
      } catch (e) {
        console.log(
          "error try catch checkStatusMedia : " + JSON.stringify(e, null, 2)
        );
      }
      // return {test : "test" };
    },

    // https://dev.catsmodish.com/api/v1/virtual/conference-stream-list
    // API_CF_06 Get conference stream list
    getlistStream() {
      const url = store.getters.url + "/api/v1/virtual/conference-stream-list";
      const token = store.getters.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      let bodyParameters = {
        // "username": user.username,
        // "password": user.password,
      };

      console.log("url : " + JSON.stringify(url, null, 2));
      console.log(
        "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      );
      console.log("config : " + JSON.stringify(config, null, 2));

      axios
        .get(url, config)
        .then((response) => {
          // your code
          console.log("getlistStream: " + this.$route.params.id);
          if (response.data.status == "success") {
            console.log("getlistStream: success " + this.$route.params.id);
            console.log(
              "response.data : " + JSON.stringify(response.data, null, 2)
            );
            // store.commit("setSteams", response.data.steams);

            //this.steams = response.data.steams;

            let steams = response.data.steams;

            // console.log(
            //   "response.data.filtered : " +
            //     JSON.stringify(this.filtered, null, 2)
            // );

            this.filtered = steams.filter(
              (element) => element.streamName === this.$route.params.id
            );

            // alert("this.filtered : " + JSON.stringify(this.filtered, null, 2));

            // this.urlStream = this.filtered.
            // if (!this.filtered.m3u8) {
            //   this.filtered["m3u8"] = this.filtered.streamUrl;
            // }

            // this.filtered["m3u8"] = this.filtered.streamUrl;
            // this.urlStream = this.steams.filter(
            //   (element) => element.streamName === this.$route.params.id
            // );
          }
          console.log(
            "response.data.filtered : " + JSON.stringify(this.filtered, null, 2)
          );
          // this.articleId = response.data.token;
        }, console.log)
        .catch(console.log);

      // return {test : "test" };
    },

    // get viewer stream
    // API_ST_03 Get stream viewer097
    // https://service.promptevent.com/api/v1/virtual/stream/viewer?name=S1613754744

    async getViewerStrem(channelCode) {
      const url =
        store.getters.url + "/api/v1/virtual/stream/viewer?name=" + channelCode;
      const token = store.getters.token;
      let dataReturn = [];

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      // let bodyParameters = {
      //   // "username": user.username,
      //   // "password": user.password,
      // };

      // console.log("url : " + JSON.stringify(url, null, 2));
      // console.log(
      //   "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      // );
      // console.log("config : " + JSON.stringify(config, null, 2));

      await axios
        .get(url, config)
        .then((response) => {
          // your code
          if (response.data.status == "success") {
            console.log(
              "getViewerStrem.data : " + JSON.stringify(response.data, null, 2)
            );

            dataReturn = response.data.viewer;

            // test refesh viwer
            // dataReturn =this.$store.getters.counterViewStream + 1;

            store.commit("SET_COUUNTER_VIEW_STREAM", dataReturn);

            // this.urlStream = this.steams.filter(
            //   (element) => element.streamName === this.$route.params.id
            // );
            // "response.data.steams : " + JSON.stringify(this.steams, null, 2)
          }
          // this.articleId = response.data.token;
        }, console.log)
        .catch(console.log);

      return dataReturn;
    },

    // get hand out
    // API_HD_03 Get stream handout list
    // https://service.promptevent.com/api/v1/virtual/stream-handout-list/S1613754744

    async getHandOut(channelCode) {
      const url =
        store.getters.url +
        "/api/v1/virtual/stream-handout-list/" +
        channelCode;
      const token = store.getters.token;
      let dataReturn = [];

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      // let bodyParameters = {
      //   // "username": user.username,
      //   // "password": user.password,
      // };

      // console.log("url : " + JSON.stringify(url, null, 2));
      // console.log(
      //   "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      // );
      // console.log("config : " + JSON.stringify(config, null, 2));

      await axios
        .get(url, config)
        .then((response) => {
          // your code
          if (response.data.status == "success") {
            console.log(
              "getHandOut.data : " + JSON.stringify(response.data, null, 2)
            );

            dataReturn = response.data.handouts;

            // this.urlStream = this.steams.filter(
            //   (element) => element.streamName === this.$route.params.id
            // );
            // "response.data.steams : " + JSON.stringify(this.steams, null, 2)
          }
          // this.articleId = response.data.token;
        }, console.log)
        .catch(console.log);

      return dataReturn;
    },
  },
};
</script>


<style scoped>
.player-container {
  position: relative;
}

.advertisement {
  position: absolute;
  color: rgb(230, 200, 98);
  padding: 5px 10px;
  text-align: right;
  background: rgba(0, 0, 0, 0.4);
  bottom: 50px;
  right: 0;
  font-size: 14px;
  font-weight: 700;
  z-index: 1 !important;
}

.bt {
  /* background-color: rgb(20, 164, 141);
  color: white; */
  color: var(--templete-font-color);
  background-color: var(--templete-color);
  margin: 25px;
}
.tbMargin {
  margin-top: 25px;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 5px;
}
.card-body {
  align-self: center;
  width: 100%;
}

@media screen and (min-width: 600px) {
  .card-body {
    align-self: center;
    width: 80%;
  }
}

.boxheader {
  /* width: 800px; */
  /* width: 80%; */
  /* margin: 50px; */

  /* background-color: rgb(20, 164, 141); */
  /* color: white; */

  color: var(--templete-font-color);
  background-color: var(--templete-color);

  /* text-align: left; */
}
.boxcontent {
  border: 1px solid black;
  /* padding-bottom: 75px; */
}

/* Pattern styles */
.left-half {
  /* background-color: #ff9e2c; */
  display: inline-block;
  /* float: left; */
  width: 70%;
}
.right-half {
  /* background-color: #b6701e; */

  display: inline-block;
  /* float: left; */
  width: 30%;
}
.btn_icon {
  cursor: pointer;
  margin: 5px;
  font-size: 25px;
  float: none;
}

/* ถ้าไม่มี mouse */
@media (pointer: coarse) {
  .btn_icon {
    float: left;
  }
}
</style>

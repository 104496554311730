<template>
  <div class="card text-center m-3">
    <header-component></header-component>

    <admin-nav-component></admin-nav-component>
    <admin-setting-nav-componentponent></admin-setting-nav-componentponent>

    <div class="card-body"><strong> Create QR CODE</strong></div>

    <section class="container has-text-left is-primary">
      <!-- <div style="height: 300px; width: 300px">
        <qrcode-stream :key="_uid" :track="this.paintOutline" @init="logErrors" />
      </div> -->

      <div style="margin: 25px;">
        <b-button
          style="background-color: #2c296b"
          @click="onCreateUserQRcode"
          class="button is-info"
          :loading="btLoading"
          :disabled="btLoading"
          expanded
          rounded
          >create and replace QR CODE all user</b-button
        >
      </div>

      
      
      <div style="margin: 25px;">
        <b-button
          style="background-color: #2c296b"
          @click="CreateQRcodeNewUserOnly"
          class="button is-info"
          :loading="btLoading"
          :disabled="btLoading"
          expanded
          rounded
          > Create QRcode New User Only</b-button
        >
      </div>

      <div style="margin: 25px;">
        <b-button
          style="background-color: #2c296b"
          @click="onDownloadAllQRCODE"
          class="button is-info"
          :loading="btLoading"
          :disabled="btLoading"
          expanded
          rounded
          >download ALL QR CODE</b-button
        >
      </div>

    </section>

    <footer-component></footer-component>
  </div>
</template>

<script>
// import { QrcodeStream } from "vue-qrcode-reader";

// import { store } from "@/store/store";
import FooterComponent from "./footer.vue";
import HeaderComponent from "./header.vue";
import adminNavComponent from "./adminNav.vue";
import adminSettingNavComponentponent from "./adminSettingNav.vue";

import DebugService from "@/service/DebugService";
import QRCodeService from "@/service/QRCodeService";
import ReportService from "@/service/ReportService";


export default {
  components: { 
    // QrcodeStream, 
    adminSettingNavComponentponent,
    adminNavComponent, FooterComponent, HeaderComponent,
    
   },

  data() {
    const options = [
      { text: "nothing (default)", value: undefined },
      { text: "outline", value: this.paintOutline },
      { text: "centered text", value: this.paintCenterText },
      { text: "bounding box", value: this.paintBoundingBox },
      
    ];

    const selected = options[1];
  

    return { 
      selected, 
      options , 
      btLoading : false
    };
  },

  methods: {
    paintOutline(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints;

        ctx.strokeStyle = "red";

        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
        // alert(detectedCode.rawValue);
        this.CheckinUser(detectedCode.rawValue);
      }
    },

    danger(msg) {
      this.$buefy.notification.open({
        duration: 5000,
        message: msg,
        position: "is-bottom-right",
        type: "is-danger",
        hasIcon: true,
      });
    },

    success(msg) {
      this.$buefy.notification.open({
        duration: 5000,
        message: msg,
        position: "is-bottom-right",
        type: "is-success",
        hasIcon: true,
      });
    },


    async onDownloadAllQRCODE(){
      alert("onDownloadAllQRCODE");

      // let statusLogin = ReportService.DownloadAllQRCODE();
      //   statusLogin.then((status) => {
      //     // alert(status);
      //     DebugService.printConsole("status login ", status);
      //     // let statusLogin = false;
      //     if (!status) {
      //       // set state unload
      //       this.danger(this.$store.getters["userLogin/messageErrorLogin"]);
      //       // this.$router.push({ path: "/" });
      //     } else {
      //       this.success("Create QRcode New User Only complete");

      //       // this.$router.push({ path: "/HomePage" });
      //     }
      //   });

        this.btLoading = true;
      // import function
   


        let res = await ReportService.DownloadAllQRCODE();

        // let res = await ReportService.reportLiveStreamingViewer(
        //   channelCode,
        //   dateSelected
        // );

        // alert(res);
        if (res == false) {
          this.btLoading = false;
          // alert(" complete  ");
        }
      
    },

    CreateQRcodeNewUserOnly(){
      alert("CreateQRcodeNewUserOnly");

      let statusLogin = QRCodeService.CreateQRcodeNewUserOnly();
        statusLogin.then((status) => {
          // alert(status);
          DebugService.printConsole("status login ", status);
          // let statusLogin = false;
          if (!status) {
            // set state unload
            this.danger(this.$store.getters["userLogin/messageErrorLogin"]);
            // this.$router.push({ path: "/" });
          } else {
            this.success("Create QRcode New User Only complete");

            // this.$router.push({ path: "/HomePage" });
          }
        });
    },

    onCreateUserQRcode(){
      alert("onCreateUserQRcode");

      let statusLogin = QRCodeService.CreateAllQRcode();
        statusLogin.then((status) => {
          // alert(status);
          DebugService.printConsole("status login ", status);
          // let statusLogin = false;
          if (!status) {
            // set state unload
            this.danger(this.$store.getters["userLogin/messageErrorLogin"]);
            // this.$router.push({ path: "/" });
          } else {
            this.success("on Create User QRcode complete");

            // this.$router.push({ path: "/HomePage" });
          }
        });

    },

    CheckinUser(codeCheckin) {
      if (confirm("Do you want to Check in?")) {
        // on confirmed
        alert(codeCheckin);
        this.success("check in complete");

        let statusLogin = QRCodeService.CheckinUser(codeCheckin);
        statusLogin.then((status) => {
          // alert(status);
          DebugService.printConsole("status login ", status);
          // let statusLogin = false;
          if (!status) {
            // set state unload
            this.danger(this.$store.getters["userLogin/messageErrorLogin"]);
            // this.$router.push({ path: "/" });
          } else {
            this.danger("check in complete");

            // this.$router.push({ path: "/HomePage" });
          }
        });

        alert("check in complete");
      }
    },

    paintBoundingBox(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const {
          boundingBox: { x, y, width, height },
        } = detectedCode;

        ctx.lineWidth = 2;
        ctx.strokeStyle = "#007bff";
        ctx.strokeRect(x, y, width, height);
      }
    },

    paintCenterText(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const { boundingBox, rawValue } = detectedCode;

        const centerX = boundingBox.x + boundingBox.width / 2;
        const centerY = boundingBox.y + boundingBox.height / 2;

        const fontSize = Math.max(12, (50 * boundingBox.width) / ctx.canvas.width);
        console.log(boundingBox.width, ctx.canvas.width);

        ctx.font = `bold ${fontSize}px sans-serif`;
        ctx.textAlign = "center";

        ctx.lineWidth = 3;
        ctx.strokeStyle = "#35495e";
        ctx.strokeText(detectedCode.rawValue, centerX, centerY);

        ctx.fillStyle = "#5cb984";
        ctx.fillText(rawValue, centerX, centerY);
      }
    },

    logErrors(promise) {
      promise.catch(console.error);
    },
  },
};
</script>

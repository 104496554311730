
<template>
  <div class="card text-center m-3">
    <header-component></header-component>

    <admin-nav-component></admin-nav-component>

    <!-- {{ "questionId :  " + this.$store.getters.questionId }}
    {{ "channel :  " + this.$store.getters.channel }}
    {{ "steamAnswers :  " + this.$store.getters.steamAnswers }}
    {{
      "steamAnswersExculdeCancel :  " +
      this.$store.getters.steamAnswersExculdeCancel
    }} -->

    <!-- <div>
sASAasa
ะำะหะำหะ{{steamAnswers}}

</div> -->
    <!-- <form ref="form" class="formQA " @submit.prevent="onSubmit">
      <div v-for="item in eaQuestionAns" :key="item.evaluationId">

    <div class="card qa">
  <div class="card-content">
    <div class="content">
      <p class="qaQuestion">Q1.Lorem ipsum leo risus, porta ac consectetur ac, vestibulum at eros. Donec id </p>

        <p>
        <b-field label="ANS" :label-position="labelPosition">
          <b-input

            type="textarea"
          
          >
          </b-input>
        </b-field>
      </p>
    </div>
  </div>
</div>
</div>

    </form> -->

    <div class="card-body"><strong> Summary Q&A </strong></div>

    <!-- v-on:change="changeEnableUser(props.row.username,props.row.enabled)"  -->
    <!-- @update="changeEnableUser(props.row.username,props.row.enabled)" -->

    <section>
      <b-field group-multiline grouped horizontal>
        <b-field label=" Please Channel : "> </b-field>
        <b-dropdown aria-role="list">
          <template #trigger="{ active }">
            <b-button
              :label="selected"
              type="is-primary"
              :icon-right="active ? 'menu-up' : 'menu-down'"
            />
          </template>
          <div v-for="item in steams" :key="item.streamName">
            <b-dropdown-item aria-role="listitem" @click="onSelected(item)">{{
              item.name
            }}</b-dropdown-item>
          </div>
        </b-dropdown>
      </b-field>
    </section>

    <!-- <b-table has-text-centered :data="steamAnswers"> -->

    <b-tabs>
      <b-tab-item label="Table">
        <b-table
          has-text-centered
          :data="this.$store.getters.steamAnswersExculdeCancel"
          :checked-rows.sync="checkedRows"
          checkable
        >
          <!-- <b-table-column has-text-centered field="id" label="ID" v-slot="props" centered>
        {{ props.row.answerId }}
      </b-table-column> -->

          <b-table-column
            field="answer"
            label="Message"
            v-slot="props"
            centered
          >
            {{ props.row.answer }}
            <!-- <b-switch
          v-model="props.row.answer"
          @input="changeEnableUser(props.row.username, props.row.enabled)"
        >
          enabled</b-switch
        > -->
          </b-table-column>
          <b-table-column
            field="firstName"
            label="Sender"
            v-slot="props"
            centered
          >
            {{ props.row.firstName }}

            <div class="control">
              <!-- <b-switch
            v-model="props.row.firstName"
            @input="
              changeEnableCertified(props.row.username, props.row.certified)
            "
            >certified</b-switch
          > -->
            </div>
          </b-table-column>

          <b-table-column
            field="lastModifiedDate"
            label="Update Time"
            v-slot="props"
            centered
          >
            {{ props.row.lastModifiedDate }}
          </b-table-column>

          <b-table-column
            field="ReplyAnswers"
            label="ReplyAnswers"
            v-slot="props"
            centered
          >
            <span
              v-for="item in props.row.steamReplyAnswers"
              :key="item.answerId"
              class="tag mr-2"
            >
              {{ item.answer }}
            </span>
          </b-table-column>

          <b-table-column v-slot="props">
            <b-field>
              <b-input
                placeholder="Reply..."
                v-model="dataReply[props.row.answerId]"
                type="search"
                icon="send"
                value=""
              >
              </b-input>
              <p class="control">
                <b-button
                  type="is-primary"
                  label="Reply"
                  @click="
                    onReplyMessage(
                      dataReply[props.row.answerId],
                      props.row.answerId
                    )
                  "
                />
              </p>
            </b-field>
          </b-table-column>

          <template #bottom-left>
            <b>Total checked</b>: {{ checkedRows.length }}
          </template>
        </b-table>
      </b-tab-item>

      <b-tab-item
        label="Selected Question"
        style="text-align: left; padding-left: 10%"
      >
        <!-- <pre>{{ checkedRows }}</pre> -->
        <!-- <pre>{{ checkedRows.firstName +" : "+ checkedRows.answer }}</pre> -->
        <div v-for="(row, i) in checkedRows" :key="row.answerId">
          <div>
            {{ i + 1 + ". " + row.answer + " : by K." + row.firstName }}
          </div>
        </div>
      </b-tab-item>
    </b-tabs>

    <div class="overflow-auto">
      <!-- <div>
        <h6>Small Pills</h6>
        <b-pagination
          v-model="currentPage"
          pills
          :total-rows="rows"
          size="sm"
        ></b-pagination>
      </div> -->

      <!-- <div class="mt-3">
        <h6>Default Pills</h6>
        <b-pagination
          v-model="currentPage"
          pills
          :total-rows="rows"
        ></b-pagination>
      </div> -->

      <!-- <div class="mt-3">
        <h6>Large Pills</h6>
        <b-pagination
          v-model="currentPage"
          pills
          :total-rows="rows"
          size="lg"
        ></b-pagination>
      </div> -->

      <!-- <VueTailwindPagination :current="currentPage" :total="total" :per-page="perPage" @page-changed="current = $event"/> -->
    </div>

    <div class="card-body">
      <paginate
        v-model="page"
        :page-count="totalPage"
        :page-range="3"
        :margin-pages="2"
        :click-handler="clickCallback"
        :prev-text="'Prev'"
        :next-text="'Next'"
        :container-class="'pagination'"
        :page-class="'page-item'"
      >
      </paginate>
    </div>

    <footer-component></footer-component>
  </div>
</template>

<script>
// import UserService from "@/service/UserService";
// import VueTailwindPagination from "@ocrv/vue-tailwind-pagination";

import axios from "axios";
import { store } from "@/store/store";
import FooterComponent from "./footer.vue";
import HeaderComponent from "./header.vue";
import Paginate from "vuejs-paginate";
import adminNavComponent from "./adminNav.vue";

import QAService from "@/service/QAService";
import UserService from "@/service/UserService";

export default {
  name: "post-request",
  components: {
    FooterComponent,
    HeaderComponent,
    Paginate,
    adminNavComponent,
    // VueTailwindPagination,
  },

  async created() {
    console.log("created");

    // get listStream
    this.getlistStream();

    this.checkSesseions();
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },

  data() {
    return {
      errors: [],

      userList: [],
      // currentPage: 1,
      btLoading: false,

      ///
      totalPage: 0,
      page: 1,

      //
      steamAnswers: [],

      steams: [],
      selected: "",
      channel: "",
      questionIdDefault: 0,

      dataReply: [],

      checkedRows: [],

      timer: "",
    };
  },
  mounted() {},

  computed: {},

  watch: {},
  filters: {},

  methods: {
    checkSesseions() {
      /// check session ///

      UserService.keepAlive(this.$store.getters.token);

      let token = store.getters.token;

      if (!token) {
        this.$router.push({ path: "/" });
      }

      clearInterval(this.timer);
      this.timer = setInterval(() => {
        UserService.keepAlive(this.$store.getters.token);

        // update  Q&A 
        this.getquestionIdDefault(this.channel, 1);

        if (!this.$store.getters.isLogin) {
          // alert(" seesion expire please login again");
          clearInterval(this.timer);
          this.$router.push({ path: "/" });
        }
      }, 5000);

      /// check session ///
    },

    onReplyMessage(message, answerId) {
      // alert(message + " : onReplyMessage : " + answerId);

      // questionIdDefault, answer,replyToId
      QAService.saveEvaluationQuestion(
        this.questionIdDefault,
        message,
        answerId
      );
      this.dataReply[answerId] = "";

      // document.getElementById("#dataReplyId").value = "";
      //  self.data.form[key] = false;
      // this.input = "";

      // this.getquestionIdDefault(this.channel, 1);
    },

    onSelected(streamObject) {
      // alert(streamObject.streamName);
      this.selected = streamObject.name;
      this.channel = streamObject.streamName;

      store.commit("SET_CHANNEL", this.channel);

      // this.getQuestionPoll(this.channel);
      this.getquestionIdDefault(this.channel, 1);

    
    },

    clickCallback: function (pageNum) {
      console.log("PAGE : " + pageNum);
      // alert(pageNum);

      // this.getUser("@", pageNum);
      // this.getUser("@", pageNum);

      // this.getQuestionStream(pageNum);
      // this.page = pageNum;
      this.getquestionIdDefault(this.channel, pageNum);
    },

    success(msg) {
      this.$buefy.notification.open({
        message: msg,
        type: "is-success",
        duration: 5000,
      });
    },
    printObject(obj) {
      console.log("printObject : " + JSON.stringify(obj, null, 2));
    },

    changeEnableUser(user, status) {
      console.log("changeEnableUser : " + user + " to status : " + status);
      this.updateStatusUser(user, status);

      // update user status
    },

    changeEnableCertified(user, status) {
      console.log("changeEnableCertified : " + user + " to status : " + status);
      this.updateCertifiedUser(user, status);

      // update user status
    },
    pageChangeHandler(selectedPage) {
      this.currentPage = selectedPage;
    },

    onSubmit() {
      this.$emit("submit", this.model);
    },
    doSomethings(val) {
      console.clear();
      console.log(val);
      alert(val);
    },
    changeRating(value) {
      console.log("===  changeRating ===  " + value);
      // console.log("===  dataRating ===  " + dataRating[item.pollId]);

      // map

      // console.log("===  changeRating ===  " + this.dataRating[value]);

      // this.$buefy.toast.open({
      //   message: "Thanks for you Rate!",
      //   type: "is-success",
      // });
    },
    goLoginPage() {
      // alert("goRegisterPage");
      this.$router.push({ path: "/" });
    },
    goStremPage(code) {
      console.log("Conference code : " + code);
      // alert("goRegisterPage");
      this.$router.push({ path: "/StreamPage/" + code, code });
    },

    getTestFn() {
      console.log("===  getTestFn ===  ");
    },

    // 1.get strem channel
    // get DDL

    // https://dev.catsmodish.com/api/v1/virtual/conference-stream-list
    // API_CF_06 Get conference stream list
    getlistStream() {
      const url = store.getters.url + "/api/v1/virtual/conference-stream-list";
      const token = store.getters.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      let bodyParameters = {
        // "username": user.username,
        // "password": user.password,
      };

      console.log("url : " + JSON.stringify(url, null, 2));
      console.log(
        "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      );
      console.log("config : " + JSON.stringify(config, null, 2));

      axios
        .get(url, config)
        .then((response) => {
          // your code
          if (response.data.status == "success") {
            console.log(
              " getlistStream response.data : " +
                JSON.stringify(response.data, null, 2)
            );
            store.commit("setSteams", response.data.steams);
            this.steams = response.data.steams;

            for (let i = 0; i < this.steams.length; i++) {
              console.log(
                " getlistStream loop : " +
                  JSON.stringify(this.steams[i], null, 2)
              );
              let streamObject = this.steams[i];

              if (this.selected == "") {
                // intital

                this.selected = streamObject.name;
                this.channel = streamObject.streamName;
                this.getquestionIdDefault(this.channel, this.page);
                // this.getQuestionPoll(this.channel);
              }
            }

            // for (let streamObject in this.steams) {
            //   console.log(
            //     " getlistStream loop : " +
            //       JSON.stringify(streamObject[0], null, 2)
            //   );
            //   if (this.selected == "") {
            //     this.selected = streamObject.name;
            //     this.channel = streamObject.streamName;
            //   }
            // }
            // "response.data.steams : " + JSON.stringify(this.steams, null, 2)
          }
          // this.articleId = response.data.token;
        }, console.log)
        .catch(console.log);

      // return {test : "test" };
    },

    // get id  What's is you question?
    // API_QA_04 Get stream question list
    // https://dev.catsmodish.com/api/v1/virtual/stream-question-list/S1612877527

    async getquestionIdDefault(streamCode, page) {
      // console.log("getChoiceRating voteId : " + voteId);
      const url =
        store.getters.url +
        "/api/v1/virtual/stream-question-list/" +
        streamCode;
      const token = store.getters.token;

      let dataReturn = 0;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      // let bodyParameters = {
      //   // "username": user.username,
      //   // "password": user.password,
      // };

      // console.log("url : " + JSON.stringify(url, null, 2));
      // console.log(
      //   "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      // );
      // console.log("config : " + JSON.stringify(config, null, 2));

      await axios
        .get(url, config)
        .then((response) => {
          // your code

          if (response.data.status == "success") {
            console.log(
              "response.data.steamQuestions : " +
                JSON.stringify(response.data.steamQuestions, null, 2)
            );
            // let steamQuestions = response.data.steamQuestions;

            // this.filtered = this.steams.filter(
            //   (element) => element.streamName === this.$route.params.id
            // );
            let filter = "What's you question?";
            // let filter = "Test create stream question 1";

            const steamQuestions = response.data.steamQuestions;

            const temp = steamQuestions.filter((steamQuestion) =>
              steamQuestion.question.includes(filter)
            );

            this.questionIdDefault = temp[0].questionId;
            dataReturn = temp[0].questionId;

            // set in state

            store.commit("SET_QUESTION_ID_DEFAULT", this.questionIdDefault);

            this.getQuestionStream(streamCode, this.questionIdDefault, page);

            // console.log(
            //   "temp.data : " + JSON.stringify(this.questionIdDefault, null, 2)
            // );

            return this.questionIdDefault;
          }
          // this.articleId = response.data.token;
        }, console.log)
        .catch(console.log);

      // return response.data.conferenceEvaluations;
      return dataReturn;
    },

    // https://dev.catsmodish.com/api/v1/virtual/conference-stream-list
    // API_CF_06 Get conference stream list
    // getlistStream() {
    //   const url = store.getters.url + "/api/v1/virtual/conference-stream-list";
    //   const token = store.getters.token;

    //   const config = {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //       "x-conference-code": store.getters.conferenceCode,
    //       "Content-Type": store.getters.ContentType,
    //     },
    //   };

    //   let bodyParameters = {
    //     // "username": user.username,
    //     // "password": user.password,
    //   };

    //   console.log("url : " + JSON.stringify(url, null, 2));
    //   console.log(
    //     "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
    //   );
    //   console.log("config : " + JSON.stringify(config, null, 2));

    //   axios
    //     .get(url, config)
    //     .then((response) => {
    //       // your code
    //       console.log("getlistStream: " + this.$route.params.id);
    //       if (response.data.status == "success") {
    //         console.log("getlistStream: success " + this.$route.params.id);
    //         console.log(
    //           "response.data : " + JSON.stringify(response.data, null, 2)
    //         );
    //         // store.commit("setSteams", response.data.steams);
    //         this.steams = response.data.steams;
    //         // console.log(
    //         //   "response.data.filtered : " +
    //         //     JSON.stringify(this.filtered, null, 2)
    //         // );

    //         this.filtered = this.steams.filter(
    //           (element) => element.streamName === this.$route.params.id
    //         );

    //         // this.urlStream = this.filtered.
    //         // if (!this.filtered.m3u8) {
    //         //   this.filtered["m3u8"] = this.filtered.streamUrl;
    //         // }

    //         // this.filtered["m3u8"] = this.filtered.streamUrl;
    //         // this.urlStream = this.steams.filter(
    //         //   (element) => element.streamName === this.$route.params.id
    //         // );
    //       }
    //       console.log(
    //         "response.data.filtered : " + JSON.stringify(this.filtered, null, 2)
    //       );
    //       // this.articleId = response.data.token;
    //     }, console.log)
    //     .catch(console.log);

    //   // return {test : "test" };
    // },

    // 2.get ans form channel
    // API_QA_06 Get stream question answer {streamName/questionId/pageNo}
    // https://service.promptevent.com/api/v1/virtual/stream-question-answer/S1613754744/2/1
    getQuestionStream(channel, questionIdDefault, page) {
      const url =
        store.getters.url +
        "/api/v1/virtual/stream-question-answer/" +
        channel +
        "/" +
        questionIdDefault +
        "/" +
        page;
      const token = store.getters.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      let bodyParameters = {
        // "username": user.username,
        // "password": user.password,
      };

      console.log("url : " + JSON.stringify(url, null, 2));
      console.log(
        "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      );
      console.log("config : " + JSON.stringify(config, null, 2));

      axios
        .get(url, config)
        .then((response) => {
          // your code
          if (response.data.status == "success") {
            console.log(
              "response.data : " + JSON.stringify(response.data, null, 2)
            );

            // this.steamAnswers = response.data.steamQuestion.steamAnswers;

            store.commit("SET_CHANNEL", channel);
            store.commit("SET_QUESTION_ID_DEFAULT", questionIdDefault);
            store.commit(
              "SET_ANSWER",
              response.data.steamQuestion.steamAnswers
            );

            this.steamAnswers = response.data.steamQuestion.steamAnswers.filter(
              (element) => element.answer != "cancel"
            );

            this.totalPage = response.data.steamQuestion.totalPage;

            console.log(
              "response.data.steamAnswers : " +
                JSON.stringify(this.steamAnswers, null, 2)
            );
          }

          // this.articleId = response.data.token;
        }, console.log)
        .catch(console.log);

      // return {test : "test" };
    },

    // get all user
    // API_USER04 Search user details (Admin)
    // https://service.promptevent.com/api/v1/virtual/user-search

    getUser(searchKey, pageNo) {
      const url = store.getters.url + "/api/v1/virtual/user-search";

      // console.log("url : " + JSON.stringify(url, null, 2));
      const token = store.getters.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      let bodyParameters = {
        searchKey: searchKey,
        pageNo: pageNo,
      };

      console.log("url : " + JSON.stringify(url, null, 2));
      console.log(
        "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      );
      console.log("config : " + JSON.stringify(config, null, 2));

      axios
        .post(url, bodyParameters, config)
        .then((response) => {
          // your code
          // this.articleId = response.data.token;
          console.log(
            "userList : " + JSON.stringify(response.data.users, null, 2)
          );
          this.userList = response.data.users;
          this.totalPage = response.data.totalPage;
          // this.currentPage = pageNo;
        }, console.log)
        .catch(console.log);
    },

    // update status enable user
    // API-USER05 Update user details (Admin)
    // https://service.promptevent.com/api/v1/virtual/user-update-admin

    updateStatusUser(username, status) {
      const url = store.getters.url + "/api/v1/virtual/user-update-admin";

      // console.log("url : " + JSON.stringify(url, null, 2));
      const token = store.getters.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      let bodyParameters = {
        username: username, // email
        enabled: status, // bool
      };

      console.log("url : " + JSON.stringify(url, null, 2));
      console.log(
        "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      );
      console.log("config : " + JSON.stringify(config, null, 2));

      axios
        .post(url, bodyParameters, config)
        .then(
          //   (response) => {
          //   // your code
          //   // this.articleId = response.data.token;
          //   console.log(
          //     "userList : " + JSON.stringify(response.data.users, null, 2)
          //   );
          //   this.userList = response.data.users;
          //   this.totalPage = response.data.totalPage;
          // },
          console.log
        )
        .catch(console.log);
    },

    // update Certified enable user
    // API-USER05 Update user details (Admin)
    // https://service.promptevent.com/api/v1/virtual/user-update-admin

    updateCertifiedUser(username, status) {
      const url = store.getters.url + "/api/v1/virtual/user-update-admin";

      // console.log("url : " + JSON.stringify(url, null, 2));
      const token = store.getters.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      let bodyParameters = {
        username: username, // email
        certified: status, // bool
      };

      console.log("url : " + JSON.stringify(url, null, 2));
      console.log(
        "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      );
      console.log("config : " + JSON.stringify(config, null, 2));

      axios
        .post(url, bodyParameters, config)
        .then(
          //   (response) => {
          //   // your code
          //   // this.articleId = response.data.token;
          //   console.log(
          //     "userList : " + JSON.stringify(response.data.users, null, 2)
          //   );
          //   this.userList = response.data.users;
          //   this.totalPage = response.data.totalPage;
          // },
          console.log
        )
        .catch(console.log);
    },

    // update Certified enable user
    // API-USER05 Update user details (Admin)
    // https://service.promptevent.com/api/v1/virtual/user-update-admin

    resetPasswordUser(username, newPassword) {
      newPassword = "Abcd12345";
      const url = store.getters.url + "/api/v1/virtual/user-update-admin";

      // console.log("url : " + JSON.stringify(url, null, 2));
      const token = store.getters.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      let bodyParameters = {
        username: username, // email
        newPassword: newPassword, // string
      };

      console.log("url : " + JSON.stringify(url, null, 2));
      console.log(
        "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      );
      console.log("config : " + JSON.stringify(config, null, 2));

      axios
        .post(url, bodyParameters, config)
        .then(
          //   (response) => {
          //   // your code
          //   // this.articleId = response.data.token;
          //   console.log(
          //     "userList : " + JSON.stringify(response.data.users, null, 2)
          //   );
          //   this.userList = response.data.users;
          //   this.totalPage = response.data.totalPage;
          // },
          console.log
        )
        .catch(console.log);
    },
  },
};
</script>
<style scoped>
.error {
  color: rgb(255, 0, 0);
}

table.text-center * {
  text-align: center;
}

.pagination {
}
.page-item {
}
</style>
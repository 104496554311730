
<template>
  <div class="card text-center m-3">
    <!-- <div :style="HeaderStyles"> -->
    <div class="footerCustom">
      <div class="columns">
        <div class="left-half">
          <div class="icon">
            <p class="heading">Home</p>
            <b-icon
              @click.native="goHomePage"
              icon="home-outline"
              style="cursor: pointer"
            ></b-icon>
          </div>

          <div class="icon" v-if="flag_fn_EA">
            <p class="heading">Evaluation</p>

            <!-- demo link -->
                 <!-- <b-icon              
              icon="format-list-checkbox"
              style="cursor: pointer"
            ></b-icon>  -->

            
            <!--  link out side EA-->
            <!-- <a
              :href="urlEA"
              target="_blank"
            >
              <b-icon
                icon="format-list-checkbox"
                style="cursor: pointer;"
                :style="{ color: templeteFontColor }"
              ></b-icon>
            </a> -->

            <!--  link inside EA System-->

            <!-- <b-icon
              @click.native="goEaPage"
              icon="format-list-checkbox"
              style="cursor: pointer"
            ></b-icon> -->

               <b-icon
              @click.native="goLinkEaPage"
              icon="format-list-checkbox"
              style="cursor: pointer"
            ></b-icon>

            

          </div>

          <!-- <div v-if="this.$store.getters.flag_fn_info"> -->
          <div v-if="this.$store.getters.flag_fn_info" class="icon">
            <!-- <div class="icon" style="visibility: hidden; display: none"> -->

            <p class="heading">Infomation</p>
            <b-icon
              icon="information-outline"
              @click.native="goInfoPage"
              style="cursor: pointer"
            ></b-icon>
          </div>
          <!-- </div> -->

          <div class="icon">
            <p class="heading">Logout</p>
            <b-icon
              @click.native="goLoginPage"
              icon="logout"
              style="cursor: pointer"
            ></b-icon>
          </div>
        </div>
      </div>

      <!-- <nav class="level">
        <div class="level-item has-text-centered">
          <div>
            <p class="heading">Home</p>
            <b-icon @click.native="goHomePage" icon="home-outline"></b-icon>
          </div>
        </div>
        <div class="level-item has-text-centered">
          <div>
            <p class="heading">Evaluation</p>
            <b-icon
              @click.native="goEaPage"
              icon="format-list-checkbox"
            ></b-icon>
          </div>
        </div>
        <div class="level-item has-text-centered">
          <div>
            <p class="heading">Infomation</p>
            <b-icon icon="information-outline"></b-icon>
          </div>
        </div>
        <div class="level-item has-text-centered">
          <div>
            <p class="heading">Logout</p>
            <b-icon @click.native="goLoginPage" icon="logout"></b-icon>
          </div>
        </div>
      </nav> -->

      <!-- <div class="footerstyle content has-text-centered" :style="{ backgroundImage: 'url(' + image + ')' }"> -->

      <div class="footerstyle content has-text-centered">
        <div>Copyright ©2020, All Rights Reserved.</div>
        <div>{{ footerData }}</div>

        <!-- <div class="content has-text-centered">
        <b-image class="logoImage" :src="logoImage"></b-image>
      </div> -->
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
export default {
  name: "footerName",
  components: {},
  data() {
    return {
      // urlEA : "https://docs.google.com/forms/d/1-6r7cm_jxVbneL7deme_kIdIKYevTN4qbYcRMdUfh2E/viewform?edit_requested=true",      
      // set on page >> RedirectEAPage

      image: require("@/assets/images/background.jpg"),
      footerData: "Powered by Prompt​ Event&Organize.",
      templeteColor: this.$store.getters.templeteBgColor,
      templeteFontColor: this.$store.getters.templeteFontColor,
      flag_fn_EA: this.$store.getters.flag_fn_EA,
      textColor: "blue",
      logoImage: require("@/assets/images/logo-footer.png"),
    };
  },
  async created() {},

  methods: {
    HeightStyles() {
      return {
        "background-image": 'url("' + this.image + '")',
        opacity: 0.7,
        "background-size": "cover",
      };
    },
    goHomePage() {
      // alert("goRegisterPage");
      this.$router.push({ path: "/HomePage" });
    },

    goInfoPage() {
      // alert("goRegisterPage");
      this.$router.push({ path: "/InfoPage" });
    },

    goLoginPage() {
      // alert("goRegisterPage");
      window.localStorage.clear();
      this.$router.push({ path: "/" });

      //        window.localStorage.clear();
      //  this.$router.push('/login');
    },
    goEaPage() {
      // alert("goRegisterPage");
      this.$router.push({ path: "/EvaluationPage" });
    },

    goLinkEaPage() {
      // alert("goRegisterPage");
      // this.$router.push({ path: "/RedirectEAPage" });

      let route = this.$router.resolve({path: '/RedirectEAPage'});
      // let route = this.$router.resolve('/link/to/page'); // This also works.
      window.open(route.href, '_blank');
    },
    

    mounted() {
      console.log("mounted");
    },
  },
};
</script>
<style scoped>
.footerCustom {
  /* height: 625px;  */

  /* background-image: var(--image);   */

  /* background-image: url("/assets/images/background.jpg");   */
  /* background-size: cover;
  opacity: 0.7; */

  /* background-color: rgb(20, 164, 141); */
  background-color: var(--templete-color);

  padding: 25px;
  /* color: rgb(255, 255, 255); */

  color: var(--templete-font-color);
  font-size: 0.8em;
}

/* Pattern styles */
.left-half {
  /* background-color: #ff9e2c; */
  display: inline-block;
  /* float: left; */
  width: 100%;
  text-align: center;
}
.right-half {
  /* background-color: #b6701e; */

  display: inline-block;
  /* float: left; */
  width: 30%;
}
.icon {
  display: inline-block;
  /* float: left; */
  width: 25%;
  /* margin: 25px; */
}

.logoImage {
  /* height: 20%; */
  width: 150px;

  margin: auto;
  /* opacity: 0; */
}
</style>

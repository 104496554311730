

// import QAService from "@/service/QAService";


export default {
    namespaced : true,


    state: {
        nameScanQR : "",
        messageErrorScanQR : "",

    },
    // setter กำหนดค่า ข้อมูล ลง state(store)
    mutations: {

       
        
        SET_nameScanQR(state, nameScanQR) {
            state.nameScanQR = nameScanQR; // กำหนดค่า state 

        },

        SET_MessageErrorScanQR(state, messageErrorScanQR) {
            state.messageErrorScanQR = messageErrorScanQR; // กำหนดค่า state 

        },       


    },


    getters: {
        // ดึงข้อมูล user จาก store ใส่ state  
        // steamAnswers: state => state.steamAnswers,

        // steamAnswers: state => state.steamAnswers.filter(
        //     (element) => element.enabled != false
        // ),

        // counterAllMessagetotal: state => state.counterChatMessagetotal + state.counterQAMessagetotal,

        nameScanQR: state => state.nameScanQR,
        messageErrorScanQR: state => state.messageErrorScanQR,
        
    },




}
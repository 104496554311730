
<template>
  <div class="card text-center m-3">
    <header-component></header-component>

    <admin-nav-component></admin-nav-component>

    <div class="card-body"><strong> Report Conference Evaluation </strong></div>

    <section class="container has-text-left is-primary">
      <b-button
        @click="downloadEA_QA"
        class="button bt"
        :loading="btLoading"
        :disabled="btLoading"
        expanded
        rounded
      >
        Download Report Conference Evaluation Question & Answer
      </b-button>

      <b-button
        @click="downloadEA_Poll"
        class="button bt"
        :loading="btLoadingPoll"
        :disabled="btLoadingPoll"
        expanded
        rounded
      >
        Download Report Conference Evaluation Poll
      </b-button>
    </section>

    <footer-component></footer-component>
  </div>
</template>

<script>
// import UserService from "@/service/UserService";

import { store } from "@/store/store";
import FooterComponent from "./footer.vue";
import HeaderComponent from "./header.vue";
import adminNavComponent from "./adminNav.vue";

// import QAService from "@/service/QAService";
import ReportService from "@/service/ReportService";
import UserService from "@/service/UserService";

export default {
  name: "post-request",
  components: {
    FooterComponent,
    HeaderComponent,

    adminNavComponent,
    // VueTailwindPagination,
  },

  async created() {
    console.log("created");
     
    this.checkSesseions();
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },

  data() {
    return {
      btLoading: false,
      btLoadingPoll: false,

      timer: "",
    };
  },
  mounted() {},

  computed: {},

  watch: {},
  filters: {},

  methods: {
    
    checkSesseions() {
      /// check session ///

      UserService.keepAlive(this.$store.getters.token);

      let token = store.getters.token;

      if (!token) {
        this.$router.push({ path: "/" });
      }

      clearInterval(this.timer);
      this.timer = setInterval(() => {
        UserService.keepAlive(this.$store.getters.token);

        if (!this.$store.getters.isLogin) {
          // alert(" seesion expire please login again");
          clearInterval(this.timer);
          this.$router.push({ path: "/" });
        }
      }, 5000);

      /// check session ///
    },

    async downloadEA_QA() {
      this.btLoading = true;
      // alert("downloadEA_QA" + this.$store.getters.token);

      let res = await ReportService.reportEvaluationQAStreaming();

      // alert(res);
      if (res == false) {
        this.btLoading = false;
        // alert(" complete  ");
      }
    },

    async downloadEA_Poll() {
      this.btLoadingPoll = true;
      // alert("downloadEA_Poll");

      let res = await ReportService.reportEvaluationPollStreaming();

      // alert(res);
      if (res == false) {
        this.btLoadingPoll = false;
        // alert(" complete  ");
      }
    },
  },
};
</script>
<style scoped>
table.text-center * {
  text-align: center;
}

.bt {
  /* background-color: rgb(20, 164, 141);
  color: white; */
  color: var(--templete-font-color);
  background-color: var(--templete-color);
  margin-top: 25px;
  margin-bottom: 25px;
}
</style>
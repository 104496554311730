
<template>
  <div class="card text-center m-3">
    <header-component></header-component>

    <admin-setting-nav-componentponent></admin-setting-nav-componentponent>

    <!-- {{ "questionId :  " + this.$store.getters.questionId }}
    {{ "channel :  " + this.$store.getters.channel }}
    {{ "steamAnswers :  " + this.$store.getters.steamAnswers }}
    {{
      "steamAnswersExculdeCancel :  " +
      this.$store.getters.steamAnswersExculdeCancel
    }} -->
    <!-- {{ "questionList :  " + this.questionList }} -->

    <div class="card-body"><strong> AdminManageQuestionPage </strong></div>

    <!-- v-on:change="changeEnableUser(props.row.username,props.row.enabled)"  -->
    <!-- @update="changeEnableUser(props.row.username,props.row.enabled)" -->

    <section>
      <b-field group-multiline grouped horizontal>
        <b-field label=" Please Channel : "> </b-field>
        <b-dropdown aria-role="list">
          <template #trigger="{ active }">
            <b-button
              :label="selected"
              type="is-primary"
              :icon-right="active ? 'menu-up' : 'menu-down'"
            />
          </template>
          <div v-for="item in steams" :key="item.streamName">
            <b-dropdown-item aria-role="listitem" @click="onSelected(item)">{{
              item.name
            }}</b-dropdown-item>
          </div>
        </b-dropdown>
      </b-field>
    </section>

    <div>
      <b-table has-text-centered :data="questionList">
        <b-table-column
          has-text-centered
          field="questionId"
          label="questionId"
          v-slot="props"
          centered
        >
          {{ props.row.questionId }}
        </b-table-column>
        <b-table-column
          has-text-centered
          field="question"
          label="question"
          v-slot="props"
          centered
        >
          {{ props.row.question }}
        </b-table-column>

        <b-table-column field="opened" label="opened" v-slot="props" centered>
          <!-- {{ props.row.enabled }} -->
          <b-switch
            v-model="props.row.opened"
            @input="changeStatusQuestion(channel, props.row)"
          >
            enabled</b-switch
          >
        </b-table-column>

        <b-table-column
          field="visible"
          label="visible "
          v-slot="props"
          centered
        >
          <!-- {{ props.row.enabled }} -->
          <b-switch
            v-model="props.row.visible"
            @input="changeStatusQuestion(channel, props.row)"
          >
            enabled</b-switch
          >
        </b-table-column>

        <b-table-column
          field="enabled"
          label="enabled "
          v-slot="props"
          centered
        >
          <!-- {{ props.row.enabled }} -->
          <b-switch
            v-model="props.row.enabled"
            @input="changeStatusQuestion(channel, props.row)"
          >
            enabled</b-switch
          >
        </b-table-column>
      </b-table>
    </div>

    <footer-component></footer-component>
  </div>
</template>

<script>
// import UserService from "@/service/UserService";
// import VueTailwindPagination from "@ocrv/vue-tailwind-pagination";

import axios from "axios";
import { store } from "@/store/store";
import FooterComponent from "./footer.vue";
import HeaderComponent from "./header.vue";
import adminSettingNavComponentponent from "./adminSettingNav.vue";

// import conferenceService from "@/service/ConferenceService";

// import QAService from "@/service/QAService";

export default {
  name: "post-request",
  components: {
    FooterComponent,
    HeaderComponent,
    adminSettingNavComponentponent,
    // VueTailwindPagination,
  },

  async created() {
    console.log("created");

    // get listStream
    this.getlistStream();

    let token = store.getters.token;

    if (!token) {
      this.$router.push({ path: "/" });
    }

    // console.log("getUser");
    // this.getUser("@", 1);
  },

  data() {
    return {
      questionList: [],

      userList: [],
      // currentPage: 1,
      btLoading: false,

      ///
      totalPage: 0,
      page: 1,

      //
      steamAnswers: [],

      steams: [],
      selected: "",
      channel: "",
      questionIdDefault: 0,

      dataReply: [],

      checkedRows: [],
    };
  },
  mounted() {},

  computed: {},

  watch: {},
  filters: {},

  methods: {
    changeStatusQuestion(channel, questionsObject) {
      console.log(
        "channel : " + channel + " to questionsObject : " + questionsObject
      );
      this.updateStatusQuestion(channel, questionsObject);
      // this.updateStatusUser(question, status);

      // update user status
    },

    formatDateInsert(dataDate) {
      // $luxon.fromSQL();
      return this.$luxon(dataDate, {
        input: { format: "yyyy-MM-dd HH:mm:ss", zone: "asia/bangkok" },
        output: { format: "yyyy-MM-dd'T'HH:mm:ss" },
      });
    },

    onSelected(streamObject) {
      // alert(JSON.stringify(streamObject, null, 2));
      this.selected = streamObject.name;
      this.channel = streamObject.streamName;

      store.commit("SET_CHANNEL", this.channel);
      this.getDataQuestion(this.channel);
      // streamObject.name;
    },

    clickCallback: function (pageNum) {
      console.log("PAGE : " + pageNum);
      // alert(pageNum);

      // this.getUser("@", pageNum);
      // this.getUser("@", pageNum);

      // this.getQuestionStream(pageNum);
      // this.page = pageNum;
      this.getquestionIdDefault(this.channel, pageNum);
    },

    success(msg) {
      this.$buefy.notification.open({
        message: msg,
        type: "is-success",
        duration: 5000,
      });
    },
    printObject(obj) {
      console.log("printObject : " + JSON.stringify(obj, null, 2));
    },

    pageChangeHandler(selectedPage) {
      this.currentPage = selectedPage;
    },

    onSubmit() {
      this.$emit("submit", this.model);
    },
    doSomethings(val) {
      console.clear();
      console.log(val);
      alert(val);
    },

    goLoginPage() {
      // alert("goRegisterPage");
      this.$router.push({ path: "/" });
    },

    // 1.get strem channel
    // get DDL

    // API_CF_10 Get conference stream list (Admin)
    // https://service.promptevent.com/api/v1/virtual/conference-stream-list-admin

    getlistStream() {
      const url =
        store.getters.url + "/api/v1/virtual/conference-stream-list-admin";
      const token = store.getters.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      let bodyParameters = {
        // "username": user.username,
        // "password": user.password,
      };

      console.log("url : " + JSON.stringify(url, null, 2));
      console.log(
        "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      );
      console.log("config : " + JSON.stringify(config, null, 2));

      axios
        .get(url, config)
        .then((response) => {
          // your code
          if (response.data.status == "success") {
            console.log(
              " getlistStream response.data : " +
                JSON.stringify(response.data, null, 2)
            );
            store.commit("setSteams", response.data.steams);
            this.steams = response.data.steams;

            for (let i = 0; i < this.steams.length; i++) {
              console.log(
                " getlistStream loop : " +
                  JSON.stringify(this.steams[i], null, 2)
              );
              let streamObject = this.steams[i];

              if (this.selected == "") {
                // intital

                this.selected = streamObject.name;
                this.channel = streamObject.streamName;
                // this.getquestionIdDefault(this.channel, this.page);
                // this.getQuestionPoll(this.channel);
              }
            }

            // for (let streamObject in this.steams) {
            //   console.log(
            //     " getlistStream loop : " +
            //       JSON.stringify(streamObject[0], null, 2)
            //   );
            //   if (this.selected == "") {
            //     this.selected = streamObject.name;
            //     this.channel = streamObject.streamName;
            //   }
            // }
            // "response.data.steams : " + JSON.stringify(this.steams, null, 2)
          }
          // this.articleId = response.data.token;
        }, console.log)
        .catch(console.log);

      // return {test : "test" };
    },

    // get all user
    // API_USER04 Search user details (Admin)
    // https://service.promptevent.com/api/v1/virtual/user-search

    // API_QA_04 Get stream question list
    // https://service.promptevent.com/api/v1/virtual/stream-question-list/S1613754744
    // https://service.promptevent.com/api/v1/virtual/stream-question-list/S1613754744
    getDataQuestion(channel) {
      const url =
        store.getters.url + "/api/v1/virtual/stream-question-list/" + channel;

      // console.log("url : " + JSON.stringify(url, null, 2));
      const token = store.getters.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      // let bodyParameters = {
      //   // searchKey: searchKey,
      //   // pageNo: pageNo,
      // };

      console.log("url : " + JSON.stringify(url, null, 2));
      // console.log(
      //   "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      // );
      // console.log("config : " + JSON.stringify(config, null, 2));

      axios
        .get(url, config)
        .then((response) => {
          // your code
          // this.articleId = response.data.token;
          console.log(
            "steamQuestions : " +
              JSON.stringify(response.data.steamQuestions, null, 2)
          );
          this.questionList = response.data.steamQuestions;
          // this.totalPage = response.data.totalPage;
          // this.currentPage = pageNo;
        }, console.log)
        .catch(console.log);
    },

    // update strem
    // https://service.promptevent.com/api/v1/virtual/conference-stream-update
    // API_CF_09 Update conference stream details

    updateChannel(stremInput) {
      const url =
        store.getters.url + "/api/v1/virtual/conference-stream-update";

      // console.log("url : " + JSON.stringify(url, null, 2));
      const token = store.getters.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      let bodyParameters = {
        name: stremInput.name,
        description: stremInput.description,
        beginDate: stremInput.beginDate,
        endDate: stremInput.endDate,
        streamName: stremInput.streamName,
        // streamUrl: "",
        streamPosterFileName: stremInput.streamPosterFileName,
        streamPosterBase64: stremInput.streamPosterBase64,
        enabled: stremInput.enabled,
      };

      console.log("url : " + JSON.stringify(url, null, 2));
      console.log(
        "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      );
      console.log("config : " + JSON.stringify(config, null, 2));

      axios
        .post(url, bodyParameters, config)
        .then(
          //   (response) => {
          //   // your code
          //   // this.articleId = response.data.token;
          //   console.log(
          //     "userList : " + JSON.stringify(response.data.users, null, 2)
          //   );
          //   this.userList = response.data.users;
          //   this.totalPage = response.data.totalPage;
          // },
          console.log
        )
        .catch(console.log);
    },

    // update status enable user
    // API-USER05 Update user details (Admin)
    // https://service.promptevent.com/api/v1/virtual/user-update-admin

    // update question
    // API_QA_02 Update stream question
    // https://service.promptevent.com/api/v1/virtual/stream-question-update

    updateStatusQuestion(channel, questionsObject) {
      const url = store.getters.url + "/api/v1/virtual/stream-question-update";

      // console.log("url : " + JSON.stringify(url, null, 2));
      const token = store.getters.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      let bodyParameters = {
        // username: username, // email
        // enabled: status, // bool

        streamName: channel,
        questionId: questionsObject.questionId,
        question: questionsObject.question,
        opened: questionsObject.opened,
        visible: questionsObject.visible,
        enabled: questionsObject.enabled,
      };

      console.log("url : " + JSON.stringify(url, null, 2));
      console.log(
        "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      );
      console.log("config : " + JSON.stringify(config, null, 2));

      axios
        .post(url, bodyParameters, config)
        .then(
          //   (response) => {
          //   // your code
          //   // this.articleId = response.data.token;
          //   console.log(
          //     "userList : " + JSON.stringify(response.data.users, null, 2)
          //   );
          //   this.userList = response.data.users;
          //   this.totalPage = response.data.totalPage;
          // },
          console.log
        )
        .catch(console.log);
    },

    // update Certified enable user
    // API-USER05 Update user details (Admin)
    // https://service.promptevent.com/api/v1/virtual/user-update-admin

    updateCertifiedUser(username, status) {
      const url = store.getters.url + "/api/v1/virtual/user-update-admin";

      // console.log("url : " + JSON.stringify(url, null, 2));
      const token = store.getters.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      let bodyParameters = {
        username: username, // email
        certified: status, // bool
      };

      console.log("url : " + JSON.stringify(url, null, 2));
      console.log(
        "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      );
      console.log("config : " + JSON.stringify(config, null, 2));

      axios
        .post(url, bodyParameters, config)
        .then(
          //   (response) => {
          //   // your code
          //   // this.articleId = response.data.token;
          //   console.log(
          //     "userList : " + JSON.stringify(response.data.users, null, 2)
          //   );
          //   this.userList = response.data.users;
          //   this.totalPage = response.data.totalPage;
          // },
          console.log
        )
        .catch(console.log);
    },

    // update Certified enable user
    // API-USER05 Update user details (Admin)
    // https://service.promptevent.com/api/v1/virtual/user-update-admin

    resetPasswordUser(username, newPassword) {
      newPassword = "Abcd12345";
      const url = store.getters.url + "/api/v1/virtual/user-update-admin";

      // console.log("url : " + JSON.stringify(url, null, 2));
      const token = store.getters.token;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "x-conference-code": store.getters.conferenceCode,
          "Content-Type": store.getters.ContentType,
        },
      };

      let bodyParameters = {
        username: username, // email
        newPassword: newPassword, // string
      };

      console.log("url : " + JSON.stringify(url, null, 2));
      console.log(
        "bodyParameters : " + JSON.stringify(bodyParameters, null, 2)
      );
      console.log("config : " + JSON.stringify(config, null, 2));

      axios
        .post(url, bodyParameters, config)
        .then(
          //   (response) => {
          //   // your code
          //   // this.articleId = response.data.token;
          //   console.log(
          //     "userList : " + JSON.stringify(response.data.users, null, 2)
          //   );
          //   this.userList = response.data.users;
          //   this.totalPage = response.data.totalPage;
          // },
          console.log
        )
        .catch(console.log);
    },
  },
};
</script>
<style scoped>
.error {
  color: rgb(255, 0, 0);
}

table.text-center * {
  text-align: center;
}

.pagination {
}
.page-item {
}
</style>